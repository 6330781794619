import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-add-sub-product-model',
  templateUrl: './add-sub-product-model.component.html',
  styleUrls: ['./add-sub-product-model.component.scss']
})
export class AddSubProductModelComponent implements OnInit {
  
  constructor(public db : DatabaseService, public dialogRef: MatDialog, public dialogAlert : DialogComponent,  @Inject(MAT_DIALOG_DATA) public stored_data :any) { }
  
  ngOnInit() {
    this.data = Object.assign({},this.stored_data.data);
    if(this.data.submodel)
    {
      this.data.submodel = '';
    }
    console.log(this.data);
    setTimeout(() => {
      this.getProductBrand();
      this.getProductModel();
    }, 300);
  }
  
  
  brandList:any = [];
  getProductBrand()
  {
    console.log('*** Product Brand List ***');
    this.db.FetchData({},'Master_Product/getProductBrand')
    .subscribe((result:any)=>{
      console.log(result);  
      this.brandList = result['brand'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  
  modelList:any = [];
  getProductModel()
  {
    console.log('*** Product Model List ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data},'Master_Product/getProductModel')
    .subscribe((result:any)=>{
      console.log(result);  
      this.modelList = result['model'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  subModelExist:any = false;
  checkSubModelExist()
  {
    console.log('*** CHECK SUB MODEL EXIST ***');
    this.db.FetchData({'data':this.data},'Master_Product/checkSubModelExist')
    .subscribe((result:any)=>{
      console.log(result);  
      this.subModelExist = result['exist'];
      console.log(this.subModelExist);
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  
  
  clearForm(myForm:any)
  {
    myForm.resetForm();
  }
  
  data:any = {};
  submitProductSubModel(myForm:any)
  {
    console.log('*** SUBMIT PROUCT SUB MODEL ***');
    console.log(this.data);
    
    this.db.FetchData({'data' : this.data},'Master_Product/submitProductSubModel')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialogAlert.success('Product Sub Model Data','Successfully Saved !!!');
        this.clearForm(myForm)
      }
      else
      {
        var error = result['error']['message']?result['error']['message']:'Something went wrong... Try Again !!!';
        this.dialogAlert.error(error);
      }
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
    
  }
  
  
}
