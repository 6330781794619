import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { VendorInformationModalComponent } from '../vendor-information-modal/vendor-information-modal.component';
import { ContactPersonModalComponent } from '../contact-person-modal/contact-person-modal.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-detail-vendor',
  templateUrl: './detail-vendor.component.html',
  styleUrls: ['./detail-vendor.component.scss']
})
export class DetailVendorComponent implements OnInit {

  list_data: any =[]
  ecrpt_id:any = '';
  skeleton_screen_active:any = false;
  currentDate:any;
  calenderMinDate:any;

  constructor(public db : DatabaseService, public dialogAlert : DialogComponent, public session : SessionService, public route : ActivatedRoute, public dialog : MatDialog) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log(this.ecrpt_id);
    });
      this.currentDate = new Date();

  }

  ngOnInit() {
    this.list_data = new Array(20);
    this.getVendorDetail();
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 14||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};

  vendorDetail:any = {};
  vendorContact:any = [];

  getVendorDetail()
  {
    this.skeleton_screen_active = true;
    this.db.FetchData({'vendor_id' : this.ecrpt_id},'Master_Vendor/getVendorDetail')
    .subscribe((result:any)=>{
      console.log(result);
      console.log('*** VENDOR DETAIL ***');
      this.vendorDetail = result['detail'];
      this.vendorContact = result['contact'];
      this.getVendorComplaintList();
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 300);

    },error=>{
      console.log(error);
      this.dialogAlert.error('Something Went wrong, Try Again !!!');
    });
  }



  data_not_found:any = false;
  pageLimit:any =20;
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pageNumber:any;
  pageNumberTemp:any;
  complaintList:any = [];
  countData:any={};

  getVendorComplaintList(pageLimit:any=20,start:any=0,status:any='Open',action:any='refresh',)
  {
    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;
    this.search.status = status;
    this.search.vendor_id = this.vendorDetail.id;

    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'vendorComplaint/getVendorComplaintList')
    .subscribe((result:any)=>{
      console.log(result);
      this.complaintList = result['complaint'];
      this.total_count = result['count'];
      this.countData = result['countData'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
      this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;

      console.log(this.pageNumber);
      if(this.complaintList.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }




 back()
    {
      window.history.go(-1);
console.log(window.history)


    }
  data:any = {};
  vendorDialog(): void {
    this.data = Object.assign({},this.vendorDetail);
    const dialogRef = this.dialog.open(VendorInformationModalComponent, {
      width: '768px',
      data : {
        data : this.data,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getVendorDetail();
    });
  }

  contactPersonList:any = [];
  modalData:any = {};
  contactDialog(): void {
    this.contactPersonList = Object.assign([],this.vendorContact);
    this.data = Object.assign({},this.vendorDetail);
    this.modalData.data = this.data;
    this.modalData.contact_data = this.contactPersonList;

    const dialogRef = this.dialog.open(ContactPersonModalComponent, {
      width: '768px',
      data : {
        data : this.modalData,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getVendorDetail();
    });
  }
  getListData(){

  }
}
