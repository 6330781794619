import { Component, OnInit } from "@angular/core";
import { DBRequest } from "../../databaseService";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogComponent } from "src/app/dialog";

import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { SnackBarOverview } from "src/app/toast";
import { DatabaseService } from "src/app/_services/database.service";

@Component({
  selector: "app-customer-product-add",
  templateUrl: "./customer-product-add.component.html",
  styleUrls: ["./customer-product-add.component.scss"],
})
export class CustomerProductAddComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;

  contactData: any = [];
  stateList: any = [];
  districtList: any = [];

  customerEncryptId: any = "";

  calenderMinDate: any;
  dateBirthMaxDate: any;
  currentDate: any;

  projectList: any = [];
  serviceCenterList: any = [];
  brandList: any = [];
  modelList: any = [];
  subModelList: any = [];
  warrantyList: any = [];

  transactionTypeList: any = [];

  isRequestInProcess: any = false;

  dropdownSettings: any = {};
  dropdownSettings1: any = {};
  dropdownSettings2: any = {};
  dropdownSettings3: any = {};
  dropdownSettings4: any = {};
  dropdownSettings5: any = {};
  dropdownSettings6: any = {};
  dropdownSettings7: any = {};
  productId: any;
  productDetail: any;

  constructor(
    private formBuilder: FormBuilder,
    public dbService: DBRequest,
    public redirect: Router,
    public route: ActivatedRoute,
    public toast: SnackBarOverview,
    public alert: DialogComponent,
    public db: DatabaseService
  ) {
    this.calenderMinDate = new Date("1950-01-01");
    this.currentDate = new Date();

    this.route.params.subscribe((params) => {
      console.log(params);
      this.customerEncryptId = params.customerId;

      console.log(this.customerEncryptId);
      this.productId = params.productId;
      console.log("*** PARAMETERs ***");
      console.log(params);
    });

    if (this.productId != "new") {
      this.getProductDetail();
    } else {
      this.productDetail = [];
    }
  }
  getProductDetail() {
    console.log("*** GET PRODUCT DETAIL ***");
    this.db
      .FetchData({ productId: this.productId }, "Project/getProductDetail")
      .subscribe((result: any) => {
        console.log(result);
        this.productDetail = result["productDetail"];
        console.log(this.productDetail);

        this.productDetail.project_name = [
          { project_name: this.productDetail.project_name },
        ];
        this.productDetail.brand = [{ brand: this.productDetail.brand }];
        this.productDetail.service_center = [
          {
            id: this.productDetail.service_center_id,
            name: this.productDetail.service_center_name,
          },
        ];
        this.productDetail.model = [{ model: this.productDetail.model }];
        this.productDetail.sub_model = [
          { submodel: this.productDetail.sub_model },
        ];
        this.productDetail.txn_type = [
          { transaction_type: this.productDetail.txn_type },
        ];

        if (this.productDetail.warranty) {
          if (this.productDetail.warranty.search(" - P") != -1) {
            console.log("entered1");

            let avr = this.productDetail.warranty.indexOf(" - P");
            this.productDetail.warranty = this.productDetail.warranty.substring(
              0,
              avr
            );
            this.productDetail.warranty =
              this.productDetail.warranty +
              " - PM " +
              this.productDetail.preventive_interval;

            console.log(this.productDetail.warranty);
          } else {
            //  registerData[`unitType`] = registerData[`warranty`][0][`unitType`];
            //  registerData[`unit`] = registerData[`warranty`][0][`unit`];
            this.productDetail.preventive_interval = 0;
            this.productDetail.warranty = this.productDetail.warranty;
            console.log(this.productDetail.warranty);
          }
        }
        // this.productDetail.warranty=this.productDetail.warranty+' - PM '+this.productDetail.preventive_interval;
        this.productDetail.warranty = [
          { unitType: this.productDetail.warranty },
        ];
        console.log(this.productDetail.warranty);
      });
  }

  ngOnInit() {
    this.getProjectList();
    this.getServiceCenterList();
    this.getBrandList();
    this.getWarrantyList();
    this.getTransactioneTypeList();

    this.onValidateFormHandler();
    this.onDropDownSettingHandler();
  }

  fgList: any = [];
  addTolist() {
    let dateSupply = moment(this.registerForm.get("dateSupply").value).format(
      "YYYY-MM-DD"
    );
    let dateInstallation = moment(
      this.registerForm.get("dateInstallation").value
    ).format("YYYY-MM-DD");

    this.fgList.push({
      fgNo: this.registerForm.get("fgNo").value,
      dateSupply: dateSupply,
      dateInstallation: dateInstallation,
      transactionType: this.registerForm.get("transactionType").value,
      warranty: this.registerForm.get("warranty").value,
    });

    console.log(this.fgList);

    this.registerForm.patchValue({
      fgNo: "",
      dateSupply: "",
      dateInstallation: "",
      transactionType: "",
      warranty: "",
    });
  }

  onSubmit() {
    this.submitted = true;

    console.log(this.registerForm);
    if (this.registerForm.invalid) {
      this.registerForm.get("projectName").markAsTouched();
      this.registerForm.get("serviceCenter").markAsTouched();
      this.registerForm.get("brandName").markAsTouched();
      this.registerForm.get("modelName").markAsTouched();
      this.registerForm.get("subModelName").markAsTouched();
      this.registerForm.get("fgNo").markAsTouched();
      this.registerForm.get("soNo").markAsTouched();
      this.registerForm.get("siNo").markAsTouched();
      this.registerForm.get("dateSupply").markAsTouched();
      this.registerForm.get("dateInstallation").markAsTouched();
      this.registerForm.get("transactionType").markAsTouched();
      this.registerForm.get("warranty").markAsTouched();
      this.registerForm.get("documentLink").markAsTouched();
      this.registerForm.get("comment").markAsTouched();

      return;
    } else {
      const registerData = JSON.parse(JSON.stringify(this.registerForm.value));

      console.log(registerData);
      registerData[`dateSupply`] = moment(registerData[`dateSupply`]).format(
        "YYYY-MM-DD"
      );
      registerData[`dateInstallation`] = moment(
        registerData[`dateInstallation`]
      ).format("YYYY-MM-DD");
      registerData[`projectId`] = registerData[`projectName`][0][`id`];
      registerData[`projectName`] =
        registerData[`projectName`][0][`project_name`];

      registerData[`serviceCenterId`] = registerData[`serviceCenter`][0][`id`];
      registerData[`serviceCenterName`] =
        registerData[`serviceCenter`][0][`name`];

      registerData[`brandName`] = registerData[`brandName`][0][`brand`];
      registerData[`modelName`] = registerData[`modelName`][0][`model`];
      registerData[`subModelName`] =
        registerData[`subModelName`][0][`submodel`];

      console.log(registerData[`warranty`][0]);
      console.log(registerData[`warranty`][0]);
      // console.log(registerData[`warranty`][0][`unitType`].search("- PM"));

      if (registerData[`warranty`][0] && this.productId) {
        if (registerData[`warranty`][0][`unitType`].search("- PM") != -1) {
          console.log("entered");

          let avr = registerData[`warranty`][0][`unitType`].indexOf("- PM");
          registerData[`warranty`][0][`preventive_interval`] = registerData[
            `warranty`
          ][0][`unitType`].substring(avr + 5);
          registerData[`warranty`][0][`unitType`] = registerData[`warranty`][0][
            `unitType`
          ].substring(0, avr - 1);
          console.log(registerData);
        } else {
          //  registerData[`unitType`] = registerData[`warranty`][0][`unitType`];
          //  registerData[`unit`] = registerData[`warranty`][0][`unit`];
          registerData[`warranty`][0][`preventive_interval`] = 0;
          console.log(registerData);
        }
      }

      const fgdata = JSON.parse(JSON.stringify(this.fgList));

      for (var i = 0; i < fgdata.length; i++) {
        fgdata[i][`txnType`] =
          fgdata[i][`transactionType`][0][`transaction_type`];

        if (fgdata[i][`warranty`][0][`unitType`]) {
          console.log(fgdata[i][`warranty`][0][`unitType`]);

          if (fgdata[i][`warranty`][0][`unitType`].search(" PM") != 1) {
            let avr = fgdata[i][`warranty`][0][`unitType`].indexOf("- PM");
            fgdata[i][`unitType`] = fgdata[i][`warranty`][0][`unitType`];
            fgdata[i][`unit`] = fgdata[i][`warranty`][0][`unit`];
            fgdata[i][`warranty`][0][`preventive_interval`] = fgdata[i][
              `warranty`
            ][0][`unitType`].substring(avr + 5);
            fgdata[i][`warranty`][0][`unitType`] = fgdata[i][`warranty`][0][
              `unitType`
            ].substring(0, avr - 1);
            fgdata[i][`preventive_interval`] =
              fgdata[i][`warranty`][0][`preventive_interval`];
            console.log(fgdata);
          } else {
            fgdata[i][`warranty`][0][`preventive_interval`] = 0;
            fgdata[i][`unitType`] = fgdata[i][`warranty`][0][`unitType`];
            fgdata[i][`unit`] = fgdata[i][`warranty`][0][`unit`];
            fgdata[i][`preventive_interval`] = 0;

            console.log(fgdata);
          }
        } else {
          fgdata[i][`unitType`] = "";
          fgdata[i][`unit`] = "";
        }

        fgdata[i][`dateSupply`] = moment(fgdata[i][`dateSupply`]).format(
          "YYYY-MM-DD"
        );
        fgdata[i][`dateInstallation`] = moment(
          fgdata[i][`dateInstallation`]
        ).format("YYYY-MM-DD");
        console.log(fgdata);
      }

      registerData[`customerId`] = this.customerEncryptId;
      if (this.productId != "new") {
        registerData[`productId`] = this.productId;
      }

      const inputData = {
        data: registerData,
        data1: fgdata,
      };

      console.log(inputData);

      this.dbService
        .onPostRequestData(inputData, "project/onSubmitProjectProductData")
        .subscribe(
          (result) => {
            console.log(result);

            this.isRequestInProcess = false;

            if (result[`status`] == "error") {
              this.alert.error(result[`statusMessage`]);
            } else {
              this.redirect.navigate([
                "/customer-product-list/" + this.customerEncryptId + "",
              ]);
              setTimeout(() => {
                if (this.productId != "new") {
                  this.alert.success("Success", "Product Updated successfully");
                } else {
                  this.alert.success("Success", "Product Saved successfully");
                }
              }, 1000);
            }
          },
          (error) => {
            this.isRequestInProcess = false;
            this.toast.openError("Something Went Wrong, Try Later", "");

            // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
          }
        );
    }
  }

  getProjectList() {
    this.isRequestInProcess = true;

    const inputData = {
      customerId: this.customerEncryptId,
    };

    this.dbService
      .onPostRequestData(inputData, "project/getProjectList")
      .subscribe(
        (result) => {
          console.log(result);

          this.isRequestInProcess = true;
          this.projectList = result[`projectList`];
        },
        (error) => {
          // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        }
      );
  }

  getServiceCenterList() {
    this.isRequestInProcess = true;

    const inputData = {
      customerId: this.customerEncryptId,
    };

    this.dbService
      .onPostRequestData(inputData, "sharedData/getServiceCenterList")
      .subscribe(
        (result) => {
          console.log(result);

          this.isRequestInProcess = false;
          this.serviceCenterList = result[`serviceCenterList`];
        },
        (error) => {
          this.isRequestInProcess = false;
          // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        }
      );
  }

  getBrandList() {
    this.isRequestInProcess = true;

    const inputData = {
      customerId: this.customerEncryptId,
    };

    this.dbService
      .onPostRequestData(inputData, "sharedData/getBrandList")
      .subscribe(
        (result) => {
          console.log(result);

          this.isRequestInProcess = false;
          this.brandList = result[`brandList`];
        },
        (error) => {
          this.isRequestInProcess = false;
          // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        }
      );
  }

  getModelList() {
    this.isRequestInProcess = true;

    this.modelList = [];
    this.subModelList = [];

    this.registerForm.get("modelName").setValue([]);
    this.registerForm.get("subModelName").setValue([]);

    let brandName = "";
    if (this.registerForm.value[`brandName`].length > 0) {
      brandName = this.registerForm.value[`brandName`][0][`brand`];
    } else {
      return false;
    }

    const inputData = {
      customerId: this.customerEncryptId,
      brandName: brandName,
    };

    this.dbService
      .onPostRequestData(inputData, "sharedData/getBrandModelList")
      .subscribe(
        (result) => {
          console.log(result);

          this.isRequestInProcess = false;
          this.modelList = result[`modelList`];
        },
        (error) => {
          this.isRequestInProcess = false;
          // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        }
      );
  }

  getSubModelList() {
    this.isRequestInProcess = true;

    this.subModelList = [];
    this.registerForm.get("subModelName").setValue([]);

    let brandName = "";
    let modelName = "";
    if (
      this.registerForm.value[`brandName`].length > 0 &&
      this.registerForm.value[`modelName`].length > 0
    ) {
      brandName = this.registerForm.value[`brandName`][0][`brand`];
      modelName = this.registerForm.value[`modelName`][0][`model`];
    } else {
      return false;
    }

    const inputData = {
      customerId: this.customerEncryptId,
      brandName: brandName,
      modelName: modelName,
    };

    this.dbService
      .onPostRequestData(inputData, "sharedData/getModelSubModelList")
      .subscribe(
        (result) => {
          console.log(result);
          this.isRequestInProcess = false;
          this.subModelList = result[`subModelList`];
        },
        (error) => {
          this.isRequestInProcess = false;
          // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        }
      );
  }

  preventive_interval: any = [];
  getWarrantyList() {
    this.isRequestInProcess = true;

    const inputData = {
      customerId: this.customerEncryptId,
    };

    this.dbService
      .onPostRequestData(inputData, "sharedData/getWarrantyList")
      .subscribe(
        (result) => {
          console.log(result);

          this.isRequestInProcess = false;
          this.warrantyList = result[`warrantyList`];

          for (let index = 0; index < this.warrantyList.length; index++) {
            if (
              this.warrantyList[index].unitType &&
              this.warrantyList[index].preventive_interval != 0
            ) {
              this.warrantyList[index].unitType =
                this.warrantyList[index].unitType +
                " - PM " +
                this.warrantyList[index].preventive_interval +
                " " +
                this.warrantyList[index].preventive_interval_type;
            } else {
              this.warrantyList[index].unitType =
                this.warrantyList[index].unitType;
            }
          }
          //  for (let index = 0; index < this.warrantyList.length; index++) {

          //  this.warrantyList[index].preventive_interval = this.warrantyList[index].preventive_interval+' '+this.warrantyList[index].preventive_interval_type;
          //   this.preventive_interval.push(this.warrantyList[index].preventive_interval);
          // }
          // console.log(this.preventive_interval);
        },
        (error) => {
          this.isRequestInProcess = false;
          // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        }
      );
  }

  getTransactioneTypeList() {
    this.isRequestInProcess = true;

    const inputData = {
      customerId: this.customerEncryptId,
    };

    this.dbService
      .onPostRequestData(inputData, "sharedData/getTransactioneTypeList")
      .subscribe(
        (result) => {
          console.log(result);

          this.isRequestInProcess = false;
          this.transactionTypeList = result[`transactionTypeList`];
          console.log(this.transactionTypeList);
        },
        (error) => {
          this.isRequestInProcess = false;
        }
      );
  }

  onValidateFormHandler() {
    this.registerForm = this.formBuilder.group({
      projectName: ["", [Validators.required]],
      serviceCenter: ["", Validators.required],
      brandName: ["", Validators.required],
      modelName: ["", Validators.required],
      subModelName: ["", Validators.required],
      fgNo: [""],
      soNo: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      siNo: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      dateSupply: [""],
      dateInstallation: [""],
      transactionType: [""],
      warranty: [""],
      documentLink: ["", [Validators.minLength(3)]],
      comment: ["", [Validators.minLength(3)]],
    });
  }

  onDropDownSettingHandler() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "project_name",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings1 = {
      singleSelection: true,
      idField: "id",
      textField: "name",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings2 = {
      singleSelection: true,
      idField: "brand",
      textField: "brand",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings3 = {
      singleSelection: true,
      idField: "model",
      textField: "model",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings4 = {
      singleSelection: true,
      idField: "submodel",
      textField: "submodel",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings5 = {
      singleSelection: true,
      idField: "transaction_type",
      textField: "transaction_type",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings6 = {
      singleSelection: true,
      idField: "unit",
      textField: "unitType",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };

    this.dropdownSettings7 = {
      singleSelection: true,
      idField: "preventive_interval",
      textField: "preventive_interval",
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true,
    };
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  get f() {
    return this.registerForm.controls;
  }
}
