import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-commissioning-list',
  templateUrl: './commissioning-list.component.html',
  styleUrls: ['./commissioning-list.component.scss']
})
export class CommissioningListComponent implements OnInit {

  skeleton_data:any =[]
  skeleton_screen_active:any= false;
  checkData : any ='';
  data:any={};
  constructor(public db:DatabaseService, public dialog : DialogComponent, public session : SessionService) { }

  ngOnInit() {
    this.skeleton_data = new Array(5);

    this.getCommissioningCheckList();
    this.getProductModel();
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 17||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }

  access_rights:any = {};

  modelList:any = [];
  getProductModel()
  {
    console.log('*** Product Model List ***');
    this.db.FetchData({},'Master_Product/getProductModel')
    .subscribe((result:any)=>{
      console.log(result);
      this.modelList = result['model'];
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
  }


  dataExist:any = false;
  checkCommissionListExist()
  {
    this.dataExist = false;
    console.log('*** CHECK COMMISSION LIST EXIST ***');
    this.db.FetchData({'data':this.data},'Master_Checklist/checkCommissionListExist')
    .subscribe((result:any)=>{
      console.log(result);

      if(result['exist'] == 1){
        this.dataExist = true;
      }
      else{
        this.dataExist = false;
      }

    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
  }




  data_not_found:any = false;
  pageLimit:any =20
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pagenumber:any;
  pageNumberTemp:any;
  checkList:any = [];

  getCommissioningCheckList(pageLimit:any=20,start:any=0,)
  {

    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;

    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'Master_Checklist/getCommissioningCheckList')
    .subscribe((result:any)=>{
      console.log(result);
      this.checkList = result['checklist'];
      this.total_count = result['count'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);

      console.log(this.total_page);
      this.pagenumber = Math.ceil((this.start)/(this.pageLimit))+1;
      this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pageNumberTemp);
      if(this.checkList.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }

      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }

  clearForm(myForm:any)
  {
    this.data ={};
    this.commissioningCheckList = [];
    myForm.resetForm();
  }


  formLoading:any = false;
  submitCheckList(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Submit Check List ***');
    console.log(this.data);
    console.log(this.commissioningCheckList);

    this.db.FetchData({'data':this.commissioningCheckList, 'session' : this.session.trico_user},'Master_Checklist/submitCheckList')
    .subscribe((result:any)=>{
      console.log(result);
      this.dialog.success('Check List Data','Successfully Saved !!!');
      this.clearForm(myForm);
      this.getCommissioningCheckList();
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });

  }

  editCheckList(check_id:any)
  {
    console.log('*** EDIT CHECK LIST ***');
    console.log('Check ID -'+check_id);
    this.data = Object.assign({},this.checkList.filter( (x:any) => x.id == check_id)[0]);
    console.log(this.data);
  }

  commissioningCheckList = [];
  onAddCheckListHandler(){

    console.log(this.data);
    this.commissioningCheckList.push(this.data);
    console.log(this.commissioningCheckList);
    this.data = {};

  }

  removeCommissioningCheckList(index:any)
  {
    console.log(index);
    this.commissioningCheckList.splice(index,1);
  }


  deleteCheckList(check_id:any,index:any){
    console.log(check_id);
    console.log(index);
    this.dialog.delete('Check List Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':check_id}, 'Master_Checklist/deleteCheckList')
        .subscribe(res => {
          console.log(res);
          this.checkList.splice(index,1);
          this.dialog.success('Deleted','Check List Data has been deleted.');
          this.getCommissioningCheckList();
        },err=>{
          console.log(err);
          this.dialog.error('Something went wrong! Try Again ...');
        });
      }
    });
  }


  updateCheckList(myForm:any)
  {
    console.log('*** Update Check List ***');
    this.formLoading = true;
    console.log(this.data);
    this.db.FetchData({'data':this.data},'Master_Checklist/updateCheckList')
    .subscribe((result:any)=>{
      console.log(result);
      this.dialog.success('Check List Data','Successfully Updated !!!');
      this.clearForm(myForm);
      this.formLoading = false;
      this.getCommissioningCheckList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });

  }


}
