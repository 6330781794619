import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-contact-person-modal',
  templateUrl: './add-contact-person-modal.component.html',
  styleUrls: ['./add-contact-person-modal.component.scss']
})
export class AddContactPersonModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
