import { Component } from '@angular/core';
import { DatabaseService } from './_services/database.service';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { SessionService } from './session.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tricolite';
  trico_user:any;
  constructor(public db: DatabaseService, public session:SessionService,public router : Router,public route : Router,private bnIdle: BnNgIdleService) { 
    console.log('*** APP COMPONENT LOADED ***');
  }
  ngOnInit():void {
    this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        console.log('session expired');
        this.session.LogOutSession();
        this.router.navigate([''])
        this.trico_user = JSON.parse(localStorage.getItem('trico_user')) || [];
        console.log(this.trico_user);

     this.trico_user = {};
    this.trico_user.tri_login = false;
    this.db.can_active = '';
    localStorage.removeItem('trico_user');
    localStorage.removeItem('st_user');
    localStorage.removeItem('login');
    localStorage.removeItem('users');
        
    console.log(this.route.url.includes);

      }
    });
  }
}
 
  

