import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-customerfeedbackreport',
  templateUrl: './customerfeedbackreport.component.html',
  styleUrls: ['./customerfeedbackreport.component.scss']
})
export class CustomerfeedbackreportComponent implements OnInit {
  searchData: any = {};
  start: any = 0;
  pageLimit: any = 100;
currentDate:any;
calenderMinDate:any;
total_page: any = 0;
  pageNumberTemp: any = 0;
  constructor(public db: DatabaseService) {
    this.getCustomerFeedbackReport('','','');
  this.currentDate = new Date();

   }

  ngOnInit() {
    this.getCustomerFeedbackReport('','','');
  }

  data: [];
  getCustomerFeedbackReport(pageLimit:any=20,start:any=0,searchData){
    console.log(searchData);
    if (this.searchData[`date_from`]) {
       this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
     }
     if (this.searchData[`date_to`]) {
       this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
     }
     this.pageLimit = pageLimit;
     this.start = start;

     this.db.FetchData({
       start: this.start,
       pageLimit: this.pageLimit,
       searchData: this.searchData,
       },'customer/filter_feedback_data')
     .subscribe((result:any)=>{
       console.log(result);
this.data= result;
console.log(this.data);
     });
   }

   fileName= 'Customer-Feedback-Report.xlsx';
   exp_data:any=[];

   exportexcel(): void  {
     let Index=1;
      let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
   }
   getPreventiveMaintenanceList(pageLimit,start){

   }

}
