import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatCheckboxChange } from '@angular/material';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-rating-feedback',
  templateUrl: './rating-feedback.component.html',
  styleUrls: ['./rating-feedback.component.scss']
})
export class RatingFeedbackComponent implements OnInit {

  data: any ={};
  seasons: string[] = ['YES', 'NO'];

  selectedRating: any = '';
  type:any;
  feedbackArray = [];
  data1:any = {};
  paramDataArray:any;
  value:any={};
  remark:any={};
  rating2:any
  data_not_found:boolean=false;
  date1 = new FormControl(new Date())
  constructor(

    public db: DatabaseService,
    public route : ActivatedRoute,
    public router: Router,
    public dialogAlert: DialogComponent,

  ) {

    this.route.params.subscribe(params=>{
      console.log(params);
      this.type = params.type;
      console.log(this.type);
      this.paramDataArray = params;
      console.log(this.paramDataArray);



    });

   }
feedbackquestions=[{  question: 'Technical understanding of the Tender','rating':'' },{  question: 'Response Quality of the Team - Technically & Behaviourally','rating':''},{  question: 'Readiness of Product & Team on CI','rating':'' },{  question: 'Adherence to Delivery Date','rating':'' },{  question: 'Product Quality & Aesthetics','rating':'' },{  question: 'Customer centricity of Team','rating':'' },{  question: 'Overall Satsfaction with the CI','rating':'' }

]
  ngOnInit() {
   this.onGetCustomerDetail()
  }

  onStarClickHandler(rating) {
    this.selectedRating = rating;
    console.log( this.selectedRating);

  }
  selectedRating1:any=[];
  onStarClickHandler1(rating,index) {
    this.rating2=rating

    this.feedbackquestions[index].rating = rating;

    console.log( this.selectedRating1);
    console.log(this.feedbackquestions[index].rating);
    // this.rating2=this.feedbackquestions[index].rating
    console.log(this.rating2)


  }

  showOptions(event, value) {

    console.log(value);


    if (event.checked) {

      this.feedbackArray.push(value);
      console.log(this.feedbackArray);

    }
    if (!event.checked) {

      let index = this.feedbackArray.indexOf(value);

      if (index > -1) {

        this.feedbackArray.splice(index, 1);
      }
    }
  }

  clearForm(myForm:any)
  {
      myForm.resetForm();
      this.data = {};
      this.selectedRating = {};

  }
  dates:any=[];
  formLoading:any = false;
  feedbackData:any=[];
    onGetCustomerDetail() {


    this.db.FetchData({

          id: this.paramDataArray.contact_name

    }, 'customer/getFeedbackInspectionDetail').subscribe(resultData => {

          console.log(resultData);

          setTimeout(() => {

                // this. StluserLIst()

          }, 500);



          this.feedbackData = resultData[`feedbackData`];
         console.log(this.feedbackData);

    }, error => {

    });

}




  onSubmitOrderClosure(myForm:any){

    console.log(this.data);

    console.log(this.selectedRating);

    console.log(this.paramDataArray);
    if(this.type =='004')
    {
      this.data.id = this.paramDataArray.contact_name;
    }
    else{
    this.data.paramDataArray = this.paramDataArray;
    }
console.log(this.data.paramDataArray);
    this.data.selectedRating = this.selectedRating;

console.log(this.date1.value);
this.dates = moment(this.date1.value).format('YYYY-MM-DD');
console.log(this.dates);

let api_url
 if(this.type =='004')
 {
   console.log(this.remark.season);

  // survey_id
  this.db.FetchData({'data':this.paramDataArray,'survey_id':this.data.id ,'feedback':this.feedbackquestions,'recommendation':this.remark.season,'remark':this.value.remarks},'customer/onSubmitFeedbackInspection')
  .subscribe((result:any)=>{

    console.log(result);

    if(result['msg'] == 'success')
    {
        this.dialogAlert.success('Customer Feedback','Successfully Saved');
        this.clearForm(myForm);
        this.formLoading = false;
        this.router.navigate(['/thanku']);
    }
    else{
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
    }

  });
 }
 else
 {
   api_url = 'customer/onSubmitOrderClosure'
   this.db.FetchData({'surveyData':this.data},'customer/onSubmitOrderClosure')
   .subscribe((result:any)=>{

     console.log(result);

     if(result['msg'] == 'success')
     {
         this.dialogAlert.success('Customer Feedback','Successfully Saved');
         this.clearForm(myForm);
         this.formLoading = false;
         this.router.navigate(['/thanku']);
     }
     else{
         this.dialogAlert.error('Something went wrong !!! Try again ...');
         this.formLoading = false;
     }

   });
 }




  }

}
