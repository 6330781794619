import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../_services/database.service';
import { SnackBarOverview } from '../toast';
import { Chart } from 'angular-highcharts';
import { SessionService } from '../session.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  skeleton_data: any = [];
  service_engineer: any = [];
  count_service_engineer: any = '';
  total_customer: any = '';
  total_customer_involve: any =''
  call_request: any = [];
  total_call_request: any = '';
  total_task: any = '';
  total_task_paid: any = '';
  total_task_free: any = '';
  total_task_commission: any = '';
  task_prior: any = [];
  task_title: any = [];
  task_title_name: any = [];
  task_title_nature:any=[];
  task_title_nature_name:any=[];
  active_login_id: any = [];
  unique_customer: any = [];

  chart1: Chart;
  chart2: Chart;
  chart3: Chart;

  formatted_date: any = '';
  months:any = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  current_datetime = new Date();
  
  
  constructor(public db:DatabaseService,public toast:SnackBarOverview,public session : SessionService) { }
  access_rights:any = {};
  
  ngOnInit() {
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 2)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
    this.DashboardData();
    console.log("Dashboard Page");
    this.skeleton_data = new Array(7)
    this.formatted_date =  this.months[this.current_datetime.getMonth()] + "-" + this.current_datetime.getFullYear();
    
  }
  
  skeleton_screen_active:any = false;
  
  DashboardData(){
    
    this.skeleton_screen_active = true;
    console.log("Dashboard Data Fetching");    
    this.db.FetchData({}, 'dashboard/getDashboardData').subscribe(resultData => {
          console.log("Dashboard Data Fetched");
            
          this.count_service_engineer=resultData['totalServiceEng'];
          this.service_engineer=resultData['serviceEngTask'];
          this.total_customer=resultData['totalCustomer'];
          this.total_customer_involve=resultData['totalCustomerInvolve'];
          this.total_call_request=resultData['totalCallRqstPending'];
          this.call_request=resultData['callRqstPending'];
          this.total_task=resultData['taskTotal'];
          this.unique_customer=resultData['unique_customer'];
          this.active_login_id=resultData['customer_count'];

          this.total_task_paid=resultData['taskPaid'];
          this.total_task_free=resultData['taskFree'];
          this.total_task_commission=resultData['taskCommission'];
          this.task_title_nature=resultData['task_nature_count'];
          
          this.task_prior=resultData['taskPrior'];
          this.task_title=resultData['taskTitle'];          
          console.log(this.task_prior);
          console.log(this.task_title);
          console.log(resultData);
  
         this.task_title.map((x:any)=>{
            this.task_title_name.push(x.name);
          });
          this.task_title_nature.map((x:any)=>{
            this.task_title_nature_name.push(x.name);
          });

          console.log(this.task_title_name);
          
          this.graph_prior();
          this.graph_title();
          this.graph_title2();

          setTimeout(() => {
              this.skeleton_screen_active = false;
          }, 500);

      
    }, error => {
      console.log(error);
      this.toast.openError('Something Went Wrong, Try Later','');
    });
    
    this.skeleton_data = new Array(5);
  }

  graph_prior(){
    //CHART 1 CIRCLE STARTS      

    let chart1 = new Chart({
      credits: {
        enabled: false
      },  
      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: '<b>Priority Chart</b>',
        align: 'center',
        verticalAlign: 'middle',
        y: 60
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '100%'
        }
      },   
      series: [{
        name: 'Priority Wise Chart',
        data: this.task_prior,
        type: undefined,
        colorByPoint: true,
        innerSize: '50%',
      }]
    });
    this.chart1 = chart1; 
    chart1.ref$.subscribe(console.log);
    
  }
  
  
  graph_title(){    
    // CHART 2 COLUMN START

    let chart2 = new Chart({
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: this.task_title_name,       
      },
      credits: {
        enabled: false
      },    
      series: [{
        name: '  Issue Category  Title',
        data: this.task_title,
        type: undefined, 
        colorByPoint: true,      
      }]
    });
    
    this.chart2 = chart2; 
    chart2.ref$.subscribe(console.log);
  }


  graph_title2(){    
    // CHART 2 COLUMN START

    let chart3 = new Chart({
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: this.task_title_nature_name,       
      },
      credits: {
        enabled: false
      },    
      series: [{
        name: 'Nature Problem Title',
        data:this.task_title_nature,
        type: undefined, 
        colorByPoint: true,      
      }]
    });
    
    this.chart3 = chart3; 
    chart3.ref$.subscribe(console.log);
  }
  
}
