import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-call-request',
  templateUrl: './customer-call-request.component.html',
  styleUrls: ['./customer-call-request.component.scss']
})
export class CustomerCallRequestComponent implements OnInit {
  
  customerEncryptId: any = '';
  skeleton_data: any =[]
  customerData: any = {};
  customerContactData: any = [];
  
  listData: any = [];
  
  searchData: any = {};
  
  allCountData: any = {};
  
  start: any = 0;
  pageLimit: any = 50;
  
  calenderMinDate: any;
  currentDate: any;
  
  isRequestInProcess: any = true;
  
  totalPage: any = '';
  pageNumber: any = '';
  totalCount: any = '';
  
  constructor( public dialog: MatDialog,
    public dbService: DBRequest,
    public alert: DialogComponent,
    public toast: SnackBarOverview,
    public route: ActivatedRoute) { }
    
    ngOnInit() {
      
      this.skeleton_data = new Array(7);
      this.route.params.subscribe(params => {
        
        console.log(params);
        this.customerEncryptId = params.customerId;
        
        console.log(this.customerEncryptId);
        
        this.getListData();
        this.onGetAllCounterData();
        this.onGetCustomerDetail();
        
      });
      
      
    }
    
    
    
    onGetCustomerDetail() {
      
      this.isRequestInProcess = true;
      
      this.dbService.onPostRequestData({
        
        customerId: this.customerEncryptId
        
      }, 'customer/onCustomerDetail').subscribe(resultData => {
        
        console.log(resultData);
        
        setTimeout(() => {
          
          this.isRequestInProcess = false;
          
        }, 500);
        
        this.customerData = resultData[`customerData`];
        this.customerContactData = resultData[`customerContactData`];
        
      }, error => {
        
        this.isRequestInProcess = false;
        this.toast.openError('Something Went Wrong, Try Later', '');
      });
      
    }
    
    
    getListData() {
      
      let isSearchDataExist = false;
      for (const key in this.searchData) {
        
        if (this.searchData.hasOwnProperty(key) && this.searchData[key]) {
          isSearchDataExist = true;
        }
      }
      
      if (this.searchData[`date`]) {
        
        this.searchData[`dateCreated`] = moment(this.searchData[`date`]).format('YYYY-MM-DD');
      }
      
      if (!isSearchDataExist) {
        this.isRequestInProcess = true;
      }
      
      this.dbService.onPostRequestData({
        
        start: this.start,
        customerId: this.customerEncryptId,
        searchData: this.searchData
        
      }, 'sharedData/getCallRequestList').subscribe(resultData => {
        
        console.log(resultData);
        
        setTimeout(() => {
          
          if (!isSearchDataExist) {
            this.isRequestInProcess = false;
          }
          
        }, 500);
        
        this.listData = resultData[`callList`];
        
        this.totalCount = resultData[`totalCount`];
        
        this.totalPage = Math.ceil(this.totalCount / this.pageLimit);
        this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;
        
      }, error => {
        
        this.isRequestInProcess = false;
        this.toast.openError('Something Went Wrong, Try Later','');
        
      });
    }
    
    
    onGetAllCounterData() {
      
      this.dbService.onPostRequestData({
        
        customerId: this.customerEncryptId
        
      }, 'customer/customerTotalCountData').subscribe(resultData => {
        
        console.log(resultData);
        
        this.allCountData = resultData[`allCountData`];
        
      }, error => {
        this.toast.openError('Something Went Wrong, Try Later', '');
      });
      
    }
    
    
    onPageInputHandler(type) {
      
      if (type == 'greater') {
        
        this.pageNumber = this.totalPage;
        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
        this.getListData();
        
      } else {
        
        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
        this.getListData();
        
      }
    }
    
    
  }
  