import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DialogComponent } from 'src/app/dialog';
import { SessionService } from 'src/app/session.service';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-stluseradd',
  templateUrl: './stluseradd.component.html',
  styleUrls: ['./stluseradd.component.scss']
})
export class StluseraddComponent implements OnInit {
  lists: any;
  rsm: any = [];
  stl:any={};
  ecrpt_id:any = '';
  announcementData:any={};
  sales_executive_update: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  stlcode:any=[]
  data:any = {};
  formLoading:any = false;
  access_rights:any = {};
  stlcodeList:any=[];
  designationList:any = [];
  stllist:any=[];
  stlId: any = {};
  selectedArea:any =[];
  moduleList:any = [];
  roleList:any = [];
  selectedFile: File[]=[];
  i:any = 0;
  formData = new FormData();

  viewChecked: boolean = false;
  addChecked: boolean = false;
  editChecked: boolean = false;
  deleteChecked: boolean = false;
  allChecked: boolean = false;

  constructor(public db : DatabaseService, public dialogAlert : DialogComponent, public router:Router, public route : ActivatedRoute, public session : SessionService) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log('*** PARAMETER ***');
      console.log(this.ecrpt_id);
      if(this.ecrpt_id != 'new')
      {

        this.getUserDetail();
        setTimeout(() => {
        }, 500);
      }
      else{
        this.getModuleList();
      }
    });

    this.data.access_level = '6';

  }

  ngOnInit()
  {


    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 25||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }


    this.getDesignationList();
    this.getRoleList();
    this.getStlcodeList()
    this.activeView(event,'');
    // for(let i = 0; i <this.assignproject.length; i++){
    //   console.log(this.assignproject[i].id);
    //   if(this.assignproject){
    //   let data2 = {id: this.assignproject[i].id, project_name: this.assignproject[i].project_name}
    //   // console.log(this.data);
    //   // console.log(this.data.project_name);
    //   this.arr1.push(data2)
    //   // this.data.project_name=
    //   console.log(this.arr1);
    //   }
    //   }
  }


  getDesignationList()
  {
    this.db.FetchData({},'Master_User/getDesignationList')
    .subscribe((result:any)=>{
      console.log(result);
      this.designationList = result['designation'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }




  getStlcodeList()
  {
    this.db.FetchData({},'Master_User/stl_codes')
    .subscribe((result:any)=>{
      console.log(result);
      this.stlcodeList = result;
      console.log(this.stlcodeList);
    console.log(this.lists);
    this.lists='';

    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });


  }


  tmp(value)
  {
    console.log(value);
    if( this.selectedArea){
    // console.log(this.lists['value']);
    this.selectedArea = value;
    console.log(this.selectedArea);
    }

  }

  getRoleList()
  {
    this.db.FetchData({},'Master_User/getRoleList')
    .subscribe((result:any)=>{
      console.log(result);
      this.roleList = result['role'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }


  getModuleList()
  {
    this.db.FetchData({},'Master_User/getModuleList')
    .subscribe((result:any)=>{
      console.log(result);
      this.moduleList = result['module'];
      this.moduleList.map((x:any)=>{
        x.viewChecked = false;
        x.addChecked = false;
        x.editChecked = false;
        x.deleteChecked = false;
        x.allChecked = false;
      });
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }



  area_assign_check(value, index, event) {
    console.log(value);
    console.log(index);
    console.log(event);


    if (event.checked) {
      this.rsm.push(value);
    }
    else {
      for (var j = 0; j < this.stlcodeList.length; j++) {
        if (this.stlcodeList[index] == this.rsm[j]) {
          this.rsm.splice(j, 1);
        }
      }
    }
    this.selectedArea = this.rsm
  }

  fileChange(event:any)
  {

    console.log(event.target.files);

    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);

      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);

    }
    console.log(this.selectedFile);
  }


  urls = new Array<string>();
  remove_image(i:any){
    console.log(i);
    this.urls.splice(i,1);
    this.selectedFile.splice(i,1);
  }


  selectAll(event:any,index:any)
  {
    console.log(this.selectedArea);
    console.log(event);
    console.log(index);
    console.log(event.checked);

    if(event.checked == true)
    {
      this.moduleList[index]['viewChecked'] = true;
      this.moduleList[index]['addChecked'] = true;
      this.moduleList[index]['editChecked'] = true;
      this.moduleList[index]['deleteChecked'] = true;
      this.moduleList[index]['allChecked'] = true;
    }

    else{
      this.moduleList[index]['viewChecked'] = false;
      this.moduleList[index]['addChecked'] = false;
      this.moduleList[index]['editChecked'] = false;
      this.moduleList[index]['deleteChecked'] = false;
      this.moduleList[index]['allChecked'] = false;
    }

    console.log(this.moduleList);

  }



  activeView(event:any,index:any)
  {
    if(event.checked == true)
    {
      this.moduleList[index]['viewChecked'] = true;

      if(this.moduleList[index]['viewChecked'] == true && this.moduleList[index]['addChecked'] == true && this.moduleList[index]['editChecked'] == true && this.moduleList[index]['deleteChecked'] == true){
        this.moduleList[index]['allChecked'] = true;
      }
    }
    else{
      this.moduleList[index]['allChecked'] = false;
      if(this.moduleList[index]['addChecked'] == true || this.moduleList[index]['editChecked'] == true || this.moduleList[index]['deleteChecked'] == true){
        this.moduleList[index]['viewChecked'] = true;
      this.moduleList[index]['allChecked'] = false;
      console.log(this.moduleList[index]['allChecked']);



      }else{
        this.moduleList[index]['viewChecked'] = false;
      }
    }

  }


  clearForm(myForm:any)
  {
    myForm.resetForm();
    this.selectedFile = [];
    this.urls = [];
    this.data = {};
    this.formData = new FormData();
    this.urls = new Array<string>();
  }


  submitStluser(myForm:any)
  {
    this.formLoading = true;
    console.log('***SUBMIT STL USER ***');
    console.log(this.data);
    console.log(this.moduleList);
    console.log(this.selectedArea);


    this.data.module = Object.assign([],this.moduleList);
    this.data.Stluser = Object.assign([],this.selectedArea);
    console.log(this.data.Stluser);

    for (let f of this.selectedFile)
    {
      this.formData.append(this.i, f, f.name);
      this.i++;
    }
    console.log(this.data);


    console.log(this.session.trico_user);
    for (var property1 in this.session.trico_user)
    {
      console.log(property1);
      this.formData.append('session['+property1+']',this.session.trico_user[property1]);
    }


    for (var property1 in this.data) {
      if(property1 == 'module')
      {
        for (var property2 in this.data[property1]) {
          if(property1 == 'module')
          {
            for (var property3 in this.data[property1][property2]) {
              this.formData.append( property1+'['+property2+']['+property3+']',this.data[property1][property2][property3]);
            }
          }
        }
      }
      else
      {
        this.formData.append('data['+property1+']',this.data[property1]);
      }
    }

    console.log(this.formData);
    for (var property1 in this.selectedArea)
    {
      console.log(property1);
      this.formData.append('stluser['+property1+']',this.selectedArea[property1]);
    }


    this.db.FileData(this.formData ,'Master_User/submitSystemUser2').subscribe((result:any)=>
    {
      console.log(result);
      if(result['msg'] == 'success') {
        this.dialogAlert.success('STL User Data','Successfully Saved')
        this.router.navigate(['/stluser']);
        this.clearForm(myForm);
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;
        }
        this.dialogAlert.error(error);
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;

    });
  }



  update_assigned_sales_executive(id)
  {
    this.sales_executive_update = id;
  }


  updateStluser(myForm:any)
  {
    this.formLoading = true;
    console.log('***UPDATE STL USER ***');
    console.log(this.data);
    console.log(this.moduleList);
    this.data.module = Object.assign([],this.moduleList);

    for (let f of this.selectedFile)
    {
      this.formData.append(this.i, f, f.name);
      this.i++;
    }
    console.log(this.data);


    console.log(this.session.trico_user);
    for (var property1 in this.session.trico_user)
    {
      console.log(property1);
      this.formData.append('session['+property1+']',this.session.trico_user[property1]);
    }


    for (var property1 in this.data) {
      if(property1 == 'module')
      {
        for (var property2 in this.data[property1]) {
          if(property1 == 'module')
          {
            for (var property3 in this.data[property1][property2]) {
              this.formData.append( property1+'['+property2+']['+property3+']',this.data[property1][property2][property3]);
            }
          }
        }
      }
      else
      {
        this.formData.append('data['+property1+']',this.data[property1]);
      }
    }

    for (var property1 in this.selectedArea)
    {
      console.log(property1);
      this.formData.append('stluser['+property1+']',this.selectedArea[property1]);
    }

    // return false;
    this.db.FileData(this.formData,'Master_User/updateSystemUser2')
    // this.db.FetchData({'data':this.data, 'module':this.moduleList},'Master_User/updateSystemUser')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['msg'] == 'success') {
        this.dialogAlert.success('STL User Data','Successfully Updated')
        this.router.navigate(['/stluser']);
        this.clearForm(myForm);
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;
        }
        this.dialogAlert.error(error);
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;

    });
    this.sales_executive_update='';

  }



  getUserDetail()
  {
    console.log('*** USER DETAIL ***');
    this.db.FetchData({'user_id':this.ecrpt_id},'Master_User/getUserDetail')
    .subscribe((result:any)=>{
      console.log(result);
      this.data = result['detail'];
      if(this.data.image_url != ''){
        this.urls.push('http://phpstack-83335-1104209.cloudwaysapps.com/api/uploads/User_Doc/'+this.data.image_url);
      }
      this.moduleList = result['module'];
      console.log( this.moduleList);
      console.log(this.data.userAssignDetail);

    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
}













