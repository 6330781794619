import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent implements OnInit {
  
  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, public router : Router, public session : SessionService) { }
  
  ngOnInit() {
    this.getStateList();
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 14||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};
  
  stateList:any = [];
  getStateList()
  {
    this.db.FetchData({},'Master_Vendor/getStateList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.stateList = result['state'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
  }
  
  
  districtList:any = [];
  getDistrictList()
  {
    console.log(this.data);
    this.db.FetchData({'data':this.data},'Master_Vendor/getDistrictList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.districtList = result['district'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
  }
  
  cp_data:any = {};
  
  contactPersonList:any = [];
  addContactPerson()
  {
    console.log(this.cp_data);
    this.contactPersonList.push(this.cp_data);
    console.log('*** CONTACT PERSON LIST ***');
    console.log(this.contactPersonList);
    this.cp_data = {};
  }
  
  removeContactPerson(index:any)
  {
    console.log(index);
    this.contactPersonList.splice(index,1);
  }
  
  clearForm(myForm:any)
  {
    this.data = {};
    this.cp_data = {};
    this.contactPersonList = [];
    myForm.resetForm();
  }
  
  data:any = {};
  formLoading:any = false;
  submitVendor(myForm:any)
  {
    this.formLoading = true;
    console.log('***SUBMIT VENDOR ***');
    console.log(this.data);
    console.log(this.contactPersonList);
    
    this.db.FetchData({'data':this.data,'contact_person':this.contactPersonList, 'session':this.session.trico_user},'Master_Vendor/submitVendor')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success') {
        this.dialogAlert.success('Vendor Data','Successfully Saved')
        this.router.navigate(['/list_vendor']);
        this.clearForm(myForm);
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;  
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });  
    
  }
  
  
}
