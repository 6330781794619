
import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';
import * as moment from 'moment';
import * as XLSX from 'xlsx'; 

import { Router, ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-customer-complaints-list',
  templateUrl: './customer-complaints-list.component.html',
  styleUrls: ['./customer-complaints-list.component.scss']
})
export class CustomerComplaintsListComponent implements OnInit {

    customerEncryptId: any = '';
    skeleton_data: any =[]
    customerData: any = {};
    customerContactData: any = [];

    listData: any = [];
cust:any={}
    searchData: any = {};

    start: any = 0;
    pageLimit: any = 50;

    calenderMinDate: any;
    currentDate: any;

    isRequestInProcess: any = true;

    totalPage: any = '';
    pageNumber: any = '';
    totalCount: any = '';

    allCountData: any = {};


    constructor(public dbService: DBRequest,
                public alert: DialogComponent,
                public toast: SnackBarOverview,
    public db : DatabaseService,
    public route: ActivatedRoute,) {

          this.calenderMinDate = new Date('1950-01-01');
          this.currentDate = new Date();
    }

    ngOnInit() {

      this.skeleton_data = new Array(7);
          this.route.params.subscribe(params => {

                console.log(params);
                this.customerEncryptId = params.customerId;

                console.log(this.customerEncryptId);
                this.getListData();
                this.onGetAllCounterData();
                this.onGetCustomerDetail();

          });
    }


 
  onGetCustomerDetail() {
                  
    this.isRequestInProcess = true;
    
    this.dbService.onPostRequestData({
          
          customerId: this.customerEncryptId
          
    }, 'customer/onCustomerDetail').subscribe(resultData => {
          
          console.log(resultData);
          
          setTimeout(() => {
                
                this.isRequestInProcess = false;
                
          }, 500);
          
          this.customerData = resultData[`customerData`];
          this.customerContactData = resultData[`customerContactData`];
          
    }, error => {
          
          this.isRequestInProcess = false;
          this.toast.openError('Something Went Wrong, Try Later', '');
    });
    
}

so:any=[]
    getListData() {

          let isSearchDataExist = false;
          for (const key in this.searchData) {

              if (this.searchData.hasOwnProperty(key) && this.searchData[key]) {
                  isSearchDataExist = true;
              }
          }

          if (this.searchData[`date`]) {

              this.searchData[`dateCreated`] = moment(this.searchData[`date`]).format('YYYY-MM-DD');
          }

          if (!isSearchDataExist) {
              this.isRequestInProcess = true;
          }

          this.dbService.onPostRequestData({

                start: this.start,
                customerId: this.customerEncryptId,
                searchData: this.searchData,
cust:"cust"

          }, 'task/getTaskList').subscribe(resultData => {

                console.log(resultData);

                setTimeout(() => {

                  if (!isSearchDataExist) {
                      this.isRequestInProcess = false;
                  }

                }, 500);

                this.listData = resultData[`taskList`];
                for(let i=0;i<this.listData.length;i++)
                {
                  for(let j=0;j<this.listData[i].fgData.length;j++){
this.so=this.listData[i].fgData[0].so_no
console.log(this.so);


                  }


                }
                this.totalCount = resultData[`totalTaskCount`];

                this.totalPage = Math.ceil(this.totalCount / this.pageLimit);
                this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;

          }, error => {

                this.isRequestInProcess = false;
                this.toast.openError('Something Went Wrong, Try Later','');

          });
    }
   fileName= 'Task-Report.xlsx';  

    exp_dataAll:any=[];
    excel_dataAll:any=[];
    exportexcel(): void  {
      let Index=1;
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);        
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       XLSX.writeFile(wb, this.fileName); 
    }

    onGetAllCounterData() {

      this.dbService.onPostRequestData({

        customerId: this.customerEncryptId

      }, 'customer/customerTotalCountData').subscribe(resultData => {

            console.log(resultData);

            this.allCountData = resultData[`allCountData`];

      }, error => {
            this.toast.openError('Something Went Wrong, Try Later', '');
      });

    }


    onPageInputHandler(type) {

          if (type == 'greater') {

              this.pageNumber = this.totalPage;
              this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
              this.getListData();

          } else {

            this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
            this.getListData();

          }
    }

}
