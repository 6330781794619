import { Component, Inject, OnInit } from '@angular/core';
import { DBRequest } from '../../databaseService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from 'src/app/dialog';

import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/toast';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-warranty-renewal',
  templateUrl: './warranty-renewal.component.html',
  styleUrls: ['./warranty-renewal.component.scss']
})
export class WarrantyRenewalComponent implements OnInit {

    registerForm: FormGroup;
    submitted = false;

    vendorList: any = [];
    priorityList: any = [];
    customerList: any = [];
    projectList: any = [];
    fgList: any = [];

    warrantyList: any= [];

    isRequestInProcess: any = false;
data:any=[];
    currentDate: any;

    dropdownSettings1: any = {};
    dropdownSettings2: any = {};
    dropdownSettings3: any = {};
    dropdownSettings4: any = {};
    surveyData :any ={};

    constructor(private formBuilder: FormBuilder,
                public dbService: DBRequest,
                public alert: DialogComponent,
                public toast: SnackBarOverview,
                public route: Router,
                public dialogRef: MatDialogRef<WarrantyRenewalComponent>,
                @Inject(MAT_DIALOG_DATA) public modalData: any) {

            this.currentDate = new Date();
            this.surveyData= modalData;
            console.log(this.surveyData);
            this.data=this.surveyData.data
            console.log(this.data);
            console.log(this.data.project_name);
            
    }

    ngOnInit() {
   
        this.getCustomerList();
        this.getWarrantyList();
        this.onValidateFormHandler();
        this.onDropDownSettingHandler();

        this.registerForm = this.formBuilder.group({
          customer: [this.data.customer_name], 
          id:[this.data.id],
          customerId: [this.data.customer_id], 

          project:  this.data.project_name,
          projectId:  this.data.project_id,
          serviceCenterId:this.data.service_center_id,
          serviceCenterName:this.data.service_center_name,
brandName:this.data.brand,
modelName:this.data.model,
subModelName:this.data.sub_model,
soNo:this.data.so_no,
siNo:this.data.si_no,
dateSupply:this.data.date_of_supply,
dateInstallation:this.data.date_of_installation,

          fgNo:[this.data.fg_no],
          validfrom:[''],
          validUpTo:[''],
          warranty:[''],
          invoiceNo:['']
        });
console.log(this.registerForm.value);

    }


    onSubmit() {

      this.submitted = true;

      console.log(this.registerForm);
     
        console.warn('in else condition');
            const registerData = JSON.parse(JSON.stringify(this.registerForm.value));
            const inputData = {
                data: registerData
            };


            // if(registerData[`warranty`][0])
            // {  if(registerData[`warranty`][0][`unitType`].search("- PM")!=-1){ 
            //   console.warn('in else if condition');
            //     console.log("entered");
                
            //     let avr= registerData[`warranty`][0][`unitType`].indexOf("- PM");
            //     registerData[`warranty`][0][`preventive_interval`] = registerData[`warranty`][0][`unitType`].substring(avr + 5);
            //     registerData[`warranty`][0][`unitType`] = registerData[`warranty`][0][`unitType`].substring(0,avr - 1);
            //     console.log(registerData);
                
            //    }else{
            //     console.warn('in else else condition');
            //     //  registerData[`unitType`] = registerData[`warranty`][0][`unitType`];
            //     //  registerData[`unit`] = registerData[`warranty`][0][`unit`];
            //     registerData[`warranty`][0][`preventive_interval`] = 0;
            //     console.log(registerData);
            //    }
            // }
            // inputData[`data`][`customerId`] = registerData[`customer`][0][`id`];
            inputData[`data`][`customerName`] = registerData[`customer`];
            inputData[`data`][`customerId`] = registerData[`customerId`];
            inputData[`data`][`serviceCenterId`] = registerData[`serviceCenterId`];
            inputData[`data`][`serviceCenterName`] = registerData[`serviceCenterName`];

            // inputData[`data`][`projectId`] = registerData[`project`][0][`id`];
            inputData[`data`][`projectName`] = registerData[`project`];
            inputData[`data`][`projectId`] = registerData[`projectId`];
            inputData[`data`][`brandName`] = registerData[`brandName`];
            inputData[`data`][`modelName`] = registerData[`modelName`];
            inputData[`data`][`subModelName`] = registerData[`subModelName`];
            inputData[`data`][`soNo`] = registerData[`soNo`];
            inputData[`data`][`siNo`] = registerData[`siNo`];
            inputData[`data`][`dateSupply`] = registerData[`dateSupply`];
            inputData[`data`][`dateInstallation`] = registerData[`dateInstallation`];

            inputData[`data`][`id`] = registerData[`id`];

            // const fgIndex = this.fgList.findIndex(row => row.fg_no == registerData[`fgNo`][0][`fg_no`]);

            inputData[`data`][`fgNo`] = registerData[`fgNo`];
            // inputData[`data`][`projectFgId`] = this.fgList[fgIndex].id;

            // inputData[`data`][`serviceCenterId`] = this.fgList[fgIndex].service_center_id;
            // inputData[`data`][`serviceCenterName`] = this.fgList[fgIndex].service_center_name;

            // inputData[`data`][`brandName`] = this.fgList[fgIndex].brand;
            // inputData[`data`][`modelName`] = this.fgList[fgIndex].model;
            // inputData[`data`][`subModelName`] = this.fgList[fgIndex].sub_model;
            // inputData[`data`][`soNo`] = this.fgList[fgIndex].so_no;
            // inputData[`data`][`siNo`] = this.fgList[fgIndex].si_no;
            // inputData[`data`][`dateSupply`] = this.fgList[fgIndex].date_of_supply;
            // inputData[`data`][`dateInstallation`] = this.fgList[fgIndex].date_of_installation;
            // inputData[`data`][`invoiceNo`] =  registerData[`invoiceNo`];
            // inputData[`data`][`preventiveInterval`] = registerData[`warranty`][0][`preventive_interval`] ;
            // inputData[`data`][`warrantyType`] = this.fgList[fgIndex].warranty_type;
            inputData[`data`][`validfrom`] = moment(registerData.validfrom).format('YYYY-MM-DD');

            inputData[`data`][`validUpToDate`] = moment(registerData.validUpTo).format('YYYY-MM-DD');
            // inputData[`data`][`warrantyUnit`] = registerData[`warranty`][0][`unit`];
            // inputData[`data`][`warrantyUnitType`] = registerData[`warranty`][0][`unitType`];


            this.isRequestInProcess = true;
            this.dbService.onPostRequestData( inputData, 'warrantyRenewal/onSubmitWarrantyRenewalData')
            .subscribe(result => {

                console.log(result);

                console.log(result);
                this.isRequestInProcess = false;

                if (result[`status`] == 'error') {

                  this.alert.error(result[`statusMessage`]);

                } else {

                    this.route.navigate(['/warranty-renewal-list']);
                    this.dialogRef.close();

                    setTimeout(() => {

                      this.alert.success('Success',  'AMC Renewal Done Successfully');

                    }, 1000);
                }

            }, error => {

                  this.isRequestInProcess = false;
                  this.toast.openError('Something Went Wrong, Try Later','');
            });
      }
    


    getCustomerList() {

          this.dbService.onPostRequestData({}, 'customer/getCustomerList')
          .subscribe(result => {

              console.log(result);
              this.customerList = result[`customerList`];

          }, error => {

                // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
          });
    }


    getProjectList() {

      let customerId = 0;
      if (this.registerForm.value[`customer`] && this.registerForm.value[`customer`].length > 0) {

          customerId = this.registerForm.value[`customer`][0][`id`];

      } else {

          this.projectList = [];
          this.fgList = [];
          this.registerForm.get('project').setValue([]);
          this.registerForm.get('fgNo').setValue([]);
          return false;
      }

      const inputData = {
        customerId: customerId,
        isDecrypt: true
      };


      this.dbService.onPostRequestData( inputData, 'project/getProjectList')
      .subscribe(result => {

          console.log(result);
          this.projectList = result[`projectList`];

      }, error => {

            // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
      });
    }


    getFGList() {

        let customerId = 0;
        let projectId = 0;
        if (this.registerForm.value[`project`] && this.registerForm.value[`project`].length > 0) {

            customerId = this.registerForm.value[`customer`][0][`id`];
            projectId = this.registerForm.value[`project`][0][`id`];

        } else {

            this.registerForm.get('fgNo').setValue([]);
            this.fgList = [];

            return false;
        }

        const inputData = {
            customerId: customerId,
            projectId: projectId,
            isDecrypt: true
        };

        this.dbService.onPostRequestData(inputData, 'sharedData/getFGList')
        .subscribe(result => {

            console.log(result);
            this.fgList = result[`fgList`];

        }, error => {

              // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        });
    }


    getWarrantyList() {

      this.isRequestInProcess = true;

      const inputData = {
      };

      this.dbService.onPostRequestData(inputData, 'sharedData/getWarrantyList')
      .subscribe(result => {

          console.log(result);

          this.isRequestInProcess = false;
          this.warrantyList = result[`warrantyList`];

          for (let index = 0; index < this.warrantyList.length; index++) {
          
            if (this.warrantyList[index].unitType && this.warrantyList[index].preventive_interval!=0)
            {  this.warrantyList[index].unitType = this.warrantyList[index].unitType+' - PM '+this.warrantyList[index].preventive_interval +' '+ this.warrantyList[index].preventive_interval_type;
          }else{
            this.warrantyList[index].unitType = this.warrantyList[index].unitType;
          } 
            }

      }, error => {

           this.isRequestInProcess = false;
            // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
      });


  }


    onValidateFormHandler() {

        this.registerForm = this.formBuilder.group({
          customer: ['', Validators.required],
          project: ['', Validators.required],
          fgNo: ['', Validators.required],
          invoiceNo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
          warranty: ['', [Validators.required]],
          validUpTo: [''],
          validfrom: [''],

          // preventiveInterval: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]]
      });
    }

    onDropDownSettingHandler() {

        this.dropdownSettings1 = {
            singleSelection: true,
            idField: 'id',
            textField: 'company_name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };


        this.dropdownSettings2 = {
          singleSelection: true,
          idField: 'id',
          textField: 'project_name',
          allowSearchFilter: true,
          maxHeight: 197,
          closeDropDownOnSelection: true
        };

        this.dropdownSettings3 = {
          // singleSelection: false,
          idField: 'fg_no',
          textField: 'fg_no',
          allowSearchFilter: true,
          maxHeight: 197,
          closeDropDownOnSelection: true
        };

        this.dropdownSettings4 = {
          singleSelection: true,
          idField: 'unit',
          textField: 'unitType',
          allowSearchFilter: true,
          maxHeight: 197,
          closeDropDownOnSelection: true
        };
    }

    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }

    numberOnly(event): boolean {

        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }

    get f() { return this.registerForm.controls; }
}
