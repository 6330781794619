import { Component, OnInit} from '@angular/core';
import { DBRequest } from '../../databaseService';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarOverview } from 'src/app/toast';
import { EmailModelComponent } from '../email-model/email-model.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EditReportModelComponent } from '../edit-report-model/edit-report-model.component';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import { SessionService } from 'src/app/session.service';


@Component({
  selector: 'app-service-report',
  templateUrl: './service-report.component.html',
  styleUrls: ['./service-report.component.scss']
})
export class ServiceReportComponent implements OnInit {

  active: any = false;
  showDivpdf:any=false;
  isRequestInProcess: any = true;
  taskEncryptId: any = '';

  taskAllData: any = {};
  emailData: any = {};
  taskId: any ={};
  workReportData: any = {};
  workReportDataLength : any ='';
  selectedReport: any = '';
  workDateCreated: any = '';
  imgFile:any=[];
  contact_name:any;

  constructor(public dbService: DBRequest, public route: ActivatedRoute,public toast: SnackBarOverview,public dialog: MatDialog, public session : SessionService) { }
  access_rights:any = {};

  ngOnInit() {

    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 1)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
    this.route.params.subscribe(params => {

      console.log(params);
      this.taskEncryptId = params.taskId;

      console.log(this.taskEncryptId);

      this.getServiceReportDetail();

    });
  }
  editWorkInformation:any=false;
  getServiceReportDetail(){

    this.isRequestInProcess = true;

    this.dbService.onPostRequestData({

      taskId: this.taskEncryptId

    }, 'Service_Report/serviceReportDetails').subscribe(resultData => {

      console.log(resultData);

      setTimeout(() => {

        this.isRequestInProcess = false;

      }, 500);

      this.taskAllData = resultData;
      this.emailData = resultData['projectContactData'],
      this.workReportData = resultData['taskWorkReport'];
      console.log(this.emailData);
      this.taskId = resultData['taskData']['id'];
      console.log(this.taskId);
      this.contact_name= this.taskAllData.taskWorkReport[0].customer_signature
      console.log(this.contact_name);

      this.workReportDataLength = resultData['taskWorkReport'].length;
      console.log(this.workReportData);


    }, error => {

      this.isRequestInProcess = false;
      this.toast.openError('Something Went Wrong, Try Later', '');
    });

  }

  data:any = {};

  openEmail(): void {
    this.data = Object.assign({},this.emailData);
    this.taskId = Object.assign({},this.taskId);
    const dialogRef = this.dialog.open(EmailModelComponent, {
      width: '500px',
      data: {
        data : this.data,
        taskId : this.taskId,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  openReportModal(target): void {

    console.log(target);

    const dialogRef = this.dialog.open(EditReportModelComponent, {
      width: '750px',
      data: {
        target : target,
        workReportData :this.workReportData,
      },

    });
    console.log(this.data.target);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }
  exportPDF(id){
    console.log(this.taskAllData);

    console.log(id);
    this.showDivpdf = true;
    this.selectedReport = id;
    setTimeout(() => {
      if(this.showDivpdf==true)
      {

        const reportIndex = this.taskAllData.taskWorkReport.findIndex(row => row.id == id );

        this.workDateCreated = this.taskAllData.taskWorkReport[reportIndex].date_created;
        this.contact_name=this.taskAllData.taskWorkReport[0].customer_signature
        console.log(this.workDateCreated);
        console.log(this.contact_name);
        console.log(reportIndex);



          var element = document.getElementById('component1');
          html2canvas(element).then(canvas=>{

            this.imgFile = canvas.toDataURL("image/jpeg", 3.0);
            console.log(this.imgFile);

          let pdf=new jspdf('1','cm','a4');
          pdf.addImage(this.imgFile,'',0.7,0.7,19,19,'FAST');
          pdf.save('ServiceReport.pdf');
        });





    }
  }, 1000);
  setTimeout(() => {
    this.showDivpdf=false
  }, 1500);
}


showDiv()
{
  this.active = true;
}

hideDiv()
{
  this.active = false;
}


}
