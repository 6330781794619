import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DBRequest } from '../databaseService';
import { DialogComponent } from '../dialog';
import { SessionService } from '../session.service';
import { DatabaseService } from '../_services/database.service';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { FeedbackmailComponent } from '../feedbackmail/feedbackmail.component';
import { AssignEngineerModalComponent } from '../task/assign-engineer-modal/assign-engineer-modal.component';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-orderinspection',
  templateUrl: './orderinspection.component.html',
  styleUrls: ['./orderinspection.component.scss']
})
export class OrderinspectionComponent implements OnInit {
  
  cp_data:any={};
  doc1:any=[]
  selectedFile: File[]=[];
  i:any = 0;
  reader = new FileReader();
  lotList:any=[];
  insp:any={};
  list:any={};
  data:any={};
  soList:any=[];
  contactPersonList:any=[]; 
  dropdownSettings3: any = {};
  so_no:any=[];
  photo = new FormData()      
  oldlot=[];
  feed:any={};
  old_lot:any=[];
  old_remark:any=[];
  remark:any='';
  sodetails:any=[];
  so:any;
  sodata:any=[];  
  urls = new Array<string>();
  date1 = new FormControl(new Date())
  private rteEle: RichTextEditorComponent;
  dates:any=[];
  
 
  constructor( public db:DatabaseService,public routes: Router,public dialogs: MatDialog, public dialog: MatDialog,public dbService: DBRequest, public dialogAlert: DialogComponent,  public router:Router, public route : ActivatedRoute, public session : SessionService) { }
  
  ngOnInit() {
    
    this.onDropDownSettingHandler();
    // this.customerLIst()
    this.getSOList()
    
  }
  
  onDropDownSettingHandler() {
    this.dropdownSettings3 = {
      singleSelection: true,
      idField: 'so_no',
      
      textField: 'so_no',
      
      allowSearchFilter: true,
      maxHeight: 197,
      closeDropDownOnSelection: true 
    };
  }
  
  getSOList() {
    
    
    this.dbService.onPostRequestData({}, 'Customer/soList')
    .subscribe(result => {
      
      console.log(result);
      this.soList = result[`soList`];
      console.log(this.soList);
      
      
    }, error => {
      console.log(error);
    });
    
  } 
  
  getsoDetails() {
    
    
    this.so=this.list.so_no[0].so_no
    
    this.dbService.onPostRequestData(  {so_no: this.so}, 'Customer/soWiseData')
    .subscribe(result => {
      
      console.log(result);
      this.sodata = result;
      this.list.cust_code=result['soWiseData']['cust_code']
      this.list.company_name=result['soWiseData']['company_name']
      this.list.project_name=result['soWiseData']['project_name']
      this.list.value=result['soWiseData']['unit']
      this.list.stl_code=result['soWiseData']['stl_code']
      this.list.activity=result['soWiseData']['activity']
      this.old_lot=result['old_lot']
      
      for (var i = 0; i < this.old_lot.length; i++) {
        if(this.old_lot[i].remark=='Cleared for Dispatch after complaince'||this.old_lot[i].remark=='Cleared for Dispatch'){
          this.oldlot=this.old_lot[i].lot
          
        }
        
        console.log(this.oldlot);
        console.log(this.old_remark);
        
        
      }
    }, error => {
      console.log(error);
    });
  }
  
  addContactPerson()
  {
    console.log(this.cp_data);
    this.contactPersonList.push(this.cp_data);
    console.log('*** CONTACT PERSON LIST ***');
    console.log(this.contactPersonList);
    this.cp_data = {};
  }  
  
  addinspectiondata()
  {
    
    console.log("hii");
    
    console.log(this.insp);
    for (var i = 0; i < this.old_lot.length; i++) {
      if(this.old_lot[i].remark=='Cleared for Dispatch after complaince'||this.old_lot[i].remark=='Cleared for Dispatch'){
        this.oldlot=this.old_lot[i].lot
        
      }
    }
    for (var i = 0; i < this.contactPersonList.length; i++) {
      if(this.contactPersonList[i]==this.cp_data){
        this.dialogAlert.error('Lot Already added for feedback! Please select other ...');
      
        this.insp.remarks=[];
        this.insp.lot=[];
        
      }
    }
    if(this.insp.lot==this.oldlot){
      this.dialogAlert.error('Lot Already filled !!! Please select other one ...');
      
      this.insp.remarks=[];
      this.insp.lot=[];
    }
    else{
      console.log(this.insp);
      this.lotList.push(this.insp);
      console.log('*** CONTACT PERSON LIST ***');
      console.log(this.lotList);
      this.insp = {};
    }}
    
    
    fileChange(event:any) {
      
      console.log(event.target.files);
      for (var i = 0; i < event.target.files.length; i++) {
        this.selectedFile.push(event.target.files[i]);
        
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
          console.log(this.urls);
          
          for (let index = 0; index < this.selectedFile.length; index++) {
            
            for (let urlIndex = 0; urlIndex < this.urls.length; urlIndex++) {
              
              if(index == urlIndex) {
                this.selectedFile[index]['path'] = this.urls[urlIndex];
              }
            }
          }
          
          console.log(this.selectedFile);
          
        }
        
        reader.readAsDataURL(event.target.files[i]);
        
      }
    }
    
    remove_image(i:any)
    {
      console.log(i);
      this.urls.splice(i,1);
      this.selectedFile.splice(i,1);
    }
    removeContactPerson(index:any)
  {
    console.log(index);
    this.contactPersonList.splice(index,1);
    console.log(this.contactPersonList);

  }
  removelot(index:any)
  {
    console.log(index);
    this.lotList.splice(index,1);
    console.log(this.lotList);

  }
    submit() 
    {
      console.log(this.date1.value);
      this.dates = moment(this.date1.value).format('YYYY-MM-DD');
      console.log(this.dates);
      if(this.lotList==''){
        this.dialogAlert.error('Please fill LOT Details properly');
        console.log("hiiii");
        
        // this.insp.remarks=[];
        // this.insp.lot=[];
      }
      else if(this.contactPersonList=='')
      {
        this.dialogAlert.error('Please fill Contact Person Details properly');
        console.log("hiiii");
        
      }
      else if(this.remark=='')
      {
        this.dialogAlert.error('Please fill Remark, it is Mandatory');
      }
      else{
      console.log(this.selectedFile);
      
      var Data = {'company_name':this.list.company_name,'inspection_date':this.dates, 'cust_code':this.list.cust_code,'activity':this.list.activity,'project_name':this.list.project_name,'so_no':this.so,'stl_code':this.list.stl_code ,'organisation_name':this.list.organisation_name,'employee_name':this.list.organi_name,'unit':this.list.value,'remark':this.remark,}

      this.db.FileData({'doc':this.photo,'data':Data ,'cp_data':this.contactPersonList,'lot_details':this.lotList},'customer/onSubmitFeedbackInspection').subscribe((result:any)=>
      {
        console.log(result.id);
        this.data=result.id;
        if(this.selectedFile.length > 0)
        {
        
          for (let f of this.selectedFile)
          {
            this.photo.append(this.i, f, f.name);
            this.i++;
          }
          console.log(this.data);
          for (var property1 in this.data) {
            
            this.photo.append(property1,this.data[property1]);
            
            this.photo.append('data['+property1+']',this.data[property1]);
          }
          console.log(this.session.trico_user);
          this.session.trico_user.id =this.data
          console.log(this.session.trico_user.id);
          
          for (var property1 in this.session.trico_user) {
            
            console.log(property1);
            this.photo.append('session['+property1+']',this.session.trico_user[property1]);
          }
          console.log(this.photo);
          
          if(this.data){
            this.db.FileData(this.photo,'customer/submitDocument')
            .subscribe((result:any)=>{
              
              console.log(result);
              this.doc1=result;
              console.log();
              
              if(result){
                this.openMailDialog()
              }
              
            })
            
          }
        }
        else
        {
              this.openMailDialog()
        }
      })
      }
    }
    
    openMailDialog(): void 
    {
        console.log(this.list.feedtype);
        
        const dialogRef = this.dialogs.open(AssignEngineerModalComponent, {
          width: '600px',
          data: {
            cust_code: this.list.cust_code,
            stl_code: this.list.stl_code,
            so_no: this.so,
            id: this.data,
            project_name:this.list.project_name
          }
          
          });
        console.log(this.data); 
        
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
    } 
    
     
    
  }
  
  
   