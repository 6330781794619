import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { DialogComponent } from '../dialog';
import { DatabaseService } from '../_services/database.service';

@Component({
  selector: 'app-feedbackmail',
  templateUrl: './feedbackmail.component.html',
  styleUrls: ['./feedbackmail.component.scss']
})
export class FeedbackmailComponent implements OnInit {

  bodyText:any;
  data:any={};
  vendorContactEmail:any=[];
  ContactpersonEmail:any=[];
  techList2:any=[];
vendor:any=[];
  private rteEle: RichTextEditorComponent;

  constructor(
    public db: DatabaseService,
      public router:Router,
      public route : ActivatedRoute,
      public dialogRef: MatDialog,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      public dialogAlert: DialogComponent,
      ) 
      { 
        this.data = modalData;
        console.log(modalData);
        // this.vendorContactEmail = this.data.vendorConDetail;
        // this.ContactpersonEmail = this.data.contactdetail;
        console.log(this.ContactpersonEmail);
//         for(var i=0;i<this.ContactpersonEmail.length; i++){
  var Data= 'http://phpstack-83335-1824762.cloudwaysapps.com/rating-feedback/004/'+this.data.feed.cust_code+'/'+this.data.feed.id+'/'+this.data.feed.stl_code+'/'+this.data.feed.so_no  
        console.log(Data);
            
//             this.techList2.push(this.ContactpersonEmail[i].name+ '-'+ this.ContactpersonEmail[i].mobile)
          
// console.log(this.techList2);

//         }

        console.log(this.vendorContactEmail);
        this.data.email=this.vendorContactEmail.email ;

        this.data.subject=`Panel Ispection Feedback!`,
        this.data.value =  `Greetings from Tricolite!,
        
       <p> We at TRICOLITE  thank you for giving us the opportunity to serve you. Inspection of the Panels is completed. Please give your feedback by clicking the link.<b> <a href="{{Data}}">${Data} </a></b> Your feedback is important to us and will help us to improve our services</p>`
        
       
        // console.log(this.data.value);

      }

  ngOnInit() {
  }

  clearForm(myForm:any)
  {
      // this.data = {};
      // myForm.resetForm();
      myForm.resetForm();
      this.selectedFile = [];
      this.urls = [];
      this.data = {};
      this.formData = new FormData();
      this.urls = new Array<string>();
  }

  selectedFile: File[]=[];
  i:any = 0;
  
  fileChange(event:any) {
    
    console.log(event.target.files);
    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
      
      let reader = new FileReader();
      reader.onload = (e: any) => {
         this.urls.push(e.target.result);
         console.log(this.urls);
      
          for (let index = 0; index < this.selectedFile.length; index++) {

              for (let urlIndex = 0; urlIndex < this.urls.length; urlIndex++) {

                      if(index == urlIndex) {
                          this.selectedFile[index]['path'] = this.urls[urlIndex];
                      }
              }
          }

          console.log(this.selectedFile);
        
      }

      reader.readAsDataURL(event.target.files[i]);
      
    }
  }

  urls = new Array<string>();

  remove_image(i:any){
  console.log(i);
  this.urls.splice(i,1);
  console.log(this.urls);
  this.selectedFile.splice(i,1);
  }

  formData = new FormData();

  formLoading:any = false;
  sendVendorComplaint(myForm:any){
    this.formLoading = true;
    console.log(this.data);

    for (let f of this.selectedFile)
    {
    this.formData.append(this.i, f, f.name);
    this.i++;
    }
    console.log(this.data);
    
    for (var property1 in this.data) {
    console.log(property1);
    this.formData.append(property1,this.data[property1]);
        this.formData.append('data['+property1+']',this.data[property1]);
    }
    
    

    this.db.FileData(this.formData,'Customer/sendFeedbackMail')
        .subscribe(resultData => {

          console.log(resultData['msg']);

          if(resultData['msg']=='success'){

            this.dialogAlert.success('Vendor Request','Successfully Mail Sent');
            // this.formLoading = false;

            
          }

          this.formLoading = false;
          this.dialogRef.closeAll();
          
    });



  }

}

