import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-pm-list',
  templateUrl: './pm-list.component.html',
  styleUrls: ['./pm-list.component.scss']
})
export class PmListComponent implements OnInit {
  skeleton_data: any =[]
  skeleton_screen_active:any = false;
  data:any = {};
  
  constructor(public session : SessionService,public db : DatabaseService, public dialogAlert : DialogComponent, public navCtrl : NgxNavigationWithDataComponent) { }
  
  ngOnInit() {
    
    this.skeleton_data = new Array(7);
    this.getPreventiveMaintenanceList(this.pageLimit,this.start);
    console.log(this.data.customer_id);
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 6)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  
  access_rights:any = {};
 
  data_not_found:any = false;
  pageLimit:any =10;
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pageNumber:any;
  pageNumberTemp:any;
  preventiveList:any = [];
  
  getPreventiveMaintenanceList(pageLimit:any=20,start:any=0,action:any='refresh')
  {
    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;
    
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'Master_Preventive/getPreventiveMaintenanceList')
    .subscribe((result:any)=>{
      console.log(result);
      this.preventiveList = result['preventive'];
      this.total_count = result['count'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
      this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pageNumber);
      if(this.preventiveList.length != 0)
      {
        this.data_not_found = false; 
      }
      else
      {
        this.data_not_found = true; 
      }        
      
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);
      console.log(this.data.customer_id);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);
      
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }
  

  createServiceRequest(data:any)
  {
    console.log(data);
    this.navCtrl.navigate('task_add', {data:data});
  }

  exp_data:any=[];
  excel_data:any=[];
  
  getDataInExcel()
  {
    this.db.FetchData({'search':this.search},'Master_Preventive/getPreventiveMaintenanceListExcel')
    .subscribe((result:any)=>{
        console.log(result);
        this.exp_data = result['preventive'];
        
        for(let i=0;i<this.exp_data.length;i++)
        {
          this.excel_data.push({
            'Customer Info.':this.exp_data[i].customer_name +'-'+this.exp_data[i].cust_code +'-'+this.exp_data[i].customer_state +'-'+this.exp_data[i].customer_district, 
            'FG No.':this.exp_data[i].fg_no
          });
        }
        
        this.db.exportAsExcelFile(this.excel_data,'PM Alert');
        
        this.excel_data = [];
        this.exp_data = [];
        
      }, error => {
        console.log(error)
        
      });
    }
}
