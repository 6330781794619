import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import html2pdf from 'html2pdf.js';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { SnackBarOverview } from 'src/app/toast';
import { DialogComponent } from 'src/app/dialog';

@Component({
    selector: 'app-export-comm-report',
    templateUrl: './export-comm-report.component.html',
    styleUrls: ['./export-comm-report.component.scss']
})
export class ExportCommReportComponent implements OnInit {
    showDivpdf: any = false;
    taskId: any = '';

    isLoadingInProcess: any = false;

    constructor(public route: ActivatedRoute, public db: DatabaseService, public router: Router, public toast: SnackBarOverview, public alert: DialogComponent) {
        this.route.params.subscribe(resp => {
            console.log(resp);
            this.taskId = resp['taskId'];
        })
    }
    db_url: any = '';
    ngOnInit() {
        this.get_task_data();
        this.db_url = this.db.UploadUrl;
    }

    check_list: any = [];
    customer_data: any = {};
    ser_engg: any = {};
    get_task_data() {
        this.isLoadingInProcess = true;
        this.db.FetchData({ "taskId": this.taskId }, "task/get_task_data")
            .subscribe(resp => {
                console.log(resp);
                this.check_list = resp['taskFGCheckList'];
                if (this.check_list.length == 0) {
                    this.alert.error('There is no Commissioning Report');
                }
                this.customer_data = resp['customer_data'];
                console.log(this.customer_data);

                this.ser_engg = resp['ser_engg'];
                setTimeout(() => {
                    // commented
                    this.exportPDF();
                }, 1500);
            })
    }


    // exportPDF(){

    //     var pdf=new jspdf('p','cm','a4');
    //     var options = {
    //         // format: 'JPEG',
    //               pagesplit: true,
    //         "background": '#000',
    //     };

    //     for(let i=0,j=0;i<this.check_list.length;i++,j++)
    //     {

    //         var element= document.getElementById('component'+i);
    //         console.log(element);

    //         html2canvas(element).then(canvas=>{
    //             const contentDataURL=canvas.toDataURL('image/png')

    //             var width = pdf.internal.pageSize.getWidth();
    //             var height = pdf.internal.pageSize.getHeight();
    //             pdf.addImage(contentDataURL,'PNG',0.2,0,width, height);

    //             console.log(contentDataURL);
    //             if(j*1 != 0 &&contentDataURL)
    //             {
    //         console.log(j);

    //                 pdf.addPage();
    //             }
    //             if(i == this.check_list.length-1 && pdf.addImage(contentDataURL,'PNG',0.2,0,width, height)
    //             )
    //             {
    //                 pdf.save('CommissioningReport.pdf');
    //                 this.isLoadingInProcess = false;

    //                 setTimeout(() => {
    //                   this.router.navigate(['task_detail/'+this.taskId]);

    //                 }, 2000);

    //             }
    //         });
    //     }
    //     // commented

    // }

    exportPDF() {
        var pdf = new jspdf('p', 'cm', 'a4');
        var options = {
            // format: 'JPEG',
            pagesplit: true,
            "background": '#000',
        };

        for (let i = 0, j = 0; i < this.check_list.length; i++, j++) {
            var element = document.getElementById('component' + i);
            console.log(element);

            html2canvas(element).then(canvas => {
                const contentDataURL = canvas.toDataURL('image/png');
                console.log(contentDataURL, 'canvas');

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                if (j !== 0) {
                    pdf.addPage();
                }

                pdf.addImage(contentDataURL, 'PNG', 0.2, 0, width, height);
                console.log(j);
                console.log(contentDataURL);

                if (i === this.check_list.length - 1) {
                    pdf.save('CommissioningReport.pdf');
                    this.isLoadingInProcess = false;

                    setTimeout(() => {
                        this.router.navigate(['task_detail/' + this.taskId]);

                    }, 2000);

                }
            });
        }
        // commented
    }

}
