import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SessionService } from 'src/app/session.service';
import { ActivatedRoute } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { LoginChangeStatusModelComponent } from 'src/app/login-request/login-change-status-model/login-change-status-model.component';
import { LoginrequesteditComponent } from 'src/app/loginrequestedit/loginrequestedit.component';



@Component({
  selector: 'app-login-request-detail',
  templateUrl: './login-request-detail.component.html',
  styleUrls: ['./login-request-detail.component.scss']
})
export class LoginRequestDetailComponent implements OnInit {
  [x: string]: any;

  isRequestInProcess: any = true;
  loginEncryptId: any = '';
  loginData: any = {};
  projectData: any = [];
  projectData1: any = [];

  constructor(
    
    
    public session : SessionService,
    public db : DatabaseService,
    public route: ActivatedRoute,
    public dialog: MatDialog,


  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
                        
      console.log(params);
      this.loginEncryptId = params.loginId;
      
      console.log(this.loginEncryptId);
      
      this.onGetLoginRequestDetail();
      
      // this.onGetAllCounterData();
    });

  }
  openDialog(): void {
    this.data = Object.assign({},this.userData);
    const dialogRef = this.dialog.open(LoginrequesteditComponent, {
      width: '650px',
      data : {
        data : this.loginData,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.onGetLoginRequestDetail();
    });
  }
  onGetLoginRequestDetail(){

    this.isRequestInProcess = true;
                  
    this.db.FetchData({
          
          loginId: this.loginEncryptId
          
    }, 'customer/onGetLoginRequestDetail').subscribe(resultData => {
          
          console.log(resultData);
          
          setTimeout(() => {
                
                this.isRequestInProcess = false;
                
          }, 500);
          this.projectData1= resultData
          this.loginData = resultData[`loginData`];
          this.projectData = resultData[`assignDetails`];
          console.log(this.projectData);
  //         this.loginData.name = this.loginData.name;
  //         this.loginData.name = this.loginData.name ? this.loginData.name.charAt(0).toUpperCase() + this.loginData.name.substr(1).toLowerCase() : '';
  // // console.log(str);
  // this.loginData.contact_person_name = this.loginDatacontact_person_name;
  // this.loginData.contact_person_name = this.loginData.contact_person_name ? this.loginData.contact_person_name.charAt(0).toUpperCase() + this.loginData.contact_person_name.substr(1).toLowerCase() : '';
          
    }, error => {
          
          this.isRequestInProcess = false;
          this.toast.openError('Something Went Wrong, Try Later', '');
    });
  }

  data:any = {};
  statusDialog(): void {
    this.data = Object.assign({},this.projectData1);
    const dialogRef = this.dialog.open(LoginChangeStatusModelComponent, {
      width: '550px',
      data : {
          data : this.data,
        }
     
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("hiiiii");

      this.onGetLoginRequestDetail();
    });
  }

}
