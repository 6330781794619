import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DatabaseService } from '../_services/database.service';
import * as XLSX from 'xlsx';
import { SessionService } from '../session.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-taskreport',
  templateUrl: './taskreport.component.html',
  styleUrls: ['./taskreport.component.scss']
})
export class TaskreportComponent implements OnInit {
  skeleton_data: any =  []
  list_data: any = []
  isLoadingInProcess: any = false;

  searchData: any = {};
  calenderMinDate: any;

  customerList: any = [];

  start: any = 0;
  pageLimit: any = 100;

  totalPage: any = '';
  pageNumber: any = '';
  totalCustomerCount: any ;

  isRequestInProcess: any = true;
  currentDate: any;

  search_box:any = false;
  stlcodeList:any=[];
  stl_code:any=[];
  search:any={};
task_type:any
access_rights:any = {};
fg:any=[]
data_not_found:any = false;
userList:any=[]
  constructor(public db: DatabaseService, public session : SessionService,public datepipe: DatePipe) {
    this.getDataInExcelAll('');
  this.getUserList()

    this.calenderMinDate = new Date('1950-01-01');
    this.currentDate = new Date();
   }

  ngOnInit() {
    this.getTransactioneTypeList()
    this.getDataInExcelAll('');
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 35)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  refresh()
  {
    this.searchData = {};

    this.getDataInExcelAll('');
  }
  getUserList()
    {
      this.db.FetchData({ 'ID':this.session.trico_user.id,
      'type':this.session.trico_user.access_level,}, 'task/getUserList').subscribe(resultData => {
        console.log(resultData);
        this.userList = resultData[`userList`];
      }, error => {
        console.log(error);
      });
    }
  data: [];
  getDataInExcelAll(searchData)
    {

      if (this.searchData[`date`]) {
        this.searchData[`dateCreated`] = moment(this.searchData[`date`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_from`]) {
        this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }

        let Index= 1;
    this.isLoadingInProcess = true;

      this.db.FetchData({start: this.start,
        pageLimit: this.pageLimit,searchData: this.searchData, isDecrypt: true, task_type : this.task_type,'type':this.session.trico_user.access_level},'task/getTaskListExcelAll2').subscribe(resultData =>
        {
          console.log(resultData);
          console.log();
    this.isLoadingInProcess = false;

          this.data = resultData['taskList']
          this.totalCustomerCount=resultData['totalTask']
          console.log(this.totalCustomerCount);

          for(let i=0;i<this.data.length;i++){
           this.fg.push(this.data[i]['fgData'])
           console.log(this.fg);

           ;
           console.log(this.totalCustomerCount);


          }
          let count = 0;

          this.totalPage = Math.ceil(this.totalCustomerCount / this.pageLimit);
        this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;



        }, error => {
          console.log(error)

        });

      }

   fileName= 'Task-Report.xlsx';
   exp_data:any=[];
   onPageInputHandler(type) {

    if (type == 'greater') {

      this.pageNumber = this.totalPage;
      this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
      this.getDataInExcelAll('');

    } else {

      this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
      this.getDataInExcelAll('');

    }
  }
   exportexcel(): void  {
     let Index=1;
      let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
   }


   exp_dataAll:any=[];
    excel_dataAll:any=[];
    transactionTypeList:any=[]
    getTransactioneTypeList() {

      this.isRequestInProcess = true;

      this.db.FetchData({}, 'sharedData/getTransactioneTypeList')
          .subscribe(result => {
              console.log(result);

              this.isRequestInProcess = false;
              this.transactionTypeList = result[`transactionTypeList`];
              console.log(this.transactionTypeList);

          }, error => {

              this.isRequestInProcess = false;
          });




  }
    getDataInExcelAll1()
    {

      if (this.searchData[`date`]) {
        this.searchData[`dateCreated`] = moment(this.searchData[`date`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_from`]) {
        this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }
      this.isLoadingInProcess = true;

        let Index= 1;

      this.db.FetchData({searchData: this.searchData, isDecrypt: true, task_type : this.task_type,'type':this.session.trico_user.access_level},'task/getTaskListExcelAll2').subscribe(resultData =>
        {
          console.log(resultData);
          this.exp_dataAll = resultData['taskList'];

          let count = 0;

          for(let i=0;i<this.exp_dataAll.length;i++)
          {
            if(this.exp_dataAll[i].created_by_type=='Customer'){
              this.exp_dataAll[i].created_by_type='APP'
            }
            else{
              this.exp_dataAll[i].created_by_type='WEB'
            }
            if (this.exp_dataAll[i].date_created) {
              this.exp_dataAll[i].date_created = moment(this.exp_dataAll[i].date_created).format('H:mm:ss');
              this.exp_dataAll[i].dateCreated = moment(this.exp_dataAll[i].dateCreated).format('DD-MM-YYYY');
            }
            if (this.exp_dataAll[i].status_update_on &&this.exp_dataAll[i].status=='Close') {
              this.exp_dataAll[i].status_update_on = moment(this.exp_dataAll[i].status_update_on).format('DD-MM-YYYY');


            }
            if (this.exp_dataAll[i].status_update_on &&this.exp_dataAll[i].status!='Close') {
              this.exp_dataAll[i].status_update_on = '--';


            }
            if (this.exp_dataAll[i].status=='Close') {
              this.exp_dataAll[i].days =  this.exp_dataAll[i].days


            }
            if(this.exp_dataAll[i].status!='Close')
            {
              this.exp_dataAll[i].days =  '--'
            }
            for(let j=0;j<this.exp_dataAll[i].fgData.length;j++)
            {



              console.log(this.exp_dataAll[i].status_update_on1);

            this.excel_dataAll.push({
                'S.no.': Index++,
                'Request No':'#SR' + this.exp_dataAll[i].task_no,
                'Created by':this.exp_dataAll[i].created_by_name,
                'Created from':this.exp_dataAll[i].created_by_type,
                'Opening Date':this.exp_dataAll[i].dateCreated,

                'Opening Time':this.exp_dataAll[i].date_created,
                // 'Expecting Closure Date':this.exp_dataAll[i].expectation_closing_date,
                'Closing Date': this.exp_dataAll[i].status_update_on,
                'Actual no. of closure days': this.exp_dataAll[i].days,
                // 'Expected no. of closure days': this.exp_data[i].status_update_on,
                'Responsibility Centre': this.exp_dataAll[i].fgData[j].service_center,
                'Service Type':this.exp_dataAll[i].transaction_type,
                'Issue category':this.exp_dataAll[i].compaint_title,
                'Nature of Problem': this.exp_dataAll[i].nature_problem,

                'Priority':this.exp_dataAll[i].priority,
                'SO No': this.exp_dataAll[i].fgData[j].so_no,
                'Customer Name':this.exp_dataAll[i].customer_name,
                'Project Name': this.exp_dataAll[i].project_name,
                'Detail Description':this.exp_dataAll[i].description,
                'Assigned Engineer':this.exp_dataAll[i].assign_to_name,
                'Customer Satisfaction Score':this.exp_dataAll[i].cust_rating,
                'Travelling Cost': this.exp_dataAll[i].travel_expense,
                'Material Cost':this.exp_dataAll[i].material_cost,
                'Manpower Cost': this.exp_dataAll[i],
                // 'Engineer Status': this.exp_dataAll[i].work_report_status,
                'Request Status':  this.exp_dataAll[i].status,
                'Reason Remark':  this.exp_dataAll[i].reason_remark

             });



             count++;
            }
          }

          this.db.exportAsExcelFile(this.excel_dataAll,'Task Report');
          this.excel_dataAll = [];
          this.exp_dataAll = [];
          this.isLoadingInProcess = false;

        }, error => {
          console.log(error)

        });

      }
}

