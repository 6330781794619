import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SessionService } from '../session.service';
import { DatabaseService } from '../_services/database.service';

@Component({
  selector: 'app-invoicereport',
  templateUrl: './invoicereport.component.html',
  styleUrls: ['./invoicereport.component.scss']
})
export class InvoicereportComponent implements OnInit {

  skeleton_data: any =  []
  list_data: any = []
  isLoadingInProcess: any = false;

  searchData: any = {};
  calenderMinDate: any;

  customerList: any = [];

  start: any = 0;
  pageLimit: any = 100;

  totalPage: any = '';
  pageNumber: any = '';
  totalCustomerCount: any ;

  isRequestInProcess: any = true;
  currentDate: any;

  search_box:any = false;
  stlcodeList:any=[];
  stl_code:any=[];
  search:any={};
task_type:any
access_rights:any = {};
fg:any=[]
data_not_found:any = false;
userList:any=[]
  constructor(public db: DatabaseService, public session : SessionService) {
    this.getDataInExcelAll('');
  this.getUserList()

    this.calenderMinDate = new Date('1950-01-01');
    this.currentDate = new Date();
   }

  ngOnInit() {
    this.getTransactioneTypeList()
    this.getDataInExcelAll('');
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 36)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  refresh()
  {
    this.searchData = {};

    this.getDataInExcelAll('');
  }
  getUserList()
    {
      this.db.FetchData({ 'ID':this.session.trico_user.id,
      'type':this.session.trico_user.access_level,}, 'task/getUserList').subscribe(resultData => {
        console.log(resultData);
        this.userList = resultData[`userList`];
      }, error => {
        console.log(error);
      });
    }
  data: [];
  getDataInExcelAll(searchData)
    {
console.log(this.searchData)

      if (this.searchData[`invoice_date`]) {
        this.searchData[`invoice_date`] = moment(this.searchData[`invoice_date`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_from`]) {
        this.searchData[`date_from`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }
console.log(this.searchData[`invoice_date`])
console.log(this.searchData[`date_from`]);
;
console.log(this.searchData[`date_to`]);


        let Index= 1;
    this.isLoadingInProcess = true;

      this.db.FetchData({start: this.start,
        pageLimit: this.pageLimit,searchData: this.searchData, isDecrypt: true, task_type : this.task_type,'type':this.session.trico_user.access_level},'Customer/allCustomerListProject').subscribe(resultData =>
        {
          console.log(resultData);
          console.log();
    this.isLoadingInProcess = false;

          this.data = resultData['customerList']
          this.totalCustomerCount=resultData['count']
          console.log(this.totalCustomerCount);

          for(let i=0;i<this.data.length;i++){
           this.fg.push(this.data[i]['fgData'])
           console.log(this.fg);

           ;
           console.log(this.totalCustomerCount);


          }
          let count = 0;

          this.totalPage = Math.ceil(this.totalCustomerCount / this.pageLimit);
        this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;



        }, error => {
          console.log(error)

        });

      }

   fileName= 'Task-Report.xlsx';
   exp_data:any=[];
   onPageInputHandler(type) {

    if (type == 'greater') {

      this.pageNumber = this.totalPage;
      this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
      this.getDataInExcelAll('');

    } else {

      this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
      this.getDataInExcelAll('');

    }
  }



   exp_dataAll:any=[];
    excel_dataAll:any=[];
    transactionTypeList:any=[]
    getTransactioneTypeList() {

      this.isRequestInProcess = true;

      this.db.FetchData({}, 'sharedData/getTransactioneTypeList')
          .subscribe(result => {
              console.log(result);

              this.isRequestInProcess = false;
              this.transactionTypeList = result[`transactionTypeList`];
              console.log(this.transactionTypeList);

          }, error => {

              this.isRequestInProcess = false;
          });




  }
    getDataInExcelAll1()
    {

      if (this.searchData[`invoice_date`]) {
        this.searchData[`invoice_date`] = moment(this.searchData[`invoice_date`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_from`]) {
        this.searchData[`date_from`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }
        let Index= 1;

      this.db.FetchData({searchData: this.searchData, isDecrypt: true, task_type : this.task_type,'type':this.session.trico_user.access_level},'Customer/allCustomerListProject').subscribe(resultData =>
        {
          console.log(resultData);
          this.exp_dataAll = resultData['customerList'];

          let count = 0;

          for(let i=0;i<this.exp_dataAll.length;i++)
          {

            if (this.exp_dataAll[i].date_created) {
              this.exp_dataAll[i].invoice_date = moment(this.exp_dataAll[i].date_created).format('DD-MM-YYYY');
              this.exp_dataAll[i].invoice_time = moment(this.exp_dataAll[i].date_created).format(' H:mm:ss');

            }





              console.log(this.exp_dataAll[`status_update_on`]);

            this.excel_dataAll.push({
                'S.no.': Index++,
                'Customer Code':this.exp_dataAll[i].cust_code,

                'Customer Name':this.exp_dataAll[i].company_name,
                'Project Name': this.exp_dataAll[i].project_name,
                'SO NO':this.exp_dataAll[i].so_no,
                'Fg no':this.exp_dataAll[i].fg_no,
                'Invoice No.':this.exp_dataAll[i].si_no,
                'Invoice Date': this.exp_dataAll[i].invoice_date,
                'Invoice Time':this.exp_dataAll[i].invoice_time,


             });



             count++;
            }


          this.db.exportAsExcelFile(this.excel_dataAll,'Invoice Report');
          this.excel_dataAll = [];
          this.exp_dataAll = [];

        }, error => {
          console.log(error)

        });

      }
}


