import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AssignstlmodalComponent } from '../assignstlmodal/assignstlmodal.component';
import { DBRequest } from '../databaseService';
import { DialogComponent } from '../dialog';
import { SessionService } from '../session.service';
import { SnackBarOverview } from '../toast';
import { DatabaseService } from '../_services/database.service';

@Component({
  selector: 'app-customer-stluser',
  templateUrl: './customer-stluser.component.html',
  styleUrls: ['./customer-stluser.component.scss']
})
export class CustomerStluserComponent implements OnInit {
  skeleton_data: any =[]
  customerProjectList: any = [];
  customerEncryptId: any = '';

  start: any = 0;
  pageLimit: any = 50;

  searchData: any = {};

  totalPage: any = '';
  pageNumber: any = '';
  totalSLCount: any = '';

  allCountData: any = {};

  calenderMinDate: any;
  currentDate: any;

  isRequestInProcess: any = true;
  projectId:null;
      toast: any;
      data: any = {};


  constructor(public dbService: DBRequest,
              public route: ActivatedRoute,
              public db:DatabaseService,
              public dialog: MatDialog,
              public dialogAlert : DialogComponent,
              public session : SessionService,
              ) {


  }
  access_rights:any = {};

  ngOnInit() {
      if(this.session.trico_user.access_level != '1')
      {
        this.session.trico_user.access_module.map((x:any)=>{
          if(x.module_id === 2)
          {
            this.access_rights = x;
          }
        });
        console.log(this.access_rights);
      }
      this.skeleton_data = new Array(7);

       this.route.params.subscribe(params => {

            console.log(params);
            this.customerEncryptId = params.customerId;

            console.log(this.customerEncryptId);

            this.onGetCustomerstlAssignList();
            this.onGetAllCounterData();
        this.onGetCustomerDetail();
      });
  }
  getassignstl:any=[]
  customerData:any = {};
  customerContactData:any = [];
  onGetCustomerDetail() {
                  
    this.isRequestInProcess = true;
    
    this.dbService.onPostRequestData({
          
          customerId: this.customerEncryptId
          
    }, 'customer/onCustomerDetail2').subscribe(resultData => {
          
          console.log(resultData);
          
          setTimeout(() => {
                
                this.isRequestInProcess = false;
                
          }, 500);
          
          this.customerData = resultData[`customerData`];
          this.customerContactData = resultData[`customerContactData`];
          
    }, error => {
          
          this.isRequestInProcess = false;
    });
    
}



  onGetCustomerstlAssignList() {

      this.isRequestInProcess = true;

      console.log(this.searchData);

      this.searchData.dateCreated = '';
      if(this.searchData[`date`]) {
           this.searchData.dateCreated = moment(this.searchData[`date`]).format('YYYY-MM-DD');
      }

      this.dbService.onPostRequestData({

         customerId: this.customerEncryptId,
         start: this.start,
         pageLimit: this.pageLimit,
         searchData: this.searchData

      }, 'SharedData/getassignstl').subscribe(resultData => {

            console.log(resultData);
this.getassignstl=resultData['fgList']
            setTimeout(() => {

                this.isRequestInProcess = false;

            }, 500);

            this.totalSLCount = resultData[`slCount`];
console.log(this.totalSLCount);

            this.totalPage = Math.ceil(this.totalSLCount / this.pageLimit);
            this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;

      }, error => {

            this.isRequestInProcess = false;
            this.toast.openError('Something Went Wrong, Try Later', '');
      });
  }
  openDocumentDialog(data): void 
  {
        console.log(this.data);
        const dialogRef = this.dialog.open(AssignstlmodalComponent, {
              width: '600px',
              data: {
                    data : data
              }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.onGetCustomerstlAssignList()
              console.log('The dialog was closed');

        });
  }

  onGetAllCounterData() {

    this.dbService.onPostRequestData({

      customerId: this.customerEncryptId

    }, 'customer/customerTotalCountData').subscribe(resultData => {

          console.log(resultData);

          this.allCountData = resultData[`allCountData`];
          console.log(this.allCountData);
          

    }, error => {
          this.toast.openError('Something Went Wrong, Try Later', '');
    });

  }



 
 
      
      

      



        
      

}






     


