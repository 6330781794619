import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';

import { Router, ActivatedRoute } from '@angular/router';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AllActionModalComponent } from '../all-action-modal/all-action-modal.component';
import { EmailModelComponent } from '../email-model/email-model.component';
import { TaskDetailModelComponent } from '../task-detail-model/task-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { SessionService } from 'src/app/session.service';
import { EngineerdatetimemodelComponent } from 'src/app/engineerdatetimemodel/engineerdatetimemodel.component';
import { ProducteditComponent } from 'src/app/productedit/productedit.component';



@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
    styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {

    active: any = false;

    taskEncryptId: any = '';
    isRequestInProcess: any = true;

    taskAllData: any = {};
    userId: any = {};

    taskContactData: any = [];
  // tslint:disable-next-line:max-line-length
    constructor(public dialog: MatDialog, public dbService: DBRequest, public alert: DialogComponent, public toast: SnackBarOverview, public route: ActivatedRoute,public router: Router, public db: DatabaseService, public session : SessionService) { }
    access_rights:any = {};

    ngOnInit() {
        console.log(this.session.trico_user);
        
        if(this.session.trico_user.access_level != '1')
        {
          this.session.trico_user.access_module.map((x:any)=>{
            if(x.module_id === 1)
            {
              this.access_rights = x;
            }
          });
          console.log(this.access_rights);
        }
        this.route.params.subscribe(params => {

            console.log(params);
            this.taskEncryptId = params.taskId;

            console.log(this.taskEncryptId);

            this.onGetTaskDetail();

        });
    }
    back()
    {
        if(this.taskAllData.taskData.task_type=='Service Request'){
           console.log("ftlllll")

        this.router.navigate(['task_list/service_request']);
        }
        if(this.taskAllData.taskData.task_type!='Service Request'){
            console.log("hloooo")

        this.router.navigate(['task_list/commissioning']);
        }

    }

    onGetTaskDetail() {
this.isRequestInProcess = true;
this.dbService.onPostRequestData({taskId: this.taskEncryptId}, 'task/onTaskDetail')
        .subscribe(resultData => {
            console.log(resultData);
            setTimeout(() => {
                this.isRequestInProcess = false;
            }, 500);
            this.taskAllData = resultData;
            console.log(this.taskAllData);
            this.userId = this.taskAllData.taskAssignData[0].userEncryptId;
            console.log(this.userId);
        }, error => {
            this.isRequestInProcess = false;
            this.toast.openError('Something Went Wrong, Try Later', '');
        });
    }




    showDiv() {
        this.active = true;
    }
    hideDiv() {
        this.active = false;
    }

    openDialog(target): void {

        console.log(target);
        const dialogRef = this.dialog.open(AllActionModalComponent, {
            width: '500px',
            data: {
                target,
                taskId: this.taskEncryptId,
                taskAllData: this.taskAllData
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.onGetTaskDetail();
        });
    }
    
    openDial(row,target): void {

        console.log(row);
        const dialogRef = this.dialog.open(AllActionModalComponent, {
            width: '500px',
            data: {
                row,
                target,
                taskId: this.taskEncryptId,
                 taskAllData: this.taskAllData
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.onGetTaskDetail();
        });
    }
    
    openDetailDialog(): void {
        const dialogRef = this.dialog.open(TaskDetailModelComponent, {
          width: '768px',
          data: {
            taskId: this.taskEncryptId,
            taskAllData: this.taskAllData.taskData
        },
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.onGetTaskDetail();
        });
      }
      openDialog2(target): void {

        console.log(target);
        const dialogRef = this.dialog.open(EngineerdatetimemodelComponent, {
            width: '500px',
            data: {
                target,
                taskId: this.taskEncryptId,
                taskAllData: this.taskAllData.taskData
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.onGetTaskDetail();
        });
    }
}
