import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-add-brand-model',
  templateUrl: './add-brand-model.component.html',
  styleUrls: ['./add-brand-model.component.scss']
})
export class AddBrandModelComponent implements OnInit {

  constructor(public db : DatabaseService, public dialog : DialogComponent, public dialogRef : MatDialog) { }

  ngOnInit() {
  }

  clearForm(myForm:any)
  {
    myForm.resetForm();
  }

  data:any = {};
  submitProductBrand(myForm:any)
  {
    console.log('*** SUBMIT PROUCT BRAND ***');
    console.log(this.data);

    this.db.FetchData({'data' : this.data},'Master_Product/submitProductBrand')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialog.success('Product Brand Data','Successfully Saved !!!');
        this.clearForm(myForm)
      }
      else
      {
        var error = result['error']['message'];
        this.dialog.error(error);
      }
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });

  }

}
