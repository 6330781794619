import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarOverview } from 'src/app/toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { NgControlStatus } from '@angular/forms';
import { SessionService } from 'src/app/session.service';
import { AttachmentModelComponent } from '..//attachment-model/attachment-model.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-vendor-add',
    templateUrl: './vendor-add.component.html',
    styleUrls: ['./vendor-add.component.scss']
})
export class VendorAddComponent implements OnInit {

    registerForm: any = [];
    submitted = false;
    ecrpt_id:any = '';
    vendorList: any = [];
    priorityList: any = [];
    customerList: any = [];
    projectList: any = [];
    fgList: any = [];

    isRequestInProcess: any = false;

    dropdownSettings: any = {};
    dropdownSettings1: any = {};
    dropdownSettings2: any = {};
    dropdownSettings3: any = {};
    dropdownSettings4: any = {};


    data:any = {};

    constructor(
        public db: DatabaseService,
        public dialogAlert: DialogComponent,
        public toast: SnackBarOverview,
        public route: Router,
        public dialog: MatDialog,
        public activatedRoute : ActivatedRoute,
        public session : SessionService ) {

            this.activatedRoute.params.subscribe(params=>{
                console.log(params);
                this.ecrpt_id = params.id;
                console.log('*** PARAMETERs ***');
                console.log(this.ecrpt_id);
            });

            if(this.ecrpt_id != 'add')
            {
                this.getVendorComplaintDetail();
            }

        }

    access_rights:any = {};

        ngOnInit() {
            if(this.session.trico_user.access_level != '1')
            {
              this.session.trico_user.access_module.map((x:any)=>{
                if(x.module_id === 7)
                {
                  this.access_rights = x;
                }
              });
              console.log(this.access_rights);
            }
            this.getVendorList();
            this.getPriorityList();
            this.getCustomerList();
            // this.onValidateFormHandler();
            this.onDropDownSettingHandler();
        }

        compDocDetail:any  = [];
        complaintContactDetail = [];
        getVendorComplaintDetail()
        {
            console.log('*** GET VENDOR COMPLAINT DETAIL ***');
            this.db.FetchData({'complaint_id':this.ecrpt_id},'vendorComplaint/getVendorComplaintDetail')
            .subscribe((result:any)=>{
                console.log(result);
                this.data = Object.assign({},result['detail']);
                this.compDocDetail = result['complaintDocDetail'];
                this.complaintContactDetail = result['complaintContactDetail'];
                console.log(this.data);
                console.log(this.compDocDetail);
                console.log(this.complaintContactDetail);

                setTimeout(() => {
                    this.getProjectList();
                    this.getFGList();
                }, 500);

            },error=>{
                console.log(error);
                this.dialogAlert.error('Something went wrong !!! Try again ...');
            });
        }


        clearForm(myForm:any)
        {
            // this.data = {};
            // myForm.resetForm();
            myForm.resetForm();
            this.selectedFile = [];
            this.urls = [];
            this.data = {};
            this.formData = new FormData();
            this.urls = new Array<string>();
        }

        vendorId: any = {};
        vendorContactList: any =[];

        onGetVendorContactList(vendorId){

            console.log("******** GET FG LIST ********");

            this.vendorId = vendorId ;

            console.log(vendorId);

            this.db.FetchData({'vendorId':this.vendorId}, 'sharedData/OnGetVendorContactList')
            .subscribe(result => {

                console.log("******** Vendor Contact List *********");

                console.log(result);

                this.vendorContactList = result['vendorContactList'];



                console.log(this.vendorContactList);

            });

        }

        removeVendorContactPerson(index:any)
        {
            console.log(index);
            this.vendorContactList.splice(index,1);
        }

        cp_data:any = {};

        addContactPerson()
        {

            console.log(this.cp_data);
            console.log(this.vendorContactList);

            let chkIndex = this.vendorContactList.findIndex(row=> row.mobile===this.cp_data.mobile);
            console.log(chkIndex);

            if(chkIndex == -1){
                this.vendorContactList.push(this.cp_data);
                console.log('*** CONTACT PERSON LIST ***');
                console.log(this.vendorContactList);
                this.cp_data = {};

            }else{
                this.dialogAlert.error("Already Exist Mobile No..!");
                this.cp_data = {};
            }


        }




        vendorContactId:any = {};
        formLoading:any = false;
        submitVendorComplaint(myForm:any)
        {
            console.log('***SUBMIT VENDOR COMPLAINT ***');
            console.log(this.data);

            this.vendorContactId =  this.data.vendorConactId;

            const contactSelectedData = [];

            if(this.vendorContactList.length > 0){

                for(let index = 0; index < this.vendorContactList.length; index++) {

                    console.log("vendor list");

                    if (this.vendorContactList.length > 0) {

                        console.log("vendor contact list");

                        this.vendorContactId = true;

                        contactSelectedData.push(this.vendorContactList[index]);

                        console.log(contactSelectedData);


                    }


                }

            }else{

                this.vendorContactId = false;

            }


            this.formLoading = true;
            this.db.FetchData({'data':this.data,'contactData':contactSelectedData , 'session':this.session.trico_user},'vendorComplaint/submitVendorComplaint')
            .subscribe((result:any)=>{
                console.log(result);
                if(result['msg'] == 'success')
                {
                    this.dialogAlert.success('Vendor Request Data','Successfully Saved');
                    this.clearForm(myForm);
                    this.formLoading = false;
                    this.route.navigate(['vendor-complaint-list']);
                }
                else{
                    this.dialogAlert.error('Something went wrong !!! Try again ...');
                    this.formLoading = false;
                }
            },error=>{
                console.log(error);
                this.dialogAlert.error('Something went wrong !!! Try again ...');
                this.formLoading = false;
            });
        }

        selectedFile: File[]=[];
        i:any = 0;

        fileChange(event:any) {

          console.log(event.target.files);
          for (var i = 0; i < event.target.files.length; i++) {
            this.selectedFile.push(event.target.files[i]);

            let reader = new FileReader();
            reader.onload = (e: any) => {
               this.urls.push(e.target.result);
               console.log(this.urls);

                for (let index = 0; index < this.selectedFile.length; index++) {

                    for (let urlIndex = 0; urlIndex < this.urls.length; urlIndex++) {

                            if(index == urlIndex) {
                                this.selectedFile[index]['path'] = this.urls[urlIndex];
                            }
                    }
                }

                console.log(this.selectedFile);

            }

            reader.readAsDataURL(event.target.files[i]);

          }
        }

        urls = new Array<string>();

        remove_image(i:any){
        console.log(i);
        this.urls.splice(i,1);
        console.log(this.urls);
        this.selectedFile.splice(i,1);
        }

        formData = new FormData();

        updateVendorComplaint(myForm:any)
        {
            console.log('***SUBMIT VENDOR COMPLAINT ***');
            console.log(this.data);


            this.vendorContactId =  this.data.vendorConactId;
            console.log(this.vendorContactId);
            console.log(this.complaintContactDetail);


            const contactSelectedData = [];

            if(this.complaintContactDetail.length > 0){

                console.log("hello");


                for(let index = 0; index < this.complaintContactDetail.length; index++) {

                    console.log("vendor list");

                    if (this.complaintContactDetail.length > 0) {

                        console.log("vendor contact list");

                        this.vendorContactId = true;

                        contactSelectedData.push(this.complaintContactDetail[index]);

                        console.log(contactSelectedData);


                    }


                }

            }else{

                this.vendorContactId = false;

            }

            console.log(contactSelectedData);
            console.log(this.data);


            for (let f of this.selectedFile)
            {
            this.formData.append(this.i, f, f.name);
            this.i++;
            }
            console.log(this.data);

            for (var property1 in this.data) {
            console.log(property1);
            this.formData.append(property1,this.data[property1]);
                this.formData.append('data['+property1+']',this.data[property1]);
            }

            console.log(this.session.trico_user);
            for (var property1 in this.session.trico_user)
            {
            console.log(property1);
            this.formData.append('session['+property1+']',this.session.trico_user[property1]);
            }

            console.log(contactSelectedData);

            for (var i = 0; contactSelectedData[i]; i++)
            for (var property1 in contactSelectedData[i]) this.formData.append('contactData['+property1+']', contactSelectedData[i][property1]);



            this.formLoading = true;
            this.db.FileData(this.formData,'vendorComplaint/updateVendorComplaint')
            .subscribe((result:any)=>{
                console.log(result);
                if(result['msg'] == 'success')
                {
                    this.dialogAlert.success('Vendor Request Data','Successfully Updated');
                    this.clearForm(myForm);
                    this.formLoading = false;
                    this.route.navigate(['vendor-complaint-list']);
                }
                else{
                    this.dialogAlert.error('Something went wrong !!! Try again ...');
                    this.formLoading = false;
                }
            },error=>{
                console.log(error);
                this.dialogAlert.error('Something went wrong !!! Try again ...');
                this.formLoading = false;
            });
        }



        getVendorList() {
            console.log('*** GET VENDOR LIST ***');
            this.db.FetchData( {}, 'vendorComplaint/getVendorList')
            .subscribe(result => {
                console.log(result);
                this.vendorList = result[`vendorList`];
            }, error => {
                console.log(error);
            });
        }

        getPriorityList() {
            console.log('*** GET PRIORITY LIST ***');
            this.db.FetchData({}, 'sharedData/getPriorityVendorList')
            .subscribe(result => {
                console.log(result);
                this.priorityList = result[`priorityList`];
                console.log(this.priorityList);

            }, error => {
                console.log(error);
            });
        }

        getCustomerList() {
            console.log('*** GET CUSTOMER LIST ***');
            this.db.FetchData({}, 'customer/getCustomerList')
            .subscribe(result => {
                console.log(result);
                this.customerList = result[`customerList`];
            }, error => {
                console.log(error);
            });

        }

        getProjectList() {
            console.log('*** GET PROJECT LIST ***');
            console.log(this.data);
            this.db.FetchData({'data':this.data}, 'sharedData/getProjectList')
            .subscribe(result => {
                console.log(result);
                this.projectList = result[`projectList`];
            }, error => {
                console.log(error);
            });
        }

        getFGList() {
            console.log('*** GET FG LIST ***');
            console.log(this.data);
            this.db.FetchData({'data':this.data}, 'sharedData/getProjectFGList')
            .subscribe(result => {
                console.log(result);
                this.fgList = result[`fg_list`];
            }, error => {
                console.log(error);
            });
        }

        onSelected($event:any,action:any='') {
            console.log('Action - '+action)
            this.data[action] = $event;
        }

        onDropDownSettingHandler() {

            this.dropdownSettings = {
                singleSelection: true,
                idField: 'id',
                textField: 'company_name',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };


            this.dropdownSettings1 = {
                singleSelection: true,
                idField: 'name',
                textField: 'name',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };

            this.dropdownSettings2 = {
                singleSelection: true,
                idField: 'id',
                textField: 'company_name',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };


            this.dropdownSettings3 = {
                singleSelection: true,
                idField: 'id',
                textField: 'project_name',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };

            this.dropdownSettings4 = {
                singleSelection: true,
                idField: 'fg_no',
                textField: 'fg_no',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };
        }



        // vendorContactDetail: any = []
        openMailDialog(): void {
            console.log(this.complaintContactDetail);

            const dialogRef = this.dialog.open(AttachmentModelComponent, {
              width: '600px',
              data: {
contactdetail: this.complaintContactDetail,
                vendorConDetail :this.data

            }

        });
            console.log(this.data);

            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
              this.getVendorComplaintDetail();
            });
          }
    }
