import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { AddBrandModelComponent } from '../add-brand-model/add-brand-model.component';
import { AddProductModelComponent } from '../add-product-model/add-product-model.component';
import { AddSubProductModelComponent } from '../add-sub-product-model/add-sub-product-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/session.service';


@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {
  
  ecrpt_id:any = '';
  
  constructor(public dialog: MatDialog, public db:DatabaseService, public dialogAlert: DialogComponent, public router : Router,  public route : ActivatedRoute, public session : SessionService) { 
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log('*** PARAMETERs ***');
      console.log(this.ecrpt_id);
    });
    if(this.ecrpt_id != 'new')
    {
      this.getProductDetail();
    }
    else{
      this.getProductBrand();
      this.getProductModel();
    }
  }
  
  ngOnInit() {
    
  }
  
  openProductBrandDialog(): void {
    const dialogRef = this.dialog.open(AddBrandModelComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getProductBrand();
    });
  }
  
  openProductModel(): void {
    const dialogRef = this.dialog.open(AddProductModelComponent, {
      width: '500px',
      data: {
        data:this.data
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getProductBrand();
      this.getProductModel();
    });
  }
  
  openProductSubModel(): void {
    const dialogRef = this.dialog.open(AddSubProductModelComponent, {
      width: '500px',
      data: {
        data:this.data
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getProductBrand();
      this.getProductModel();
      this.getProductSubModel();
    });
  }
  
  
  
  brandList:any = [];
  getProductBrand()
  {
    console.log('*** Product Brand List ***');
    this.db.FetchData({},'Master_Product/getProductBrand')
    .subscribe((result:any)=>{
      console.log(result);  
      this.brandList = result['brand'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  modelList:any = [];
  getProductModel()
  {
    console.log('*** Product Model List ***');
    this.db.FetchData({'data':this.data},'Master_Product/getProductModel')
    .subscribe((result:any)=>{
      console.log(result);  
      this.modelList = result['model'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  submodelList:any = [];
  sublist2:any = [];
  sublist3:any = [];

  getProductSubModel()
  {
    console.log('*** Product Sub Model List ***');
    this.db.FetchData({'data':this.data},'Master_Product/getProductSubModel')
    .subscribe((result:any)=>{
      console.log(result);  
      this.submodelList = result['submodel'];
      for(var i=0;i<this.submodelList.length; i++){
this.sublist2=this.submodelList[i].submodel
console.log(this.sublist2);
this.sublist3.push(this.submodelList[i].submodel)
console.log(this.sublist3);

      }
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  clearForm(myForm:any)
  {
    myForm.resetForm();
  }
  
  data:any = {};
  formLoading:any = false;
  submitProduct(myForm:any)
  {
    this.formLoading = true;
    console.log('*** SUBMIT PRODUCT ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data , 'session':this.session.trico_user},'Master_Product/submitProduct')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialogAlert.success('Product Data','Successfully Saved');
        this.clearForm(myForm);
        this.formLoading = false;
        this.router.navigate(['productlist']);
      }
      else{
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      }
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });
    
  }
  
  
  
  
  getProductDetail() 
  {
    console.log('*** GET PRODUCT DETAIL ***');
    this.db.FetchData({'product_id':this.ecrpt_id},'Master_Product/getProductDetail')
    .subscribe((result:any)=>{
      console.log(result);  
      
      this.data = Object.assign({},result['data']);
      
      console.log(this.data);
      this.getProductBrand();
      this.getProductModel();
      this.getProductSubModel();
      
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  
  updateProduct(myForm:any)
  {
    console.log('*** UPDATE PRODUCT ***');
    console.log(this.data.submodel);
    this.formLoading = true;
    this.db.FetchData({'data':this.data},'Master_Product/updateProduct')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialogAlert.success('Product Data','Successfully Updated');
        this.clearForm(myForm);
        this.formLoading = false;
        this.router.navigate(['productlist']);
      }
      else{
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      }
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });
    
  }
  
  
}
