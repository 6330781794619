import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-emergency-contact-list',
  templateUrl: './emergency-contact-list.component.html',
  styleUrls: ['./emergency-contact-list.component.scss']
})
export class EmergencyContactListComponent implements OnInit {

  skeleton_screen_active: any = false;
  constructor(public db: DatabaseService, public dialog: DialogComponent, public session: SessionService) { }

  ngOnInit() {
    this.getEmergencyData();
    if (this.session.trico_user.access_level != '1') {
      this.session.trico_user.access_module.map((x: any) => {
        if (x.module_id === 15 || x.module_id === 18) {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  emergency_data: any = [];
  getEmergencyData() {
    this.skeleton_screen_active = true;
    this.db.FetchData({}, 'Master_Emergency/getEmergencyData')
      .subscribe((result: any) => {
        console.log(result);
        this.emergency_data = result['emergency'];
        this.data_day = this.emergency_data.filter((x: any) => x.emergency_type == 'Day')[0];
        this.data_night = this.emergency_data.filter((x: any) => x.emergency_type == 'Night')[0];

        if (this.data_day == undefined) {
          this.data_day = {};
        }
        if (this.data_night == undefined) {
          this.data_night = {};
        }
        console.log(this.data_day);
        console.log(this.data_night);

        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);

      }, error => {
        console.log(error);
        this.dialog.error('Something went wrong !!! Try again ...');
        this.skeleton_screen_active = false;
      });
  }
  access_rights: any = {};

  data_night: any = {};
  data_day: any = {};

  clear_form(myForm: any) {
    myForm.resetForm();
  }

  formLoadingDay: any = false;
  submitDayEmergency(myForm: any) {
    this.formLoadingDay = true;
    console.log('*** Submit Day Emergency Data ***');
    console.log(this.data_day);
    this.db.FetchData({ 'data': this.data_day, 'session': this.session.trico_user }, 'Master_Emergency/submitEmergency')
      .subscribe((result: any) => {
        console.log(result);
        this.dialog.success('Day Emergency Data', 'Successfully Saved !!!');
        this.clear_form(myForm);
        this.formLoadingDay = false;
        this.getEmergencyData();
      }, error => {
        console.log(error);
        this.dialog.error('Something went wrong !!! Try again ...');
      });
  }


  updateDayEmergency(myForm: any) {
    this.formLoadingDay = true;
    console.log('*** Update Day Emergency Data ***');
    console.log(this.data_day);
    this.db.FetchData({ 'data': this.data_day }, 'Master_Emergency/updateEmergency')
      .subscribe((result: any) => {
        console.log(result);
        this.dialog.success('Day Emergency Data', 'Successfully Updated !!!');
        this.clear_form(myForm);
        this.formLoadingDay = false;
        this.getEmergencyData();
      }, error => {
        console.log(error);
        this.dialog.error('Something went wrong !!! Try again ...');
      });
  }

  formLoadingNight: any = false;
  submitNightEmergency(myForm: any) {
    this.formLoadingNight = true;
    console.log('*** SUBMIT NIGHT EMERGENCY DATA  ***');
    console.log(this.data_night);
    this.db.FetchData({ 'data': this.data_night, 'session': this.session.trico_user }, 'Master_Emergency/submitEmergency')
      .subscribe((result: any) => {
        console.log(result);
        this.dialog.success('Night Emergency Data', 'Successfully Saved !!!');
        this.clear_form(myForm);
        this.formLoadingNight = false;
        this.getEmergencyData();
      }, error => {
        console.log(error);
        this.dialog.error('Something went wrong !!! Try again ...');
      });
  }

  updateNightEmergency(myForm: any) {
    this.formLoadingNight = true;
    console.log('*** UPDATE NIGHT EMERGENCY DATA ***');
    console.log(this.data_night);
    this.db.FetchData({ 'data': this.data_night }, 'Master_Emergency/updateEmergency')
      .subscribe((result: any) => {
        console.log(result);
        this.dialog.success('Night Emergency Data', 'Successfully Updated !!!');
        this.clear_form(myForm);
        this.formLoadingNight = false;
        this.getEmergencyData();
      }, error => {
        console.log(error);
        this.dialog.error('Something went wrong !!! Try again ...');
      });
  }



}
