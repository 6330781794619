import { Component, OnInit } from '@angular/core';
import { SnackBarOverview } from 'src/app/toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { RemarkModalComponent } from '../remark-modal/remark-modal.component';
import {MatDialog,} from '@angular/material/dialog';
import { SessionService } from 'src/app/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss']
})
export class CallListComponent implements OnInit {


  skeleton_data : any = []
  skeleton_screen_active:any = false;
  constructor(public db: DatabaseService, public dialogAlert: DialogComponent, public toast: SnackBarOverview, public dialog: MatDialog, public session : SessionService,public route1:Router) {

    if(this.db.ListSearch.callstatus){
      this.search = this.db.ListSearch.callstatus
      this.start = this.db.ListSearch.start

       }
       else
       {
        this.search.status='Pending'
       }
      //  this.getTaskList(this.pageLimit,this.start,this.searchData.status);
    this.getCallList(this.pageLimit,this.start,this.search.status);
    }

    access_rights:any= {};
    ngOnInit() {

      console.log('*** CALL LIST LOADED ***');
      if(this.session.trico_user.access_level != '1')
      {
        this.session.trico_user.access_module.map((x:any)=>{
          if(x.module_id === 3)
          {
            this.access_rights = x;
          }
        });
        console.log(this.access_rights);
      }
      console.log(this.access_rights);

      this.skeleton_data = new Array(7);
    }

    data_not_found:any = false;
    pageLimit:any =20;
    start:any = 0;
    search:any = {};
    total_count:any;
    total_page:any;
    pageNumber:any;
    pageNumberTemp:any;
    callList:any = [];
    countData:any = {};


    getCallList(pageLimit:any=20,start:any=0,status:any='Pending')
    {
      this.pageLimit = pageLimit;
      this.start = start;
      this.skeleton_screen_active = true;
      this.data_not_found = false;
      this.search.status = status;


      this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'sharedData/getCallRequestList')
      .subscribe((result:any)=>{
        console.log(result);
        this.callList = result['callList'];
        this.total_count = result['totalCount'];
        this.countData = result['statusCountData'];
        this.total_page = Math.ceil(this.total_count/this.pageLimit);
        this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
        this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;

        console.log(this.pageNumber);
        if(this.callList.length != 0)
        {
          this.data_not_found = false;
        }
        else
        {
          this.data_not_found = true;
        }
        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);

        console.log('Data Not Found - '+this.data_not_found);
        console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.skeleton_screen_active = false;
      });
    }
    tothepage(id)
    {
      this.db.ListSearch.callstatus = this.search
      // this.db.ListSearch.pagelimit = this.pageLimit
      this.db.ListSearch.start = this.start

      console.log(id);
      this.route1.navigate(['customerdetail/'+id]);
      console.log(this.search.status)
console.log(this.db.ListSearch.callstatus);


    }


    deleteCallRequest(call_id:any,index:any){
      console.log(call_id);
      console.log(index);
      this.dialogAlert.delete('Call Request Data').then((result:any) => {
        console.log(result);
        if(result){
          this.db.FetchData( {'id':call_id}, 'sharedData/deleteCallRequest')
          .subscribe(res => {
            console.log(res);
            this.callList.splice(index,1);
            this.dialogAlert.success('Deleted','Call Request Data has been deleted.');
            this.getCallList();
          },err=>{
            console.log(err);
            this.dialogAlert.error('Something went wrong! Try Again ...');
          });
        }
      });
    }


    data:any = {};
    openRemark(call_id:any): void {

      this.data = this.callList.filter(x =>x.id == call_id)[0];
      console.log(this.data);

      const dialogRef = this.dialog.open(RemarkModalComponent, {
        width: '500px',
        data: { data : this.data}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.getCallList();
      });
    }

    exp_data:any=[];
    excel_data:any=[];

    getDataInExcel()
    {
      this.db.FetchData({'search':this.search},'sharedData/getCallRequestList').subscribe((result:any)=>
      {
          console.log(result);
          this.exp_data = result['callList'];

          for(let i=0;i<this.exp_data.length;i++)
          {
            this.excel_data.push({
              'Date':this.exp_data[i].dateCreated,
              'Customer Name.':this.exp_data[i].company_name,
              'Code':this.exp_data[i].customer_code,
              'State & District':this.exp_data[i].customer_state +','+ this.exp_data[i].customer_district,
              'Contact Person':this.exp_data[i].contact_person +'-'+ this.exp_data[i].contact_mobile,
              'Suitable Date & Time':this.exp_data[i].callOnDate +'-'+ this.exp_data[i].callOnTime,
              'Remark':this.exp_data[i].remark
            });
          }

          this.db.exportAsExcelFile(this.excel_data,'Call Back Request');

          this.excel_data = [];
          this.exp_data = [];

        }, error => {
          console.log(error)

        });
      }

      getSparePartList(page,start){

      }

  }
