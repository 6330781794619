import { Component, OnInit, Renderer2 } from '@angular/core';
import { RouterStateSnapshot, Router, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { DatabaseService } from '../_services/database.service';
import { DialogComponent } from '../_services/DialogComponent';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss']
})
export class LeftNavigationComponent implements OnInit {

  masterTab:any = false;
  taskTab:any = false;
  parameter:any;
  router:any;
report:any;
  type1 ="1";
  type2 ="2";
  type3 ="3";


  customer_code = "c0011";
  contact_name="bhanu";
  mobile_no="956088294";


  constructor(public routerData : Router, private renderer: Renderer2, public db : DatabaseService, public dialogAlert : DialogComponent, public session : SessionService) {
    this.router = routerData;
    console.log('*** CONSTRUCTOR - NAVIGATION LOAD ***');
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  access_module:any = {};

  ngOnInit() {
    this.getCounter();
    this.isActive('');
    console.log(this.session);

    console.log('*** LEFT NAVIGATION LOAD ***');
    console.log(this.session.trico_user.access_module);

    this.session.trico_user.access_module.map((x:any)=>{
      console.log(x);
      if(x.view === 1)
      {
        this.access_module[x.module_name] = true;
      }
      else{
        this.access_module[x.module_name] = false;
      }
    });
    console.log(this.access_module);
  }

  isActive(tab:any): boolean {
    if (this.router.currentInstruction && this.router.currentInstruction.component.routeData) {
      console.log('Active Tab '+this.router.currentInstruction.component.routeData.data['activeTab']);
      return tab == this.router.currentInstruction.component.routeData.data['activeTab'];
    }
    return false;
  }


  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.getCounter();
    }
    if (event instanceof NavigationEnd) {
    }
  }



  counter:any={};
  getCounter()
  {
    this.db.FetchData({'type':this.session.trico_user.access_level, 'STLCODE':this.session.trico_user.assign_detail},'Master_User/getCounter')
    .subscribe((result:any)=>{
      console.log(result);
      this.counter = result['counter'];

    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }


  status:boolean = false;

  toggleHeader() {
    this.renderer.removeClass(document.body, 'toggle-active');
  }



  logout(){
    this.session.LogOutSession();
    this.router.navigate(['']);
  }


}
