import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from 'src/app/dialog';

import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarOverview } from 'src/app/toast';
import { DatabaseService } from 'src/app/_services/database.service';


@Component({
    selector: 'app-technicians-add',
    templateUrl: './technicians-add.component.html',
    styleUrls: ['./technicians-add.component.scss']
})

export class TechniciansAddComponent implements OnInit {
    
    registerForm: FormGroup;
    submitted = false;
    
    stateList: any = [];
    districtList: any = [];
    
    designationList: any = [];
    
    isRequestInProcess: any = false;
    
    dropdownSettings: any = {};
    dropdownSettings1: any = {};
    dropdownSettings2: any = {};
    
    ecrpt_id:any;
    
    constructor(private formBuilder: FormBuilder,
        public db: DatabaseService,
        public route: Router,
        public toast: SnackBarOverview,
        public dialogAlert: DialogComponent,
        public activatedRoute : ActivatedRoute ) {
            
            this.activatedRoute.params.subscribe(params=>{
                console.log(params);
                this.ecrpt_id = params.id;
                console.log('*** PARAMETER ***');
                console.log(this.ecrpt_id);
                if(this.ecrpt_id != 'add')
                {
                    this.getTechnicianDetail();
                }

            });
        }
        
        ngOnInit() {
            
            this.getStateList();
            this.getDesignationList();
            this.onValidateFormHandler();
            this.onDropDownSettingHandler();
        }
        



        getTechnicianDetail()
        {
          console.log('*** GET USER DETAIL ***');
          this.db.FetchData({'user_id':this.ecrpt_id},'technician/getTechnicianDetail')
          .subscribe((result:any)=>{
            console.log(result);  

            this.registerForm.setValue({
                pincode: 'abc',
                street: 'def'
             });

             
            console.log(this.registerForm);
          },error=>{
            console.log(error);
            this.dialogAlert.error('Something went wrong !!! Try again ...');
          });
        }


        
        onSubmit() {
            
            this.submitted = true;
            
            console.log(this.registerForm);
            if (this.registerForm.invalid) {
                
                this.registerForm.get('name').markAsTouched();
                this.registerForm.get('designation').markAsTouched();
                this.registerForm.get('email').markAsTouched();
                this.registerForm.get('mobile').markAsTouched();
                this.registerForm.get('loginStatus').markAsTouched();
                this.registerForm.get('street').markAsTouched();
                this.registerForm.get('stateName').markAsTouched();
                this.registerForm.get('districtName').markAsTouched();
                this.registerForm.get('city').markAsTouched();
                this.registerForm.get('pincode').markAsTouched();
                
                return;
                
            } else {
                
                const registerData = JSON.parse(JSON.stringify(this.registerForm.value));

                console.log(this.registerForm.value);
                
                
                const inputData = {
                    data: registerData
                };
                
                inputData[`data`][`designation`] = this.registerForm.value[`designation`][0][`designation`];
                if(this.registerForm.value[`stateName`] == ''  && this.registerForm.value[`districtName`] == ''){

                    inputData[`data`][`stateName`] = this.registerForm.value[`stateName`];

                }else{

                inputData[`data`][`stateName`] = this.registerForm.value[`stateName`][0][`state_name`];
                inputData[`data`][`districtName`] = this.registerForm.value[`districtName`][0][`district_name`];


                }
                inputData[`data`][`city`] = this.registerForm.value[`city`];
                inputData[`data`][`pincode`] = this.registerForm.value[`pincode`];
                
                this.isRequestInProcess = true;
                this.db.FetchData( inputData, 'technician/onSubmitTechnicianData')
                .subscribe(result => {
                    
                    console.log(result);
                    
                    this.isRequestInProcess = false;
                    if (result[`status`] == 'error') {
                        
                        this.dialogAlert.error(result[`statusMessage`]);
                        
                    } else {
                        
                        setTimeout(() => {
                            
                            this.dialogAlert.success('Success',  'Service Engineer Saved successfully');

                            
                        }, 1000);

                        this.route.navigate(['/engineerlist']);

                    }
                    
                    
                }, error => {
                    
                    this.isRequestInProcess = false;
                    this.toast.openError('Something Went Wrong, Try Later','');
                });
            }
        }
        
        
        getStateList() {
            
            this.db.FetchData( {}, 'address/getStateList')
            .subscribe(result => {
                
                console.log(result);
                this.stateList = result[`stateList`];
                
            }, error => {
                
                // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
            });
        }
        
        
        getDistrictList() {
            
            this.districtList = [];
            
            console.log(this.registerForm.value);
            this.registerForm.get('districtName').setValue([]);
            
            if (this.registerForm.value[`stateName`] && this.registerForm.value[`stateName`][0]) {
                
                const stateName = this.registerForm.value[`stateName`][0][`state_name`];
                
                this.db.FetchData({stateName: stateName}, 'address/getDistrictList')
                .subscribe(result => {
                    
                    console.log(result);
                    this.districtList = result[`districtList`];
                    
                }, error => {
                    
                    // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
                });
            }
        }
        
        
        getDesignationList() {
            
            this.db.FetchData( {}, 'sharedData/getDesignationList')
            .subscribe(result => {
                
                console.log(result);
                this.designationList = result[`designationList`];
                
            }, error => {
                
                // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
            });
        }
        
        
        
        onValidateFormHandler() {
            
            this.registerForm = this.formBuilder.group({
                
                name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
                designation: ['', [Validators.required]],
                email: ['', [Validators.required, Validators.email]],
                mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                loginStatus: ['', [Validators.required]],
                street: [''],
                stateName: [''],
                districtName: [''],
                city: ['', [Validators.minLength(3), Validators.maxLength(30)]],
                pincode: ['', [Validators.minLength(6), Validators.maxLength(6)]]
            });
            
            this.registerForm.get('loginStatus').setValue('Yes');
        }
        
        onDropDownSettingHandler() {
            
            this.dropdownSettings = {
                singleSelection: true,
                idField: 'designation',
                textField: 'designation',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };
            
            this.dropdownSettings1 = {
                singleSelection: true,
                idField: 'state_name',
                textField: 'state_name',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };
            
            this.dropdownSettings2 = {
                singleSelection: true,
                idField: 'district_name',
                textField: 'district_name',
                allowSearchFilter: true,
                maxHeight: 197,
                closeDropDownOnSelection: true
            };
        }
        
        onReset() {
            this.submitted = false;
            this.registerForm.reset();
        }
        
        numberOnly(event): boolean {
            
            const charCode = (event.which) ? event.which : event.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                return false;
            }
            return true;
        }
        
        get f() { return this.registerForm.controls; }
        
    }
    