import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-engineer-report',
  templateUrl: './engineer-report.component.html',
  styleUrls: ['./engineer-report.component.scss']
})
export class EngineerReportComponent implements OnInit {
  objectKeys = Object.keys;
  skeleton_data: any = []
  Products: any = []
  optionValue: any;
  searchData: any = {};
  public ProductDetails: object = [];
  lv: any;
  search_val: any = {}
  isLoadingInProcess: any = false;
  currentDate:any;
  calenderMinDate:any;

  constructor(public db: DatabaseService) {
      this.currentDate = new Date();

  }

  ngOnInit() {


    // this.getEngineerData();
    this.skeleton_data = new Array(7);
  }
  techList2: any = [];

  techList: any = [];
  techList1: any = [];
  data_not_found: any = false;
  search: any = {};
  tmp_userList: any = [];
  taskList: any = [];

  tmpsearch: any = {};

  getEngineerData() {
    if (this.searchData[`date_from`] && this.searchData[`date_to`]) {
      console.log(this.searchData)

      const printContents = document.getElementById('printData');
      if (this.searchData[`date_from`]) {
        this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }
      this.isLoadingInProcess = true;
      console.log(this.isLoadingInProcess);

      this.db.FetchData(this.searchData, 'Task/engineerData').subscribe(resultData => {
        console.log(resultData);
        this.techList1 = resultData;
        this.isLoadingInProcess = false;

        this.techList = resultData;
        console.log(this.techList);
        this.taskList = resultData['open_complaint_info'];


        console.log(this.techList);

        this.techList2 = [];
        for (var i = 0; i < this.techList.length; i++) {
          if (this.techList[i].user_name != null) {
            console.log(this.techList[i]);

            this.techList2.push(this.techList[i])

          }
          console.log(this.taskList);

        }

      });



    }


  }

  getItemsList(search) {
    console.log(search);

    this.techList1 = [];
    for (var i = 0; i < this.tmp_userList.length; i++) {
      search = search.toLowerCase();
      this.tmpsearch = this.tmp_userList[i]['curr_status'].toLowerCase();
      if (this.tmpsearch.includes(search)) {
        this.techList1.push(this.tmp_userList[i]);
      }
    }
    console.log(this.techList1);

  }
  opval = false;
  title = 'Engineer Request';
  time: any
  date: any
  get_payments(optionValue, action: any = '') {
    console.log(this.search.curr_status);

    if (action == "refresh") {


      this.search.curr_status = '';
      this.techList1 = [];

    }



    if (optionValue != 'eng') {
      this.opval = true;
      this.title = optionValue + ' Service Request';
    }
    else {
      this.opval = false;
      this.title = 'Engineer Request';
      this.getEngineerData();
    }
    console.log(optionValue);
    if (this.searchData[`date_from`]) {
      this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
    }
    if (this.searchData[`date_to`]) {
      this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
    }
    this.isLoadingInProcess = true;
    console.log(this.isLoadingInProcess);

    this.db.FetchData({ report_type: this.optionValue, 'searchdata': this.searchData }
      , 'Task/customerData').subscribe(resultData => {
        console.log(resultData);
        this.techList1 = resultData;
        this.isLoadingInProcess = false;

        console.log(this.techList1);
        this.tmp_userList = this.techList1;
        for (let index = 0; index < this.techList1.length; index++) {

          this.techList1[index].company_name = this.techList1[index].company_name + ' - ' + this.techList1[index].cust_code;
          var t = this.techList1[index].task_created_date
          var a = t.split(" ");
          this.techList1[index].task_created_date = a[0];
          this.techList1[index].time = a[1];
          console.log(this.date);
          console.log(this.time);
          var s = this.techList1[index].clouserDate
          var b = s.split(" ");
          this.techList1[index].clouserDate = b[0];
          this.techList1[index].date = b[1];
          console.log(this.date);
          console.log(this.time);

        }

      });
  }
  exp_data1: any;
  excel_data: any = [];
  getDataInExcel() {
    this.db.FetchData({
    }, 'Task/customerData').subscribe(resd => {
      console.log(resd);
      this.exp_data1 = resd;
      console.log(this.exp_data1);
    });
  }

  refresh() {
    this.searchData = {};
    this.search.curr_status = ''
    if (this.optionValue == "eng") {
      this.get_payments("eng");
    }
    if (this.optionValue == "MV") {
      this.get_payments("MV");
    }

    if (this.optionValue == "LV") {
      this.get_payments("LV");
    }


  }
  fileName = 'EngineerComplaint.xlsx';
  exp_data: any = [];
  exportexcel(): void {
    let Index = 1;
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }
  exportexcel1() {
    this.isLoadingInProcess = true;

    this.db.FetchData({ report_type: this.optionValue, 'searchdata': this.searchData }, 'Task/customerData').subscribe(resultData => {
      this.exp_data = resultData
      console.log(resultData);

      for (let i = 0; i < this.exp_data.length; i++) {
        this.exp_data[i].company_name = this.exp_data[i].company_name + ' - ' + this.exp_data[i].cust_code;
        this.exp_data[i].comp_contact_person = this.exp_data[i].comp_contact_person + ' - ' + this.exp_data[i].comp_contact_mobile;
        var t = this.exp_data[i].task_created_date
        var a = t.split(" ");
        this.exp_data[i].task_created_date = a[0];
        this.exp_data[i].time = a[1];
        console.log(this.date);
        console.log(this.time);
        var s = this.exp_data[i].clouserDate
        var b = s.split(" ");
        this.exp_data[i].clouserDate = b[0];
        this.exp_data[i].date = b[1];
        console.log(this.date);
        console.log(this.time);

        this.excel_data.push({
          'Request No': this.exp_data[i].service_req_no,
          'Opening Date': this.exp_data[i].task_created_date,
          'Closing Date': this.exp_data[i].clouserDate,
          ' Actual No. Of Closure Days': this.exp_data[i].days_diff,
          'Service Tyoe': this.exp_data[i].transaction_type,
          'Customer Name': this.exp_data[i].company_name,

          'Project Name': this.exp_data[i].project_name,
          'So No': this.exp_data[i].so_no,


          'Detail Description': this.exp_data[i].description,
          'Assigned Engineer ': this.exp_data[i].technicianName,

          // 'Request Login  Time':this.exp_data[i].time,
          'Customer Contact Person / Mo. No..': this.exp_data[i].comp_contact_person,
          // 'Closure Time':this.exp_data[i].date,
          'Request Status': this.exp_data[i].curr_status,
        });
      }

      if (this.optionValue == 'LV') {
        this.db.exportAsExcelFile(this.excel_data, 'LV Request');
      }
      if (this.optionValue == 'MV') {
        this.db.exportAsExcelFile(this.excel_data, 'MV Request');
      }
      this.excel_data = [];
      this.exp_data = [];

    }, error => {
      console.log(error)

    });
    this.isLoadingInProcess = false;

  }
  fileName2 = 'LvServiceComplaint.xlsx';
  // exportexcel2(): void {
  //   let Index=1;
  //    let element2 = document.getElementById('excel2-table');
  //    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element2);
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Sheet2');
  //      XLSX.writeFile(wb, this.fileName2);
  // }
  SearchProduct(name: string) {
    let obj = this.Products.filter(m => m.Name == name);
    this.ProductDetails = obj;
    return this.ProductDetails;
  }



}
