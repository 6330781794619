import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-priority-list',
  templateUrl: './priority-list.component.html',
  styleUrls: ['./priority-list.component.scss']
})
export class PriorityListComponent implements OnInit {
  
  skeleton_data: any = []
  skeleton_screen_active:any = false;
  constructor(public db : DatabaseService, public dialogAlert : DialogComponent, public session : SessionService) { }
  
  ngOnInit() {
    this.skeleton_data = new Array(7);
    this.getOperatorList();
    this.getUserList();
    this.getPriorityList();
    this.getTransactioneTypeList()
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 12||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};
  
  operatorList:any = [];
  getOperatorList()
  {
    this.db.FetchData({},'Master_Priority/getOperatorList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.operatorList = result['operator'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
  }
  
  userList:any = [];
  getUserList()
  {
    this.db.FetchData({},'Master_Priority/getUserList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.userList = result['user'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
  }
  
  
  escalation_data:any = {};
  
  priorityUserList:any = [];
  addPriorityUser()
  {
    this.escalation_data.mail_alert = this.escalation_data.alert_mail;
    if(this.escalation_data.mail_alert == true){

      this.escalation_data.mail_alert = 'MAIL';

    }

    this.escalation_data.sms_alert = this.escalation_data.alert_sms;

    if(this.escalation_data.sms_alert == true){

      this.escalation_data.sms_alert = 'SMS';
    }

    console.log(this.escalation_data.mail_alert );
    
    console.log(this.escalation_data.sms_alert);
    
    console.log(this.priorityUserList);

    console.log(this.escalation_data.user_id);
    
    var getRow = this.priorityUserList.filter(x => (x.user_id == this.escalation_data.user_id))[0];
    console.log(getRow);
    
    if(getRow !=  undefined)
    {
      var TempIndex = this.priorityUserList.indexOf(getRow);
      console.log(TempIndex);
    }
    console.log(TempIndex);
    
    if(TempIndex != undefined)
    {
      console.log('repeated add');
      this.dialogAlert.error('User already in list');
    }
    else{
      console.log('add');
      this.priorityUserList.push(this.escalation_data);
    }
    
    console.log(this.escalation_data);
    console.log(this.priorityUserList);
    this.escalation_data = {};
    
  }
  
  
  removePriorityUser(index:any)
  {
    console.log(index);
    this.priorityUserList.splice(index,1);
  }
  
  clearForm(myForm:any)
  {
    this.data = {};
    this.editData = {};
    this.escalation_data = {};
    this.priorityUserList = [];
    myForm.resetForm();
  }
  
  data:any = {};
  formLoading:any = false;
  submitPriorityEscalation(myForm:any)
  {
    console.log('*** SUBMIT PRIORITY ESCALATION ***');
    this.formLoading = true;
    console.log(this.data);
    console.log(this.priorityUserList);
    
    this.db.FetchData({'data':this.data, 'priorityUser' : this.priorityUserList, 'session':this.session.trico_user},'Master_Priority/submitPriorityEscalation')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success') {
        this.dialogAlert.success('Priority Data','Successfully Saved')
        this.clearForm(myForm);
        this.getPriorityList();
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;  
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });  
  }
  

  onActivate(event:any) {
    console.log(event);
    document.body.scrollTop = 0;
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}
  
  updatePriorityEscalation(myForm:any)
  {
    console.log('*** UPDATE PRIORITY ESCALATION ***');
    this.formLoading = true;
    console.log(this.data);
    console.log(this.priorityUserList);
    
    this.db.FetchData({'data':this.data, 'priorityUser' : this.priorityUserList},'Master_Priority/updatePriorityEscalation')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success') {
        this.dialogAlert.success('Priority Data','Successfully Updated')
        this.clearForm(myForm);
        this.getPriorityList();
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });  
  }



  priorityList:any = [];
  totalCount:any = '';
  data_not_found:any = false;
  getPriorityList()
  {
    console.log('*** PRIORITY LIST ***');
    this.skeleton_screen_active = true;
    this.db.FetchData({},'Master_Priority/getPriorityList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.priorityList = result['priority'];
      console.log(this.priorityList);
      
      this.totalCount = result['count'];

      if(this.priorityList.length != 0)
      {
        this.data_not_found = false; 
      }
      else
      {
        this.data_not_found = true; 
      }      
      this.skeleton_screen_active = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
    
  }
  
  editData:any ={};
  escalation = {};
  transactionTypeList:any=[];
  getTransactioneTypeList() {


    this.db.FetchData({}, 'sharedData/getTransactioneTypeList')
        .subscribe(result => {
            console.log(result);

            this.transactionTypeList = result[`transactionTypeList`];
            console.log(this.transactionTypeList);

        });


}
  editPriority(priority_id:any)
  {
    console.log('*** EDIT PRIORITY DATA ***');
    
    this.editData = Object.assign({},this.priorityList.filter( (x:any) => x.id == priority_id)[0]);
    this.data.id = this.editData.id;
    this.data.priority_id = this.editData.id;
    this.data.name = this.editData.name;
    this.data.type = this.editData.type;
    
    console.log(this.data);
    this.priorityUserList = Object.assign([],this.editData.priority_user);
    console.log(this.priorityUserList);

  }

  
  deletePriority(priority_id:any,index:any){
    console.log(priority_id);
    console.log(index);
    this.dialogAlert.delete('Priority Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':priority_id}, 'Master_Priority/deletePriority')
        .subscribe(res => {
          console.log(res);
          this.priorityList.splice(index,1);
          this.dialogAlert.success('Deleted','Priority Data has been deleted.');
          this.getPriorityList();
        },err=>{
          console.log(err);
          this.dialogAlert.error('Something went wrong! Try Again ...');
        });
      }
    });
  }
  exp_data:any=[];
  excel_data:any=[];
  getDataInExcel()
  {
    this.db.FetchData({},'Master_Priority/getPriorityList').subscribe(resultData => 
      {
        console.log(resultData);
        this.exp_data = resultData['priority'];
        for(let i=0;i<this.exp_data.length;i++){

this.exp_data
        }
        
        for(let i=0;i<this.exp_data.length;i++)
        {
          for(let j = 0; j < this.exp_data[i].priority_user.length; j++)
          {
            if(this.exp_data[i].priority_user[j].alert_mail==1){
              this.exp_data[i].priority_user[j].alert_mail="MAIL"
            }
            if(this.exp_data[i].priority_user[j].alert_mail==0){
              this.exp_data[i].priority_user[j].alert_mail="  "
            }
            if(this.exp_data[i].priority_user[j].alert_sms==1){
              this.exp_data[i].priority_user[j].alert_sms="SMS"
            }
            if(this.exp_data[i].priority_user[j].alert_sms==0){
              this.exp_data[i].priority_user[j].alert_sms="  "
            }
            this.excel_data.push({
              'Priority':this.exp_data[i].name,
              ' Service Type':this.exp_data[i].type,
              ' Name':this.exp_data[i].priority_user[j].name,
              'DaY of Request Levels':this.exp_data[i].priority_user[j].operator+ '  '+ this.exp_data[i].priority_user[j].complaint_days ,
              'Alert Type':this.exp_data[i].priority_user[j].alert_mail+ '  '+ this.exp_data[i].priority_user[j].alert_sms
             
            });
        }}
        
        this.db.exportAsExcelFile(this.excel_data,'Priorty list');

        this.excel_data = [];
        this.exp_data = [];
        
      }, error => {
        console.log(error)
        
      });
    }
  
}
