import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogComponent } from '../dialog';
import { DatabaseService } from '../_services/database.service';

@Component({
  selector: 'app-feedbackdocument',
  templateUrl: './feedbackdocument.component.html',
  styleUrls: ['./feedbackdocument.component.scss']
})
export class FeedbackdocumentComponent implements OnInit {

  constructor(public dialogRef: MatDialog, public dialogAlert : DialogComponent,  @Inject(MAT_DIALOG_DATA) public stored_data :any, public db : DatabaseService) { }
  attachment_data:any = [];
  ngOnInit() {
    this.attachment_data = Object.assign([],this.stored_data.data);
    console.log(this.attachment_data);
  }

  
  
  
}
