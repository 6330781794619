import { Component, OnInit } from '@angular/core';
import { DBRequest } from '../../databaseService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from 'src/app/dialog';

import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarOverview } from 'src/app/toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { query } from '@angular/animations';

@Component({
  selector: 'app-customer-project-add',
  templateUrl: './customer-project-add.component.html',
  styleUrls: ['./customer-project-add.component.scss']
})
export class CustomerProjectAddComponent implements OnInit {

    registerForm: FormGroup;
    contactForm: FormGroup;
    submitted = false;
    projectId:any;

    contactData: any =  [];
    stateList: any = [];
    districtList: any = [];

    customerEncryptId: any = '';

    isRequestInProcess: any = false;

    dropdownSettings: any = {};
    dropdownSettings1: any = {};
      projectDetail: any;
      contactPerson:any;
      contactUpdate:any={id:'',name:'',designation:'',email:'',mobile:''};  
      
      

    constructor(private formBuilder: FormBuilder,
                public dbService: DBRequest,
                public alert: DialogComponent,
                public toast: SnackBarOverview,
                public route: ActivatedRoute,
                public redirect: Router,public db:DatabaseService) {

                  this.route.params.subscribe(params=>{
                        console.log(params);
                        this.customerEncryptId = params.customerId;
                        this.projectId = params.projectId;
                        console.log('*** PARAMETERs ***');
                        console.log(this.projectId);
                      });
                      
                      if(this.projectId != 'new')
                      {
                        this.getProjectDetail();
                       
                        
                      }
                      else{
                        this.projectDetail=[];
                        this.contactPerson=[];

                      }
                     


      //    this.route.params.subscribe(params => {

      //               console.log(params);
                    

      //               console.log(this.customerEncryptId);
      //     });


    }
    getProjectDetail() 
  {
    console.log('*** GET PROJECT DETAIL ***');
    this.db.FetchData({'projectId':this.projectId},'Project/getProjectDetail')
    .subscribe((result:any)=>{
      console.log(result);
      this.projectDetail=result['projectDetail'];

      this.projectDetail.state_name = [{state_name: this.projectDetail.state_name}];
      this.projectDetail.district_name=[{district_name:this.projectDetail.district_name}];
      
      
      this.contactPerson=result['contact_person'];
          
     
      




    });

}

  

    ngOnInit() {

        this.getStateList();
        this.onValidateFormHandler();
        this.onDropDownSettingHandler();
    }
  

    onSubmit() {

      this.submitted = true;

      console.log(this.registerForm);
      
      if (this.registerForm.invalid) {
            // || this.contactForm.invalid

            this.registerForm.get('projectName').markAsTouched();
            this.registerForm.get('street').markAsTouched();
            this.registerForm.get('stateName').markAsTouched();
            this.registerForm.get('districtName').markAsTouched();
            this.registerForm.get('city').markAsTouched();
            this.registerForm.get('pincode').markAsTouched();

            // this.contactForm.get('contactName').markAsTouched();
            // this.contactForm.get('designation').markAsTouched();
            // this.contactForm.get('contactEmail').markAsTouched();
            // this.contactForm.get('contactMobile').markAsTouched();

            return;

      } else {

            const contactData = this.contactForm[`value`];
            this.onValidateContactEmptyDetailHandler(contactData);

            if (contactData[`contactName`] && contactData[`designation`] && contactData[`contactEmail`] && contactData[`contactMobile`]) {
                  this.onAddContactHandler();
            }

            const inputData = {
                data: JSON.parse(JSON.stringify(this.registerForm.value)),
            };
            if(this.projectId=='new'){}
            else{
            inputData[`data`][`projectId`]=this.projectId;
            }
            inputData[`data`][`customerId`] = this.customerEncryptId;

            inputData[`data`][`stateName`] = this.registerForm.value[`stateName`][0][`state_name`];
            inputData[`data`][`districtName`] = this.registerForm.value[`districtName`][0][`district_name`];
            inputData[`data`][`contactData`] = JSON.parse(JSON.stringify(this.contactData));

            console.log(this.registerForm.value);
            console.log(inputData);

            this.isRequestInProcess = true;

            this.dbService.onPostRequestData( inputData, 'project/onSubmitProjectData')
            .subscribe(result => {

                console.log(result);
                this.isRequestInProcess = false;

                if (result[`status`] == 'error') {

                    this.alert.error(result[`statusMessage`]);

                } else {

                    this.redirect.navigate(['/customer-project-list/' + this.customerEncryptId + '']);

                    setTimeout(() => {

                        if(this.projectId=='new'){

                      this.alert.success('Success',  'Project Saved successfully');
                        }
                        else{
                              this.alert.success('Success',  'Project Updated successfully');

                        }

                    }, 1000);
                }

            }, error => {

                    this.isRequestInProcess = false;
                    this.toast.openError('Something Went Wrong, Try Later','');
            });
      }
    }
    


    getStateList() {

        this.dbService.onPostRequestData( {}, 'address/getStateList')
        .subscribe(result => {

            console.log(result);
            this.stateList = result[`stateList`];
            console.log(this.stateList);
            

        }, error => {

              // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
        });
    }


    getDistrictList() {

      this.districtList = [];
      console.log(this.projectDetail.state_name);
      

      console.log(this.registerForm.value);
      this.registerForm.get('districtName').setValue([]);

      if (this.registerForm.value[`stateName`] && this.registerForm.value[`stateName`][0]) {

            const stateName = this.registerForm.value[`stateName`][0][`state_name`];

            this.dbService.onPostRequestData({stateName: stateName}, 'address/getDistrictList')
            .subscribe(result => {

                console.log(result);
                this.districtList = result[`districtList`];

            }, error => {

                  // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
            });
      }
  }



  onAddContactHandler() {

    const contactData = this.contactForm.value;

    console.log(contactData);


    let isContactEmpty = false;
    if (!contactData[`contactName`] || !contactData[`contactEmail`] || !contactData[`contactMobile`]) {

          isContactEmpty  = true;
    }

    if (this.contactForm.invalid || isContactEmpty){

          this.registerForm.get('projectName').markAsTouched();
          this.registerForm.get('street').markAsTouched();
          this.registerForm.get('stateName').markAsTouched();
          this.registerForm.get('districtName').markAsTouched();
          this.registerForm.get('city').markAsTouched();
          this.registerForm.get('pincode').markAsTouched();

          this.contactForm.get('contactName').markAsTouched();
          // this.contactForm.get('designation').markAsTouched();
          this.contactForm.get('contactEmail').markAsTouched();
          this.contactForm.get('contactMobile').markAsTouched();


          if (isContactEmpty) {
              this.alert.error('Fill Contact All Details To Continue!');
          }

          return;
    }


    this.onValidateContactEmptyDetailHandler(contactData);

    this.contactData.push({

          contactName: contactData[`contactName`],
          designation: contactData[`designation`],
          contactEmail: contactData[`contactEmail`],
          contactMobile: contactData[`contactMobile`],
    });

    const contactNameControl = this.contactForm.get('contactName');
    const designationControl = this.contactForm.get('designation');
    const contactEmailControl = this.contactForm.get('contactEmail');
    const contactMobileControl = this.contactForm.get('contactMobile');

    contactNameControl.setValidators([Validators.minLength(3), Validators.maxLength(30)]);
    designationControl.setValidators([Validators.minLength(3), Validators.maxLength(30)]);
    contactEmailControl.setValidators([Validators.email]);
    contactMobileControl.setValidators([Validators.minLength(10), Validators.maxLength(10)]);

    contactNameControl.updateValueAndValidity();
    designationControl.updateValueAndValidity();
    contactEmailControl.updateValueAndValidity();
    contactMobileControl.updateValueAndValidity();

    this.contactForm.get('contactName').setValue('');
    this.contactForm.get('designation').setValue('');
    this.contactForm.get('contactEmail').setValue('');
    this.contactForm.get('contactMobile').setValue('');
}


onValidateContactEmptyDetailHandler(contactData) {

        let isAnyContactColFilled = false;
        let isAnyContactColEmpty = false;
        if (contactData[`contactName`]  || contactData[`contactEmail`] || contactData[`contactMobile`]) {

              isAnyContactColFilled = true;
        }

        if (!contactData[`contactName`]  || !contactData[`contactEmail`] || !contactData[`contactMobile`]) {
              isAnyContactColEmpty = true;
        }

        if (isAnyContactColFilled && isAnyContactColEmpty) {

            this.alert.error('Fill Contact All Details To Proceed !');
            return false;
        }
}


onContactDeleteHandler(index) {

      this.alert.confirmation('').then((confirmResult) => {

            if (confirmResult) {

                 this.contactData.splice(index, 1);

                 if (this.contactData.length == 0) {

                      const contactNameControl = this.contactForm.get('contactName');
                      const designationControl = this.contactForm.get('designation');
                      const contactEmailControl = this.contactForm.get('contactEmail');
                      const contactMobileControl = this.contactForm.get('contactMobile');

                      contactNameControl.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30)]);
                      designationControl.setValidators([ Validators.minLength(3), Validators.maxLength(30)]);
                      contactEmailControl.setValidators([Validators.required, Validators.email]);
                      contactMobileControl.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);

                      contactNameControl.updateValueAndValidity();
                      designationControl.updateValueAndValidity();
                      contactEmailControl.updateValueAndValidity();
                      contactMobileControl.updateValueAndValidity();
                 }
            }
      });
  }


  onValidateFormHandler() {

        this.registerForm = this.formBuilder.group({

              projectName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
              street: [''],
              stateName: ['', Validators.required],
              districtName: ['', Validators.required],
              city: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
              pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
        });


        this.contactForm = this.formBuilder.group({

              contactName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
              designation: ['', [Validators.minLength(3), Validators.maxLength(30)]],
              contactEmail: ['', [Validators.required, Validators.email]],
              contactMobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        });
  }

  onDropDownSettingHandler() {

        this.dropdownSettings = {
            singleSelection: true,
            idField: 'state_name',
            textField: 'state_name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings1 = {
            singleSelection: true,
            idField: 'district_name',
            textField: 'district_name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };
  }

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

  numberOnly(event): boolean {

      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  }

  deleteContact(mail,index) {
    console.log(mail);
    console.log(index);

    this.alert.confirmation('').then((confirmResult) => {

          if (confirmResult) {

            this.contactPerson.splice(index, 1);
               this.db.FetchData( {'mail':mail}, 'Project/deleteContact')
               .subscribe(res => {
                 console.log(res);
                 
                 
                 
               });
    
                 
     
               }
          
    }
    );


  }

  editContact(i){
        
        
      this.contactUpdate=this.contactPerson[i]
       
        console.log(this.contactUpdate);

  }
  updateContact(){
     
      
      this.dbService.onPostRequestData( {'data':this.contactUpdate}, 'project/updateContact')
      .subscribe(result => {

          console.log(result);
          

          if (result[`status`] == 'error') {

              this.alert.error(result[`statusMessage`]);

          } else {

              

              setTimeout(() => {

                this.alert.success('Success',  'Contact Updated successfully');

              }, 1000);
          }

      }, error => {

              this.isRequestInProcess = false;
              this.toast.openError('Something Went Wrong, Try Later','');
      });

      this.contactUpdate={id:'',name:'',designation:'',email:'',mobile:''};   
}


  get f() { return this.registerForm.controls; }
  get p() { return this.contactForm.controls; }


}
