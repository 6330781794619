import { OnInit, Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })


export class DialogComponent implements OnInit {
  closeAll() {
    throw new Error("Method not implemented.");
  }
  
  constructor() { }
  
  ngOnInit() {
  } 
  
  confirmation(msg:any){
    return  Swal.fire({
      title: 'Are you sure?',
      text: 'You change in to '+msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Change it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        return true;
        // Swal.fire(
        //   'Deleted!',
        //   'Your imaginary file has been deleted.',
        //   'success'
        //   )
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your '+ msg +' data is not modfied :)',
          'error'
          )
        }
      });
    }
    
    
    delete(msg:any){
     return Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this '+msg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          return true;
          //  Swal.fire(
          // 'Deleted!',
          // 'Your imaginary file has been deleted.',
          // 'success'
          // )
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your '+ msg +' data is not modfied :)',
            'error'
            )
          }
        })
      }
      
      success(title:any,msg:any){
        Swal.fire(
          title+'!',
          msg+'.',
          'success'
          )
        }
        
        error(msg:any){
          Swal.fire(
            'Oops...',
            msg+'.',
            'error'
            )
          }
          
          // error(msg:any){
          //   Swal.fire({
          //     type: 'error',
          //     title: 'Oops...',
          //     text: msg,
          //     // footer: '<a href>Why do I have this issue?</a>'
          //   })
          // }
          
        }