import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import { CustomerDocumentAddComponent } from '../customer-document-add/customer-document-add.component';
import { DocumentsModalComponent } from 'src/app/master/documents/documents-modal/documents-modal.component';

import { CostomerDocumentModalOpenComponent } from '../costomer-document-modal-open/costomer-document-modal-open.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { SessionService } from 'src/app/session.service';


@Component({
      selector: 'app-customer-document-list',
      templateUrl: './customer-document-list.component.html',
      styleUrls: ['./customer-document-list.component.scss']
})
export class CustomerDocumentListComponent implements OnInit {
id: any;
      constructor(public dialog: MatDialog,
                  public db: DatabaseService,
                  public alert: DialogComponent,
                  public toast: SnackBarOverview,
                  public route: ActivatedRoute,
                  public session : SessionService,) {

                  this.calenderMinDate = new Date('1950-01-01');
                  this.currentDate = new Date();
            }
list: any = {};
      customerEncryptId: any = '';
      skeleton_data: any = [];
      customerData: any = {};
      customerContactData: any = [];

      listData: any = [];

      searchData: any = {};

      start: any = 0;
      pageLimit: any = 50;

      allCountData: any = {};

      calenderMinDate: any;
      currentDate: any;

      isRequestInProcess: any = true;

      totalPage: any = '';
      pageNumber: any = '';
      totalCount: any = '';


            data: any = {};
            access_rights:any = {};

            ngOnInit() {
                  if(this.session.trico_user.access_level != '1')
                  {
                    this.session.trico_user.access_module.map((x:any)=>{
                      if(x.module_id === 2)
                      {
                        this.access_rights = x;
                      }
                    });
                    console.log(this.access_rights);
                  }
                  this.skeleton_data = new Array(7);
                  this.route.params.subscribe(params => {

                        console.log(params);
                        this.customerEncryptId = params.customerId;

                        console.log(this.customerEncryptId);
                        this.getListData();
                        this.onGetAllCounterData();
                        this.onGetCustomerDetail();

                  });
            }




            onGetCustomerDetail() {

                  this.isRequestInProcess = true;

                  this.db.FetchData({

                        customerId: this.customerEncryptId

                  }, 'customer/onCustomerDetail').subscribe(resultData => {

                        console.log(resultData);

                        setTimeout(() => {

                              this.isRequestInProcess = false;

                        }, 500);

                        this.customerData = resultData[`customerData`];
                        this.customerContactData = resultData[`customerContactData`];

                  }, error => {

                        this.isRequestInProcess = false;
                        this.toast.openError('Something Went Wrong, Try Later', '');
                  });

            }


            getListData() {

                  let isSearchDataExist = false;
                  for (const key in this.searchData) {

                        if (this.searchData.hasOwnProperty(key) && this.searchData[key]) {
                              isSearchDataExist = true;
                        }
                  }

                  if (this.searchData[`date`]) {

                        this.searchData[`dateCreated`] = moment(this.searchData[`date`]).format('YYYY-MM-DD');
                  }

                  if (!isSearchDataExist) {
                        this.isRequestInProcess = true;
                  }

                  this.db.FetchData({

                        start: this.start,
                        customerId: this.customerEncryptId,
                        searchData: this.searchData

                  }, 'customer/getCustomerDocumentList').subscribe(resultData => {

                        console.log(resultData);

                        setTimeout(() => {

                              if (!isSearchDataExist) {
                                    this.isRequestInProcess = false;
                              }

                        }, 500);

                        this.listData = resultData[`listData`];

                        this.totalCount = resultData[`totalCount`];

                        this.totalPage = Math.ceil(this.totalCount / this.pageLimit);
                        this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;

                  }, error => {

                        this.isRequestInProcess = false;
                        this.toast.openError('Something Went Wrong, Try Later', '');

                  });
            }


            onPageInputHandler(type) {

                  if (type == 'greater') {

                        this.pageNumber = this.totalPage;
                        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
                        this.getListData();

                  } else {

                        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
                        this.getListData();

                  }
            }


            onGetAllCounterData() {

                  this.db.FetchData({

                        customerId: this.customerEncryptId

                  }, 'customer/customerTotalCountData').subscribe(resultData => {

                        console.log(resultData);

                        this.allCountData = resultData[`allCountData`];

                  }, error => {
                        this.toast.openError('Something Went Wrong, Try Later', '');
                  });

            }



            deleteDocument( doc_id: any, index: any) {
                  console.log(doc_id);
                  console.log(index);
                  this.alert.delete('Document Data Attachment').then((result: any) => {
                        console.log(result);
                        if (result) {
                              this.db.FetchData( {id: doc_id}, 'customer/deleteDocument')
                              .subscribe(res => {
                                    console.log(res);
                                    this.listData.splice(index, 1);
                                    this.alert.success('Deleted', 'Document Data Attachment has been deleted.');
                                    this.dialog.closeAll();
                              }, err => {
                                    console.log(err);
                                    this.alert.error('Something went wrong! Try Again ...');
                              });
                        }
                  });
            }
            openDocumentDialog(id: any): void 
            {
                  console.log(this.listData);
                  this.data =  Object.assign({}, this.listData.filter((x: any) => x.id == id)[0].attachment);
                  console.log(this.data);
                  const dialogRef = this.dialog.open(CostomerDocumentModalOpenComponent, {
                        width: '600px',
                        data: {
                              data : this.data
                        }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                        console.log('The dialog was closed');
                        this.getListData();
                  });
            }


            openDialog(): void {
                  const dialogRef = this.dialog.open(CustomerDocumentAddComponent, {
                        width: '500px',
                        data: {data: this.customerData}
                  });

                  dialogRef.afterClosed().subscribe(result => {
                        console.log('The dialog was closed');
                        this.getListData();
                  });
            }




            sendSms(id: any) {
                  alert(id);


              this.db.FetchData({'cust_doc_id': id},'customer/sendDocumentLinkToCustomer')
                  .subscribe((result => {
                      console.log(result);

                  }));
              }




      }
