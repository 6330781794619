import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-contact-person-model',
  templateUrl: './contact-person-model.component.html',
  styleUrls: ['./contact-person-model.component.scss']
})
export class ContactPersonModelComponent implements OnInit {

  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog) { }

  contactPersonList:any = [];

  data:any = {};


  ngOnInit() {
    this.contactPersonList = Object.assign([],this.stored_data.data);
    console.log(this.contactPersonList);

  
  }
 
  cp_data:any = {};
  addContactPerson()
  {
    this.cp_data.customer_id = this.contactPersonList[0].customer_id;
    this.cp_data.project_id = this.contactPersonList[0].project_id;
    this.cp_data.created_by = this.contactPersonList[0].created_by;
    this.cp_data.created_by_name = this.contactPersonList[0].created_by_name;
    this.cp_data.created_by_type = this.contactPersonList[0].created_by_type;


    console.log(this.cp_data);
    this.contactPersonList.push(this.cp_data);
    console.log('*** CONTACT PERSON LIST ***');
    console.log(this.contactPersonList);
    this.cp_data = {};
  }
  
  removeContactPerson(index:any)
  {
    console.log(index);
    this.contactPersonList.splice(index,1);
  }

  formLoading:any = false;

  addNewContactDetails(){

    console.log(this.contactPersonList);

    this.db.FetchData({'contact_person':this.contactPersonList},'project/addNewContactDetails')
      .subscribe((result:any)=>{
        console.log(result);

        if(result['msg'] == 'success') {
          this.dialogAlert.success('Task Contact Data','Successfully Updated')
        }
        else{
          var error = '';
          for (let index = 0; index < result['error'].length; index++) {
            error += result['error'][index]['message']+ ' ' ;  
          }
          this.dialogAlert.error(error);
          this.formLoading = false;
        }
        this.formLoading = false;
        this.dialogRef.closeAll();
      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      });  


  }
  


}
