import { Component, OnInit , Inject} from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-task-detail-model',
  templateUrl: './task-detail-model.component.html',
  styleUrls: ['./task-detail-model.component.scss']
})
export class TaskDetailModelComponent implements OnInit {
  
  data:any = {};
  
  expectingDate:any ='';
  // date = new Date();
  registerForm: FormGroup;
  visit_date: any;
  complaintTypeList: any = [];
  transactionTypeList: any = [];
  natureList:any=[];
  
  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog,private formBuilder: FormBuilder,public datepipe : DatePipe) { }
  
  ngOnInit() {
    
    this.data = Object.assign({},this.stored_data.taskAllData);
    
    console.log(this.data);
    this.visit_date = this.data['visit_date_time']
    console.log(this.visit_date);
    
    
    // this.data.expectation_closing_date = moment(new Date(this.data.expectation_closing_date)).format("MM/DD/YYYY");
    
    
    // this.expectDateCreated =  new this.data.expectation_closing_date((new this.data.expectation_closing_date().getTime()));
    console.log(this.data.expectation_closing_date);
    this.getTransactioneTypeList()
    this.getPriorityList();
    this.getTransactioneTypeList();
    this.getComplaintTypeList();
    this.getNatureProblemList();
    // this.onGetPriorityWiseDate('1')
    
    this.registerForm = this.formBuilder.group({
      expectingDate: [this.data.expectation_closing_date], 
      priority: [ this.data.priority, Validators.required],
      task_type:[this.data.task_type],
      service_request_type:[this.data.compaint_title],
      service_type:[this.data.transaction_type],
      description:[this.data.fg_task_description],
// issue_category:[this.data.];
      nature_issue:[this.data.fg_nature_problem]

    });
    
    
    console.log(this.registerForm.value);
    this.dates = this.registerForm.value['expectingDate'];
    console.log(this.dates);
    
    
  }
  
  priorityList:any=[];
  getPriorityList() 
  {
    this.db.FetchData({}, 'sharedData/getPriorityList')
    .subscribe(result => {
      console.log(result);
      this.priorityList = result[`priorityList`];
    }, error => {
      console.log(error);                
    });
  }
  getNatureProblemList() {

    this.db.FetchData({}, 'sharedData/getNatureProblemList')
        .subscribe(result => {
            console.log(result);
            this.natureList = result[`natureList`];
        }, error => {
            console.log(error);
        });

}
  date: Date;
  expDate : any;
  dates: any;
  
// transactionTypeList:any=[]
getTransactioneTypeList() {


  this.db.FetchData({}, 'sharedData/getTransactioneTypeList')
      .subscribe(result => {
          console.log(result);

          this.transactionTypeList = result[`transactionTypeList`];
          console.log(this.transactionTypeList);

      }, error => {

      });


}
  // onGetPriorityWiseDate()
  // {
    
  //   var priority ;
    
  //   priority = this.registerForm.value['priority']
  //   console.log(priority);
    
  //   if(priority == 'High'){
      
  //     if(this.visit_date != '0000-00-00 00:00:00'){
  //       console.log("inside visit date");
  //       console.log(this.visit_date);
  //       this.date = moment(this.visit_date).local().toDate();
  //       console.log(this.date);
  //     }
  //     else{
  //       console.log("inside else");
  //       this.date = new Date();
  //     }

  //     console.log( this.date);
  //     this.date.setDate( this.date.getDate() + 2 );
      
  //     console.log('High');
  //     console.log(this.date);
      
  //     var expDate = this.date;
  //     console.log(expDate);
  //     var date = moment(new Date(expDate));
  //     this.dates = date.format("MM/DD/YYYY");
  //     console.log(this.dates);
  //     this.registerForm.value['expectingDate'] = this.dates
      
      
  //   }
  //   if(priority == 'Low'){

  //     if(this.visit_date != '0000-00-00 00:00:00'){
  //       console.log("inside visit date");
  //       console.log(this.visit_date);
  //       this.date = moment(this.visit_date).local().toDate();
  //       console.log(this.date);
  //     }
  //     else{
  //       console.log("inside else");
  //       this.date = new Date();
  //     }
      
  //     this.date.setDate( this.date.getDate() + 4 );
  //     console.log('Low');
  //     console.log(this.date);
      
  //     var expDate = this.date;
  //     console.log(expDate);
      
      
  //     var date = moment(new Date(expDate));
  //     this.dates = date.format("MM/DD/YYYY");
  //     console.log(this.dates);
  //     this.registerForm.value['expectingDate'] = this.dates
      
  //   }
    
  //   if(priority == 'Medium'){

  //     if(this.visit_date != '0000-00-00 00:00:00'){
  //       console.log("inside visit date");
  //       console.log(this.visit_date);
  //       this.date = moment(this.visit_date).local().toDate();
  //       console.log(this.date);
  //     }
  //     else{
  //       console.log("inside else");
  //       this.date = new Date();
  //     }
      
  //     this.date.setDate( this.date.getDate() + 3 );
  //     console.log('Medium');
  //     console.log(this.date);
      
  //     var expDate = this.date;
  //     console.log(expDate);
      
      
  //     var date = moment(new Date(expDate));
  //     this.dates = date.format("MM/DD/YYYY");
  //     console.log(this.dates);
  //     this.registerForm.value['expectingDate'] = this.dates
      
      
  //   }
  //   if(priority == 'Breakdown-NCR'){

  //      if(this.visit_date != '0000-00-00 00:00:00'){
  //       console.log("inside visit date");
  //       console.log(this.visit_date);
  //       this.date = moment(this.visit_date).local().toDate();
  //       console.log(this.date);
  //     }
  //     else{
  //       console.log("inside else");
  //       this.date = new Date();
  //     }
      
  //     this.date.setDate( this.date.getDate() + 1 );
  //     console.log('Breakdown-NCR');
  //     console.log(this.date);
      
  //     var expDate = this.date;
  //     console.log(expDate);
      
      
  //     var date = moment(new Date(expDate));
  //     this.dates = date.format("MM/DD/YYYY");
  //     console.log(this.dates);
  //     this.registerForm.value['expectingDate'] = this.dates
      
      
  //   }
  //   if(priority == 'Breakdown-Rest of India'){
      
  //     if(this.visit_date != '0000-00-00 00:00:00'){
  //       console.log("inside visit date");
  //       console.log(this.visit_date);
  //       this.date = moment(this.visit_date).local().toDate();
  //       console.log(this.date);
  //     }
  //     else{
  //       console.log("inside else");
  //       this.date = new Date();
  //     }

  //     this.date.setDate( this.date.getDate() + 2 );
  //     console.log('Breakdown-Rest of India');
  //     console.log(this.date);
      
  //     var expDate = this.date;
  //     console.log(expDate);
      
      
  //     var date = moment(new Date(expDate));
  //     this.dates = date.format("MM/DD/YYYY");
  //     console.log(this.dates);
      
      
  //     this.registerForm.value['expectingDate'] = this.dates
  //   }
  // }
  
  getComplaintTypeList() {
    this.db.FetchData({}, 'sharedData/getComplaintTypeList')
        .subscribe(result => {
            console.log(result);
            this.complaintTypeList = result[`complaintTypeList`];
        }, error => {
            console.log(error);
        });
}
  formLoading:any = false;
  onUpdateComplaintDetail()
  {
    console.log(this.data);
    console.log(this.dates);
    
    console.log(this.registerForm.value);
    
    this.data.priority = this.registerForm.value['priority'];
    this.data.transaction_type = this.registerForm.value['service_type'];
    this.data.compaint_title = this.registerForm.value['service_request_type'];
    this.data.fg_nature_problem = this.registerForm.value['nature_issue'];
    this.data.fg_task_description = this.registerForm.value['description'];

    this.data.expectation_closing_date = this.datepipe.transform(this.registerForm.value['expectingDate'],"yyyy-MM-dd hh:mm:ss");
    
    console.log(this.data.priority , this.data.expectation_closing_date);
    console.log(this.data);
    
    
    this.db.FetchData(this.data ,'Task/updateComplaintDetail')
    .subscribe((result:any)=>{
      
      console.log(result);
      
      if(result['msg'] == 'success') {
        this.dialogAlert.success('Task Detail','Successfully Updated')
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;  
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.dialogRef.closeAll();
      
    });
    
    
  }
  
}
