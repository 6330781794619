import { Component, OnInit } from '@angular/core';
import { SnackBarOverview } from 'src/app/toast';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { AllActionModalComponent } from '../all-action-modal/all-action-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';
import { getIndex } from '@syncfusion/ej2-angular-richtexteditor';
import { ReasonremarkComponent } from '../reasonremark/reasonremark.component';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
status: any;
  skeleton_data: any =[]
  taskList: any = [];
  searchData: any = {};
  allCountData: any = {};
  start: any = 0;
  pageLimit: any = 100;
  calenderMinDate: any;
  currentDate: any;
  totalPage: any = '';
  pageNumber: any = '';
  totalTaskCount: any = '';
  task_type:any;
  pageNumberTemp:any;
  constructor(public db: DatabaseService,
    public dialog: MatDialog,
    public dialogAlert: DialogComponent,
    public toast: SnackBarOverview,
    public route : ActivatedRoute,
    public route1 : Router,

    public session : SessionService
    ) {
      this.calenderMinDate = new Date('1950-01-01');
      this.currentDate = new Date();
      this.route.params.subscribe(params=>{
        console.log(params);
        this.task_type = params.type;
        this.getAllCountData();
        this.getPriorityList();
        this.getUserList();
        console.log(this.task_type);
        console.log(this.db.ListSearch.task);
        console.log(this.searchData.status);


        if(this.db.ListSearch.task){
          this.searchData = this.db.ListSearch.task
          // this.pageLimit = this.db.ListSearch.pagelimit
          this.start = this.db.ListSearch.start
           }
           else
           {
            this.searchData.status='Open'

           }
           this.getTaskList(this.pageLimit,this.start,this.searchData.status);
      });
    }
    access_rights:any = {};
    ngOnInit(){
      console.log(this.db.ListSearch.task)



console.log(this.searchData.status)

      this.skeleton_data = new Array(7);
      console.log('*** TASK LIST LOADED ***');
      if(this.session.trico_user.access_level != '1')
      {
        this.session.trico_user.access_module.map((x:any)=>{
          if(x.module_id === 1)
          {
            this.access_rights = x;
          }
        });
        console.log(this.access_rights);
      }
    }
    priorityList:any = [];

    tothepage(id)
    {
      this.db.ListSearch.task = this.searchData
      // this.db.ListSearch.pagelimit = this.pageLimit
      this.db.ListSearch.start = this.start

      console.log(id);
      this.route1.navigate(['task_detail/'+id]);
      console.log(this.searchData.status)
console.log(this.db.ListSearch.task);


    }
    getPriorityList()
    {
      this.db.FetchData({ 'ID':this.session.trico_user.id,
      'type':this.session.trico_user.access_level,}, 'task/getPriorityList').subscribe(resultData => {
        console.log(resultData);
        this.priorityList = resultData[`priorityList`];
      }, error => {
        console.log(error);
        this.toast.openError('Something Went Wrong, Try Later','');
      });
    }
    userList:any = [];
    getUserList()
    {
      this.db.FetchData({ 'ID':this.session.trico_user.id,
      'type':this.session.trico_user.access_level,}, 'task/getUserList').subscribe(resultData => {
        console.log(resultData);
        this.userList = resultData[`userList`];
      }, error => {
        console.log(error);
        this.toast.openError('Something Went Wrong, Try Later','');
      });
    }
    skeleton_screen_active:any = false;
    data_not_found:any = false;
    getTaskList(pageLimit:any=20,start:any=0,status:any='Open') {
      this.pageLimit = pageLimit;
      this.start = start;
      this.searchData.status = status;
      this.skeleton_screen_active = true;
      if (this.searchData[`date`]) {
        this.searchData[`dateCreated`] = moment(this.searchData[`date`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_from`]) {
        this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }
      this.data_not_found = false;
      this.db.FetchData({
        start: this.start,
        pageLimit: this.pageLimit,
        searchData: this.searchData,
        isDecrypt: true,
        task_type : this.task_type,
        'type':this.session.trico_user.access_level,
        'STLCODE':this.session.trico_user.assign_detail
      }, 'task/getTaskList').subscribe(resultData => {
        console.log(resultData);
        this.taskList = resultData[`taskList`];
        this.totalTaskCount = resultData[`totalTaskCount`];
        this.totalPage = Math.ceil(this.totalTaskCount / this.pageLimit);
        this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;

        if(!this.taskList.length)
        {
          this.data_not_found = true;
        }

        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);

        this.getAllCountData();
      }, error => {
        console.log(error)
        this.skeleton_screen_active = false;
        this.toast.openError('Something Went Wrong, Try Later','');
      });
      this.db.ListSearch.task=''
    }

    getAllCountData() {
      this.db.FetchData({
        task_type : this.task_type,
        // 'ID':this.session.trico_user.id,
        'type':this.session.trico_user.access_level,
        'STLCODE':this.session.trico_user.assign_detail
      }, 'task/getTaskAllCountData').subscribe(resultData => {
        console.log(resultData);
        this.allCountData = resultData[`allCountData`];
      }, error => {
        console.log(error);
        this.toast.openError('Something Went Wrong, Try Later','');
      });
    }
    deleteTask(task_id:any,index:any){
      console.log('*** DELETE TASK ***');
      console.log('Task ID '+task_id);
      console.log('Index '+index);
      var taskType = this.task_type == 'service_request' ? 'Service Request' : 'Commissioning';
      this.dialogAlert.delete(taskType+' task data').then((result:any) => {
        console.log(result);
        if (result) {
          this.db.FetchData( {'id':task_id}, 'task/deleteTask')
          .subscribe(res => {
            console.log(res);
            this.taskList.splice(index,1);
            this.dialogAlert.success('Deleted','Task Data has been deleted.');
            this.getTaskList();
          },err=>{
            console.log(err);
            this.dialogAlert.error('Something went wrong! Try Again ...');
          });
        }
      });
    }

    openDialog(target): void {
      const dialogRef = this.dialog.open(AllActionModalComponent, {
        width: '500px',
        // data: {name: this.name, animal: this.animal}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.getTaskList();
      });
    }

    exp_data:any=[];
    excel_data:any=[];

    getDataInExcel()
    {
      let Index= 1;

      this.db.FetchData({searchData: this.searchData, isDecrypt: true, task_type : this.task_type},'task/getTaskListExcel').subscribe(resultData =>
        {
          console.log(resultData);
          this.exp_data = resultData['taskList'];
console.log(this.exp_data);

          let count = 0;

          for(let i=0;i<this.exp_data.length;i++)
          {
            if(this.exp_data[i].created_by_type=='Customer'){
              this.exp_data[i].created_by_type='APP'
            }
            else{
              this.exp_data[i].created_by_type='WEB'
            }
            if (this.exp_data[i].date_created) {
              this.exp_data[i].date_created = moment(this.exp_data[i].date_created).format('DD-MM-YYYY');
            }
            if (this.exp_data[i].status_update_on &&this.exp_data[i].status==  'Close' ) {
              this.exp_data[i].status_update_on = moment(this.exp_data[i].status_update_on).format('DD-MM-YYYY  ');
            }
            if (this.exp_data[i].status_update_on &&this.exp_data[i].status!=  'Close' ) {
              this.exp_data[i].status_update_on = '--';
            }
            for(let j=0;j<this.exp_data[i].fgData.length;j++)
            {
            this.excel_data.push({
                'S.no.': Index++,
                'Request No':'#SR' + this.exp_data[i].task_no,
                'Created by':this.exp_data[i].created_by_name,
                'Created from':this.exp_data[i].created_by_type,

                'Opening Date':this.exp_data[i].date_created,

                'Expecting Closure Date':this.exp_data[i].expectation_closing_date,
                'Closing Date': this.exp_data[i].status_update_on,
              //  ' Expecting Closure Date' : this.exp_data[i].expectation_closing_date,
                ' Days': this.exp_data[i].days,
                // 'Expected no. of closure days': this.exp_data[i].days,
                'Responsibility Centre': this.exp_data[i].fgData[j].service_center,
                'Issue category':this.exp_data[i].compaint_title,
                // 'Complaint Type':this.exp_data[i].task_type == 'service_request' ? 'Service Request' : 'Commissioning Request'  +'-'+ this.exp_data[i].compaint_title,
                'Request Type':this.exp_data[i].transaction_type,
                'Nature of Problem': this.exp_data[i].nature_problem,
                'Priority':this.exp_data[i].priority,
                'SO No': this.exp_data[i].fgData[j].so_no,
                'Customer Name':this.exp_data[i].customer_name,
                'Project Name': this.exp_data[i].project_name,
                'Detail Description':this.exp_data[i].description,
                'Engineer Assigned':this.exp_data[i].assign_to_name,
                'Customer Satisfaction Score':this.exp_data[i].cust_rating,
                'Travelling Cost': this.exp_data[i].travel_expense,
                'Material Cost':this.exp_data[i].material_cost,
                'Manpower Cost': this.exp_data[i],
                'Engineer Status': this.exp_data[i].work_report_status,
                'Request Status':this.exp_data[i].status==  'Open' ? 'Open'  :  this.exp_data[i].status,
                'Reason Remark': this.exp_data[i].reason_remark,




                /*'Date & Time':this.exp_data[i].dateCreated +'-'+ this.exp_data[i].timeCreated,
                'Created By':this.exp_data[i].created_by_name,
                 'Priority':this.exp_data[i].priority,
                'Request Type & Complaint Title':this.exp_data[i].task_type == 'service_request' ? 'Service Request' : 'Commissioning Request'  +'-'+ this.exp_data[i].compaint_title,
                'Expecting Closing Date':this.exp_data[i].expectation_closing_date,
                'Nature of Issue':this.exp_data[i].nature_problem,
                'Description':this.exp_data[i].description,
                'CUSTOMER Name & Code':this.exp_data[i].customer_name +'-'+ this.exp_data[i].customer_code,
                'Project':this.exp_data[i].project_name,
                'CUSTOMER Address':this.exp_data[i].project_street + this.exp_data[i].project_state_name + this.exp_data[i].project_pincode,
                'PRODUCT Fg/So/Si No.':this.exp_data[i].fgData[j].fg_no ,
                'PRODUCT Valid Upto':this.exp_data[i].fgData[j].valid_upto,
                'PRODUCT Responsibility Center':this.exp_data[i].fgData[j].service_center,
                'LAST ACTIVITY Name':this.exp_data[i].last_updated_by_name,
                'LAST ACTIVITY Description':this.exp_data[i].last_updated_remark,
                'ASSIGN Engineer Name':this.exp_data[i].assign_to_name,
                'CUSTOMER FEEDBACK':this.exp_data[i].cust_rating,
                'NO. OF CLOSING DAYS ': this.exp_data[i].days,
                'CLOSE DATE': this.exp_data[i].status_update_on,*/

             });

             if(this.exp_data[i].status=='Close')
             {

               delete this.excel_data[count]['Expecting Closure Date'];
             }
              if(this.exp_data[i].status=='Close')
             {

               delete this.excel_data[count]['Expected no. of closure days'];
             }
             if(this.exp_data[i].status=='Open')
             {

               delete this.excel_data[count]['Actual no. of closure days'];
             }

             if(this.exp_data[i].status=='Close')
             {

               delete this.excel_data[count]['Engineer Status'];
             }

             if(this.exp_data[i].status=='Open')
             {

               delete this.excel_data[count]['Closing Date'];
             }

             if(this.exp_data[i].status=='Close')
             {

               delete this.excel_data[count][' Expecting Closure Date'];
             }



             count++;
            }
          }

          this.db.exportAsExcelFile(this.excel_data,this.task_type == 'service_request' ? 'Service Request Task' : 'Commissioning Task');
          this.excel_data = [];
          this.exp_data = [];

        }, error => {
          console.log(error)

        });
      }

    exp_dataAll:any=[];
    excel_dataAll:any=[];

    getDataInExcelAll()
    {

      if (this.searchData[`date`]) {
        this.searchData[`dateCreated`] = moment(this.searchData[`date`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_from`]) {
        this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }

        let Index= 1;

      this.db.FetchData({searchData: this.searchData, isDecrypt: true, task_type : this.task_type},'task/getTaskListExcelAll').subscribe(resultData =>
        {
          console.log(resultData);
          this.exp_dataAll = resultData['taskList'];

          let count = 0;

          for(let i=0;i<this.exp_dataAll.length;i++)
          {
            if(this.exp_dataAll[i].created_by_type=='Customer'){
              this.exp_dataAll[i].created_by_type='APP'
            }
            else{
              this.exp_dataAll[i].created_by_type='WEB'
            }
            if (this.exp_dataAll[i].date_created) {
              this.exp_dataAll[i].date_created = moment(this.exp_dataAll[i].date_created).format('DD-MM-YYYY');
            }
            if (this.exp_dataAll[i].status_update_on &&this.exp_dataAll[i].status=='Close') {
              this.exp_dataAll[i].status_update_on = moment(this.exp_dataAll[i].status_update_on).format('DD-MM-YYYY ');
            }
            if (this.exp_dataAll[i].status_update_on &&this.exp_dataAll[i].status!='Close') {
              this.exp_dataAll[i].status_update_on = '--';
            }
            for(let j=0;j<this.exp_dataAll[i].fgData.length;j++)
            {
              console.log(this.exp_dataAll);

            this.excel_dataAll.push({
                'S.no.': Index++,
                'Request No':'#SR' + this.exp_dataAll[i].task_no,
                'Created by':this.exp_dataAll[i].created_by_name,
                'Created from':this.exp_dataAll[i].created_by_type,
                'Opening Date':this.exp_dataAll[i].date_created,
                'Expecting Closure Date':this.exp_dataAll[i].expectation_closing_date,
                'Closing Date': this.exp_dataAll[i].status_update_on,
                ' Days': this.exp_dataAll[i].days,
                // 'Expected no. of closure days': this.exp_data[i].status_update_on,
                'Responsibility Centre': this.exp_dataAll[i].fgData[j].service_center,
                'Request Type':this.exp_dataAll[i].transaction_type,
                'Nature of Problem': this.exp_dataAll[i].nature_problem,
                'Issue category':this.exp_dataAll[i].compaint_title,

                'Priority':this.exp_dataAll[i].priority,
                'SO No': this.exp_dataAll[i].fgData[j].so_no,
                'Customer Name':this.exp_dataAll[i].customer_name,
                'Project Name': this.exp_dataAll[i].project_name,
                'Detail Description':this.exp_dataAll[i].description,
                'Engineer Assigned':this.exp_dataAll[i].assign_to_name,
                'Customer Satisfaction Score':this.exp_dataAll[i].cust_rating,
                'Travelling Cost': this.exp_dataAll[i].travel_expense,
                'Material Cost':this.exp_dataAll[i].material_cost,
                'Manpower Cost': this.exp_dataAll[i],
                'Engineer Status': this.exp_dataAll[i].work_report_status,
                'Request Status':this.exp_dataAll[i].status==  'Open' ? 'Open'  :  this.exp_dataAll[i].status

             });

             if(this.exp_dataAll[i].status=='Close')
             {
               delete this.excel_dataAll[count]['Expecting Closure Date'];
             }
              if(this.exp_dataAll[i].status=='Close')
             {
               delete this.excel_dataAll[count]['Expected no. of closure days'];
             }
             if(this.exp_dataAll[i].status=='Open' || this.exp_dataAll[i].status=='Pending' || this.exp_dataAll[i].status=='Running' || this.exp_dataAll[i].status=='')
             {
               delete this.excel_dataAll[count]['Actual no. of closure days'];
             }

             if(this.exp_dataAll[i].status=='Close')
             {
               delete this.excel_dataAll[count]['Engineer Status'];
             }

             if(this.exp_dataAll[i].status=='Open' || this.exp_dataAll[i].status=='Pending' || this.exp_dataAll[i].status=='Running' || this.exp_dataAll[i].status=='')
             {
               delete this.excel_dataAll[count]['Closing Date'];
             }

             if(this.exp_dataAll[i].status=='Close')
             {
               delete this.excel_dataAll[count][' Expecting Closure Date'];
             }

             count++;
            }
          }

          this.db.exportAsExcelFile(this.excel_dataAll,this.task_type == 'service_request' ? 'Service Request Task' : 'Commissioning Task');
          this.excel_dataAll = [];
          this.exp_dataAll = [];

        }, error => {
          console.log(error)

        });

      }

      openDetailDialog(taskId,reason_remark): void {
        const dialogRef = this.dialog.open(ReasonremarkComponent, {
          width: '468px',
          data: {
            taskId:taskId,
            reason_remark:reason_remark
        },
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.getTaskList();
        });
      }

    }
