import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-spare-category-modal',
  templateUrl: './spare-category-modal.component.html',
  styleUrls: ['./spare-category-modal.component.scss']
})
export class SpareCategoryModalComponent implements OnInit {

  constructor(public dialogAlert : DialogComponent, public db : DatabaseService, public dialogRef : MatDialog) { }

  ngOnInit() {
  }

  clearForm(myForm:any)
  {
    this.data = {};
    myForm.resetForm();
  }
  
  data:any = {};
  submitSparePartCategory(myForm:any)
  {
    console.log('*** SUBMIT SPARE PART CATEGORY ***');
    console.log(this.data);

    this.db.FetchData({'data' : this.data},'Master_SparePart/submitSparePartCategory')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialogAlert.success('Spare Part Make','Successfully Saved !!!');
        this.clearForm(myForm)
      }
      else
      {
        var error = result['error']['message'];
        this.dialogAlert.error(error);
      }
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });

  }

}
