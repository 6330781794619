import { Component, OnInit , Inject} from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-customer-contact-person-modal',
  templateUrl: './customer-contact-person-modal.component.html',
  styleUrls: ['./customer-contact-person-modal.component.scss']
})
export class CustomerContactPersonModalComponent implements OnInit {

  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog) { }

  contactPersonList:any = [];
  deleteperson:any=[];
  data:any = {};
id:any
modelExist: boolean = false;
  ngOnInit() {
    this.contactPersonList = Object.assign([],this.stored_data.data);
    // this.id= Object.assign([],this.stored_data.id);
    console.log(this.contactPersonList);
    console.log(this.stored_data.id);

  this.id=this.stored_data.id
  }

  cp_data:any = {};
  addContactPerson()
  {
    this.cp_data.customer_id = this.id;
    // this.cp_data.created_by = this.contactPersonList[0].created_by;
    // this.cp_data.created_by_name = this.contactPersonList[0].created_by_name;
    // this.cp_data.created_by_type = this.contactPersonList[0].created_by_type;
    // contact_delete

    console.log(this.cp_data);
    this.contactPersonList.push(this.cp_data);
    console.log('*** CONTACT PERSON LIST ***');
    console.log(this.contactPersonList);
    this.cp_data = {};
  }

  removeContactPerson(index:any)
  {
    console.log(index);
    this.contactPersonList.splice(index,1);
    console.log(this.contactPersonList);

  }


  formLoading:any = false;
  deleteContactDetails(index:any){

    console.log(this.contactPersonList[index]);
this.deleteperson.push(this.contactPersonList[index]);
console.log(this.deleteperson);

    this.db.FetchData({'contact_person':this.deleteperson,'action' : 'contact_delete'},'customer/updateCustomer')
      .subscribe((result:any)=>{
        console.log(result);
        this.contactPersonList.splice(index,1);



        this.formLoading = false;
        // this.dialogRef.closeAll();
      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      });


  }

  addNewContactDetails(){

    console.log(this.contactPersonList);

    this.db.FetchData({'contact_person':this.contactPersonList,'action' : 'contact'},'customer/updateCustomer')
      .subscribe((result:any)=>{
        console.log(result);

        if(result['msg'] == 'success') {
          this.dialogAlert.success('Task Contact Data','Successfully Updated')
        }
        else{
          var error = '';
          for (let index = 0; index < result['error'].length; index++) {
            error += result['error'][index]['message']+ ' ' ;
          }
          this.dialogAlert.error(error);
          this.formLoading = false;
        }
        this.formLoading = false;
        this.dialogRef.closeAll();
      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      });


  }

}
