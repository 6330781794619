import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-technicians-edit-model',
  templateUrl: './technicians-edit-model.component.html',
  styleUrls: ['./technicians-edit-model.component.scss']
})
export class TechniciansEditModelComponent implements OnInit {

  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog) { }

  data:any = {};


  ngOnInit() {
    this.data = Object.assign({},this.stored_data.data);
    this.getStateList();
    this.getDistrictList();
  }

  stateList:any = [];
  getStateList()
  {
    this.db.FetchData({},'Master_Vendor/getStateList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.stateList = result['state'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
  }
  
  
  districtList:any = [];
  getDistrictList()
  {
    console.log(this.data);
    this.db.FetchData({'data':this.data},'Master_Vendor/getDistrictList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.districtList = result['district'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
  }

  
  formLoading:any = false;
  updateEngineer()
  {
    console.log('*** UPDATE ENGINEER ***');
    console.log(this.data);
    
    this.db.FetchData({'data':this.data},'technician/updateEngineer')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success') {
        this.dialogAlert.success('Service Engineer Data','Successfully Updated')
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;  
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });  
  }
  

}
