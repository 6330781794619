import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-warranty-list',
  templateUrl: './warranty-list.component.html',
  styleUrls: ['./warranty-list.component.scss']
})
export class WarrantyListComponent implements OnInit {

  skeleton_data:any =[]
  skeleton_screen_active:any= false;
  data:any={};
  constructor(public db:DatabaseService, public dialog : DialogComponent, public session : SessionService) { }

  ngOnInit() {
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 10||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
    this.skeleton_data = new Array(5);

    this.getWarrantyList();

  }
  access_rights:any = {};

  data_not_found:any = false;
  pageLimit:any =20
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pagenumber:any;
  warrantyList:any = [];

  getWarrantyList()
  {
    this.skeleton_screen_active = true;
    this.data_not_found = false;

    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'Master_Warranty/getWarrantyList')
    .subscribe((result:any)=>{
      console.log(result);
      this.warrantyList = result['warranty'];
      this.total_count = result['count'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pagenumber = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pagenumber);
      if(this.warrantyList.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }

      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
  }

  clearForm(myForm:any)
  {
    this.data = {};
    myForm.resetForm();
  }


  formLoading:any = false;
  submitWarranty(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Submit Warranty ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data, 'session':this.session.trico_user},'Master_Warranty/submitWarranty')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['msg'] == 'success')
      {
        this.dialog.success('Warranty Data','Successfully Saved !!!');
      }
      else{
        this.dialog.error(result['error']);
      }

      this.clearForm(myForm);
      this.formLoading = false;
      this.getWarrantyList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });

  }

  editWarranty(id:any)
  {
    console.log('*** EDIT WARRANTY DATA ***');
    console.log('ID -'+id);
    this.data = Object.assign({},this.warrantyList.filter( (x:any) => x.id == id)[0]);
    console.log(this.data);
  }

  updateWarranty(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Update Warranty ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data},'Master_Warranty/updateWarranty')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['msg'] == 'success')
      {
        this.dialog.success('Warranty Data','Successfully Updated !!!');
      }
      else{
        this.dialog.error(result['error']);
      }
      this.clearForm(myForm);
      this.formLoading = false;
      this.getWarrantyList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });

  }

  deleteWarranty(warranty_id:any,index:any){
    console.log(warranty_id);
    console.log(index);
    this.dialog.delete('Warranty Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':warranty_id}, 'Master_Warranty/deleteWarranty')
        .subscribe(res => {
          console.log(res);
          this.warrantyList.splice(index,1);
          this.dialog.success('Deleted','Warranty Data has been deleted.');
          this.getWarrantyList();
        },err=>{
          console.log(err);
          this.dialog.error('Something went wrong! Try Again ...');
        });
      }
    });
  }




}
