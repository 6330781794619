import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SessionService } from 'src/app/session.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';


@Component({
  selector: 'app-login-request-list',
  templateUrl: './login-request-list.component.html',
  styleUrls: ['./login-request-list.component.scss']
})
export class LoginRequestListComponent implements OnInit {

  loginList = [];
  totalLoginCount: any = '';
  start: any = 0;
  pageLimit: any = 20;
  data_not_found: any = false;
  search: any = {};
  total_count: any;
  total_page: any;
  pageNumber: any;
  pageNumberTemp: any;
  countData: any = {};
  access_rights: any;
  isRequestInProcess: boolean = false;
  constructor(
    public dialogAlert: DialogComponent,
    public session: SessionService,
    public db: DatabaseService,
    public route1: Router,

    public dialog: MatDialog,
  ) {
    // this.getLoginRequestList();
    if (this.db.ListSearch.loginstatus) {
      this.search = this.db.ListSearch.loginstatus
      // this.pageLimit = this.db.ListSearch.pagelimit
      this.start = this.db.ListSearch.start
    }
    else {
      this.search.status = 'Pending'
    }
    this.getLoginRequestList(this.pageLimit, this.start, this.search.status);


  }

  ngOnInit() {


  }

  skeleton_screen_active: any = false;

  getLoginRequestList(pageLimit: any = 20, start: any = 0, status = 'Pending') {

    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;
    this.search.status = status;
    this.isRequestInProcess = true;

    console.log(this.search.status);

    this.db.FetchData({

      'search': this.search, 'start': this.start, 'pagelimit': this.pageLimit

    }, 'customer/getLoginRequestList').subscribe(resultData => {

      console.log(resultData);

      this.loginList = resultData[`loginList`];
      for (let i = 0; i < this.loginList.length; i++) {

        this.loginList[i].name = this.loginList[i].name;
        //         this.loginList[i].name = this.loginList[i].name ? this.loginList[i].name.charAt(0).toUpperCase() + this.loginList[i].name.substr(1).toLowerCase() : '';
        // // console.log(str);
        // this.loginList[i].contact_person_name = this.loginList[i].contact_person_name;
        // this.loginList[i].contact_person_name = this.loginList[i].contact_person_name ? this.loginList[i].contact_person_name.charAt(0).toUpperCase() + this.loginList[i].contact_person_name.substr(1).toLowerCase() : '';
      }
      this.totalLoginCount = resultData[`totalLoginCount`];
      this.countData = resultData['statusCountData'];
      console.log(this.loginList);
      this.isRequestInProcess = false;

      if (!this.loginList.length) {
        this.data_not_found = true;
        this.isRequestInProcess = false;
      }

      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      this.total_page = Math.ceil(this.totalLoginCount / this.pageLimit);
      this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;
    });
  }

  exp_data: any = [];
  excel_data: any = [];
  tothepage(id) {
    this.db.ListSearch.loginstatus = this.search
    // this.db.ListSearch.pagelimit = this.pageLimit
    this.db.ListSearch.start = this.start

    console.log(id);
    this.route1.navigate(['login-request-detail/' + id]);
    console.log(this.search.loginstatus)
    console.log(this.db.ListSearch.task);


  }
  getDataInExcel() {
    this.db.FetchData({ 'search': this.search }, 'customer/getLoginRequestListExcel').subscribe(resultData => {
      console.log(resultData);
      this.exp_data = resultData['loginList'];

      for (let i = 0; i < this.exp_data.length; i++) {
        if (this.exp_data[i].date_created) {
          this.exp_data[i].date_created = moment(this.exp_data[i].date_created).format('DD-MM-YYYY  ');
        }


        this.excel_data.push({
          'Date': this.exp_data[i].date_created,
          'Company Name': this.exp_data[i].company_name,
          'Project Name': this.exp_data[i].project_name,
          'Project Address': this.exp_data[i].project_state + ',' + this.exp_data[i].project_district + ',' + this.exp_data[i].project_city + ',' + this.exp_data[i].project_pincode,
          'Name': this.exp_data[i].contact_person_name == "" ? this.exp_data[i].name : this.exp_data[i].contact_person_name,
          'Mobile': this.exp_data[i].contact_mobile,
          'Landline No.': this.exp_data[i].landline_no,
          'Email': this.exp_data[i].contact_email == "" ? this.exp_data[i].email : this.exp_data[i].contact_email,
          'Designation': this.exp_data[i].contact_designation,
          'Status': this.exp_data[i].status,
        });
      }

      this.db.exportAsExcelFile(this.excel_data, 'Login Request');

      this.excel_data = [];
      this.exp_data = [];

    }, error => {
      console.log(error)

    });
  }
  deleteloginrequest(user_id: any, index: any) {
    console.log(user_id);
    console.log(index);
    this.dialogAlert.delete('Login Request Data').then((result: any) => {
      console.log(result);
      if (result) {
        this.db.FetchData({ 'id': user_id }, 'Customer/deleteLoginRequest')
          .subscribe(res => {
            console.log(res);
            this.loginList.splice(index, 1);
            this.dialogAlert.success('Deleted', 'Login Request Data Data has been deleted.');
            this.getLoginRequestList();
          }, err => {
            console.log(err);
            this.dialogAlert.error('Something went wrong! Try Again ...');
          });
      }
    });
  }
}
