import { Component, OnInit ,Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SessionService } from 'src/app/session.service';


@Component({
  selector: 'app-email-model',
  templateUrl: './email-model.component.html',
  styleUrls: ['./email-model.component.scss']
})
export class EmailModelComponent implements OnInit {


  toppings = new FormControl();

  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog, public session : SessionService) { }

  contactDetails:any = [];

  taskId : any = {};

  data:any = {};


  ngOnInit() {
     
    this.contactDetails = Object.assign([],this.stored_data.data);
    console.log(this.contactDetails);
    this.taskId = Object.assign({},this.stored_data.taskId);
    console.log(this.taskId);
    

    
  }


  formLoading:any = false;

  sendViewServiceReport(myForm:any){

    console.log(this.data);

    this.db.FetchData({'data':this.data,'taskId':this.taskId[0]},'Service_Report/sendViewServiceReport')
    .subscribe((result:any)=>{
      console.log(result); 
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });
    
  }

}
