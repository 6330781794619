import { Component, OnInit } from '@angular/core';
import { DialogComponent } from '../dialog';
import { SessionService } from '../session.service';
import { DatabaseService } from '../_services/database.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  skeleton_data:any =[]
  skeleton_screen_active:any= false;
  password:any
  data:any
  model: any = {};
  constructor(public db:DatabaseService, public dialog : DialogComponent, public dialogRef : MatDialog, public session: SessionService) { }
  
  ngOnInit() {
    this.skeleton_data = new Array(5);
    console.log(this.session.trico_user);
    
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 26||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};
  
  data_not_found:any = false;
  pageLimit:any =20
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pagenumber:any;
  serviceCenterList:any = [];
  
 
  
  
  clearForm(myForm:any)
  {
    this.data = {};
    myForm.resetForm();
  }
  
  
  formLoading:any = false;
  submitServiceCenter()
  {
    this.formLoading = true;
    console.log('*** Submit Service Center ***');
    console.log(this.data);
    this.db.FetchData({'data':this.password, 'session':this.session.trico_user.id},'Master_ServiceCenter/submitServiceCenter')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success') {
        this.dialog.success('Password changed','Successfully  !!!');
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;  
        }
        this.dialog.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
    
  }  
 
  onSubmit() {
    if(this.model.password!=this.model.confirmPassword){
      this.dialog.error('Please  enter both fields same');

      return;
    }
      this.db.FetchData({'data':this.model.confirmPassword, 'session':this.session.trico_user.id},'dashboard/update_password')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result == true) {
        this.dialog.success('Password changed','Successfully  !!!');
      }
     
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
    this.dialogRef.closeAll();
    
  }
  
  
  
  editServiceCenter(id:any)
  {
    console.log('*** EDIT SERVICE CENTER LIST ***');
    console.log('Service Center ID -'+id);
    this.data = Object.assign({},this.serviceCenterList.filter( (x:any) => x.id == id)[0]);
    console.log(this.data);
  }
  
  
 
  
  
  
}

