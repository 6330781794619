import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/dialog';
import { SessionService } from 'src/app/session.service';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-stluser',
  templateUrl: './stluser.component.html',
  styleUrls: ['./stluser.component.scss']
})
export class StluserComponent implements OnInit {
  skeleton_data: any =[]
  skeleton_screen_active:any = false;
  data:any = {};
  hide:any = [];
  constructor(public db : DatabaseService, public dialogAlert : DialogComponent, public session : SessionService) { }
  
  ngOnInit() {
    this.skeleton_data = new Array(7);
    this.getStlUserList(this.pageLimit,this.start);
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 18||x.module_id === 25)
        {
          this.access_rights = x;
        }
       
      });
      console.log(this.access_rights);
    }
  }
  
  access_rights:any = {};
 
  data_not_found:any = false;
  pageLimit:any =20;
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pageNumber:any;
  pageNumberTemp:any;
  StlUserList:any = [];
total:any=[];
  userType : any ='';



  
  getStlUserList(pageLimit:any=20,start:any=0,action:any='refresh')
  {
    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;
    // Master_User/getSystemUserList
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit,'type': 6},'Master_User/getSystemUserList2')
    .subscribe((result:any)=>{
      console.log(result);
      this.StlUserList = result['user'];
      this.total_count = result['count'];
     
      

      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
      this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pageNumber);
      console.log(this.session.trico_user);
      this.userType = this.session.trico_user.type;
      console.log(this.userType);
      
      if(this.StlUserList.length != 0)
      {
        this.data_not_found = false; 
        this.StlUserList.map((x:any)=>{
          x.hide = true;
        });
      }
      else
      {
        this.data_not_found = true; 
      }       
     
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);
      
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }
  
  userDelete(user_id:any,index:any){
    console.log(user_id);
    console.log(index);
    this.dialogAlert.delete('STL User Data').then((result:any) => {
      console.log(result);
      if(result){
        // Master_User/userDelete
        this.db.FetchData( {'id':user_id}, 'Master_User/userDelete')
        .subscribe(res => {
          console.log(res);
          this.StlUserList.splice(index,1);
          this.dialogAlert.success('Deleted','STL User Data has been deleted.');
          this.getStlUserList();
        },err=>{
          console.log(err);
          this.dialogAlert.error('Something went wrong! Try Again ...');
        });
      }
    });
  }


}
