import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerAddComponent } from './customer/customer-add/customer-add.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { SalesOrderAddComponent } from './sales-order/sales-order-add/sales-order-add.component';
import { TechniciansAddComponent } from './technicians/technicians-add/technicians-add.component';
import { TechniciansListComponent } from './technicians/technicians-list/technicians-list.component';
import { TechniciansDetailComponent } from './technicians/technicians-detail/technicians-detail.component';
import { ProductListComponent } from './master/product/product-list/product-list.component';
import { ProductAddComponent } from './master/product/product-add/product-add.component';
import { SpareListComponent } from './master/spare/spare-list/spare-list.component';
import { SpareAddComponent } from './master/spare/spare-add/spare-add.component';
import { ComplaintListComponent } from './master/complaint/complaint-list/complaint-list.component';
import { PriorityListComponent } from './master/priority/priority-list/priority-list.component';
import { UserListComponent } from './master/user/user-list/user-list.component';

import { UserAddComponent } from './master/user/user-add/user-add.component';
import { CallListComponent } from './call/call-list/call-list.component';
import { FeedbackListComponent } from './feedback/feedback-list/feedback-list.component';
import { TaskAddComponent } from './task/task-add/task-add.component';
import { TaskListComponent } from './task/task-list/task-list.component';
import { TaskDetailComponent } from './task/task-detail/task-detail.component';
import { WarrantyListComponent } from './master/warranty/warranty-list/warranty-list.component';
import { ServiceCenterListComponent } from './master/service-center/service-center-list/service-center-list.component';
import { AddVendorComponent } from './master/vendor/add-vendor/add-vendor.component';
import { EmergencyContactListComponent } from './master/emergency-contact/emergency-contact-list/emergency-contact-list.component';
import { DocumentListComponent } from './master/documents/document-list/document-list.component';
import { CommissioningListComponent } from './master/commissioning/commissioning-list/commissioning-list.component';
import { ListVendorComponent } from './master/vendor/list-vendor/list-vendor.component';
import { DetailVendorComponent } from './master/vendor/detail-vendor/detail-vendor.component';

import { CustomerComplaintsListComponent } from './customer/customer-complaints-list/customer-complaints-list.component';
import { CustomerProjectListComponent } from './customer/customer-project-list/customer-project-list.component';
import { CustomerProjectAddComponent } from './customer/customer-project-add/customer-project-add.component';
import { CustomerProductListComponent } from './customer/customer-product-list/customer-product-list.component';
import { CustomerProductAddComponent } from './customer/customer-product-add/customer-product-add.component';
import { CustomerDocumentListComponent } from './customer/customer-document-list/customer-document-list.component';
import { CustomerDocumentAddComponent } from './customer/customer-document-add/customer-document-add.component';
import { CustomerCallRequestComponent } from './customer/customer-call-request/customer-call-request.component';
import { CustomerAmcListComponent } from './customer/customer-amc-list/customer-amc-list.component';
import { WarrantyRenewalListComponent } from './warranty/warranty-renewal-list/warranty-renewal-list.component';
import { VendorComplaintListComponent } from './vendor/vendor-complaint-list/vendor-complaint-list.component';
import { VendorAddComponent } from './vendor/vendor-add/vendor-add.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ServiceReportComponent } from './task/service-report/service-report.component';
import { LoginComponent } from './login/login.component';
import { PmListComponent } from './preventive-maintenance/pm-list/pm-list.component';
import { AuthGuardLog } from './AuthGuardLog';
import { AuthGuard } from './AuthGuard';
import { LoginRequestDetailComponent } from './login-request/login-request-detail/login-request-detail.component';
import { LoginRequestListComponent } from './login-request/login-request-list/login-request-list.component';
import { ExportCommReportComponent } from './task/export-comm-report/export-comm-report.component';
import { RatingFeedbackComponent } from './rating-feedback/rating-feedback.component';
import { CustomerFeedbackListComponent } from './customer-feedback/customer-feedback-list/customer-feedback-list.component';
import { ThankuPageComponent } from './thanku-page/thanku-page.component';
import { NatureOfProblemListComponent } from './master/nature-of-problem-list/nature-of-problem-list.component';
import { EngineerReportComponent } from './report/engineer-report/engineer-report.component';
import { MisReportComponent } from './report/mis-report/mis-report.component';
import { CustomerfeedbackreportComponent } from './customerfeedbackreport/customerfeedbackreport.component';
import { StluserComponent } from './master/stluser/stluser.component';
import { StluseraddComponent } from './master/stluseradd/stluseradd.component';
// import { CustomerStluserComponent } from './customer-stluser/customer-stluser.component';
import { OrderinspectionComponent } from './orderinspection/orderinspection.component';
import { FeedbackdetailComponent } from './feedbackdetail/feedbackdetail.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { LoginrequesteditComponent } from './loginrequestedit/loginrequestedit.component';
import { TaskreportComponent } from './taskreport/taskreport.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { InvoicereportComponent } from './invoicereport/invoicereport.component';
import { StarcustomerComponent } from './starcustomer/starcustomer.component';

const routes: Routes =
[
  { path: 'dashboard', component:  DashboardComponent, canActivate: [AuthGuard] },
  // { path: 'changepassword', component:  ChangepasswordComponent, canActivate: [AuthGuard] },

  { path: 'service-report/:taskId', component:  ServiceReportComponent , canActivate: [AuthGuard]},
  { path: 'commission-report/:taskId', component:  ExportCommReportComponent , canActivate: [AuthGuard]},
  { path: 'customerlist', component: CustomerListComponent ,canActivate: [AuthGuard]},
  { path: 'stluser', component: StluserComponent ,canActivate: [AuthGuard]},
  { path: 'loginrequestedit', component: LoginrequesteditComponent ,canActivate: [AuthGuard]},

  { path: 'customeradd', component: CustomerAddComponent ,canActivate: [AuthGuard]},
  { path: 'customerdetail/:customerId', component: CustomerDetailComponent  ,canActivate: [AuthGuard]},
  { path: 'salesorderadd', component: SalesOrderAddComponent ,canActivate: [AuthGuard]},
  { path: 'engineerlist', component:  TechniciansListComponent, canActivate: [AuthGuard]},
  { path: 'engineer/:id', component: TechniciansAddComponent  ,canActivate: [AuthGuard]},
  { path: 'engineer/detail/:id', component: TechniciansDetailComponent  ,canActivate: [AuthGuard]},
  { path: 'productlist', component: ProductListComponent  ,canActivate: [AuthGuard]},
  { path: 'productadd/:id', component: ProductAddComponent  ,canActivate: [AuthGuard]},
  { path: 'sparelist', component: SpareListComponent  ,canActivate: [AuthGuard]},
  { path: 'sparepart/:id', component: SpareAddComponent  ,canActivate: [AuthGuard]},
  { path: 'complaintlist', component:  ComplaintListComponent ,canActivate: [AuthGuard]},
  { path: 'prioritylist', component:  PriorityListComponent ,canActivate: [AuthGuard]},
  { path: 'userlist', component:  UserListComponent ,canActivate: [AuthGuard]},
  { path: 'useradd/:id', component:  UserAddComponent ,canActivate: [AuthGuard]},
  { path: 'stluseradd/:id', component:  StluseraddComponent ,canActivate: [AuthGuard]},
  { path: 'orderinspection', component:  OrderinspectionComponent ,canActivate: [AuthGuard]},
  { path: 'feedbackdetail/:id', component:  FeedbackdetailComponent ,canActivate: [AuthGuard]},

  { path: 'calllist', component:  CallListComponent ,canActivate: [AuthGuard]},
  { path: 'feedbacklist', component:  FeedbackListComponent ,canActivate: [AuthGuard]},
  { path: '', component: LoginComponent, canActivate: [AuthGuardLog] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard]},
  { path: 'warranty_list', component:  WarrantyListComponent ,canActivate: [AuthGuard]},
  { path: 'service_list', component:  ServiceCenterListComponent ,canActivate: [AuthGuard]},
  { path: 'add_vendor', component:  AddVendorComponent ,canActivate: [AuthGuard]},
  { path: 'emergency_contact_list', component:  EmergencyContactListComponent ,canActivate: [AuthGuard]},
  { path: 'document_list', component:  DocumentListComponent ,canActivate: [AuthGuard]},
  { path: 'commissioning_list', component:  CommissioningListComponent ,canActivate: [AuthGuard]},
  { path: 'list_vendor', component:  ListVendorComponent ,canActivate: [AuthGuard]},
  { path: 'detail_vendor/:id', component:  DetailVendorComponent ,canActivate: [AuthGuard]},
  { path: 'task_add', component:  TaskAddComponent ,canActivate: [AuthGuard]},
  { path: 'task_list/:type', component:  TaskListComponent ,canActivate: [AuthGuard]},
  { path: 'task_detail/:taskId', component:  TaskDetailComponent ,canActivate: [AuthGuard]},
  { path: 'customer-complaints-list/:customerId', component:  CustomerComplaintsListComponent ,canActivate: [AuthGuard]} ,
  { path: 'customer-project-list/:customerId', component:  CustomerProjectListComponent ,canActivate: [AuthGuard]},
  { path: 'customer-project-add/:customerId/:projectId', component:  CustomerProjectAddComponent ,canActivate: [AuthGuard]},
  { path: 'customer-project-edit/:customerId/:projectId', component:  CustomerProjectAddComponent ,canActivate: [AuthGuard]},
  { path: 'customer-product-list/:customerId', component:  CustomerProductListComponent ,canActivate: [AuthGuard]},
  { path: 'customer-complaints-list/:customerId', component:  CustomerProductListComponent ,canActivate: [AuthGuard]},
  { path: 'customer-product-add/:customerId/:productId', component:  CustomerProductAddComponent ,canActivate: [AuthGuard]},
  { path: 'customer-product-edit/:customerId/:productId', component:  CustomerProductAddComponent ,canActivate: [AuthGuard]},
  { path: 'customer-document-list/:customerId', component:  CustomerDocumentListComponent ,canActivate: [AuthGuard]},
  { path: 'customer-document-add/:customerId', component:  CustomerDocumentAddComponent ,canActivate: [AuthGuard]},
  { path: 'customer-call-request/:customerId', component:  CustomerCallRequestComponent ,canActivate: [AuthGuard]},
  { path: 'customer-amc-list/:customerId', component:  CustomerAmcListComponent ,canActivate: [AuthGuard]},
  { path: 'warranty-renewal-list', component:  WarrantyRenewalListComponent ,canActivate: [AuthGuard]},
  { path: 'vendor-complaint-list', component:  VendorComplaintListComponent ,canActivate: [AuthGuard]},
  { path: 'nature-of-problem-list', component:  NatureOfProblemListComponent ,canActivate: [AuthGuard]},
  { path: 'vendor-add', component:  VendorAddComponent ,canActivate: [AuthGuard]},
  { path: 'taskreport', component:  TaskreportComponent ,canActivate: [AuthGuard]},
  { path: 'invoicereport', component:  InvoicereportComponent ,canActivate: [AuthGuard]},
  { path: 'starcustomer', component:  StarcustomerComponent ,canActivate: [AuthGuard]},



  { path: 'login-request-list', component:  LoginRequestListComponent ,canActivate: [AuthGuard]},
  { path: 'login-request-detail/:loginId', component:  LoginRequestDetailComponent ,canActivate: [AuthGuard]},

  { path: 'rating-feedback/:type/:customer_code/:contact_name/:mobile_no', component:  RatingFeedbackComponent},
  { path: 'rating-feedback/:type/:customer_code/:contact_name/:mobile_no/:so_no1/:so_no2', component:  RatingFeedbackComponent},
  { path: 'rating-feedback/:type/:customer_code/:contact_name/:mobile_no/:so_no1/:so_no2/:so_no3/:so_no4', component:RatingFeedbackComponent},
  { path: 'rating-feedback/:type/:customer_code/:contact_name/:mobile_no/:so_no1/:so_no2/:so_no3', component:  RatingFeedbackComponent},

  { path: 'customer-feedback/:type', component:  CustomerFeedbackListComponent ,canActivate: [AuthGuard]},
  { path: 'vendor-complaint/:id', component:  VendorAddComponent ,canActivate: [AuthGuard]},
  { path: 'pm-list', component: PmListComponent  ,canActivate: [AuthGuard]},
  { path: 'eng-report', component: EngineerReportComponent  ,canActivate: [AuthGuard]},
  { path: 'mis-report', component: MisReportComponent  ,canActivate: [AuthGuard]},
  { path: 'customer-feedback-report', component: CustomerfeedbackreportComponent  ,canActivate: [AuthGuard]},
  // { path: 'customer-stluser/:customerId', component: CustomerStluserComponent  ,canActivate: [AuthGuard]},



  { path: 'thanku', component: ThankuPageComponent },
  { path: '**', redirectTo: ''},
  {
    path: 'list', component:  TaskListComponent, canActivate: [AuthGuard],
    children:
    [
      { path: 'add', component:  TaskAddComponent },
      { path: 'detail', component:  TaskDetailComponent }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    AngularFontAwesomeModule,
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
