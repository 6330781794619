import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DatabaseService } from './_services/database.service';
import { DialogComponent } from './_services/DialogComponent';

@Injectable({
  providedIn: 'root'
})

export class SessionService implements OnInit{
  
  constructor(public route: ActivatedRoute, public router:Router, public db:DatabaseService, public dialogAlert:DialogComponent) { }
  
  trico_user:any = {};
  
  ngOnInit()
  {
    this.trico_user.tri_login = false;
    console.log(this.trico_user);
  }
  
  GetSession():  Observable<any> 
  {
    this.trico_user = JSON.parse(localStorage.getItem('trico_user')) || [];
    console.log(this.trico_user);
    return of(this.trico_user);
  }
  
  LogOutSession()
  {
    this.trico_user = {};
    this.trico_user.tri_login = false;
    this.db.can_active = '';
    localStorage.removeItem('trico_user');
    localStorage.removeItem('st_user');
    localStorage.removeItem('login');
    localStorage.removeItem('users');

  }
  NextUrl2:any = '';
  
  NextUrl:any = '';
  NextUrl_temp:any = '';
  SetSession(data:any){
    
    // this.NextUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    // this.NextUrl2 = this.route.snapshot.queryParams['returnUrl'] || '/login-request-list';
    
    this.db.FetchData(data,'login/submit')
    .subscribe((data:any) => {
      if(data['user'])
      {
        console.log(data);
        this.trico_user = data['user'];
        this.trico_user.tri_login = true;
        this.db.can_active = '1';
        localStorage.setItem('trico_user',JSON.stringify(this.trico_user));
        if(this.trico_user.access_level == 6||this.trico_user.access_level == 2)
        {
         this.NextUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        }
        else
        {
          this.NextUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        }
        console.log(this.NextUrl);
        console.log('****LOGIN****');
        this.router.navigate([this.NextUrl]);
       
      }
      else{
        this.dialogAlert.error('Please check your Username and Password');
      }
    },error=>{
      this.dialogAlert.error('Something went wrong !!! Try Again...');
      console.log(error);
    });
   
  }
 
  
}