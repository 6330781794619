import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';


@Component({
  selector: 'app-contact-person-modal',
  templateUrl: './contact-person-modal.component.html',
  styleUrls: ['./contact-person-modal.component.scss']
})
export class ContactPersonModalComponent implements OnInit {

  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog) { }

  contactPersonList:any = [];
  data:any = {};
  ngOnInit() {
    console.log(this.stored_data);
    this.contactPersonList = Object.assign([],this.stored_data.data.contact_data);
    this.data = Object.assign({},this.stored_data.data.data);
  }

  cp_data:any = {};
  addContactPerson()
  {
    console.log(this.data);
    this.contactPersonList.push(this.data);
    console.log('*** CONTACT PERSON LIST ***');
    console.log(this.contactPersonList);
    this.data = {};
  }
  
  removeContactPerson(index:any)
  {
    console.log(index);
    this.contactPersonList.splice(index,1);
  }
  
   
  formLoading:any = false;
  updateVendor()
  {
    console.log('*** UPDATE VENDOR ***');
    console.log(this.contactPersonList);
    
    this.db.FetchData({'contact_person':this.contactPersonList,'data':this.data,'action':'contact'},'Master_Vendor/updateVendor')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success') {
        this.dialogAlert.success('Vendor Contact Data','Successfully Updated')
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;  
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });  
  }
  

}
