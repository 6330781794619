
import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-customer-product-list',
  templateUrl: './customer-product-list.component.html',
  styleUrls: ['./customer-product-list.component.scss']
})
export class CustomerProductListComponent implements OnInit {


      skeleton_data: any =[]
  fgList: any = [];
  customerEncryptId: any = '';

  start: any = 0;
  pageLimit: any = 50;

  searchData: any = {};

  totalPage: any = '';
  pageNumber: any = '';
  totalFGCount: any = '';

  allCountData: any = {};

  calenderMinDate: any;
  currentDate: any;

  isRequestInProcess: any = true;
  


  constructor(public dbService: DBRequest,
              public dialogAlert: DialogComponent,
              public toast: SnackBarOverview,
              public route: ActivatedRoute,
              public db:DatabaseService,
              public session : SessionService,) {

          this.calenderMinDate = new Date('1950-01-01');
          this.currentDate = new Date();
         
  }
  access_rights:any = {};
 
  ngOnInit() {
      if(this.session.trico_user.access_level != '1')
      {
        this.session.trico_user.access_module.map((x:any)=>{
          if(x.module_id === 2)
          {
            this.access_rights = x;
          }
        });
        console.log(this.access_rights);
      }
      this.skeleton_data = new Array(7);
       this.route.params.subscribe(params => {

            console.log(params);
            this.customerEncryptId = params.customerId;

            console.log(this.customerEncryptId);

            this.onGetProjectProductList();
            this.onGetAllCounterData();
            this.onGetCustomerDetail();

      });
  }
  customerData:any = {};
  customerContactData:any = [];
  
  onGetCustomerDetail() {
                  
    this.isRequestInProcess = true;
    
    this.dbService.onPostRequestData({
          
          customerId: this.customerEncryptId
          
    }, 'customer/onCustomerDetail').subscribe(resultData => {
          
          console.log(resultData);
          
          setTimeout(() => {
                
                this.isRequestInProcess = false;
                
          }, 500);
          
          this.customerData = resultData[`customerData`];
          this.customerContactData = resultData[`customerContactData`];
          
    }, error => {
          
          this.isRequestInProcess = false;
          this.toast.openError('Something Went Wrong, Try Later', '');
    });
    
}

  onGetProjectProductList() {

      this.isRequestInProcess = true;
 
      console.log(this.searchData);

      this.searchData.dateCreated = '';
      if (this.searchData[`date`]) {
           this.searchData.dateCreated = moment(this.searchData[`date`]).format('YYYY-MM-DD');
      }

      this.dbService.onPostRequestData({

         customerId: this.customerEncryptId,
         start: this.start,
         pageLimit: this.pageLimit,
         searchData: this.searchData

      }, 'sharedData/getFGList').subscribe(resultData => {

            console.log(resultData);

            setTimeout(() => {

                this.isRequestInProcess = false;

            }, 500);

            this.fgList = resultData[`fgList`];
            console.log(this.fgList);
            this.totalFGCount = resultData[`totalFGCount`];

            this.totalPage = Math.ceil(this.totalFGCount / this.pageLimit);
            this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;

      }, error => {

            this.isRequestInProcess = false;
            this.toast.openError('Something Went Wrong, Try Later', '');
      });
  }


  onGetAllCounterData() {

    this.dbService.onPostRequestData({

      customerId: this.customerEncryptId

    }, 'customer/customerTotalCountData').subscribe(resultData => {

          console.log(resultData);

          this.allCountData = resultData[`allCountData`];

    }, error => {
          this.toast.openError('Something Went Wrong, Try Later', '');
    });

  }


  onPageInputHandler(type) {

      if (type == 'greater') {

          this.pageNumber = this.totalPage;
          this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
          this.onGetProjectProductList();

      } else {

          this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
          this.onGetProjectProductList();

      }
  }
  deleteProduct(id){
      console.log(id);
      
      
      this.dialogAlert.delete('Product Data').then((result:any) => {
            console.log(result);
            if(result){
                  this.db.FetchData( {'productId':id}, 'Project/deleteProduct')
                  .subscribe(res => {
                    console.log(res);
                    
                    
                  
              
                this.dialogAlert.success('Deleted','Product Data Has Been Deleted.');
                this.onGetProjectProductList();
                this.onGetAllCounterData();
                this.onGetCustomerDetail();
              },err=>{
                console.log(err);
                this.dialogAlert.error('Something went wrong! Try Again ...');
              });
            }
          });

     }

}
