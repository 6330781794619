import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';
import { SessionService } from 'src/app/session.service';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  
  skeleton_data: any =  []
  list_data: any = []
  
  data: any = {};
  searchData: any = {};
  
  customerList: any = [];
  
  start: any = 0;
  pageLimit: any = 100;
  
  totalPage: any = '';
  pageNumber: any = '';
  totalCustomerCount: any = '';
  
  isRequestInProcess: any = true;
  
  search_box:any = false;
  stlcodeList:any=[];
  stl_code:any=[];
  constructor(public dbService: DBRequest,
    public dialogAlert : DialogComponent,
    public alert: DialogComponent,
    public toast: SnackBarOverview,
    public session : SessionService,

    public db : DatabaseService) {
      
      
      
    }
    
    access_rights:any = {};
    ngOnInit() {
     
      
      console.log('*** CUSTOMER LIST LOADED ***');
      if(this.session.trico_user.access_level != '1')
      {
        this.session.trico_user.access_module.map((x:any)=>{
          if(x.module_id === 2)
          {
            this.access_rights = x;
          }
        });
        console.log(this.access_rights);
      }
      console.log(this.access_rights);
      this.skeleton_data = new Array(7);
      this.list_data = new Array(20);
      this.getCustomerList();
   this.getStlcodeList()
   
   console.log(this.stl_code);
    }

    
    getStlcodeList()
  {
    this.db.FetchData({},'Master_User/stl_codes')
    .subscribe((result:any)=>{
      console.log(result);  
      this.stlcodeList = result;
      console.log(this.stlcodeList);
   
      
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });  
   

  }
    
    getCustomerList() {
      
      
      let isSearchDataExist = false;
      for (const key in this.searchData) {
        
        if (this.searchData.hasOwnProperty(key) && this.searchData[key]) {
          isSearchDataExist = true;
        }
      }
      
      if (!isSearchDataExist) {
        this.isRequestInProcess = true;
      }
      
      this.dbService.onPostRequestData({
        
        start: this.start,
        pageLimit: this.pageLimit,
        searchData: this.searchData,
       'STLCODE':this.session.trico_user.assign_detail,
        'type':this.session.trico_user.access_level,
      }, 'customer/getCustomerList2').subscribe(resultData => {
        
        console.log(resultData);
        
        
        setTimeout(() => {
          
          if (!isSearchDataExist) {
            this.isRequestInProcess = false;
          }
          
        }, 500);
        
        this.customerList = resultData[`customerList`];
        
        this.totalCustomerCount = resultData[`totalCustomerCount`];
        
        this.totalPage = Math.ceil(this.totalCustomerCount / this.pageLimit);
        this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;
        
      }, error => {
        
        this.isRequestInProcess = false;
        this.toast.openError('Something Went Wrong, Try Later','');
        
      });
    }
    
    
    onDeleteCustomerHandler(index) {
      
      this.alert.confirmation('Customer Data').then((confirmResult) => {
        if (confirmResult) {
          this.isRequestInProcess = true;
          this.dbService.onPostRequestData({
            customerId: this.customerList[index].id
          }, 'customer/onDeleteCustomerData').subscribe(resultData => {
            console.log(resultData);
            this.customerList.splice(index, 1);
            this.getCustomerList();
          }, error => {
            console.log(error);
            this.isRequestInProcess = false;
            this.toast.openError('Something Went Wrong, Try Later','');
          });
          
          
        }
      });
    }
    
    onPageInputHandler(type) {
      
      if (type == 'greater') {
        
        this.pageNumber = this.totalPage;
        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
        this.getCustomerList();
        
      } else {
        
        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
        this.getCustomerList();
        
      }
    }
    
    showfilter() {
      
      this.search_box = true;
    }
    
    hidefilter()  {
      
      this.search_box = false;
    }
    
    exp_data:any=[];  
    excel_data:any=[];
    project:any=[]
    so_no:any=[]
    si_no:any=[]
    date_created:any=[]

    getDataInExcel()
    {
      this.dbService.onPostRequestData({searchData: this.searchData,type:this.session.trico_user.access_level},'customer/allCustomerListExcel2').subscribe(resultData => 
        {
          console.log(resultData);
          this.exp_data = resultData;
          
          for(let i=0;i<this.exp_data.length;i++)
          {  
          for(let j=0;j<this.exp_data[i].project.length;j++)
{
  this.project=this.exp_data[i].project[j].project_name
this.so_no=this.exp_data[i].project[j].so_no
this.si_no=this.exp_data[i].project[j].si_no
this.date_created=this.exp_data[i].project[j].date_created

            this.excel_data.push({
              'Customer Name':this.exp_data[i].company_name,
              'Code':this.exp_data[i].cust_code,
              'STL Code':this.exp_data[i].stl_code,
              'Email ID':this.exp_data[i].email,
              'Landline No.':this.exp_data[i].landline_no,
              'Contact Person Name':this.exp_data[i].contactPerson && this.exp_data[i].contactPerson['name']  ? this.exp_data[i].contactPerson['name'] : '',
              'Contact Person Designation':this.exp_data[i].contactPerson && this.exp_data[i].contactPerson['designation'] != null ? this.exp_data[i].contactPerson['designation'] : '',
              'Contact Person Email Id':this.exp_data[i].contactPerson && this.exp_data[i].contactPerson['email'] != null ? this.exp_data[i].contactPerson['email'] : '',
              'Contact Person Mobile No.':this.exp_data[i].contactPerson && this.exp_data[i].contactPerson['mobile'] != null ? this.exp_data[i].contactPerson['mobile'] : '',
              'State & District':this.exp_data[i].state_name +','+ this.exp_data[i].district_name,
              'Type':this.exp_data[i].cust_type,
              'No. Req':this.exp_data[i].total_complaint,
              'Running Req..':this.exp_data[i].running_complaint,
              'Last Request':this.exp_data[i].days,
              'Status':this.exp_data[i].status,

            
            });
          }
        }
          this.db.exportAsExcelFile(this.excel_data,'Customers');
          
          this.excel_data = [];
          this.exp_data = [];
          
        }, error => {
          console.log(error)
          
        });
      }
      
    }
    