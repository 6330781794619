import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-add-product-model',
  templateUrl: './add-product-model.component.html',
  styleUrls: ['./add-product-model.component.scss']
})
export class AddProductModelComponent implements OnInit {


  constructor(public db : DatabaseService, public dialogRef: MatDialog, public dialogAlert : DialogComponent,  @Inject(MAT_DIALOG_DATA) public stored_data :any) {
   }
  
  ngOnInit() {
    this.getProductBrand();
    this.data = Object.assign({},this.stored_data.data);
    if(this.data.model)
    {
      this.data.model = '';
    }
  }
  
  
  brandList:any = [];
  getProductBrand()
  {
    console.log('*** Product Brand List ***');
    this.db.FetchData({},'Master_Product/getProductBrand')
    .subscribe((result:any)=>{
      console.log(result);  
      this.brandList = result['brand'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  modelExist:any = false;
  checkModelExist()
  {
    console.log('*** CHECK MODEL EXIT ***');
    this.db.FetchData({'data':this.data},'Master_Product/checkModelExist')
    .subscribe((result:any)=>{
      console.log(result);  
      this.modelExist = result['exist'];
      console.log(this.modelExist);
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  

  
  clearForm(myForm:any)
  {
    myForm.resetForm();
  }
  
  data:any = {};
  submitProductModel(myForm:any)
  {
    console.log('*** SUBMIT PROUCT MODEL ***');
    console.log(this.data);
    
    this.db.FetchData({'data' : this.data},'Master_Product/submitProductModel')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialogAlert.success('Product Model Data','Successfully Saved !!!');
        this.clearForm(myForm)
      }
      else
      {
        var error = result['error']['message']?result['error']['message']:'Something went wrong... Try Again !!!';
        this.dialogAlert.error(error);
      }
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
    
  }
  
}
