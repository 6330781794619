import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  * as FileSaver from 'file-saver';
import  * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService 
{  
  ListSearch:any={}

  MyUrl: any = 'https://crm.tricolite.com/api/';
  UploadUrl: string = "https://crm.tricolite.com/api/uploads/";
  UploadUrl1: string = "https://crm.tricolite.com/api/uploads/Customer_Doc";

  DbUrl: string = "https://crm.tricolite.com/api/index.php/";                   //// live

  // MyUrl: any = 'http://phpstack-83335-1824762.cloudwaysapps.com/api/';
  // UploadUrl: string = "http://phpstack-83335-1824762.cloudwaysapps.com/api/uploads/";
  // DbUrl: string = "http://phpstack-83335-1824762.cloudwaysapps.com/api/index.php/";    //// test



  // tslint:disable-next-line:variable-name
  can_active: string = '';

  constructor(public http: HttpClient) { }
  header: any = new HttpHeaders();


  FetchData( request_data : any, fn: any) {
    this.header.append('Content-Type', 'application/json');
    console.log(request_data);
    return this.http.post(this.DbUrl + fn, JSON.stringify(request_data), {headers:this.header});
  }
  upload_image(val, fn_name) {
    // this.header.append('Content-Type', 'multipart/form-data');

    console.log(val);
    return this.http.post(this.DbUrl + fn_name, val, { headers: this.header });

}
  FileData(request_data: any, fn: any) {
    // this.header.append('Content-Type', undefined);
    console.log(request_data);
    return this.http.post( this.DbUrl + fn, request_data, {headers : this.header});
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
