import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/dialog';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-nature-of-problem-list',
  templateUrl: './nature-of-problem-list.component.html',
  styleUrls: ['./nature-of-problem-list.component.scss']
})
export class NatureOfProblemListComponent implements OnInit {


  skeleton_data:any =[]
  skeleton_screen_active:any= false;
  data:any={};

  constructor(public db:DatabaseService, public dialog : DialogComponent, public session : SessionService) {
    console.log(this.session.trico_user);
   }

  ngOnInit() {
    this.skeleton_data = new Array(7);

    this.getNatureOfProblemList();
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 18 ||x.module_id === 27)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};

  data_not_found:any = false;
  pageLimit:any =20
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pagenumber:any;
  natureOfProblemList:any = [];

  getNatureOfProblemList()
  {
    this.skeleton_screen_active = true;
    this.data_not_found = false;

    this.db.FetchData('','Master_Complaint/getNatureOfProblemList').subscribe((result:any)=>
    {
      console.log(result);
      this.natureOfProblemList = result['natureOfProblem_title'];
      this.total_count = result['count'];

      if(this.natureOfProblemList.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }

      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }

  clearForm(myForm:any)
  {
    this.data = {};
    myForm.resetForm();
  }


  formLoading:any = false;
  submitComplaintTitle(myForm:any)
  {
    this.formLoading = true;
    console.log(this.data);
    this.db.FetchData({'data':this.data, 'session': this.session.trico_user} ,'Master_Complaint/submitNatureOfProblemTitle')
    .subscribe((result:any)=>{
      console.log(result);
      // this.dialog.success('Complaint Title Data','Successfully Saved !!!');
      if(result['msg'] == 'success')
      {
        this.dialog.success('Complaint Title Data','Successfully Saved !!!');
      }
      else{
        this.dialog.error(result['error']);
      }
      this.clearForm(myForm);
      this.formLoading = false;
      this.getNatureOfProblemList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
  }


  editComplaintTitleList(id:any)
  {
    console.log('*** EDIT COMPLAINT TITLE DATA LIST ***');
    console.log('ID -'+id);
    this.data = Object.assign({},this.natureOfProblemList.filter( (x:any) => x.id == id)[0]);
    console.log(this.data);
    this.data['title'] = this.data['nature'];
  }

  updateComplaintTitle(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Submit Complaint Title List ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data, 'session':this.session.trico_user},'Master_Complaint/updateNatureOfProblemTitle')
    .subscribe((result:any)=>{
      console.log(result);

      if(result['msg'] == 'success')
      {
        this.dialog.success('Nature Of Problem Title Data','Successfully Saved !!!');
      }
      else{
        this.dialog.error(result['error']);
      }
      this.clearForm(myForm);
      this.formLoading = false;
      this.getNatureOfProblemList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
  }

  deleteComplaintTitleList(title_id:any,index:any){
    console.log(title_id);
    console.log(index);
    this.dialog.delete('Nature Of Problem Title Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':title_id}, 'Master_Complaint/deleteNatureOfProblemTitleList')
        .subscribe(res => {
          console.log(res);
          this.natureOfProblemList.splice(index,1);
          this.dialog.success('Deleted','Nature Of Problem Title Data has been deleted.');
          this.getNatureOfProblemList();
        },err=>{
          console.log(err);
          this.dialog.error('Something went wrong! Try Again ...');
        });
      }
    });
  }




}
