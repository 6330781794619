import { Component, OnInit, Renderer2 } from '@angular/core';
import { SessionService } from '../session.service';
import { Router } from '@angular/router';
import { DatabaseService } from '../_services/database.service';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  status:boolean = false;
  constructor(public dailog: MatDialog,public session : SessionService, public router : Router, private renderer: Renderer2, public db : DatabaseService) { }

  ngOnInit() {
    console.log(this.session.trico_user);
    this.abacusConnect();
  }

  logout(){     
    this.session.LogOutSession();
    this.router.navigate(['']);
  } 
  openDetailDialog(): void {
    const dialogRef = this.dailog.open(ChangepasswordComponent, {
      width: '500px',
   
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.onGetTaskDetail();
    });
  }
  toggleHeader() {
    this.status = !this.status;
    if(this.status) {
        this.renderer.addClass(document.body, 'toggle-active');
    }
    else {
        this.renderer.removeClass(document.body, 'toggle-active');
    } 
  }
  
  // count : any 
  count: any = [];

  abacusConnect(){

    this.db.FetchData( {}, 'AbacusConnect/abacusConnect')
            .subscribe(result => {
                
                console.log(result);
                this.count = result;
                
                
            }, error => {
                
                // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
            });

  }

  goToAbacusConnect(){

    window.open('http://crmsupport.abacusdesk.com/projecttaskdetail/ckxmWHJMNXpJeTNxbWlXdzQ5TjRKUT09', '_blank');

  }



}
