import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SessionService } from 'src/app/session.service';
import { ModelComponentOutOfMasterComponent } from 'src/app/documents/model-component-out-of-master/model-component-out-of-master.component';


@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

  skeleton_data:any =[]

  skeleton_screen_active:any= false;
  data:any={};

  constructor(public db:DatabaseService, public dialog : DialogComponent,  public modal: MatDialog, public session : SessionService) { }

  ngOnInit() {
    this.skeleton_data = new Array(5);
    this.getDocumentList();
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 16||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};

  data_not_found:any = false;
  pageLimit:any =20
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pagenumber:any;
  documentList:any = [];
  type:any;
  goToLink(url: string){
    window.open(url, "_blank");
}
  getDocumentList()
  {
    this.skeleton_screen_active = true;
    this.data_not_found = false;

    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'Master_Document/getDocumentList')
    .subscribe((result:any)=>{
      console.log(result);
      this.documentList = result['document'];
      this.total_count = result['count'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pagenumber = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pagenumber);
      if(this.documentList.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }

      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 300);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }



  selectedFile: File[]=[];
  i:any = 0;
  reader = new FileReader();

  fileChange(event:any) {

    console.log(event.target.files);
    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);

      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls.push(event.target.result);
        console.log(this.urls);

        for (let index = 0; index < this.selectedFile.length; index++) {

          for (let urlIndex = 0; urlIndex < this.urls.length; urlIndex++) {

            if(index == urlIndex) {
              this.selectedFile[index]['path'] = this.urls[urlIndex];
            }
          }
        }

        console.log(this.selectedFile);

      }

      reader.readAsDataURL(event.target.files[i]);
      console.log(event.target.files[i])

    }
  }

  urls = new Array<string>();
  remove_image(i:any){
    console.log(i);
    this.urls.splice(i,1);
    this.selectedFile.splice(i,1);
  }


  clearForm(myForm:any)
  {
    myForm.resetForm();
    this.selectedFile = [];
    this.urls = [];
    this.data = {};
    this.formData = new FormData();
    this.urls = new Array<string>();
  }


  formData = new FormData();
  formLoading:any = false;
  submitDocument(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Submit Document ***');
    console.log(this.data);
    console.log(this.selectedFile);

    this.formData = new FormData();

    this.i = 0;
    // for (let f of this.selectedFile)
    // {
    //   this.formData.append(this.i, f, f.name);
    //   this.i++;
    // }
    for(var j=0; j<this.selectedFile.length; j++)
    {
      this.formData.append(this.i,this.selectedFile[j],this.selectedFile[j].name);
      this.i++;
    }

    console.log(this.data);

    for (var property1 in this.data) {

        console.log(property1);
        this.formData.append(property1,this.data[property1]);
        this.formData.append('data['+property1+']',this.data[property1]);
    }

    console.log(this.session.trico_user);
    for (var property1 in this.session.trico_user) {

        console.log(property1);
        this.formData.append('session['+property1+']',this.session.trico_user[property1]);
    }

    this.db.upload_image(this.formData,'Master_Document/submitDocument')
    .subscribe((result:any)=>{
      console.log(result);

      if(result['msg'] == 'success')
      {
        this.reader = new FileReader();
        this.dialog.success('Document Data','Successfully Saved !!!');
      }
      else{
        this.dialog.error(result['error']);
      }
      this.clearForm(myForm);
      this.formLoading = false;
      this.getDocumentList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });

  }

  submiturl(myForm:any)
  {
    console.log('*** Submit URL ***');
    console.log(this.data);

    this.formData = new FormData();

    this.i = 0;

    console.log(this.data);

    for (var property1 in this.data) {

        console.log(property1);
        this.formData.append(property1,this.data[property1]);
        this.formData.append('data['+property1+']',this.data[property1]);
    }

    console.log(this.session.trico_user);
    for (var property1 in this.session.trico_user) {

        console.log(property1);
        this.formData.append('session['+property1+']',this.session.trico_user[property1]);
    }

    this.db.FileData(this.formData,'Master_Document/submitUrl')
    .subscribe((result:any)=>{
      console.log(result);

      if(result['msg'] == 'success')
      {
        this.reader = new FileReader();
        this.dialog.success('URL','Successfully Saved !!!');
      }
      else{
        this.dialog.error(result['error']);
      }
      this.clearForm(myForm);
      this.formLoading = false;
      this.getDocumentList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });


  }
  deleteDocument(doc_id:any,index:any){
    console.log(doc_id);
    console.log(index);
    this.dialog.delete('Document Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':doc_id}, 'Master_Document/deleteDocument')
        .subscribe(res => {
          console.log(res);
          this.documentList.splice(index,1);
          this.dialog.success('Deleted','Document Data has been deleted.');
          this.getDocumentList();
        },err=>{
          console.log(err);
          this.dialog.error('Something went wrong! Try Again ...');
        });
      }
    });
  }


  openDocument(id:any): void {
    this.data =  Object.assign({},this.documentList.filter((x:any)=>x.id == id)[0].attachment);
    console.log(this.data)
    const dialogRef = this.modal.open(ModelComponentOutOfMasterComponent, {
      width: '600px',
      data:{
        data : this.data
      }
    });
  }

}
