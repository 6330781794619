import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeStatusModelComponent } from '../customer/change-status-model/change-status-model.component';
import { CustomerContactPersonModalComponent } from '../customer/customer-contact-person-modal/customer-contact-person-modal.component';
import { CustomerInfoModelComponent } from '../customer/customer-info-model/customer-info-model.component';
import { DBRequest } from '../databaseService';
import { DialogComponent } from '../dialog';
import { FeedbackdocumentComponent } from '../feedbackdocument/feedbackdocument.component';
import { SessionService } from '../session.service';
import { SnackBarOverview } from '../toast';
import { DatabaseService } from '../_services/database.service';

@Component({
  selector: 'app-feedbackdetail',
  templateUrl: './feedbackdetail.component.html',
  styleUrls: ['./feedbackdetail.component.scss']
})
export class FeedbackdetailComponent implements OnInit {
      lotData:any=[];
  active:any = {};
  skeleton_data: any =[]
  customerEncryptId: any = '';
  isRequestInProcess: any = true;
sales_executive_update: any;
fgData: any = [];
documentData: any = [];
questionData: any = [];
  data_not_found:boolean=false;
contactData: any = [];
feedbackData: any = [];
  assignUserList:any=[];
  allCountData: any = {};
   stlList:any=[];
tmp_userList:any=[];
rsm:any=[];
ass_user:any=[];
  constructor(public dbService: DBRequest,
      public db:DatabaseService,
        public alert: DialogComponent,
        public toast: SnackBarOverview,
        public router:Router, public route : ActivatedRoute,
        public dialog: MatDialog,
        public session : SessionService,
        public modal: MatDialog,) {


        }
access_rights:any = {};

        ngOnInit() {
              if(this.session.trico_user.access_level != '1')
              {
                this.session.trico_user.access_module.map((x:any)=>{
                  if(x.module_id === 2)
                  {
                    this.access_rights = x;
                  }
                });
                console.log(this.access_rights);
              }
console.log(this.session);

              this.skeleton_data = new Array(7);

              this.route.params.subscribe(params => {

                    console.log(params);
                    this.customerEncryptId = params.id;

                    console.log(this.customerEncryptId);

                    this.onGetCustomerDetail();

                    this.onGetAllCounterData();
              });
        }



        onGetCustomerDetail() {

              this.isRequestInProcess = true;

              this.db.FetchData({

                    id: this.customerEncryptId

              }, 'customer/getFeedbackInspectionDetail').subscribe(resultData => {

                    console.log(resultData);

                    setTimeout(() => {

                          this.isRequestInProcess = false;
                          // this. StluserLIst()

                    }, 500);

                    this.contactData = resultData[`contactData`];
                    console.log( this.contactData);

                    this.feedbackData = resultData[`feedbackData`];
                   console.log(this.feedbackData);
                   this.fgData = resultData[`fgData`];
                   console.log(this.fgData);
                   this.documentData = resultData[`documentData`];
                   console.log(this.documentData)
                   this.questionData = resultData[`questionData`];
                   this.lotData = resultData[`lotData`];

              }, error => {

                    this.isRequestInProcess = false;
                    this.toast.openError('Something Went Wrong, Try Later', '');
              });

        }

        openDocument(id:any): void {
            this.data =  Object.assign({},this.documentData.filter((x:any)=>x.id == id));
            console.log(this.data)
            const dialogRef = this.modal.open(FeedbackdocumentComponent, {
              width: '600px',
              data:{
                data : this.data
              }
            });
          }
        onGetAllCounterData() {

            this.db.FetchData({

                    customerId: this.customerEncryptId

              }, 'customer/customerTotalCountData').subscribe(resultData => {

                    console.log(resultData);

                    this.allCountData = resultData[`allCountData`];

              }, error => {
                    this.toast.openError('Something Went Wrong, Try Later', '');
              });

        }

        data:any = {};


  }

