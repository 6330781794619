import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-mis-report',
  templateUrl: './mis-report.component.html',
  styleUrls: ['./mis-report.component.scss']
})
export class MisReportComponent implements OnInit {
  constructor(public db: DatabaseService) {
      this.currentDate = new Date();

   }

  optionValue: any;
  searchData: any = {};
  fileName1 = 'PaidService.xlsx';
  fileName2 = 'WarrantyService.xlsx';
  currentDate:any;
  calenderMinDate:any;

  ngOnInit() {
    this.getData(this.optionValue);
      }
  comissioning: any={};
  complain: any= [];
  engineerData: any= {};
  techList1: any;
  opval = false;
  title = 'Under Warranty';
  taskData: any;
  allData :any={}
  assign: any;
  data_not_found:any = false;

  getData(optionValue) {
    console.log(optionValue);
    if(!optionValue){
      this.optionValue = 'underwarranty';
    }
    if (optionValue != 'underwarranty') {
      this.opval= true;
      this.title = optionValue + ' Warranty ';
    }
    else {
      this.opval = false;
      this.title = optionValue + ' Warranty';
    }
    if (this.searchData[`date_from`]) {
      this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
    }
    if (this.searchData[`date_to`]) {
      this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
    }
    this.data_not_found = false;
    this.optionValue
    this.db.FetchData({'searchData': this.searchData,service_filter: this.optionValue}
      , 'Task/finalMis').subscribe(resultData => {
        console.log(resultData);
        this.allData = resultData;
        console.log(this.allData);
        this.techList1 = resultData['SERVICECENTER'];
        console.log(this.techList1);
        this.engineerData = resultData['enGdata'];
        console.log(this.engineerData);
        this.complain = resultData['complaint_by_filter'];
        for (let index = 0; index < this.complain.length; index++) {

          var t = this.complain[index].old_open_task_id
    var a = t.split("/");
    this.complain[index].old_open_task_id = a[0];
    this.complain[index].time  = a[1];

    console.log(this.complain[index].time);

        }

        // for (let index = 0; index < this.engineerData.length; index++) {
        // if(this.engineerData[index].all_leads_count !=0 &&this.engineerData[index].closed_lead_count !=0 &&this.engineerData[index].open_lead_count !=0 ){

        //   this.engineerData.push(this.engineerData[index])
        //   console.log(this.engineerData);

        // }


        // }
        console.log(this.complain);
        this.taskData= resultData['TASKDATAREC'];
        console.log(this.taskData);
        this.assign = resultData['ASSIGNPARTS'];
        console.log(this.assign);
        this.comissioning = resultData['total_comissioning_completed'];
        console.log(this.comissioning);
      });
      if(this.allData.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }
    }

    getDataInUnder(): void  {
      const Index = 1;
      const element1 = document.getElementById('excel2-table');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName2);
    }

    getDataInPaid(): void {
      const Index = 1;
      const element2 = document.getElementById('excel1-table');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element2);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet2');
      XLSX.writeFile(wb, this.fileName1);
    }
  }
