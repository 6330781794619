import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';


@Component({
  selector: 'app-change-status-model',
  templateUrl: './change-status-model.component.html',
  styleUrls: ['./change-status-model.component.scss']
})
export class ChangeStatusModelComponent implements OnInit {

  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog) { }

  data: any = {};
  modelExist: boolean = false;

  ngOnInit() {
    this.data = Object.assign({},this.stored_data.data);

    console.log(this.data);
  }

   formLoading:any = false;
  date :any = {};
  cust_code
  updateStatus() {
    console.log('*** UPDATE CUSTOMER STATUS ***');
    console.log(this.data.accountValidUpTo);
    this.date = this.data.accountValidUpTo;
    this.cust_code = this.data.cust_code;
    console.log(this.cust_code);
    this.db.FetchData({'data':this.data,'activeDate':this.date,'custCode':this.cust_code,'action':'status'},'customer/updateCustomer')
    .subscribe((result: any) => {
      console.log(result);
      if ( result['msg'] == 'success') {
        this.dialogAlert.success('Customer Status','Successfully Updated')
      } else {
        var error = '';



        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < result['error'].length; index++) {
          error += result ['error'] [index]['message']+ ' ' ;
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });
  }

}
