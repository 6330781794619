import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/session.service';
import { SnackBarOverview } from 'src/app/toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {

  skeleton_data: any = []
  skeleton_screen_active:any = false;
  constructor(public db: DatabaseService,
              public dialogAlert: DialogComponent,
    public session : SessionService,
    public toast: SnackBarOverview,) {
  }

  ngOnInit() {
   
    this.getFeedbackList(this.pageLimit,this.start);
    this.skeleton_data = new Array(7);
  }

  data_not_found:any = false;
  pageLimit:any =20;
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pageNumber:any;
  pageNumberTemp:any;
  feedBackList:any = [];
  countData:any = {};


  getFeedbackList(pageLimit:any=20,start:any=0,status:any='Today',action:any='refresh')
  {
    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;
    this.search.typeName = status;
    
    
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit, 'ID':this.session.trico_user.id,
    'type':this.session.trico_user.access_level,},'sharedData/getFeedbackList')
    .subscribe((result:any)=>{
      console.log(result);
      this.feedBackList = result['feedback'];
      this.total_count = result['totalCount'];
      this.countData = result['countData'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
      this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;
      
      console.log(this.pageNumber);
      if(this.feedBackList.length != 0)
      {
        this.data_not_found = false; 
      }
      else
      {
        this.data_not_found = true; 
      }        
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);
      
      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);
      
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }
  

  exp_data:any=[];
    excel_data:any=[];
    
    getDataInExcel()
    {
      this.db.FetchData({'search':this.search},'sharedData/getFeedbackListExcel').subscribe((result:any)=>
      {
          console.log(result);
          this.exp_data = result['feedback'];
          
          for(let i=0;i<this.exp_data.length;i++)
          {
            this.excel_data.push({
              'Feedback Date':this.exp_data[i].feedbackDate,
              'Req. Date':this.exp_data[i].complaintDate,
              'Req. No.': '#SR' + this.exp_data[i].task_no,
              'Customer':this.exp_data[i].customer_name,
              'Project':this.exp_data[i].project_name,
              'FG No.':this.exp_data[i].fg_no,
              'Service Engineer':this.exp_data[i].assign_to_name,
              'Feedback':this.exp_data[i].remark
            });
          }
          
          this.db.exportAsExcelFile(this.excel_data,'Feedback & Queries');
          
          this.excel_data = [];
          this.exp_data = [];
          
        }, error => {
          console.log(error)
          
        });
      }

}
