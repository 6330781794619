import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-product-information-modal',
  templateUrl: './add-product-information-modal.component.html',
  styleUrls: ['./add-product-information-modal.component.scss']
})
export class AddProductInformationModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
