import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SpareCategoryModalComponent } from '../spare-category-modal/spare-category-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-spare-add',
  templateUrl: './spare-add.component.html',
  styleUrls: ['./spare-add.component.scss']
})
export class SpareAddComponent implements OnInit {

  ecrpt_id:any = '';

  constructor(public db : DatabaseService, public dialog : DialogComponent, public modal : MatDialog, public router : Router, public session : SessionService, public route : ActivatedRoute) { 
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log(this.ecrpt_id);
    });

    if(this.ecrpt_id != 'add')
    {
      this.getSparePartDetail();
    }

  }
  
  ngOnInit() {
    this.getSparePartCategory()
  }
  
  categoryList:any = [];
  getSparePartCategory()
  {
    console.log('*** GET SPARE PART CATEGORY ***');
    this.db.FetchData({},'Master_SparePart/getSparePartCategory')
    .subscribe((result:any)=>{
      console.log(result);  
      this.categoryList = result['category'];
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
  }



  getSparePartDetail()
  {
    console.log('*** GET SPARE PART DETAIL ***');
    this.db.FetchData({'spare_part_id':this.ecrpt_id},'Master_SparePart/getSparePartDetail')
    .subscribe((result:any)=>{
      console.log(result);  
      this.data = Object.assign({},result['data']);
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });
  }
  
  clearForm(myForm:any)
  {
    this.data = {};
    myForm.resetForm();
  }
  
  data:any = {};
  formLoading:any = false;
  submitSparePart(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Submit Spare Part ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data, 'session':this.session.trico_user},'Master_SparePart/submitSparePart')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialog.success('Spare Part Data','Successfully Saved !!!');
        this.formLoading = false;
        this.clearForm(myForm);
        this.router.navigate(['/sparelist']);
      }
      else{
        console.log(result['error']['message']);
        this.dialog.error(result['error']['message']);
        this.formLoading = false;
      }
      this.getSparePartCategory();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });
  }


  updateSparePart(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Updated Spare Part ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data, 'session':this.session.trico_user},'Master_SparePart/updateSparePart')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialog.success('Spare Part Data','Successfully Updated !!!');
        this.formLoading = false;
        this.clearForm(myForm);
        this.router.navigate(['/sparelist']);
      }
      else{
        console.log(result['error']['message']);
        this.dialog.error(result['error']['message']);
        this.formLoading = false;
      }
      this.getSparePartCategory();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
      this.formLoading = false;
    });
  }
  
  
  
  openSparePartCategory(): void {
    const dialogRef = this.modal.open(SpareCategoryModalComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getSparePartCategory();
    });
  }
  
  
}
