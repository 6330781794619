import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';
import * as moment from 'moment';

import { WarrantyRenewalComponent } from '../warranty-renewal/warranty-renewal.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-warranty-renewal-list',
  templateUrl: './warranty-renewal-list.component.html',
  styleUrls: ['./warranty-renewal-list.component.scss']
})
export class WarrantyRenewalListComponent implements OnInit {


  calenderMinDate: any;
  currentDate: any;
  skeleton_screen_active: any = false;
  skeleton_data: any = [];
  status: any = 'AMC Expire';
  statusmode: any = 'AMC Expire';

  constructor(public db: DatabaseService,
    public dialog: MatDialog,
    public session: SessionService,
    public dialogAlert: DialogComponent,
    public toast: SnackBarOverview, public route1: Router) {

    this.calenderMinDate = new Date('1950-01-01');
    this.currentDate = new Date();
    if (this.db.ListSearch.warrantystatus) {
      this.search = this.db.ListSearch.warrantystatus
      // this.pageLimit = this.db.ListSearch.pagelimit
      this.start = this.db.ListSearch.start
    }
    else {
      this.search.status = 'AMC Expire'
    }
    this.getWarrantyRenewalList(this.pageLimit, this.start, this.search.status);

  }

  ngOnInit() {
    this.skeleton_data = new Array(7);
    if (this.session.trico_user.access_level != '1') {
      this.session.trico_user.access_module.map((x: any) => {
        if (x.module_id === 6) {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }

  access_rights: any = {};

  data_not_found: any = false;
  pageLimit: any = 15;
  start: any = 0;
  search: any = {};
  search_val: any = {};

  totalCount: any;
  total_page: any;
  pageNumber: any;
  pageNumberTemp: any;
  renewalList: any = [];
  data: any = {};
  amcsigned: any = {};

  seasons: string[] = ['YES', 'NO'];
  tothepage(id) {
    this.db.ListSearch.warrantystatus = this.search
    // this.db.ListSearch.pagelimit = this.pageLimit
    this.db.ListSearch.start = this.start

    console.log(id);
    this.route1.navigate(['customerdetail/' + id]);
    console.log(this.search.loginstatus)
    console.log(this.db.ListSearch.task);


  }
  getWarrantyRenewalList(pageLimit: any = 15, start: any = 0, status: any = 'AMC Expire',) {
    if (this.search[`date`]) {
      this.search[`date`] = moment(this.search[`date`]).format('YYYY-MM-DD');
    }
    if (this.search_val[`date_from`]) {
      this.search_val[`dateFrom`] = moment(this.search_val[`date_from`]).format('YYYY-MM-DD');
    }
    if (this.search_val[`date_to`]) {
      this.search_val[`dateTo`] = moment(this.search_val[`date_to`]).format('YYYY-MM-DD');
      console.log(this.search_val[`dateTo`])

    }
    console.log(this.statusmode);
    // status=this.statusmode;
    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;
    this.search.status = status;
    console.log(this.pageLimit);
    console.log(this.start);


    this.db.FetchData({
      'search': this.search, 'start': this.start, 'date_to': this.search_val['dateTo'], 'date_from': this.search_val['dateFrom'], 'pagelimit': this.pageLimit, 'ID': this.session.trico_user.id,
      'type': this.session.trico_user.access_level,
    }, 'warrantyRenewal/getRenewalList')
      .subscribe((result: any) => {
        console.log(result);
        this.renewalList = result['renewal'];
        this.totalCount = result['totalCount'];
        this.total_page = Math.ceil(this.totalCount / this.pageLimit);
        this.pageNumber = Math.ceil((this.start) / (this.pageLimit)) + 1;
        this.pageNumberTemp = Math.ceil((this.start) / (this.pageLimit)) + 1;

        console.log(this.pageNumber);
        if (this.renewalList.length != 0) {
          this.data_not_found = false;
        }
        else {
          this.data_not_found = true;
        }
        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);

        console.log('Data Not Found - ' + this.data_not_found);
        console.log('Skeleton Screen Active - ' + this.skeleton_screen_active);

      }, error => {
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.skeleton_screen_active = false;
      });
  }


  refresh() {
    this.search = {};
    this.search_val = {};


    this.getWarrantyRenewalList()
      ;
  }


  deleteVendorComplaint(complaint_id: any, index: any) {
    console.log('*** VENDOR DELETE ***');
    console.log(complaint_id);
    console.log(index);
    this.dialogAlert.delete('Vendor Complain Data').then((result: any) => {
      console.log(result);
      if (result) {
        this.db.FetchData({ 'id': complaint_id }, 'customer/onDeleteCustomerData')
          .subscribe(res => {
            console.log(res);
            this.renewalList.splice(index, 1);
            this.dialogAlert.success('Deleted', 'Vendor Complain Data has been deleted.');
            this.getWarrantyRenewalList();
          }, err => {
            console.log(err);
            this.dialogAlert.error('Something went wrong! Try Again ...');
          });
      }
    });
  }



  log
  openDialog(row): void {

    const dialogRef = this.dialog.open(WarrantyRenewalComponent, {
      // width: '600',
      // height: '600'
      width: '600px',
      data: {
        data: row,
        amcsigned: row.favoriteSeason
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getWarrantyRenewalList();
    });
  }


  exp_data: any = [];
  excel_data: any = [];

  getDataInExcel() {
    this.db.FetchData({
      'search': this.search, 'date_to': this.search_val['dateTo'], 'date_from': this.search_val['dateFrom'], 'ID': this.session.trico_user.id,
      'type': this.session.trico_user.access_level,
    }, 'warrantyRenewal/getRenewalList')
      .subscribe((result: any) => {
        console.log(result);
        this.exp_data = result['renewal'];

        for (let i = 0; i < this.exp_data.length; i++) {
          if (this.exp_data[i].warranty_valid_upto) {
            this.exp_data[i].warranty_valid_upto = moment(this.exp_data[i].warranty_valid_upto).format('DD-MM-YYYY');
            console.log(this.exp_data[i].warranty_valid_upto)

          }
          if (this.exp_data[i].date_created) {
            this.exp_data[i].date_created = moment(this.exp_data[i].date_created).format('DD-MM-YYYY');
            console.log(this.exp_data[i].date_created)

          }
          this.excel_data.push({
            'Date Created': this.exp_data[i].date_created,
            'Expire Date': this.exp_data[i].warranty_valid_upto,
            'Customer Name.': this.exp_data[i].customer_name,
            'Project': this.exp_data[i].project_name,
            'So No.': this.exp_data[i].so_no,

            'FG No.': this.exp_data[i].fg_no,
            'Activity': this.exp_data[i].model,
            'Sub Activity': this.exp_data[i].sub_model,
            'Last Warranty Type': this.exp_data[i].last_warranty_type
          });
        }

        this.db.exportAsExcelFile(this.excel_data, 'Warranty Renewal');

        this.excel_data = [];
        this.exp_data = [];

      }, error => {
        console.log(error)

      });
  }

}
