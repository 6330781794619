import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/session.service';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  skeleton_data: any =[]
  skeleton_screen_active:any = false;
  data:any = {};

  constructor(public db : DatabaseService, public dialogAlert : DialogComponent,public session : SessionService) { }





  ngOnInit() {
    this.skeleton_data = new Array(5);

    this.getProductList(this.pageLimit,this.start);

    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 18||x.module_id === 8)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};


  data_not_found:any = false;
  pageLimit:any =20
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pageNumber:any;
  pageNumberTemp:any;
  productList:any = [];
  editqty:any=false;

  getProductList(pageLimit:any=20,start:any=0,action:any='refresh')
  {
    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;

    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'Master_Product/getProductList')
    .subscribe((result:any)=>{
      console.log(result);
      this.productList = result['product'];
      console.log(this.productList);
      for(var i=0; i<this.productList.length;i++){
        for(var j=0; j<this.productList[i].model.length;j++){
          this.productList[i].model[j].submodel.map((s)=>
            {
                console.log(s);
                s.editqty=false

            })
        }


      }

      this.total_count = result['count'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
      this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pageNumber);
      if(this.productList.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });

  }

  edit_qty()
    {
        this.editqty=true;
    }

submodel:any=[];
    update_product(modelId,submodel)
    {
//       for(var i=0; i<this.productList.length;i++){
//         for(var j=0; j<this.productList[i].model.length;j++){
//         for(var k=0; k<this.productList[i].model[j].submodel.length;k++){
// console.log(this.productList[i].model[j].submodel);


//         this.submodel=this.productList[i].model[j].submodel[index];
        console.log(submodel.model);

      //   }
      // }

      // }
        console.log(modelId);


        this.db.FetchData({ 'new_submodel':submodel.model,'id':modelId},'Master_Product/updateName')
        .subscribe((result)=>{
            console.log(result);
            this.editqty=false;

        })
    }
  deleteProduct(product_id:any,model_name:any,index:any){
    console.log(product_id);
    console.log(model_name);
    console.log(index);
    this.dialogAlert.delete('Product Model Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':product_id, 'model':model_name}, 'Master_Product/deleteProduct')
        .subscribe(res => {
          console.log(res);
          this.dialogAlert.success('Deleted','Product Model Data has been deleted.');
          this.getProductList();
        },err=>{
          console.log(err);
          this.dialogAlert.error('Something went wrong! Try Again ...');
        });
      }
    });
  }
  calc(id,submodel){


  }
}
