
import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/dialog';
import { DBRequest } from '../../databaseService';
import { SnackBarOverview } from 'src/app/toast';
import {MatDialog} from '@angular/material/dialog';
import { CustomerInfoModelComponent } from '../customer-info-model/customer-info-model.component';
import { ChangeStatusModelComponent } from '../change-status-model/change-status-model.component';
import { CustomerContactPersonModalComponent } from '../customer-contact-person-modal/customer-contact-person-modal.component';



import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/session.service';

@Component({
      selector: 'app-customer-detail',
      templateUrl: './customer-detail.component.html',
      styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
      
  
      active:any = {};
      skeleton_data: any =[]
      customerEncryptId: any = '';
      isRequestInProcess: any = true;
  sales_executive_update: any;
      
      customerData: any = {};
      customerContactData: any = [];
      assignUserList:any=[];
      allCountData: any = {};
       stlList:any=[];
  tmp_userList:any=[];
  rsm:any=[];
  ass_user:any=[];
      constructor(public dbService: DBRequest,
            public alert: DialogComponent,
            public toast: SnackBarOverview,
            public router:Router, public route : ActivatedRoute,
            public dialog: MatDialog,
            public session : SessionService,) { 


            }
    access_rights:any = {};

            ngOnInit() {
                  if(this.session.trico_user.access_level != '1')
                  {
                    this.session.trico_user.access_module.map((x:any)=>{
                      if(x.module_id === 2)
                      {
                        this.access_rights = x;
                      }
                    });
                    console.log(this.access_rights);
                  }
    console.log(this.session);
                  
                  this.skeleton_data = new Array(7);
                  
                  this.route.params.subscribe(params => {
                        
                        console.log(params);
                        this.customerEncryptId = params.customerId;
                        
                        console.log(this.customerEncryptId);
                        
                        this.onGetCustomerDetail();
                        
                        this.onGetAllCounterData();
                  });
            }
           
            back()
            {
                window.history.go(-1);
            }
            
            onGetCustomerDetail() {
                  
                  this.isRequestInProcess = true;
                  
                  this.dbService.onPostRequestData({
                        
                        customerId: this.customerEncryptId
                        
                  }, 'customer/onCustomerDetail2').subscribe(resultData => {
                        
                        console.log(resultData);
                        
                        setTimeout(() => {
                              
                              this.isRequestInProcess = false;
                              // this. StluserLIst()
                              
                        }, 500);
                        
                        this.customerData = resultData[`customerData`];
                        console.log( this.customerData);
                        
                        this.customerContactData = resultData[`customerContactData`];
      this.assignUserList=resultData[`customerData`].assignlist;
                       console.log(this.assignUserList);
                        
                  }, error => {
                        
                        this.isRequestInProcess = false;
                        this.toast.openError('Something Went Wrong, Try Later', '');
                  });
                  
            }
            
            
            onGetAllCounterData() {
                  
                  this.dbService.onPostRequestData({
                        
                        customerId: this.customerEncryptId
                        
                  }, 'customer/customerTotalCountData').subscribe(resultData => {
                        
                        console.log(resultData);
                        
                        this.allCountData = resultData[`allCountData`];
                        
                  }, error => {
                        this.toast.openError('Something Went Wrong, Try Later', '');
                  });
                  
            }
            
            data:any = {};
            data2:any = {};
            
            openDialog(): void {
                  this.data = Object.assign({},this.customerData);
                  const dialogRef = this.dialog.open(CustomerInfoModelComponent, {
                    width: '650px',
                    data : {
                        data : this.data,
                      }
                  });
              
                  dialogRef.afterClosed().subscribe(result => {
                        this.onGetCustomerDetail();

                  });
                }


                statusDialog(): void {
                  this.data = Object.assign({},this.customerData);
                  const dialogRef = this.dialog.open(ChangeStatusModelComponent, {
                    width: '550px',
                    data : {
                        data : this.data,
                        id:this.customerData.id,
                      }
                   
                  });
              
                  dialogRef.afterClosed().subscribe(result => {
                        this.onGetCustomerDetail();
                  });
                }

                
          openConatct(): void {
            this.data = Object.assign({},this.customerContactData);
            this.data2 = Object.assign({},this.customerData);

            const dialogRef = this.dialog.open(CustomerContactPersonModalComponent, {
              width: '768px',
              data : {
                  data : this.data,
id:this.data2.id
                }
             
            });
        
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
              this.onGetCustomerDetail();

            });


          
      }
  
      }
      