import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-order-add',
  templateUrl: './sales-order-add.component.html',
  styleUrls: ['./sales-order-add.component.scss']
})
export class SalesOrderAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
