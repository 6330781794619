import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SessionService } from 'src/app/session.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-login-change-status-model',
  templateUrl: './login-change-status-model.component.html',
  styleUrls: ['./login-change-status-model.component.scss']
})
export class LoginChangeStatusModelComponent implements OnInit {
  constructor(
    public session : SessionService,
    public db : DatabaseService,
    public dialogAlert: DialogComponent,
    @Inject(MAT_DIALOG_DATA)
    public stored_data :any,
    public dialogRef : MatDialog) { }
    list1:any = {};
    data:any = {};
    data1:any = {};
assignproject:any=[];
    project1:any=[];
    company_name1:any=[]
    customerCodeList = [];
    dropdownSettings: any = {};
    dropdownSettings2: any = {};
    list:any = {};
    customerProjectList:any = [];
    todayDate = new Date();
    customerData:any;
    selectedproject:any=[];
    arr1=[];
    isLoadingInProcess: any = false;
    modelExist: boolean = false;

    ngOnInit() {
      this.onDropDownSettingHandler();
      console.log(this.stored_data.data);
      this.assignproject=this.stored_data.data['assignDetails']
      console.log(this.assignproject);

      this.stored_data.data=this.stored_data.data['loginData']
      this.data = Object.assign({},this.stored_data.data);
      console.log(this.data);

      this.customerData =new FormControl();


      if (this.selectedproject) {

        this.projectExists();
      }


      this.data.accountValidUpTo = moment(this.data.account_valid_upto).format("YYYY-MM-DD");
      console.log(this.data);
      this.data.company_name=this.data.company_name+''+this.data.cust_code
      console.log(this.data.company_name);
      this.company_name1=this.data.company_name
      this.onGetCustomerCodeList();
      for(let i = 0; i <this.assignproject.length; i++){
      console.log(this.assignproject[i].id);
      if(this.assignproject){
      let data2 = {id: this.assignproject[i].id, project_name: this.assignproject[i].project_name}
      // console.log(this.data);
      // console.log(this.data.project_name);
      this.arr1.push(data2)
      // this.data.project_name=
      console.log(this.arr1);
      }
      }
    }


    formLoading:any = false;
    date :any = {};
    cust_code:any;
    updateStatus()
    {

      console.log('*** UPDATE CUSTOMER STATUS ***');
      console.log(this.data.accountValidUpTo);
      this.date = this.data.accountValidUpTo;
      this.cust_code = this.data.cust_code;
      this.list =this.list1.id;
      console.log(this.list);
      console.log(this.cust_code);
      console.log(this.data);
      console.log(this.data.projectId);
      console.log(this.data.cust_code);

      this.data.session = this.data.session;
      console.log(this.data.session);
      for(let index = 0; index <this.customerProjectList.length; index++){
        for(let i = 0; i <this.arr1.length; i++){
          if(this.arr1[i].id  ==this.customerProjectList[index].id){
            console.log("hiiii");
            this.selectedproject.push(this.customerProjectList[index])


          }
          console.log( this.selectedproject);

        }

      }
      if( this.data.contact_person_name ==''&&this.data.contact_email==''){
      this.data.contact_person_name=this.data.superior_name;
      this.data.contact_email=this.data.superior_email;
      }
      this.isLoadingInProcess = true;

      this.db.FetchData({'data':this.data,'activeDate':this.date,'custCode':this.cust_code ,'project':this.selectedproject},'customer/updateLoginRequest')
      .subscribe((result:any)=>{
        console.log(result);
    this.isLoadingInProcess= false;

        console.log(this.data.cust_code);

        if(result['msg'] == 'success') {
          this.dialogAlert.success('Login Request Updated','Successfully');

        }
        else{
          var error = '';
          for (let index = 0; index < result['error'].length; index++) {
            error += result['error'][index]['message']+ ' ' ;
          }
          this.dialogAlert.error(error);
          this.formLoading = false;
        }
        this.formLoading = false;
        this.dialogRef.closeAll();
      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      });
    }

    onGetCustomerCodeList(){

      this.db.FetchData({},'customer/onGetCustomerCodeList')
      .subscribe((result:any)=>{

        console.log(result);

        this.customerCodeList = result['customerCodeData'];
        for (let index = 0; index < this.customerCodeList.length; index++) {

          this.customerCodeList[index].company_name = this.customerCodeList[index].company_name + ' - ' + this.customerCodeList[index].cust_code;
        }
        console.log(this.customerCodeList);


      });

    }

    onDropDownSettingHandler() {

      this.dropdownSettings = {
        singleSelection: true,
        idField: 'cust_code',
        textField: 'company_name',
        allowSearchFilter: true,
        maxHeight: 197,
        closeDropDownOnSelection: true
      };
      this.dropdownSettings2 = {
        singleSelection: false,
        idField: 'id',

        textField: 'project_name',

        allowSearchFilter: true,
        maxHeight: 197,
        closeDropDownOnSelection: true
      };
    }


    projectExists(){

      this.db.FetchData({'cust_code':this.data.cust_code},'customer/onGetProjectList')
      .subscribe((result:any)=>{

        console.log(result);

        this.customerProjectList = result['customerProjectList'];
        //   for (let index = 0; index < this.customerProjectList.length; index++) {

        //     this.customerProjectList[index].project_name = this.customerProjectList[index].id + ' - ' + this.customerProjectList[index].project_name + ' - '+this.customerProjectList[index].street  + ' - '+this.customerProjectList[index].city + ' - '+this.customerProjectList[index].district_name+ ' - '+this.customerProjectList[index].pincode + ' - '+this.customerProjectList[index].state ;
        // }
        console.log();

        console.log(this.customerProjectList);


      });

    }


    test(){
      console.log(this.arr1);
      console.log(this.data.project_name);
      console.log(this.customerData['value']);
      if(!this.selectedproject)
      this.selectedproject = this.customerData['value'];
      console.log(this.selectedproject);

    }
  }
