import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../_services/database.service';
import { DialogComponent } from '../_services/DialogComponent';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public db: DatabaseService, public dialogAlert : DialogComponent, public session : SessionService) { }

  ngOnInit() {
  }

  data:any = {};

  clearForm(myForm:any)
  {
    myForm.resetForm();
  }


  submitLogin(myForm:any)
  {
    console.log('*** SUBMIT LOGIN ***');
    console.log(this.data);
    this.session.SetSession(this.data);
    this.clearForm(myForm);
  }


}
