import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AllActionModalComponent } from '../all-action-modal/all-action-modal.component';
import { DialogComponent } from 'src/app/_services/DialogComponent';

@Component({
  selector: 'app-edit-report-model',
  templateUrl: './edit-report-model.component.html',
  styleUrls: ['./edit-report-model.component.scss']
})
export class EditReportModelComponent implements OnInit {
  
  data: any;
  formData: any = {};
  workData: any =[];
  
  constructor(
    
    public db : DatabaseService,
    public dialogAlert: DialogComponent,
    public dialogRef : MatDialog,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    ) {
      
      this.data = modalData;
      console.log(modalData);
      
      this.workData = this.data.workReportData[0];
      console.log(this.workData);
      
      
    }
    
    ngOnInit() {
      
      if (this.data.target == 'workInfo') {
        
        console.log(this.data.target);
        
      }
      
      if (this.data.target == 'engExpense') {
        
        console.log(this.data.target);
        
      }
      
      
    }
    
    formLoading:any = false;
    
    updateDailyWorkReport(){
      
      console.log(this.workData);
      
      this.db.FetchData({'data':this.workData},'Service_Report/updateDailyWorkReport')
      .subscribe((result:any)=>{
        
        console.log(result);
        
        if(result['msg'] == 'success') {
          this.dialogAlert.success('Work Detail','Successfully Updated')
          this.dialogRef.closeAll();
          
        }
        this.formLoading = false;
        this.dialogRef.closeAll();
        
      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      });  
      
      
    }
    
    updateExpenseReport(){
      
      console.log(this.workData);
      
      this.db.FetchData({'data':this.workData},'Service_Report/updateExpenseReport')
      .subscribe((result:any)=>{
        
        console.log(result);
        
        if(result['msg'] == 'success') {
          this.dialogAlert.success('Expense Detail','Successfully Updated')
        }
        this.formLoading = false;
        this.dialogRef.closeAll();
        
      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.formLoading = false;
      });  
      
      
    }
    
  }
  