import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from './session.service';
import { DatabaseService } from './_services/database.service';


@Injectable()
export class AuthGuard implements CanActivate {
    
    trico_user:any = {};
    
    constructor(public loc: Location, private router: Router, public session: SessionService,public db: DatabaseService) {
        console.log('Auth Guard');
    }
    
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        
        this.session.GetSession().subscribe(
            data => {
                console.log(data);
                this.trico_user = data;
                console.log(state.url);
            },
            error => {
                console.log(error);
            });
            
            if(this.trico_user.tri_login)
            {
                console.log(this.loc);
                console.log(this.trico_user);
                this.db.can_active = '1';
                return true;
            }
            else
            {
                this.db.can_active = '';
                this.router.navigate([''], { queryParams: { returnUrl: state.url }});
                return false;
            }
            
        }
        
    }