import { Component, OnInit } from '@angular/core';
import { SnackBarOverview } from 'src/app/toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';
import { MatDialog } from '@angular/material';
import { TechniciansEditModelComponent } from '../technicians-edit-model/technicians-edit-model.component';

@Component({
  selector: 'app-technicians-list',
  templateUrl: './technicians-list.component.html',
  styleUrls: ['./technicians-list.component.scss']
})
export class TechniciansListComponent implements OnInit {
  
  skeleton_data: any = [];
  
  data: any = {};
  searchData: any = {};
  
  techList: any = [];
  
  start: any = 0;
  pageLimit: any = 20;
  
  totalPage: any = '';
  pageNumber: any = '';
  totalCount: any = '';
  
  isRequestInProcess: any = true;
  
  search_box:any = false;
  
  constructor(public db: DatabaseService,
    public dialogAlert: DialogComponent,
    public toast: SnackBarOverview,
    public session : SessionService,
    public dialog: MatDialog,) {
      
    }
    
    access_rights:any = {};
    ngOnInit() {
      console.log('*** SERVICE ENGINEER LIST LOADED ***');
      if(this.session.trico_user.access_level != '1')
      {
        this.session.trico_user.access_module.map((x:any)=>{
          if(x.module_id === 4)
          {
            this.access_rights = x;
          }
        });
        console.log(this.access_rights);
      }
      console.log(this.access_rights);
      this.getTechList();
      this.skeleton_data = new Array(7);
    }
    skeleton_screen_active:any = false;
    data_not_found:any = false;
    
    getTechList() {
      
      this.skeleton_screen_active = true;
      this.data_not_found = false;
      
      this.db.FetchData({
        
        start: this.start,
        pageLimit: this.pageLimit,
        searchData: this.searchData
        
      }, 'technician/getTechnicianList').subscribe(resultData => {
        
        console.log(resultData);
        
        this.techList = resultData[`technicianList`];
        this.totalCount = resultData[`totalCount`];
        
        if(!this.techList.length)
        {
          this.data_not_found = true;
        }
        
        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);
        
        this.totalPage = Math.ceil(this.totalCount / this.pageLimit);
        this.pageNumber = Math.ceil(this.start / this.pageLimit) + 1;
        
      }, error => {
        console.log(error);
        this.isRequestInProcess = false;
        this.toast.openError('Something Went Wrong, Try Later','');
        
      });
    }
    openDialog(row): void {

      console.log(row);
      const dialogRef = this.dialog.open(TechniciansEditModelComponent, {
          width: '500px',
          data: {
            row
          },
      });
      dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.getTechList();
      });
  }
    
    deleteTechnician(user_id:any,index:any)
    {
      console.log(user_id);
      console.log(index);
      this.dialogAlert.delete('Service Engineer Data').then((result:any) => {
        console.log(result);
        if(result){
          this.db.FetchData( {'id':user_id}, 'technician/deleteTechnician')
          .subscribe(res => {
            console.log(res);
            this.techList.splice(index,1);
            this.dialogAlert.success('Deleted','Service Engineer Data has been deleted.');
            this.getTechList();
          },err=>{
            console.log(err);
            this.dialogAlert.error('Something went wrong! Try Again ...');
          });
        }
      });
    }
    
    
    
    
    onDeleteCustomerHandler(index) {
      
      this.dialogAlert.confirmation('').then((confirmResult) => {
        
        if (confirmResult) {
          
          this.isRequestInProcess = true;
          
          this.db.FetchData({
            
            customerId: this.techList[index].id
            
          }, 'customer/onDeleteCustomerData').subscribe(resultData => {
            
            console.log(resultData);
            
            this.techList.splice(index, 1);
            
            this.getTechList();
            
          }, error => {
            
            this.isRequestInProcess = false;
            this.toast.openError('Something Went Wrong, Try Later','');
          });
          
          
        }
      });
    }
    
    onPageInputHandler(type) {
      
      if (type == 'greater') {
        
        this.pageNumber = this.totalPage;
        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
        this.getTechList();
        
      } else {
        
        this.start = (this.pageNumber * this.pageLimit) - this.pageLimit;
        this.getTechList();
        
      }
    }
    
    showfilter() {
      
      this.search_box = true;
    }
    
    hidefilter()  {
      
      this.search_box = false;
    }
    
    exp_data:any=[];
    excel_data:any=[];
    
    getDataInExcel()
    {
      this.db.FetchData({searchData: this.searchData},'technician/getTechnicianListExcel').subscribe(resultData => 
        {
          console.log(resultData);
          this.exp_data = resultData['technicianList'];
          
          for(let i=0;i<this.exp_data.length;i++)
          {
            this.excel_data.push({
              'Name':this.exp_data[i].name,
              'Mobile No.':this.exp_data[i].mobile,
              'E-Mail':this.exp_data[i].email,
              'Designation':this.exp_data[i].designation,
              'State & District':this.exp_data[i].state_name +','+ this.exp_data[i].district_name,
              'Open Com.':this.exp_data[i].open_complaint,
              'Close Com. MTD':this.exp_data[i].mtd_close_complaint,
              'Close Com. YTD':this.exp_data[i].ytd_close_complaint
            });
          }
          
          this.db.exportAsExcelFile(this.excel_data,'Service Engineer');
          
          this.excel_data = [];
          this.exp_data = [];
          
        }, error => {
          console.log(error)
          
        });
      }

   
      userStatus(index,id)
      {
        console.log(id);
        console.log(index);
    
        console.log(this.techList[index].login_blocked);
        if(this.techList[index].login_blocked==1)
        {
          this.techList[index].login_blocked=0;
          console.log(this.techList[index].login_blocked);
       }
        else
        {
          this.techList[index].login_blocked=1;
          console.log(this.techList[index].login_blocked);
        }
          let value=this.techList[index].login_blocked
          this.db.FetchData({'tech_id':id,'active':value },"technician/login_block")
            .subscribe(resp=>{
            console.log(resp);
            this.getTechList();
          })
      }
    }
    