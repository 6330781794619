import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CustomerFeedbackModelComponent } from '../customer-feedback-model/customer-feedback-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import { SnackBarOverview } from 'src/app/toast';
import * as moment from 'moment';
import { SessionService } from 'src/app/session.service';
import { OrderinspectionComponent } from 'src/app/orderinspection/orderinspection.component';
import { DialogComponent } from 'src/app/dialog';



@Component({
  selector: 'app-customer-feedback-list',
  templateUrl: './customer-feedback-list.component.html',
  styleUrls: ['./customer-feedback-list.component.scss']
})
export class CustomerFeedbackListComponent implements OnInit {

  skeleton_data:any = [];
  type:any;
  start: any = 0;
  pageLimit: any = 100;
  searchData: any = {};
  feedType:any;
  data_not_found:boolean = false;
data5:any=['','hhhhh'];
isRequestInProcess: any = true;
isLoadingInProcess: any = false;
currentDate:any;
 calenderMinDate:any;




criteria:any=['']
  constructor(
          public dialog: MatDialog,
          public db: DatabaseService,
          public route : ActivatedRoute,
          public toast: SnackBarOverview,
          public session : SessionService,
          public alert: DialogComponent,
        ) {
  this.currentDate = new Date();

          this.route.params.subscribe(params=>{

            console.log(params);
            this.type = params.type;
            console.log(this.type);

            if(this.type == 'order_closure'){

              this.feedType = 'Order Closure';

            }

            if(this.type == 'order_execution'){

              this.feedType = 'Order Execution';

            }
            if(this.type == 'product_commissioning'){

              this.feedType = 'Product Commissioning';

            }
            if(this.type == 'feedback_inspection'){

              this.feedType = 'Panel-Inspection';

            }

            this.getSurveyData(this.pageLimit,this.start);

          });
         }

      ngOnInit()
      {
        if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 32||x.module_id === 22)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
        this.skeleton_data = new Array(7);
      }

      access_rights:any = {};

    skeleton_screen_active:any = false;
    surveyList:any = [];
    getSurveyData(pageLimit:any=20,start:any=0){
    this.data_not_found = false;

     if (this.searchData[`date_from`]) {
        this.searchData[`dateFrom`] = moment(this.searchData[`date_from`]).format('YYYY-MM-DD');
      }
      if (this.searchData[`date_to`]) {
        this.searchData[`dateTo`] = moment(this.searchData[`date_to`]).format('YYYY-MM-DD');
      }
      this.pageLimit = pageLimit;
      this.start = start;

      this.db.FetchData({
        start: this.start,
        pageLimit: this.pageLimit,
        searchData: this.searchData,
        type: this.type,
        'ID':this.session.trico_user.id,
        'user_type':this.session.trico_user.access_level,},'customer/getSurveyData')
      .subscribe((result:any)=>{
        //
        console.log(result.surveyData);

        this.surveyList = result.surveyData;
        if(this.surveyList.length != 0)
        {
          this.data_not_found = false;
        }
        else
        {
          this.data_not_found = true;
        }
        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);
        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);
      });
    }



  openDetail(id,type): void {
    console.log(id);
    console.log(type);


    const dialogRef = this.dialog.open(CustomerFeedbackModelComponent, {
      width: '768px',
      data: {

        feedType : type,
        feedId : id,


    },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  exp_data:any=[];
    excel_data:any=[];

    getDataInExcel2()
    {
      this.db.FetchData({start: this.start,
        pageLimit: this.pageLimit,
        searchData: this.searchData,
        type: this.type,
        'ID':this.session.trico_user.id,
        'user_type':this.session.trico_user.access_level},'customer/getSurveyDataExcelPanel').subscribe((result:any)=>
      {
          console.log(result);
          // this.excel_data.push({
          //   '':this.data5
          // });

          this.exp_data = result['surveyData'];
          for(let i=0;i<this.exp_data.length;i++)
          {
            if (this.exp_data[i].inspection_date) {
              this.exp_data[i].inspection_date= moment(this.exp_data[i].inspection_date).format('YYYY-MM-DD');
            }

            this.excel_data.push({

              'Inspection Date':this.exp_data[i].inspection_date,
              'Customer Code.':this.exp_data[i].customer_code,
              'Customer Name.':this.exp_data[i].customer_name,
              'Project Name.':this.exp_data[i].project_name,
              'STL.':this.exp_data[i].stl_code,
              'LOT NO..':this.exp_data[i].lot,
              'Unit.':this.exp_data[i].unit,
              'ONSITE/OFFLINE.':this.exp_data[i].feedback_location,
              'INSPECTION DONE BY.':this.exp_data[i].contact_name,
              'DESIGNATION.':this.exp_data[i].designation,
              'NAME OF ORGANISATION.':this.exp_data[i].party_name,
              'Technical understanding of the Tender':this.exp_data[i].rating1,
              'Response Quality of the Team - Technically & Behaviourally':this.exp_data[i].rating2,
              'Readiness of Product & Team on CI':this.exp_data[i].rating3,
              'Adherence to Delivery Date':this.exp_data[i].rating4,
              'Product Quality & Aesthetics':this.exp_data[i].rating5,
              'Customer centricity of Team':this.exp_data[i].rating6,
               'Overall Satsfaction with the CI':this.exp_data[i].rating7,
               'REMARKS':this.exp_data[i].customer_remark,

              // 'Contact Person':this.exp_data[i].contact_name +'-'+ this.exp_data[i].mobile_no,

            });
          }

          this.db.exportAsExcelFile( this.excel_data,'Customer Feedback');

          this.excel_data = [];
          this.exp_data = [];

        }, error => {
          console.log(error)

        });
      }
      refresh()
      {
        this.searchData = {};

        this.getSurveyData('','');
      }
    getDataInExcel()
    {
      this.db.FetchData({searchData: this.searchData, type: this.type},'customer/getSurveyDataExcel').subscribe((result:any)=>
      {
          console.log(result);
          this.exp_data = result['surveyData'];

          for(let i=0;i<this.exp_data.length;i++)
          {
            this.excel_data.push({

              'Date Created':this.exp_data[i].date_created,
              'Customer Name.':this.exp_data[i].customerData['company_name'] +'-'+ this.exp_data[i].customer_code,
              'Rating':this.exp_data[i].rating +'Star',
              'Contact Person':this.exp_data[i].contact_name +'-'+ this.exp_data[i].mobile_no,

            });
          }

          this.db.exportAsExcelFile(this.data5+this.excel_data,'Customer Feedback');

          this.excel_data = [];
          this.exp_data = [];

        }, error => {
          console.log(error)

        });
      }
      getstlexcel(){
        this.db.FetchData({},'Customer/excelForStlwise').subscribe((result:any)=>
        {
            console.log(result);
            this.exp_data = result['soWiseData'];

            for(let i=0;i<this.exp_data.length;i++)
            {
              this.excel_data.push({

                'STL Code':this.exp_data[i].stl_code,

                'YTD Average Overall Score.':this.exp_data[i].total_ra,
                'No of Customers rated <8 in any parameter':this.exp_data[i].count,
                'Total no. of inspections':this.exp_data[i].total_inscpection,

              });
            }

            this.db.exportAsExcelFile(this.excel_data,'INSPECTION STLWISE');

            this.excel_data = [];
            this.exp_data = [];

          }, error => {
            console.log(error)

          });
      }
      getUnitexcel(){
        this.db.FetchData({},'Customer/excelForUnitwise').subscribe((result:any)=>
        {
            console.log(result);
            this.exp_data = result['soWiseData'];

            for(let i=0;i<this.exp_data.length;i++)
            {
              this.excel_data.push({

                'UNIT':this.exp_data[i].unit,

                'YTD Average Overall Score.':this.exp_data[i].total_ra,
                'No of Customers rated <8 in any parameter':this.exp_data[i].count,
                'Total no. of inspections':this.exp_data[i].total_inscpection,

              });
            }

            this.db.exportAsExcelFile(this.excel_data,'INSPECTION UNITWISE');

            this.excel_data = [];
            this.exp_data = [];

          }, error => {
            console.log(error)

          });
      }
      openDocumentDialog(): void
      {
            const dialogRef = this.dialog.open(OrderinspectionComponent, {
                  width: '600px',



            });
            console.log('The dialog was closed');

            dialogRef.afterClosed().subscribe(result => {
                  console.log('The dialog was closed');

            });
      }

      onDeleteCustomerHandler(id,index) {
      console.log(id)
        this.alert.confirmation('Customer Data').then((confirmResult) => {
          if (confirmResult) {
            this.isRequestInProcess = true;
            this.db.FetchData({
              id: id
            }, 'customer/deleteFeedback').subscribe(resultData => {
              console.log(resultData);
              this.surveyList.splice(index, 1);
              this.getSurveyData();
            }, error => {
              console.log(error);
              this.isRequestInProcess = false;
              this.toast.openError('Something Went Wrong, Try Later','');
            });


          }
        });
      }
}
