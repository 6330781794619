import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

  ecrpt_id:any = '';
  viewChecked: boolean = false;
  addChecked: boolean = false;
  editChecked: boolean = false;
  deleteChecked: boolean = false;
  allChecked: boolean = false;

  constructor(public db : DatabaseService, public dialogAlert : DialogComponent, public router:Router, public route : ActivatedRoute, public session : SessionService) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log('*** PARAMETER ***');
      console.log(this.ecrpt_id);
      if(this.ecrpt_id != 'new')
      {
        this.getUserDetail();
        this.getStateList();
        setTimeout(() => {
          this.getDistrictList();
        }, 500);
      }
      else{
        this.getStateList();
        this.getModuleList();
      }
    });

    this.data.access_level = '2';

  }

  ngOnInit() {
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 13||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
    this.getDesignationList();
    this.getRoleList();
this.activeView('','');

  }
  access_rights:any = {};

  designationList:any = [];
  getDesignationList()
  {
    this.db.FetchData({},'Master_User/getDesignationList')
    .subscribe((result:any)=>{
      console.log(result);
      this.designationList = result['designation'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }

  roleList:any = [];
  getRoleList()
  {
    this.db.FetchData({},'Master_User/getRoleList')
    .subscribe((result:any)=>{
      console.log(result);
      this.roleList = result['role'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }


  moduleList:any = [];
  getModuleList()
  {
    this.db.FetchData({},'Master_User/getModuleList')
    .subscribe((result:any)=>{
      console.log(result);
      this.moduleList = result['module'];
      this.moduleList.map((x:any)=>{
        x.viewChecked = false;
        x.addChecked = false;
        x.editChecked = false;
        x.deleteChecked = false;
        x.allChecked = false;
      });
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }




  stateList:any = [];
  getStateList()
  {
    this.db.FetchData({},'Master_User/getStateList')
    .subscribe((result:any)=>{
      console.log(result);
      this.stateList = result['state'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });



  }


  districtList:any = [];
  getDistrictList()
  {
    console.log(this.data);
    this.db.FetchData({'data':this.data},'Master_User/getDistrictList')
    .subscribe((result:any)=>{
      console.log(result);
      this.districtList = result['district'];
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }

  selectedFile: File[]=[];
  i:any = 0;

  fileChange(event:any) {

    console.log(event.target.files);

    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);

      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);

    }
    console.log(this.selectedFile);
  }


  urls = new Array<string>();
  remove_image(i:any){
    console.log(i);
    this.urls.splice(i,1);
    this.selectedFile.splice(i,1);
  }


  selectAll(event:any,index:any)
  {
    console.log(event);
    console.log(index);
    console.log(event.checked);

    if(event.checked == true)
    {
      this.moduleList[index]['viewChecked'] = true;
      this.moduleList[index]['addChecked'] = true;
      this.moduleList[index]['editChecked'] = true;
      this.moduleList[index]['deleteChecked'] = true;
      this.moduleList[index]['allChecked'] = true;
    }

    else{
      this.moduleList[index]['viewChecked'] = false;
      this.moduleList[index]['addChecked'] = false;
      this.moduleList[index]['editChecked'] = false;
      this.moduleList[index]['deleteChecked'] = false;
      this.moduleList[index]['allChecked'] = false;
    }

    console.log(this.moduleList);

  }

  activeView(event:any,index:any)
  {
    if(event.checked == true)
    {
      this.moduleList[index]['viewChecked'] = true;

      if(this.moduleList[index]['viewChecked'] == true && this.moduleList[index]['addChecked'] == true && this.moduleList[index]['editChecked'] == true && this.moduleList[index]['deleteChecked'] == true){
        this.moduleList[index]['allChecked'] = true;
      }
    }
    else{
      this.moduleList[index]['allChecked'] = false;
      if(this.moduleList[index]['addChecked'] == true || this.moduleList[index]['editChecked'] == true || this.moduleList[index]['deleteChecked'] == true){
        this.moduleList[index]['viewChecked'] = true;
      this.moduleList[index]['allChecked'] = false;
      console.log(this.moduleList[index]['allChecked']);



      }else{
        this.moduleList[index]['viewChecked'] = false;
      }
    }

  }

  formData = new FormData();

  clearForm(myForm:any)
  {
    myForm.resetForm();
    this.selectedFile = [];
    this.urls = [];
    this.data = {};
    this.formData = new FormData();
    this.urls = new Array<string>();
  }

  data:any = {};
  formLoading:any = false;
  submitSystemUser(myForm:any)
  {
    this.formLoading = true;
    console.log('***SUBMIT SYSTEM USER ***');
    console.log(this.data);
    console.log(this.moduleList);

    this.data.module = Object.assign([],this.moduleList);

    for (let f of this.selectedFile)
    {
      this.formData.append(this.i, f, f.name);
      this.i++;
    }
    console.log(this.data);


    console.log(this.session.trico_user);
    for (var property1 in this.session.trico_user)
    {
      console.log(property1);
      this.formData.append('session['+property1+']',this.session.trico_user[property1]);
    }


    for (var property1 in this.data) {
      if(property1 == 'module')
      {
        for (var property2 in this.data[property1]) {
          if(property1 == 'module')
          {
            for (var property3 in this.data[property1][property2]) {
              this.formData.append( property1+'['+property2+']['+property3+']',this.data[property1][property2][property3]);
            }
          }
        }
      }
      else
      {
        this.formData.append('data['+property1+']',this.data[property1]);
      }
    }

    console.log(this.formData);


    this.db.FileData(this.formData,'Master_User/submitSystemUser2')
    // this.db.FileData({'data':this.data, 'module':this.moduleList, 'session' : this.session.trico_user},'Master_User/submitSystemUser')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['msg'] == 'success') {
        this.dialogAlert.success('System User Data','Successfully Saved')
        this.router.navigate(['/userlist']);
        this.clearForm(myForm);
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;
        }
        this.dialogAlert.error(error);
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;

    });
  }



  updateSystemUser(myForm:any)
  {
    this.formLoading = true;
    console.log('***UPDATE SYSTEM USER ***');
    console.log(this.data);
    console.log(this.moduleList);
    this.data.module = Object.assign([],this.moduleList);

    for (let f of this.selectedFile)
    {
      this.formData.append(this.i, f, f.name);
      this.i++;
    }
    console.log(this.data);


    console.log(this.session.trico_user);
    for (var property1 in this.session.trico_user)
    {
      console.log(property1);
      this.formData.append('session['+property1+']',this.session.trico_user[property1]);
    }


    for (var property1 in this.data) {
      if(property1 == 'module')
      {
        for (var property2 in this.data[property1]) {
          if(property1 == 'module')
          {
            for (var property3 in this.data[property1][property2]) {
              this.formData.append( property1+'['+property2+']['+property3+']',this.data[property1][property2][property3]);
            }
          }
        }
      }
      else
      {
        this.formData.append('data['+property1+']',this.data[property1]);
      }
    }


    // return false;
    this.db.FileData(this.formData,'Master_User/updateSystemUser2')
    // this.db.FetchData({'data':this.data, 'module':this.moduleList},'Master_User/updateSystemUser')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['msg'] == 'success') {
        this.dialogAlert.success('System User Data','Successfully Updated')
        this.router.navigate(['/userlist']);
        this.clearForm(myForm);
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;
        }
        this.dialogAlert.error(error);
      }
      this.formLoading = false;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.formLoading = false;

    });
  }





  getUserDetail()
  {
    console.log('*** USER DETAIL ***');
    this.db.FetchData({'user_id':this.ecrpt_id},'Master_User/getUserDetail')
    .subscribe((result:any)=>{
      console.log(result);
      this.data = result['detail'];
      if(this.data.image_url != ''){
        this.urls.push('http://phpstack-83335-1104209.cloudwaysapps.com/api/uploads/User_Doc/'+this.data.image_url);
      }
      this.moduleList = result['module'];
      console.log( this.moduleList);
      },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }



}
