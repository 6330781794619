import { Component, OnInit, Inject } from '@angular/core';
import { DBRequest } from '../../databaseService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';



@Component({
      selector: 'app-customer-document-add',
      templateUrl: './customer-document-add.component.html',
      styleUrls: ['./customer-document-add.component.scss']
})
export class CustomerDocumentAddComponent implements OnInit {

      registerForm: FormGroup;
      submitted = false;
      customerDocumentList: any = [];
      customerEncryptId: any = '';

      start: any = 0;
      pageLimit: any = 50;

      searchData: any = {};

      totalPage: any = '';
      pageNumber: any = '';
      totalDocumentCount: any = '';

      allCountData: any = {};

      calenderMinDate: any;
      currentDate: any;

      isRequestInProcess: any = true;
       max_size = 20971520;


      imageError: string;

      constructor(private formBuilder: FormBuilder,
            public db: DatabaseService,
            public dialogAlert: DialogComponent,
            public session : SessionService,
            public route : ActivatedRoute ,
            @Inject(MAT_DIALOG_DATA) public stored_data :any,
            public dialogRef : MatDialog) {

            }

            ngOnInit() {

                  // console.log(this.max_size);



                  this.onValidateFormHandler();
                  console.log(this.stored_data);
                  this.customerEncryptId = this.stored_data.data.customerId
                  console.log(this.customerEncryptId);
                  this.onGetCustomerDetail();

            }


            customerData:any = {};
            customerContactData:any = [];
            onGetCustomerDetail() {

                  this.isRequestInProcess = true;

                  this.db.FetchData({

                        customerId: this.customerEncryptId

                  }, 'customer/onCustomerDetail').subscribe(resultData => {

                        console.log(resultData);

                        setTimeout(() => {

                              this.isRequestInProcess = false;

                        }, 500);

                        this.customerData = resultData[`customerData`];
                        this.customerContactData = resultData[`customerContactData`];

                  }, error => {

                        this.isRequestInProcess = false;
                        this.dialogAlert.error('Something Went Wrong, Try Later');
                  });

            }



            data:any = {};

            selectedFile: File[]=[];
            i:any = 0;



            fileChange(event:any) {
                  for (var i = 0; i < event.target.files.length; i++) {
                        this.selectedFile.push(event.target.files[i]);

                        let reader = new FileReader();
                        reader.onload = (e: any) => {
                              this.urls.push(e.target.result);
                        }
                        reader.readAsDataURL(event.target.files[i]);

                  }
                  console.log(this.selectedFile);
            }


            // }

            urls = new Array<string>();
            remove_image(i:any){
                  console.log(i);
                  this.urls.splice(i,1);
                  this.selectedFile.splice(i,1);
            }


            clearForm(myForm:any)
            {
                  myForm.resetForm();
                  this.selectedFile = [];
                  this.urls = [];
                  this.data = {};
                  this.formData = new FormData();
                  this.urls = new Array<string>();
            }
            formData = new FormData();

            // max_size = 20971520;

            submitCustomerDocument(myForm:any)
            {
                  // max_size = 20971520;

                  console.group('**** SUBMIT DOCUMENT ****');

                 


                  this.data.customerEncryptId = this.customerEncryptId;

                  console.log(this.data);
                  console.log(this.selectedFile);

                  this.i = 0;
                  for (let f of this.selectedFile)
                  {
                        this.formData.append(this.i, f, f.name);
                        this.i++;
                  }
                  console.log(this.data);

                  for (var property1 in this.data) {
                        console.log(property1);
                        this.formData.append(property1,this.data[property1]);
                        this.formData.append('data['+property1+']',this.data[property1]);       
                  }

                  console.log(this.session.trico_user);
                  for (var property1 in this.session.trico_user)
                  {
                        console.log(property1);
                        this.formData.append('session['+property1+']',this.session.trico_user[property1]);
                  }

                  // return false;

                  this.db.FileData(this.formData,'customer/saveCustomerDocument')
                  .subscribe((result:any)=>{
                        console.log(result);
                        this.dialogAlert.success('Customer Document Data','Successfully Saved !!!');
                        this.clearForm(myForm);
                        this.dialogRef.closeAll();
                  },error=>{
                        console.log(error);
                        this.dialogAlert.error('Something went wrong !!! Try again ...');
                  });

            }

      
            onSubmit() {

                  this.submitted = true;

                  console.log(this.registerForm);
                  if (this.registerForm.invalid) {

                        this.registerForm.get('subject').markAsTouched();
                        return;

                  } else {

                        this.db.FetchData( {}, 'document/onSubmitProjectData')
                        .subscribe(r => {

                              console.log(r);


                        }, error => {

                              // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
                        });
                  }
            }


            onValidateFormHandler() {

                  this.registerForm = this.formBuilder.group({

                        subject: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
                  });
            }



            onReset() {
                  this.submitted = false;
                  this.registerForm.reset();
            }


            get f() { return this.registerForm.controls; }

      }
