import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SessionService } from 'src/app/session.service';


@Component({
  selector: 'app-remark-modal',
  templateUrl: './remark-modal.component.html',
  styleUrls: ['./remark-modal.component.scss']
})
export class RemarkModalComponent implements OnInit {

  data:any = {};
  constructor(public db : DatabaseService, public dialogRef: MatDialog, public dialogAlert : DialogComponent,  @Inject(MAT_DIALOG_DATA) public stored_data :any, public session : SessionService) {
  }
 
  ngOnInit() {
    this.data = Object.assign({},this.stored_data.data);
    console.log(this.data);
  }

  clearForm(myForm:any)
  {
    myForm.resetForm();
  }

  updateCallRemark(myForm:any)
  {
    console.log('***UPDATE CALL REMARK ***');

    this.db.FetchData({'data':this.data, 'session' : this.session.trico_user},'sharedData/updateCallRemark')
    .subscribe((result:any)=>{
      console.log(result);  
      if(result['msg'] == 'success')
      {
        this.dialogAlert.success('Call List Data','Remark Updated Successfully !!!');
        this.clearForm(myForm)
      }
      this.dialogRef.closeAll();
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });

  }

}
