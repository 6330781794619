import { DatePipe } from '@angular/common';
import { Component, OnInit , Inject} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/dialog';

@Component({
  selector: 'app-reasonremark',
  templateUrl: './reasonremark.component.html',
  styleUrls: ['./reasonremark.component.scss']
})
export class ReasonremarkComponent implements OnInit {

  taskid:any;
  submitted = false;
   formLoading:any = false;
   registerForm: FormGroup;
    data:any={};

  constructor(public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog,private formBuilder: FormBuilder,public datepipe : DatePipe) {

    // console.log(stored_data);
    this.taskid = stored_data;
    console.log(this.taskid);

   }

  ngOnInit() {
    this.registerForm = new FormGroup({
      reason_remark: new FormControl(this.data.reason_remark, [
        Validators.required,
      ])
    });
    this.registerForm = this.formBuilder.group({
      reason_remark:[this.taskid.reason_remark],


    });
  }

    get f() {
    return this.registerForm.controls;
  }

onAddRemark()
{
  this.data.taskid=this.taskid.taskId;
  this.data.reason_remark=this.registerForm.value.reason_remark;
  console.log(this.data);

    this.submitted = true;
    console.log(this.data);
   if (this.registerForm.invalid) {
    this.registerForm.get('city').markAsTouched();
    return;
   }
   else{
    console.log('my form is valid');

    this.db.FetchData({'data':this.data} ,'Task/addRemark')
    .subscribe((result:any)=>{

      console.log(result);

      if(result['msg'] == 'success') {
        this.dialogAlert.success('Reason Remark','Successfully Updated')
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.dialogRef.closeAll();

    });
   }


  }

}
