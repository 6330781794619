import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SessionService } from 'src/app/session.service';


@Component({
  selector: 'app-service-center-list',
  templateUrl: './service-center-list.component.html',
  styleUrls: ['./service-center-list.component.scss']
})
export class ServiceCenterListComponent implements OnInit {

  skeleton_data:any =[]
  skeleton_screen_active:any= false;
  data:any={};

  constructor(public db:DatabaseService, public dialog : DialogComponent, public session: SessionService) { }

  ngOnInit() {
    this.skeleton_data = new Array(5);

    this.getServiceCenterList();
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 26||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  access_rights:any = {};

  data_not_found:any = false;
  pageLimit:any =20
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pagenumber:any;
  serviceCenterList:any = [];

  getServiceCenterList()
  {
    this.skeleton_screen_active = true;
    this.data_not_found = false;

    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'Master_ServiceCenter/getServiceCenterList')
    .subscribe((result:any)=>{
      console.log(result);
      this.serviceCenterList = result['servicecenter'];
      this.total_count = result['count'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pagenumber = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pagenumber);
      if(this.serviceCenterList.length != 0)
      {
        this.data_not_found = false;
      }
      else
      {
        this.data_not_found = true;
      }

      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }


  clearForm(myForm:any)
  {
    this.data = {};
    myForm.resetForm();
  }


  formLoading:any = false;
  submitServiceCenter(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Submit Service Center ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data, 'session':this.session.trico_user},'Master_ServiceCenter/submitServiceCenter')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['msg'] == 'success') {
        this.dialog.success('Service Center Data','Successfully Saved !!!');
        this.clearForm(myForm);
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;
        }
        this.dialog.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.clearForm(myForm);
      this.getServiceCenterList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });

  }


  updateServiceCenter(myForm:any)
  {
    this.formLoading = true;
    console.log('*** Update Service Center ***');
    console.log(this.data);
    this.db.FetchData({'data':this.data},'Master_ServiceCenter/updateServiceCenter')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['msg'] == 'success') {
        this.dialog.success('Service Center Data','Successfully Updated');
        this.clearForm(myForm);
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;
        }
        this.dialog.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.formLoading = false;
      this.clearForm(myForm);
      this.getServiceCenterList();
    },error=>{
      console.log(error);
      this.dialog.error('Something went wrong !!! Try again ...');
    });

  }


  editServiceCenter(id:any)
  {
    console.log('*** EDIT SERVICE CENTER LIST ***');
    console.log('Service Center ID -'+id);
    this.data = Object.assign({},this.serviceCenterList.filter( (x:any) => x.id == id)[0]);
    console.log(this.data);
  }


  deleteServiceCenter(service_id:any,index:any){
    console.log(service_id);
    console.log(index);
    this.dialog.delete('Service Center Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':service_id}, 'Master_ServiceCenter/deleteServiceCenter')
        .subscribe(res => {
          console.log(res);
          this.serviceCenterList.splice(index,1);
          this.dialog.success('Deleted','Service Center Data has been deleted.');
          this.getServiceCenterList();
        },err=>{
          console.log(err);
          this.dialog.error('Something went wrong! Try Again ...');
        });
      }
    });
  }



}
