import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/session.service';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';


@Component({
  selector: 'app-list-vendor',
  templateUrl: './list-vendor.component.html',
  styleUrls: ['./list-vendor.component.scss']
})
export class ListVendorComponent implements OnInit {

  skeleton_data: any =[]
  skeleton_screen_active:any = false;
  data:any = {};
  
  constructor(public db : DatabaseService, public dialogAlert : DialogComponent, public session : SessionService) { }
  
  ngOnInit() {
  
    this.skeleton_data = new Array(7);
    this.getVendorList(this.pageLimit,this.start);
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 14||x.module_id === 18)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  
  access_rights:any = {};
 
  data_not_found:any = false;
  pageLimit:any =10;
  start:any = 0;
  search:any = {};
  total_count:any;
  total_page:any;
  pageNumber:any;
  pageNumberTemp:any;
  vendorList:any = [];
  
  getVendorList(pageLimit:any=20,start:any=0,action:any='refresh')
  {
    this.pageLimit = pageLimit;
    this.start = start;
    this.skeleton_screen_active = true;
    this.data_not_found = false;
    
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'Master_Vendor/getVendorList')
    .subscribe((result:any)=>{
      console.log(result);
      this.vendorList = result['vendor'];
      this.total_count = result['count'];
      this.total_page = Math.ceil(this.total_count/this.pageLimit);
      this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
      this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;
      console.log(this.pageNumber);
      if(this.vendorList.length != 0)
      {
        this.data_not_found = false; 
      }
      else
      {
        this.data_not_found = true; 
      }        
      
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);

      console.log('Data Not Found - '+this.data_not_found);
      console.log('Skeleton Screen Active - '+this.skeleton_screen_active);
      
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
      this.skeleton_screen_active = false;
    });
  }
  
  
  vendorDelete(vendor_id:any,index:any){
    console.log(vendor_id);
    console.log(index);
    this.dialogAlert.delete('Vendor Data').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':vendor_id}, 'Master_Vendor/vendorDelete')
        .subscribe(res => {
          console.log(res);
          this.vendorList.splice(index,1);
          this.dialogAlert.success('Deleted','Vendor Data has been deleted.');
          this.getVendorList();
        },err=>{
          console.log(err);
          this.dialogAlert.error('Something went wrong! Try Again ...');
        });
      }
    });
  }


}
