import { Component, OnInit ,Inject} from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import {NgForm} from '@angular/forms';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



@Component({
  selector: 'app-attachment-model',
  templateUrl: './attachment-model.component.html',
  styleUrls: ['./attachment-model.component.scss']
})


export class AttachmentModelComponent implements OnInit {
  
  bodyText:any;
  data:any={};
  vendorContactEmail:any=[];
  ContactpersonEmail:any=[];
  techList2:any=[];
vendor:any=[];
  private rteEle: RichTextEditorComponent;

  constructor(
    public db: DatabaseService,
      public router:Router,
      public route : ActivatedRoute,
      public dialogRef: MatDialog,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      public dialogAlert: DialogComponent,
      ) 
      { 
        this.data = modalData;
        console.log(modalData);
        this.vendorContactEmail = this.data.vendorConDetail;
        this.ContactpersonEmail = this.data.contactdetail;
        console.log(this.ContactpersonEmail);
        for(var i=0;i<this.ContactpersonEmail.length; i++){
         
            
            this.techList2.push(this.ContactpersonEmail[i].name+ '-'+ this.ContactpersonEmail[i].mobile)
          
console.log(this.techList2);

        }

        console.log(this.vendorContactEmail);
        this.data.email=this.vendorContactEmail.email ;

        this.data.subject=this.vendorContactEmail.nature_problem + ' - ' + this.vendorContactEmail.project_name;
        this.data.value =  `Greetings from Tricolite!,
        <p>This is to inform you that <b>${this.vendorContactEmail.nature_problem}</b> is faulty at <b>${this.vendorContactEmail.project_name} </b>site. </p>

       <p> For more information, please refer the attached details and please contact our service engineer  <b>(${this.techList2})</b></p>
        
        <p>We hereby request you to depute your service engineer to rectify the issue on priority.</p>
        
       <p> Your immediate response will be highly appreciated.</p>
        
        <p>Please reply to this email as token of your acceptance for taking further action.</p>
        
       <p> With Regards<br>
        For <b>Tricolite Electrical Industries Ltd.</b><br>

       <b>Rajan Yadav</b><br>
       <b>Mobile + 91 9958998882</b><br>
</p>`

        // console.log(this.data.value);

      }

  ngOnInit() {
  }

  clearForm(myForm:any)
  {
      // this.data = {};
      // myForm.resetForm();
      myForm.resetForm();
      this.selectedFile = [];
      this.urls = [];
      this.data = {};
      this.formData = new FormData();
      this.urls = new Array<string>();
  }

  selectedFile: File[]=[];
  i:any = 0;
  
  fileChange(event:any) {
    
    console.log(event.target.files);
    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
      
      let reader = new FileReader();
      reader.onload = (e: any) => {
         this.urls.push(e.target.result);
         console.log(this.urls);
      
          for (let index = 0; index < this.selectedFile.length; index++) {

              for (let urlIndex = 0; urlIndex < this.urls.length; urlIndex++) {

                      if(index == urlIndex) {
                          this.selectedFile[index]['path'] = this.urls[urlIndex];
                      }
              }
          }

          console.log(this.selectedFile);
        
      }

      reader.readAsDataURL(event.target.files[i]);
      
    }
  }

  urls = new Array<string>();

  remove_image(i:any){
  console.log(i);
  this.urls.splice(i,1);
  console.log(this.urls);
  this.selectedFile.splice(i,1);
  }

  formData = new FormData();

  formLoading:any = false;
  sendVendorComplaint(myForm:any){
    this.formLoading = true;
    console.log(this.data);

    for (let f of this.selectedFile)
    {
    this.formData.append(this.i, f, f.name);
    this.i++;
    }
    console.log(this.data);
    
    for (var property1 in this.data) {
    console.log(property1);
    this.formData.append(property1,this.data[property1]);
        this.formData.append('data['+property1+']',this.data[property1]);
    }
    
  

    this.db.FileData(this.formData,'VendorComplaint/sendVendorComplaint')
        .subscribe(resultData => {

          console.log(resultData['msg']);

          if(resultData['msg']=='success'){

            this.dialogAlert.success('Vendor Request','Successfully Mail Sent');
            // this.formLoading = false;

            
          }

          this.formLoading = false;
          this.dialogRef.closeAll();
          
    });



  }

}
