import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { SnackBarOverview } from 'src/app/toast';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import { SessionService } from 'src/app/session.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-vendor-complaint-list',
  templateUrl: './vendor-complaint-list.component.html',
  styleUrls: ['./vendor-complaint-list.component.scss']
})
export class VendorComplaintListComponent implements OnInit {

  calenderMinDate: any;
  currentDate: any;
  countData: any = {};

  constructor(public db: DatabaseService,
    public dialog: MatDialog,
    public dialogAlert: DialogComponent,
    public toast: SnackBarOverview,public route1: Router,
    public session : SessionService) {

      this.calenderMinDate = new Date('1950-01-01');
      this.currentDate = new Date();
      if(this.db.ListSearch.vendorstatus){
        this.search.status = this.db.ListSearch.vendorstatus
        // this.pageLimit = this.db.ListSearch.pagelimit
        this.start = this.db.ListSearch.start
         }
         else
         {
          this.search.status='Open'
         }
      this.getVendorComplaintList(this.pageLimit,this.start,this.search.status);

    }

    skeleton_data: any =[]
    skeleton_screen_active:any = false;
    access_rights:any = {};
    ngOnInit() {
      console.log('*** VENDOR COMPLAINT LIST ***');
      if(this.session.trico_user.access_level != '1')
      {
        this.session.trico_user.access_module.map((x:any)=>{
          if(x.module_id === 7)
          {
            this.access_rights = x;
          }
        });
        console.log(this.access_rights);
      }

      this.skeleton_data = new Array(7);

    }

    data_not_found:any = false;
    pageLimit:any =20;
    start:any = 0;
    search:any = {};
    total_count:any;
    total_page:any;
    pageNumber:any;
    pageNumberTemp:any;
    complaintList:any = [];

    getVendorComplaintList(pageLimit:any=20,start:any=0,status:any='Open',)
    {
      this.pageLimit = pageLimit;
      this.start = start;
      this.skeleton_screen_active = true;
      this.data_not_found = false;
      this.search.status = status;


      this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pageLimit},'vendorComplaint/getVendorComplaintList')
      .subscribe((result:any)=>{
        console.log(result);
        this.complaintList = result['complaint'];
        this.total_count = result['count'];
        this.countData = result['countData'];
        this.total_page = Math.ceil(this.total_count/this.pageLimit);
        this.pageNumber = Math.ceil((this.start)/(this.pageLimit))+1;
        this.pageNumberTemp = Math.ceil((this.start)/(this.pageLimit))+1;

        console.log(this.pageNumber);
        if(this.complaintList.length != 0)
        {
          this.data_not_found = false;
        }
        else
        {
          this.data_not_found = true;
        }
        setTimeout(() => {
          this.skeleton_screen_active = false;
        }, 500);

        console.log('Data Not Found - '+this.data_not_found);
        console.log('Skeleton Screen Active - '+this.skeleton_screen_active);

      },error=>{
        console.log(error);
        this.dialogAlert.error('Something went wrong !!! Try again ...');
        this.skeleton_screen_active = false;
      });
    }


    deleteVendorComplaint(complaint_id:any,index:any)
    {
      console.log('*** VENDOR DELETE ***');
      console.log(complaint_id);
      console.log(index);
      this.dialogAlert.delete('Vendor Complain Data').then((result:any) => {
        console.log(result);
        if(result){
          this.db.FetchData( {'id':complaint_id}, 'vendorComplaint/deleteVendorComplaint')
          .subscribe(res => {
            console.log(res);
            this.complaintList.splice(index,1);
            this.dialogAlert.success('Deleted','Vendor Complain Data has been deleted.');
            this.getVendorComplaintList();
          },err=>{
            console.log(err);
            this.dialogAlert.error('Something went wrong! Try Again ...');
          });
        }
      });
    }

    tothepage(id)
  {
    this.db.ListSearch.vendorstatus = this.search.status
    // this.db.ListSearch.pagelimit = this.pageLimit
    this.db.ListSearch.start = this.start

    console.log(id);
    this.route1.navigate(['vendor-complaint/'+id]);
    console.log(this.search.loginstatus)
console.log(this.db.ListSearch.task);


  }
    exp_data:any=[];
    excel_data:any=[];

    getDataInExcel()
    {
      this.db.FetchData({'search':this.search},'vendorComplaint/getVendorComplaintListExcel')
      .subscribe((result:any)=>{
          console.log(result);
          this.exp_data = result['complaint'];

          for(let i=0;i<this.exp_data.length;i++)
          {
            if (this.exp_data[i].date_created) {
              this.exp_data[i].date_created = moment(this.exp_data[i].date_created).format('DD-MM-YYYY - H:mm:ss');
            }
            // if (this.exp_dataAll[i].status_update_on) {
            //   this.exp_dataAll[i].status_update_on = moment(this.exp_dataAll[i].status_update_on).format('DD-MM-YYYY - H:mm:ss ');
            // }
            this.excel_data.push({
              'Date & Time':this.exp_data[i].date_created ,
              'Request Id':'#V-COM-' + this.exp_data[i].id,
              'Vendor Name':this.exp_data[i].vendor_name,
              'Product':this.exp_data[i].product_issue_name,
              'Nature of Problem':this.exp_data[i].nature_problem,
              'Days':this.exp_data[i].days
            });
          }

          this.db.exportAsExcelFile(this.excel_data,'Vendor Complaint');

          this.excel_data = [];
          this.exp_data = [];

        }, error => {
          console.log(error)

        });
      }
      getListData(){

      }
  }
