import { OnInit, Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({ providedIn: 'root' })


export class DialogComponent implements OnInit {
  [x: string]: any;

  constructor() { }

  ngOnInit() {

  }

  confirmation( msg: any ) {

          return Swal.fire({
              title: 'Are you sure?',
              text: '',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, do it!',
              cancelButtonText: 'No, keep it'

          }).then((result) => {

                if (result.value) {

                    return true;

                } else if (result.dismiss === Swal.DismissReason.cancel) {

                      Swal.fire( 'Cancelled', '', 'error');
                      return false;
                }
          });
    }


    delete(msg) {

          return Swal.fire({
            title: 'Are you sure?',
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {

                  if (result.value) {

                      return true;

                  } else if (result.dismiss === Swal.DismissReason.cancel) {

                      Swal.fire( 'Cancelled', '', 'error' );

                      return false;
                  }
          });
    }


    downloadConfirm(msg) {

          return Swal.fire({

              title: 'Do you want to download ?',
              text: '',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, Download it!',
              cancelButtonText: 'No'

          }).then((result) => {

                  if (result.value) {

                      return true;

                  } else if (result.dismiss === Swal.DismissReason.cancel) {

                    Swal.fire('Cancelled', '', 'error');
                      return false;

                  }
            });
      }


      success(title, msg) {

              Swal.fire({

                  position: 'center',
                  type: 'success',
                  title: 'Success',
                  text: msg,
                  timer: 1500
              });
      }

      error(msg: any) {

            Swal.fire({
              type: 'error',
              title: 'Error...',
              text: msg,
            });
      }

    }
