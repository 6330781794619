import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SessionService } from 'src/app/session.service';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { ActivatedRoute } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { TechniciansEditModelComponent } from '../technicians-edit-model/technicians-edit-model.component';


@Component({
  selector: 'app-technicians-detail',
  templateUrl: './technicians-detail.component.html',
  styleUrls: ['./technicians-detail.component.scss']
})
export class TechniciansDetailComponent implements OnInit {
  
  list_data: any =[]
  skeleton: any = []
  ecrpt_id:any;
  
  constructor(public db : DatabaseService, public dialog: MatDialog,  public session : SessionService, public dialogAlert:DialogComponent, public route : ActivatedRoute) { 
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log(this.ecrpt_id);
    });
  }
  access_rights:any = {};

  skeleton_screen_active:any = false;
  ngOnInit() {
    this.skeleton = new Array(4);
    this.list_data = new Array(5);
    this.onTechnicianDetail();
    if(this.session.trico_user.access_level != '1')
    {
      this.session.trico_user.access_module.map((x:any)=>{
        if(x.module_id === 4)
        {
          this.access_rights = x;
        }
      });
      console.log(this.access_rights);
    }
  }
  
  
  userData:any ={};
  techTaskDataList:any = [];
  countData:any = {};
  data_not_found:any;
  onTechnicianDetail()
  {
    this.skeleton_screen_active = true;
    console.log('*** GET USER DETAIL ***');
    this.db.FetchData({'user_id':this.ecrpt_id},'technician/onTechnicianDetail')
    .subscribe((result:any)=>{
      console.log(result);  
      this.userData = Object.assign({},result['techData']);
      this.techTaskDataList = result['techTaskData'];
      
      if(this.techTaskDataList.length)
      { this.data_not_found = false; }
      else { this.data_not_found = true; }
      this.countData = result['countData'];
      
      
      setTimeout(() => {
        this.skeleton_screen_active = false;
      }, 500);
      
      console.log(this.userData);
      this.tabs['task'] = true;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  partList:any = [];
  totalPartCount:any;
  getTechnicianAssignPart()
  {
    console.log('*** GET USER ASSIGNED PARTS DETAIL ***');
    this.db.FetchData({'user_id':this.ecrpt_id},'technician/getTechnicianSparePartList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.partList = result['partList'];
      this.totalPartCount = result['totalPartCount'];
      console.log(this.userData);
      this.tabs['assign_part'] = true;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  installedPartList:any = [];
  totalInstalledPartCount:any;
  getTechnicianInstalledSparePartList()
  {
    console.log('*** GET USER INSTALLED PARTS DETAIL ***');
    this.db.FetchData({'user_id':this.ecrpt_id},'technician/getTechnicianInstalledSparePartList')
    .subscribe((result:any)=>{
      console.log(result);  
      this.installedPartList = result['partList'];
      this.totalInstalledPartCount = result['totalPartCount'];
      console.log(this.userData);
      this.tabs['install_part'] = true;
    },error=>{
      console.log(error);
      this.dialogAlert.error('Something went wrong !!! Try again ...');
    });
  }
  
  
  tabs:any = {};
  tabActive(action:any)
  {
    this.tabs = {};
    console.log('Action '+action);
    this.tabs[action] = !this.tabs[action];
    if(action == 'task')
    {
      this.onTechnicianDetail();
    }
    if(action == 'assign_part')
    {
      this.getTechnicianAssignPart();
    }
    if(action == 'install_part')
    {
      this.getTechnicianInstalledSparePartList();
    }
  }
  


  data:any = {};
  openDialog(): void {
    this.data = Object.assign({},this.userData);
    const dialogRef = this.dialog.open(TechniciansEditModelComponent, {
      width: '650px',
      data : {
        data : this.data,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("hiiiii");
      
      this.onTechnicianDetail();

    });
  }
  
  
}
