import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogComponent } from '../dialog';
import { DatabaseService } from '../_services/database.service';
import { AmazingTimePickerService } from 'amazing-time-picker';

@Component({
  selector: 'app-engineerdatetimemodel',
  templateUrl: './engineerdatetimemodel.component.html',
  styleUrls: ['./engineerdatetimemodel.component.scss']
})
export class EngineerdatetimemodelComponent implements OnInit {

  data:any = {};
  
  expectingDate:any ='';
  // date = new Date();
  registerForm: FormGroup;
  visit_date: any;
  visit_time:any;
  complaintTypeList: any = [];
  transactionTypeList: any = [];
  natureList:any=[];
  date:any=[];
  constructor(private atp: AmazingTimePickerService,public db : DatabaseService, public dialogAlert: DialogComponent, @Inject(MAT_DIALOG_DATA) public stored_data :any, public dialogRef : MatDialog,private formBuilder: FormBuilder,public datepipe : DatePipe) { }
  
  ngOnInit() {
    
    this.data = Object.assign({},this.stored_data.taskAllData);
    
    console.log(this.data);
    this.visit_date = this.data['visit_date_time'],''
    this.visit_date= this.datepipe.transform(this.data['visit_date_time'],"yyyy-MM-dd");
    this.visit_time= this.datepipe.transform(this.data['visit_date_time'],"hh:mm");

    console.log(this.visit_date);
    console.log(this.visit_time);
    
    // this.data.expectation_closing_date = moment(new Date(this.data.expectation_closing_date)).format("MM/DD/YYYY");
    
    
    // this.expectDateCreated =  new this.data.expectation_closing_date((new this.data.expectation_closing_date().getTime()));
    console.log(this.data.expectation_closing_date);
    
   
    // this.onGetPriorityWiseDate('1')
    
    this.registerForm = this.formBuilder.group({
      Date: [this.visit_date], 
      time: [this.visit_time], 

      // task_type:[this.data.task_type],
      // service_request_type:[this.data.compaint_title],
      // service_type:[this.data.transaction_type],
      // description:[this.data.fg_task_description],

      // nature_issue:[this.data.fg_nature_problem]

    });
    
    
    console.log(this.registerForm.value);
    this.dates = this.registerForm.value['Date'];
    this.time = this.registerForm.value['time'];

    console.log(this.dates);
    
    
  }
  
  priorityList:any=[];
 
  
  expDate : any;
  dates: any;
  time:any;
  
  open() {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
        this.registerForm.value[`time`] = time;
    });
}
  
  getComplaintTypeList() {
    this.db.FetchData({}, 'sharedData/getComplaintTypeList')
        .subscribe(result => {
            console.log(result);
            this.complaintTypeList = result[`complaintTypeList`];
        }, error => {
            console.log(error);
        });
}
  formLoading:any = false;
  onUpdateComplaintDetail()
  {
    console.log(this.data);
    console.log(this.dates);
    
    console.log(this.registerForm.value);
    
    // this.data.priority = this.registerForm.value['priority'];
    // this.data.transaction_type = this.registerForm.value['service_type'];
    // this.data.compaint_title = this.registerForm.value['service_request_type'];
    // this.data.fg_nature_problem = this.registerForm.value['nature_issue'];
    // this.data.fg_task_description = this.registerForm.value['description'];

    this.data.visit_date_time = (this.datepipe.transform( this.registerForm.value['Date'],"yyyy-MM-dd")+' '+this.registerForm.value['time']+':00' )
    // this.data.visit_date_time=this.datepipe.transform(this.registerForm.value['time'],"yyyy-MM-dd hh:mm:ss")
    console.log( this.data.visit_date_time);
    console.log(this.data);
    
    
    this.db.FetchData({'taskId':this.data.id,'visit_date_time':this.data.visit_date_time },'Task/updateVisitingTime')
    .subscribe((result:any)=>{
      
      console.log(result);
      
      if(result =="Success") {
        this.dialogAlert.success('Engineer Visiting Date and Time','Successfully Updated')
      }
      else{
        var error = '';
        for (let index = 0; index < result['error'].length; index++) {
          error += result['error'][index]['message']+ ' ' ;  
        }
        this.dialogAlert.error(error);
        this.formLoading = false;
      }
      this.formLoading = false;
      this.dialogRef.closeAll();
      
    });
    
    
  }
  
}
