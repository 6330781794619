import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponent } from 'src/app/_services/DialogComponent';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';


@Component({
  selector: 'app-costomer-document-modal-open',
  templateUrl: './costomer-document-modal-open.component.html',
  styleUrls: ['./costomer-document-modal-open.component.scss']
})

export class CostomerDocumentModalOpenComponent implements OnInit {
  constructor(public dialogRef: MatDialog, public dialogAlert : DialogComponent,  @Inject(MAT_DIALOG_DATA) public stored_data :any, public db : DatabaseService) { }
  attachment_data:any= {};
  ngOnInit() {
    this.attachment_data = Object.assign([],this.stored_data.data);
    // console.log(this.attachment_data);
    console.log('ssssssssssss',this.attachment_data);

  }

  deleteDocumentImage(image_id:any,index:any){
    console.log(image_id);
    console.log(index);
    this.dialogAlert.delete('Document Data Attachment').then((result:any) => {
      console.log(result);
      if(result){
        this.db.FetchData( {'id':image_id}, 'customer/deleteDocumentImage')
        .subscribe(res => {
          console.log(res);
          this.attachment_data.splice(index,1);

          this.dialogAlert.success('Deleted','Document Data Attachment has been deleted.');
          this.dialogRef.closeAll();
        },err=>{
          console.log(err);
          this.dialogAlert.error('Something went wrong! Try Again ...');
        });
      }
    });
  }
  
}
