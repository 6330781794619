import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';
import { DatabaseService } from './_services/database.service';

@Injectable()
export class AuthGuardLog implements CanActivate {
  
  trico_user:any = {};
  constructor(private router: Router,public session: SessionService, public db: DatabaseService) {
    console.log('Auth Guard Log');
  }
  
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    
    this.session.GetSession().subscribe(
      data => {
        this.trico_user = data;
        console.log(this.trico_user);
        console.log(state.url);
      },
      error => {
        console.log(error);
      });
      
      
      if (this.trico_user.tri_login) {
        console.log(this.trico_user);
        if(state.url != '/')   {          
          //this.router.navigate([state.url]);
        }else
        {
          console.log('Access Level - '+this.trico_user.access_level);
          this.router.navigate(['/dashboard']);
        }
        this.db.can_active = '1';
        return false;
        
      }else{
        this.db.can_active = '';
        return true;
      }
      
      
      
      
    }
    
  }
  
  