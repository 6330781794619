import { Component, OnInit } from '@angular/core';
import { DBRequest } from '../../databaseService';
import { FormBuilder, FormGroup, Validators, FormControlName } from '@angular/forms';
import { DialogComponent } from 'src/app/dialog';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/toast';

@Component({
    selector: 'app-customer-add',
    templateUrl: './customer-add.component.html',
    styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent implements OnInit {

     registerForm: FormGroup;
     contactForm: FormGroup;
     submitted = false;

     isRequestInProcess: any = false;

     contactData: any =  [];
     stateList: any = [];
     districtList: any = [];

     dropdownSettings: any = {};
     dropdownSettings1: any = {};
     contactMobile: FormControlName;

     constructor(private formBuilder: FormBuilder,
                 public dbService: DBRequest,
                 public alert: DialogComponent,
                 public toast: SnackBarOverview,
                 public route: Router,) {


     }

     ngOnInit() {

          this.getStateList();
          this.onValidateFormHandler();
          this.onDropDownSettingHandler();
     }


     onSubmit() {

        this.submitted = true;

        console.log(this.registerForm);
        if (this.registerForm.invalid || this.contactForm.invalid) {

              this.registerForm.get('companyName').markAsTouched();
              this.registerForm.get('custCode').markAsTouched();
              this.registerForm.get('custType').markAsTouched();
            //   this.registerForm.get('email').markAsTouched();
            //   this.registerForm.get('landlineNo').markAsTouched();
              this.registerForm.get('accountValidUpTo').markAsTouched();
              this.registerForm.get('street').markAsTouched();
              this.registerForm.get('stateName').markAsTouched();
              this.registerForm.get('districtName').markAsTouched();
              this.registerForm.get('city').markAsTouched();
              this.registerForm.get('pincode').markAsTouched();
              this.contactForm.get('contactName').markAsTouched();
            //   this.contactForm.get('designation').markAsTouched();
            //   this.contactForm.get('contactEmail').markAsTouched();
              this.contactForm.get('contactMobile').markAsTouched();

              return;

        } else {

              const contactData = this.contactForm[`value`];
              this.onValidateContactEmptyDetailHandler(contactData);

              if (contactData[`contactName`] && contactData[`contactEmail`] && contactData[`contactMobile`]) {
                    this.onAddContactHandler();
              }

              const inputData = {
                  data: JSON.parse(JSON.stringify(this.registerForm.value)),
              };

              inputData[`data`][`stateName`] = this.registerForm.value[`stateName`][0][`state_name`];
              inputData[`data`][`districtName`] = this.registerForm.value[`districtName`][0][`district_name`];
              inputData[`data`][`contactData`] = JSON.parse(JSON.stringify(this.contactData));

              console.log(this.registerForm.value);
              console.log(inputData);


              this.isRequestInProcess = true;
              this.dbService.onPostRequestData(inputData, 'customer/onSubmitCustomerData')
              .subscribe(result => {

                  console.log(result);

                  console.log(this.registerForm.value);
                  this.isRequestInProcess = false;

                  if (result[`status`] == 'error') {

                      this.alert.error(result[`statusMessage`]);

                  } else {

                      this.route.navigate(['/customerlist']);
                      setTimeout(() => {

                        this.alert.success('Success',  'Customer Saved successfully');

                      }, 1000);
                  }

              }, error => {

                     this.isRequestInProcess = false;
                     this.toast.openError('Something Went Wrong, Try Later','');
              });
        }
     }


     getStateList() {

          this.dbService.onPostRequestData( {}, 'address/getStateList')
          .subscribe(result => {

              console.log(result);
              this.stateList = result[`stateList`];

          }, error => {

                // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
          });
     }


     getDistrictList() {

        this.districtList = [];

        console.log(this.registerForm.value);
        this.registerForm.get('districtName').setValue([]);

        if (this.registerForm.value[`stateName`] && this.registerForm.value[`stateName`][0]) {

              const stateName = this.registerForm.value[`stateName`][0][`state_name`];

              this.dbService.onPostRequestData({stateName: stateName}, 'address/getDistrictList')
              .subscribe(result => {

                  console.log(result);
                  this.districtList = result[`districtList`];

              }, error => {

                    // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
              });
        }
    }



    onAddContactHandler() {

        const contactData = this.contactForm.value;

        console.log(contactData);


        let isContactEmpty = false;
        if (!contactData[`contactName`] || !contactData[`contactMobile`]) {

              isContactEmpty  = true;
        }

        if (this.contactForm.invalid || isContactEmpty) {

              this.registerForm.get('companyName').markAsTouched();
              this.registerForm.get('custCode').markAsTouched();
              this.registerForm.get('custType').markAsTouched();
            //   this.registerForm.get('email').markAsTouched();
            //   this.registerForm.get('landlineNo').markAsTouched();
              this.registerForm.get('accountValidUpTo').markAsTouched();
              this.registerForm.get('street').markAsTouched();
              this.registerForm.get('stateName').markAsTouched();
              this.registerForm.get('districtName').markAsTouched();
              this.registerForm.get('city').markAsTouched();
              this.registerForm.get('pincode').markAsTouched();
              this.contactForm.get('contactName').markAsTouched();
            //   this.contactForm.get('designation').markAsTouched();
            //   this.contactForm.get('contactEmail').markAsTouched();
              this.contactForm.get('contactMobile').markAsTouched();


              if (isContactEmpty) {
                  this.alert.error('Fill Contact All Details To Continue!');
              }

              return;
        }


        this.onValidateContactEmptyDetailHandler(contactData);

        this.contactData.push({

              contactName: contactData[`contactName`],
              designation: contactData[`designation`],
              contactEmail: contactData[`contactEmail`],
              contactMobile: contactData[`contactMobile`],
        });

        const contactNameControl = this.contactForm.get('contactName');
        const designationControl = this.contactForm.get('designation');
        const contactEmailControl = this.contactForm.get('contactEmail');
        const contactMobileControl = this.contactForm.get('contactMobile');

        contactNameControl.setValidators([Validators.minLength(3), Validators.maxLength(30)]);
        designationControl.setValidators([Validators.minLength(3), Validators.maxLength(30)]);
        contactEmailControl.setValidators([Validators.email]);
        contactMobileControl.setValidators([Validators.minLength(10), Validators.maxLength(10)]);

        contactNameControl.updateValueAndValidity();
        designationControl.updateValueAndValidity();
        contactEmailControl.updateValueAndValidity();
        contactMobileControl.updateValueAndValidity();

        this.contactForm.get('contactName').setValue('');
        this.contactForm.get('designation').setValue('');
        this.contactForm.get('contactEmail').setValue('');
        this.contactForm.get('contactMobile').setValue('');
    }


    onValidateContactEmptyDetailHandler(contactData) {

            let isAnyContactColFilled = false;
            let isAnyContactColEmpty = false;
            if (contactData[`contactName`] || contactData[`contactMobile`]) {

                  isAnyContactColFilled = true;
            }

            if (!contactData[`contactName`] || !contactData[`contactMobile`]) {
                  isAnyContactColEmpty = true;
            }

            if (isAnyContactColFilled && isAnyContactColEmpty) {

                this.alert.error('Fill Contact All Details To Proceed !');
                return false;
            }
    }


    onContactDeleteHandler(index) {

          this.alert.confirmation('').then((confirmResult) => {

                if (confirmResult) {

                     this.contactData.splice(index, 1);

                     if (this.contactData.length == 0) {

                          const contactNameControl = this.contactForm.get('contactName');
                          const designationControl = this.contactForm.get('designation');
                          const contactEmailControl = this.contactForm.get('contactEmail');
                          const contactMobileControl = this.contactForm.get('contactMobile');

                          contactNameControl.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30)]);
                          designationControl.setValidators([ Validators.minLength(3), Validators.maxLength(30)]);
                          contactEmailControl.setValidators([Validators.email]);
                          contactMobileControl.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);

                          contactNameControl.updateValueAndValidity();
                          designationControl.updateValueAndValidity();
                          contactEmailControl.updateValueAndValidity();
                          contactMobileControl.updateValueAndValidity();
                     }
                }
          });
    }


    onValidateFormHandler() {

          this.registerForm = this.formBuilder.group({

                companyName: ['',[Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                custCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                custType: ['', Validators.required],
                email: ['', [Validators.email]],
                landlineNo: ['', [Validators.minLength(5), Validators.maxLength(15)]],
                accountValidUpTo: ['',Validators.required],
                street: ['',Validators.required],
                stateName: ['', Validators.required],
                districtName: ['', Validators.required],
                city: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
          });


          this.contactForm = this.formBuilder.group({

                contactName: ['', [ Validators.minLength(3), Validators.maxLength(30)]],
                designation: ['', [ Validators.minLength(3), Validators.maxLength(30)]],
                contactEmail: ['', [ Validators.email]],
                contactMobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
          });
    }

    onDropDownSettingHandler() {

          this.dropdownSettings = {
              singleSelection: true,
              idField: 'state_name',
              textField: 'state_name',
              allowSearchFilter: true,
              maxHeight: 197,
              closeDropDownOnSelection: true
          };

          this.dropdownSettings1 = {
              singleSelection: true,
              idField: 'district_name',
              textField: 'district_name',
              allowSearchFilter: true,
              maxHeight: 197,
              closeDropDownOnSelection: true
          };
    }

    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }

    numberOnly(event): boolean {

        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }

    get f() { return this.registerForm.controls; }
    get p() { return this.contactForm.controls; }
}
