import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DBRequest } from '../databaseService';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../dialog';

@Component({
  selector: 'app-assignstlmodal',
  templateUrl: './assignstlmodal.component.html',
  styleUrls: ['./assignstlmodal.component.scss']
})
export class AssignstlmodalComponent implements OnInit {
  stlList:any=[];
  tmp_userList:any=[];
  rsm:any=[];
  ass_user:any=[];
  sales_executive_update: any;
  active:any = {};
  assignUserList:any=[];
  customerEncryptId: any = '';
  isRequestInProcess: any = true;
  customerData: any = {};
      customerContactData: any = [];
  announcementData:any={};
   
    constructor(public dbService: DBRequest, public dialogAlert: DialogComponent,  @Inject(MAT_DIALOG_DATA) public stored_data :any, public router:Router, public route : ActivatedRoute, public dialogRef: MatDialogRef<AssignstlmodalComponent>) { }

  ngOnInit() {
console.log(this.stored_data.data);
this.assignUserList=this.stored_data.data
console.log(this.assignUserList);

this.route.params.subscribe(params => {

  console.log(params);
  this.customerEncryptId = params.customerId;

  console.log(this.customerEncryptId);
  this.StluserLIst()

 
});

  }
  
  
  StluserLIst()
  {

    this.dbService.onPostRequestData({'type':6},"Master_User/getSystemUserList2").subscribe((result=>{
      console.log(result);
      this.stlList=result['user'];
      console.log(this.stlList);
      
      this.tmp_userList=this.stlList;
      
      for(let i=0;i<this.stlList.length;i++)
      {
        console.log('in')
        
        for(let j=0;j<this.assignUserList.length;j++)
        {
          console.log('in2')
          
          if(this.stlList[i].id==this.assignUserList[j]['id'])
          {
            console.log('in23')
            
            this.stlList[i].check=true;
            this.rsm.check=true;
            console.log(this.rsm);
            
          }
          console.log(this.rsm);
          
        }
        
      }
    }))
    
    
  }
  selectedArea: any;
  
  tmp(value) {
   
    this.selectedArea = value;
    console.log(this.selectedArea);
  }
  stl_user(value,index,event)
  {
    if(event.checked)
    {
      if(this.rsm.indexOf(this.stlList[index]['id']) === -1)
      {
        this.rsm.push(value);
        console.log(this.rsm);
      }
    }
    else
    {
      for( var j=0;j<this.stlList.length;j++)
      {
        if(this.stlList[index]['id']==this.rsm[j])
        {
          this.rsm.splice(j,1);
        }
      }
      console.log(this.rsm);
    }
    this.ass_user =  this.rsm
  }
  formLoading:any = false;

  update_assigned_stl()
  {
    console.log(this.ass_user);
    console.log(this.stlList)

    this.dbService.onPostRequestData({ 'user_id' :this.selectedArea, 'customer_id':this.stored_data.data.customer_id, 'so_no':this.stored_data.data.so_no},"Customer/assignstl").subscribe((result=>{
      console.log(result);
      this.stlList = this.tmp_userList;
      this.stlList.map((el)=>{ el.check = false});

      if(this.rsm)
      {
        for(var i=0;i<this.rsm.length;i++)
        {
          var index_val = this.stlList.map((el) => el.id).indexOf(this.rsm[i]);
          console.log(index_val);

          this.stlList[index_val].check = !this.stlList[index_val].check;
        }
      }
      console.log(this.stlList);
      if(result['msg'] == 'success') {
      this.dialogRef.close();

      }


    }))

 

  }
 

     
     
}
