import { Component, OnInit,Inject} from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-customer-feedback-model',
  templateUrl: './customer-feedback-model.component.html',
  styleUrls: ['./customer-feedback-model.component.scss']
})
export class CustomerFeedbackModelComponent implements OnInit {
  var = true;
  typeFeedback: any =[];
  surveyData :any ={};
  constructor(

    public db: DatabaseService,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    
  ) { 


    this.surveyData= modalData;
    console.log(this.surveyData);

    this.onGetFeedbackDetail(this.surveyData);


  }

  ngOnInit() {
  }
  surveyListData : any ={};
  onGetFeedbackDetail(surveyData){

    console.log(surveyData);
    

    this.db.FetchData({'surveyData': this.surveyData},'customer/onGetFeedbackDetail')
    .subscribe((result:any)=>{

      console.log(result.surveyData);

      this.surveyListData= result.surveyData;
      console.log(this.surveyListData);
      
      

    });

  }


}
