import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { Location } from '@angular/common';
import { ActivatedRoute} from '@angular/router';


@Injectable({ providedIn: 'root' })
export class DBRequest {
 ListSearch: any = {}

DBRequestPath: any = 'https://crm.tricolite.com/api/index.php/';
UploadUrl:string = "https://crm.tricolite.com/api/uploads/";
UploadUrl1:string = "https://crm.tricolite.com/api/uploads/Customer_Doc";     ////live
     ////live

      // DBRequestPath: any = 'http://phpstack-83335-1824762.cloudwaysapps.com/api/index.php/';
      // UploadUrl:string = "http://phpstack-83335-1824762.cloudwaysapps.com/api/uploads/";  //// test
      // phpstack-83335-1824762.cloudwaysapps.com

      constructor(
            public location: Location,
            public http: HttpClient) {

            }

            header: any = new HttpHeaders();

            onPostRequestData(requestData: any, fn: any) {
                  this.header.append('Content-Type', 'application/json');
                  console.log(requestData);
                  return this.http.post( this.DBRequestPath + fn, JSON.stringify(requestData), {headers: this.header});
            }

            onGetRequestData(requestData: any, fn: any) {
                  this.header.append('Content-Type', undefined);
                  console.log(requestData);
                  return this.http.post( this.DBRequestPath + fn, requestData, {headers: this.header});
            }

      }
