import { Component, OnInit, Inject } from '@angular/core';
import { DBRequest } from '../../databaseService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from 'src/app/dialog';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { SnackBarOverview } from 'src/app/toast';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/session.service';

@Component({
    selector: 'app-all-action-modal',
    templateUrl: './all-action-modal.component.html',
    styleUrls: ['./all-action-modal.component.scss']
})
export class AllActionModalComponent implements OnInit {

  registerForm: FormGroup;
  registerContactForm: FormGroup;
  registerTechForm: FormGroup;
  registerPartForm: FormGroup;
  submitted = false;

  partList: any = [];
  assignedTaskList: any = [];

  techData: any = {};
  partData: any = {};
  fgList2: any = {};

  fgList: any = [];
  fgStoredData: any = [];
  natureList: any =[];

  savedTechnicianList: any = [];
  technicianList: any = [];
  subOrdinateList: any = [];

  data: any;
  formData: any = {};
  selectedFGData: any = {};
  selectedTechData: any = {};

  technicianId: any = {};
  isRequestInProcess: any = false;

  dropdownSettings: any = {};
  dropdownSettings1: any = {};
  dropdownSettings2: any = {};
  dropdownSettings3: any = {};
  dropdownSettings4: any ={};
  dropdownSettingsSoNo: any ={};


  contactPersons:any = {};

  constructor(private formBuilder: FormBuilder,
              public dbService: DBRequest,
              public alert: DialogComponent,
              public toast: SnackBarOverview,
              public redirect: Router,
              public route: ActivatedRoute,
              public dialogRef: MatDialogRef<AllActionModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: any,
              public session : SessionService) {

          this.data = modalData;
          console.log(modalData);
if(!modalData.id){
          this.contactPersons = this.data.taskAllData.taskContactData;

          this.formData.status = this.data.taskAllData.taskData.status;
          this.formData.remark = this.data.taskAllData.taskData.status_reason;
          this.selectedTechData = this.data.taskAllData.taskData;
}
          if(this.selectedTechData.assign_to && this.selectedTechData.assign_to != '0') {

              this.technicianId = this.selectedTechData.assign_to;

              this.formData.technician = [{
                id: this.selectedTechData.assign_to,
                name: this.selectedTechData.assign_to_name
              }];


          }

          const subOrdinateArr = [];
          for (let index = 0; index < this.data.taskAllData.taskAssignData.length; index++) {

               const assignData = this.data.taskAllData.taskAssignData[index];
               if (assignData[`type`] == 'Secondary') {

                   subOrdinateArr.push({
                        id: assignData.id,
                        name: assignData.user_name
                   });
               }
          }

          this.formData.subordinates = subOrdinateArr;
          this.getTechAlreadyAssignedTaskList();
          this.getSOList()
  }

  ngOnInit() {

      this.onModifyDataHandler();
      this.onDropDownSettingHandler();

      if (this.data.target == 'assignPart') {
          this.getPartList();
      }
      console.log(this.data.target);


      if (this.data.target == 'assignTech') {
          this.getTechnicianList();
      }

  }

  onSaveModelDataHandler() {

          let inputData;
          let apiURL;

          if (this.data.target == 'updateContact') {

                const selectedContact = this.data.taskAllData.projectContactData.filter(row => row.checked == true);

                if (selectedContact.length == 0) {
                    this.alert.error('No Contact Selected!');
                    return;
                }

                inputData = {

                    taskId: this.data.taskId,
                    contactData: this.data.taskAllData.projectContactData,
                    session : this.session.trico_user

                };

                apiURL = 'task/onUpdateTaskContact';
          }

          if (this.data.target == 'updateProduct') {

                // if (!this.selectedFGData || !this.selectedFGData[`fg_no`]) {

                //       this.alert.error('Fill Required Fields!');
                //       return;
                // }

       if(this.data.row){
                inputData = {
                      p_id:this.data.row.id
                 ,   taskId: this.data.taskId,
                    selectedFGData: this.fgList2,
                   session : this.session.trico_user
                }};
                if(!this.data.row){
                  inputData = {

                      taskId: this.data.taskId,
                      selectedFGData: this.fgList2,
                     session : this.session.trico_user
                  }};
                apiURL = 'task/onUpdateTaskProduct';
          }


          if (this.data.target == 'assignPart') {

              if ((this.formData.part && this.formData.part.length > 0) || (this.formData.assignQty && this.formData.assignQty == 0)) {

                    this.alert.error('To Continue, Add Selected Part To List!');
                    return;
              }

              inputData = {

                  taskId: this.data.taskId,
                  taskAssignedPartData: this.data.taskAllData.taskAssignedPartData,
                  session : this.session.trico_user
              };

              apiURL = 'task/onUpdateTaskAssignPart';
          }

          if (this.data.target == 'assignTech') {

              if (!this.formData.technician || this.formData.technician.length == 0) {

                    this.alert.error('No Engineer Selected!');
                    return;
              }

              inputData = {

                  taskId: this.data.taskId,
                  technicianData: this.selectedTechData,
                  subOrdinateData: this.formData.subordinates,
                  session : this.session.trico_user,
                  contactPersons : this.contactPersons,
                  company_name : this.data.taskAllData.taskCustomerData.company_name
              };

              apiURL = 'task/onUpdateTaskTechnician';
          }


          if (this.data.target == 'updateStatus') {

            if (!this.formData.status || ((this.formData.status == 'Pending' || this.formData.status == 'Hold' || this.formData.status == 'Reject') && !this.formData.remark)) {

                  this.alert.error('Fill Required Fields!');
                  return;
            }

            if(!this.formData.remark) {

                 this.formData.remark = '';
            }

            inputData = {

                taskId: this.data.taskId,
                status: this.formData.status,
                remark: this.formData.remark,
                session : this.session.trico_user
            };

            apiURL = 'task/onUpdateTaskStatus';
        }

          this.isRequestInProcess = true;

          this.dbService.onPostRequestData(inputData, apiURL).subscribe(resultData => {

                console.log(resultData);

                setTimeout(() => {

                  this.isRequestInProcess = false;

                }, 500);

                console.log(resultData);
                if (resultData[`status`] == 'error') {

                    console.log(resultData);
                    this.alert.error(resultData[`statusMessage`]);

                } else {

                    this.dialogRef.close();

                    setTimeout(() => {

                        this.alert.success('Success',  'Updated Successfully');

                    }, 1000);
                }

          }, error => {

                this.isRequestInProcess = false;
                this.toast.openError('Something Went Wrong, Try Later','');

          });
  }
  soList:any=[]
  getSOList() {

    const inputData = {

        customerId: this.data.taskAllData.taskData.customer_id,
        projectId: this.data.taskAllData.taskData.project_id,
        isDecrypt: true
    };

    this.dbService.onPostRequestData(inputData, 'sharedData/getSOList')
        .subscribe(result => {

            console.log(result);

this.soList=result['soList']
console.log(this.soList);


        }, error => {
            console.log(error);
        });
}
  getFGList() {

    console.log("kiiiiii");


        const inputData = {

            projectId: this.data.taskAllData.taskData.project_id
            ,
            soNo: this.formData.soNo[0].so_no,
            isDecrypt: true

        };

        this.dbService.onPostRequestData(inputData, 'sharedData/getFGList_So')
            .subscribe(result => {

                console.log(result);
                this.fgList = result[`fgList`];
console.log(this.fgList);


            }, error => {
                console.log(error);
            });






}
getFGList2() {

  console.log("kiiiiii");


      const inputData = {

          projectId: this.data.taskAllData.taskData.project_id
          ,
          soNo: this.formData.soNo[0].so_no,
          fgNo:this.formData.fgNo[0].fg_no,
          isDecrypt: true

      };

      this.dbService.onPostRequestData(inputData, 'sharedData/getFGList_Fg')
          .subscribe(result => {

              console.log(result);
              this.fgList2 = result[`fgList`];
console.log(this.fgList2);


          }, error => {
              console.log(error);
          });






}

  onModifyDataHandler() {

      if (this.data.target == 'updateContact') {

              const taskSelectedContact = [];
              for (let index = 0; index < this.data.taskAllData.projectContactData.length; index++) {

                  const rowData = this.data.taskAllData.projectContactData[index];

                  const isIndexExist = this.data.taskAllData.taskContactData.findIndex(row => row.contact_id == rowData.id);

                  if(isIndexExist !== -1) {
                    this.data.taskAllData.projectContactData[index].checked = true;
                  }

              }

              console.log(this.data.taskAllData.projectContactData);

              console.log(taskSelectedContact);
      }


      if (this.data.target == 'updateProduct') {

            const taskSelectedContact = [];
            for (let index = this.data.taskAllData.taskProjectFGData.length-1; index >= 0; index--) {

              const rowData = this.data.taskAllData.taskProjectFGData[index];

              const isIndexExist = this.data.taskAllData.taskFGData.findIndex(row => row.fg_no == rowData.fg_no);

              if (isIndexExist === -1) {
                  this.data.taskAllData.taskProjectFGData[index].isAdded = true;
              } else {

                  this.data.taskAllData.taskProjectFGData.splice(index, 1);

              }
          }

            console.log(this.data.taskAllData.taskProjectFGData);
      }
  }

  onChangeFGHandler() {
    console.log(this.formData.soNo);


       if (this.formData.fgNo && this.formData.fgNo.length > 0) {

           const fgNo = this.formData.fgNo[0].fg_no;
           const isIndex = this.data.taskAllData.taskProjectFGData.findIndex(row => row.fg_no == fgNo);
           this.selectedFGData = this.data.taskAllData.taskProjectFGData[isIndex];
           console.log(this.selectedFGData);


       } else {

           this.selectedFGData = {};
       }
  }

  getPartList() {

        this.dbService.onPostRequestData({}, 'sharedData/getPartList')
        .subscribe(result => {

            console.log(result);
            this.partList = result[`partList`];
            console.log(this.partList);

              this.partList.map((x:any)=>{
              x.item_part_name = x.part_name+' - '+x.item_no;
              });

        }, error => {

        });
  }

  getNatureProblemList(){

      this.dbService.onPostRequestData({}, 'sharedData/getNatureProblemList')
      .subscribe(result => {
          console.log(result);
          this.natureList = result[`natureList`];
      }, error => {
          console.log(error);
      });

  }

  getTechAlreadyAssignedTaskList() {

      const userId = this.technicianId;

      console.log(userId);

      this.dbService.onPostRequestData( {userId: userId}, 'task/getTechnicianAlreadyAssignedTask')
      .subscribe(result => {

          console.log(result);
          this.assignedTaskList = result[`assignedTaskList`];

      }, error => {

            // M.toast({html: 'Error: Something went wrong! Try Again',classes: 'rounded'});
      });
  }

  selectedFile: File[]=[];
  i:any = 0;

  fileChange(event:any) {

    console.log(event.target.files);
    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);

      let reader = new FileReader();
      reader.onload = (e: any) => {
         this.urls.push(e.target.result);
         console.log(this.urls);

          for (let index = 0; index < this.selectedFile.length; index++) {

              for (let urlIndex = 0; urlIndex < this.urls.length; urlIndex++) {

                      if(index == urlIndex) {
                          this.selectedFile[index]['path'] = this.urls[urlIndex];
                      }
              }
          }

          console.log(this.selectedFile);

      }

      reader.readAsDataURL(event.target.files[i]);

    }
  }

  urls = new Array<string>();

  remove_image(i:any){
  console.log(i);
  this.urls.splice(i,1);
  console.log(this.urls);
  this.selectedFile.splice(i,1);
}

// clearForm(myForm:any)
// {
//     // this.data = {};
//     // myForm.resetForm();
//     myForm.resetForm();
//     this.selectedFile = [];
//     this.urls = [];
//     this.data = {};
//     this.formData = new FormData();
//     this.urls = new Array<string>();
// }



  uploadFormData = new FormData();
  formLoading:any = false;
  uploadReports(){

    for (let f of this.selectedFile)
    {
      this.uploadFormData.append(this.i, f, f.name);
      this.i++;
    }

    console.log(this.session.trico_user);
    for (var property1 in this.session.trico_user)
    {
      console.log(property1);
      this.uploadFormData.append('session['+property1+']',this.session.trico_user[property1]);
    }

    const inputData = {

        taskId: this.data.taskId

    };

    for (var property1 in inputData)
    {
      console.log(property1);
      this.uploadFormData.append('data['+property1+']',inputData[property1]);
    }

    this.dbService.onGetRequestData(this.uploadFormData,'task/uploadServiceReport')
    .subscribe((result:any)=>{
      console.log(result);

        // return false;
      if(result['msg'] == 'success')
      {
        this.alert.success('Document Data','Successfully Saved !!!');
      }
      else{
        this.alert.error(result['error']);
      }
      // this.clearForm(myForm);
      this.formLoading = false;
    //   this.getDocumentList();
    },error=>{
      console.log(error);
      this.alert.error('Something went wrong !!! Try again ...');
    });


  }

  onAddPartToAssignListHandler() {


       if (!this.formData.part || this.formData.part.length == 0 || !this.formData.assignQty || this.formData.assignQty == 0 || this.formData.assignQty < 0) {

                  this.alert.error('Fill Required Field!');
                  return;
       }

       const isIndex = this.data.taskAllData.taskAssignedPartData.findIndex(row => row.part_id == this.formData.part[0].id);

       if (isIndex === -1) {

            const partIndex = this.partList.findIndex(row => row.id == this.formData.part[0].id);

            const part_name = this.partList.filter(x=>x.id == this.formData.part[0].id)[0].part_name;

            this.data.taskAllData.taskAssignedPartData.push({

                  part_id: this.formData.part[0].id,
                  part_name: part_name,
                  part_no: this.partList[partIndex].item_no,
                  assign_qty: this.formData.assignQty,
                  assign_to: this.data.taskAllData.taskData.assign_to,
                  assign_to_name: this.data.taskAllData.taskData.assign_to_name,
                  del: 0
            });


       } else {

            this.data.taskAllData.taskAssignedPartData[isIndex].assign_qty = parseInt(this.data.taskAllData.taskAssignedPartData[isIndex].assign_qty ) + parseInt(this.formData.assignQty);
       }

       this.formData.part = [];
       this.formData.assignQty = 0;
       console.log(this.data.taskAllData.taskAssignedPartData);
       this.toast.openSucess('Qty Updated Successfully!','');

  }

  onDropDownSettingHandler() {

      this.dropdownSettings = {
          singleSelection: true,
          idField: 'fg_no',
          textField: 'fg_no',
          allowSearchFilter: true,
          maxHeight: 197,
          closeDropDownOnSelection: true
      };


      this.dropdownSettingsSoNo = {
        singleSelection: true,
        idField: 'so_no',
        textField: 'so_no',
        allowSearchFilter: true,
        maxHeight: 197,
        closeDropDownOnSelection: true
    };


      this.dropdownSettings1 = {
          singleSelection: true,
          idField: 'id',
          textField: 'item_part_name',
          allowSearchFilter: true,
          maxHeight: 197,
          closeDropDownOnSelection: true
      };


      this.dropdownSettings2 = {
        singleSelection: true,
        idField: 'id',
        textField: 'name',
        allowSearchFilter: true,
        maxHeight: 197,
        closeDropDownOnSelection: true
      };



      this.dropdownSettings3 = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        allowSearchFilter: true,
        maxHeight: 197,
        closeDropDownOnSelection: false
      };

      this.dropdownSettings4 = {
        singleSelection: false,
        idField: 'id',
        textField: 'nature',
        allowSearchFilter: true,
        maxHeight: 197,
        closeDropDownOnSelection: false
      };
  }


  getTechnicianList() {

        this.isRequestInProcess = true;

        this.dbService.onPostRequestData({}, 'sharedData/getTechnicianList')
        .subscribe(result => {

            console.log(result);

            this.isRequestInProcess = false;
            this.savedTechnicianList = result[`technicianList`];
            console.log(this.savedTechnicianList);


            this.technicianList = JSON.parse(JSON.stringify(this.savedTechnicianList));
            console.log(this.technicianList);

            this.subOrdinateList = JSON.parse(JSON.stringify(this.technicianList));

            const technicianId = this.technicianId;
            if(technicianId && technicianId != '0')  {

                this.subOrdinateList = this.savedTechnicianList.filter(row => row.id != technicianId);

            } else {

                this.subOrdinateList = this.savedTechnicianList;
            }

        }, error => {

            this.isRequestInProcess = false;
        });
  }


  onChangeTechnicianHandler() {

        const technicianData = this.formData.technician;

        if (technicianData && technicianData.length > 0) {

           if(this.technicianList.length > 0) {

              const technicianId = technicianData[0].id;
              this.subOrdinateList = this.savedTechnicianList.filter(row => row.id != technicianId);

              this.technicianId = technicianId;
              const userDataIndex = this.savedTechnicianList.findIndex(row => row.id == technicianId);
              this.selectedTechData.assign_to = this.savedTechnicianList[userDataIndex].id;
              this.selectedTechData.assign_to_name = this.savedTechnicianList[userDataIndex].name;
              this.selectedTechData.designation = this.savedTechnicianList[userDataIndex].designation;
              this.selectedTechData.mobile = this.savedTechnicianList[userDataIndex].mobile;
              this.selectedTechData.assign_street = this.savedTechnicianList[userDataIndex].street;
              this.selectedTechData.assign_state_name = this.savedTechnicianList[userDataIndex].state_name;
              this.selectedTechData.assign_district_name = this.savedTechnicianList[userDataIndex].district_name;
              this.selectedTechData.assign_city = this.savedTechnicianList[userDataIndex].city;
              this.selectedTechData.assign_pincode = this.savedTechnicianList[userDataIndex].pincode;

              this.subOrdinateList = this.savedTechnicianList.filter(row => row.id != technicianId);

              this.getTechAlreadyAssignedTaskList();
           }

        } else {

            this.subOrdinateList = [];
            this.formData.subordinates = [];
            this.assignedTaskList = [];
            this.selectedTechData = {};

            this.subOrdinateList = JSON.parse(JSON.stringify(this.savedTechnicianList));
        }
  }


  onChangeSubOrdinateHandler() {

    const technicianData = this.formData.subordinates;

    if (technicianData && technicianData.length > 0) {

          this.technicianList = this.savedTechnicianList.filter(row => {

              let isFound = false;
              for (let index = 0; index < technicianData.length; index++) {

                   if (technicianData[index].id == row.id) {
                        isFound = true;
                   }
              }
              if (isFound) {

                  return false;

              } else {
                  return true;
              }
          });

    } else {

          this.technicianList = JSON.parse(JSON.stringify(this.savedTechnicianList));
    }
 }


  onDeletePartHandler(index) {

    this.alert.confirmation('').then((confirmResult) => {

        if (confirmResult) {
            this.data.taskAllData.taskAssignedPartData[index].del = 1;;
        }
     });
  }


  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

  numberOnly(event): boolean {

      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  }

  get f() { return this.registerForm.controls; }
  onChangeNatureHandler(){

  }

}
