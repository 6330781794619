import { Component, OnInit } from '@angular/core';
import { DBRequest } from '../../databaseService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from 'src/app/dialog';

import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { SnackBarOverview } from 'src/app/toast';
import { SessionService } from 'src/app/session.service';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactPersonModelComponent } from '../contact-person-model/contact-person-model.component';
import { formatNumber, formatDate } from '@angular/common';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-task-add',
    templateUrl: './task-add.component.html',
    styleUrls: ['./task-add.component.scss']
})
export class TaskAddComponent implements OnInit {

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;

    registerForm: FormGroup;
    submitted = false;

    stateList: any = [];
    districtList: any = [];
    priorityList: any = [];
    natureList: any = [];


    customerList: any = [];
    projectList: any = [];
    complaintTypeList: any = [];
    fgList: any = [];
    soList: any = [];

    data: any = {};
    fgStoredData: any = [];

    customerBasicDetail: any = {};
    projectBasicDetail: any = {};
    fgBasicDetail: any = {};
    soBasicDetail: any = {};

    natureBasicDetail: any = {};
    transactionTypeDetail: any = {};

    transactionTypeList: any = [];

    projectContactList: any = [];


    savedTechnicianList: any = [];
    technicianList: any = [];
    subOrdinateList: any = [];

    isRequestInProcess: any = false;

    serviceTypeList: any = [];

    dropdownSettings: any = {};
    dropdownSettings1: any = {};
    dropdownSettings2: any = {};
    dropdownSettings3: any = {};
    dropdownSettings4: any = {};
    dropdownSettings5: any = {};
    dropdownSettings6: any = {};
    dropdownSettings7: any = {};
    dropdownSettings8: any = {};
    dropdownSettings9: any = {};
    dropdownSettings10: any = {};
    dropdownSettings11: any = {};



    constructor(private formBuilder: FormBuilder,
        public db: DatabaseService,
        public redirect: Router,
        public route: ActivatedRoute,
        public toast: SnackBarOverview,
        public alert: DialogComponent,
        public session: SessionService,
        public navCtrl: NgxNavigationWithDataComponent,
        public datepipe: DatePipe,
        private atp: AmazingTimePickerService,
        public dialog: MatDialog
    ) {

        console.log('*** Navigation Data ***');
        console.log(this.navCtrl.get('data')); // it will console Virendra
        // console.log(this.navCtrl.data); // it will console whole data object here
console.log(this.data);

    }

    ngOnInit() {
        this.getCustomerList();
        this.getComplaintTypeList();
        this.getStateList();
        this.getPriorityList();
        this.getTechnicianList();
        this.onValidateFormHandler();
        this.onDropDownSettingHandler();
        this.getNatureProblemList();
        this.getTransactioneTypeList();
        console.log(this.getTransactioneTypeList);
        this.getFGList()
        console.log(this.registerForm.value.date);
        console.log(this.registerForm.value['date']);
        
        
    }

   
    getCustomerList() {
        this.isRequestInProcess = true;
        this.db.FetchData({
        }, 'customer/getCustomerList').subscribe(resultData => {
            console.log(resultData);
            setTimeout(() => {
                this.isRequestInProcess = false;
            }, 500);
            this.customerList = resultData[`customerList`];
            for (let index = 0; index < this.customerList.length; index++) {

                if(this.customerList[index].fg_no){
                    this.customerList[index].company_name = this.customerList[index].company_name + ' - ' + this.customerList[index].cust_code + ' - ' + (this.customerList[index].fg_no ? this.customerList[index].fg_no : '');
                    
                }
                else{
                    this.customerList[index].company_name = this.customerList[index].company_name + ' - ' + this.customerList[index].cust_code;
                     
                }


            }
        }, error => {
            console.log(error);
            this.isRequestInProcess = false;
            this.toast.openError('Something Went Wrong, Try Later', '');
        });
    }

    date: Date;
    expDate: any;
    dates: any;
    open() {
        const amazingTimePicker = this.atp.open();
        amazingTimePicker.afterClose().subscribe(time => {
            this.registerForm.value[`time`] = time;
        });
    }
    onGetPriorityWiseDate() {

        const priorityData = this.registerForm.value[`priority`];
        console.log(priorityData);
         const date1 =this.registerForm.value[`date`]
          console.log(date1);

        if (priorityData[0]['name'] == 'High') {

        this.date = moment(date1).local().toDate();
console.log(this.date);

            this.date.setDate(this.date.getDate() + 2);

            console.log('High');
            console.log(this.date);

            var expDate = this.date;

            var date = moment(new Date(expDate));
            this.dates = date.format("MM/DD/YYYY");
            console.log(this.dates);

        }
        if (priorityData[0]['name'] == 'Low') {

            this.date = moment(date1).local().toDate();

            this.date.setDate(this.date.getDate() + 4);
            console.log('Low');
            console.log(this.date);

            var expDate = this.date;

            var date = moment(new Date(expDate));
            this.dates = date.format("MM/DD/YYYY");
            console.log(this.dates);

        }
        if (priorityData[0]['name'] == 'Medium') {
            this.date = moment(date1).local().toDate();

            this.date.setDate(this.date.getDate() + 3);
            console.log('Medium');
            console.log(this.date);

            var expDate = this.date;

            var date = moment(new Date(expDate));
            this.dates = date.format("MM/DD/YYYY");
            console.log(this.dates);


        }
        if (priorityData[0]['name'] == 'Breakdown-ncr') {
            this.date = moment(date1).local().toDate();

            this.date.setDate(this.date.getDate() + 1);
            console.log('Breakdown-NCR');
            console.log(this.date);

            var expDate = this.date;

            var date = moment(new Date(expDate));
            this.dates = date.format("MM/DD/YYYY");
            console.log(this.dates);


        }
        if (priorityData[0]['name'] == 'Breakdown - ROI') {
            this.date = moment(date1).local().toDate();

            this.date.setDate(this.date.getDate() + 2);
            console.log('Breakdown-Rest of India');
            console.log(this.date);

            var expDate = this.date;

            var date = moment(new Date(expDate));
            this.dates = date.format("MM/DD/YYYY");
            console.log(this.dates);


        }
    }

    onSubmit() {
        this.submitted = true;

        console.log(this.registerForm);
        if (this.registerForm.invalid) {
            console.log('*** INVALID FORM ***');
            this.registerForm.get('company').markAsTouched();
            this.registerForm.get('project').markAsTouched();
            this.registerForm.get('requestType').markAsTouched();
            this.registerForm.get('complaintType').markAsTouched();
            // this.registerForm.get('description').markAsTouched();
            this.registerForm.get('fgNo').markAsTouched();
            this.registerForm.get('soNo').markAsTouched();
            this.registerForm.get('natureProblem').markAsTouched();
            this.registerForm.get('technician').markAsTouched();
            this.registerForm.get('date').markAsTouched();
            this.registerForm.get('time').markAsTouched();

            // this.registerForm.get('expectingDate').markAsTouched();
            // this.registerForm.get('time').markAsTouched();
            this.registerForm.get('priority').markAsTouched();
            this.registerForm.get('subordinates').markAsTouched();
            this.registerForm.get('street').markAsTouched();
            this.registerForm.get('stateName').markAsTouched();
            this.registerForm.get('districtName').markAsTouched();
            this.registerForm.get('city').markAsTouched();
            this.registerForm.get('pincode').markAsTouched();
            this.registerForm.get('transactionType').markAsTouched();

            return;

        } else {
            console.log('*** VALID FORM ***');


            let isAnyContactSelectedForTask = false;
            const contactSelectedData = [];
            if (this.projectContactList.length > 0) {

                for (let index = 0; index < this.projectContactList.length; index++) {

                    if (this.projectContactList[index].checked) {

                        isAnyContactSelectedForTask = true;
                        contactSelectedData.push(this.projectContactList[index]);
                    }
                }

            } else {

                isAnyContactSelectedForTask = true;
            }

            if (!isAnyContactSelectedForTask) {
                this.alert.error('Contact Information Required!');
                return false;
            }

            this.onAddFGDetailHandler();

            this.isRequestInProcess = true;

            const registerData = JSON.parse(JSON.stringify(this.registerForm.value));

            const inputData = {

                data: registerData
            };

            console.log(registerData[`expectingDate`]);
            console.log(registerData[`dates`]);



            inputData[`data`][`contactData`] = contactSelectedData;
            inputData[`data`][`customerId`] = registerData[`company`][0][`id`];


            // --separate FG and Company Name--


            if (registerData[`company`][0][`company_name`]) {
                if (registerData[`company`][0][`company_name`].search("- FG") != -1) {
                    console.warn('in else if condition');
                    console.log("entered");

                    let avr = registerData[`company`][0][`company_name`].indexOf("- FG");
                    registerData[`company`][0][`company_name`] = registerData[`company`][0][`company_name`].substring(0, avr - 1);
                    console.log(registerData);

                } else {
                    registerData[`company`][0][`company_name`] = registerData[`company`][0][`company_name`];

                }
            }


            // ----

            inputData[`data`][`customerName`] = registerData[`company`][0][`company_name`];
            inputData[`data`][`preventive_maintenance`] = registerData[`preventiveMaintenance`] ? registerData[`preventiveMaintenance`] : '0';

            inputData[`session`] = this.session.trico_user;
            console.log(this.customerList);
            console.log(inputData[`data`][`customerId`]);

            const customerIndex = this.customerList.findIndex(row => row.id == inputData[`data`][`customerId`]);

            console.log(customerIndex);

            inputData[`data`][`customerCode`] = this.customerList[customerIndex][`cust_code`];
            inputData[`data`][`customerMobile`] = this.customerList[customerIndex][`mobile`];

            inputData[`data`][`projectId`] = registerData[`project`][0][`id`];
            inputData[`data`][`projectName`] = registerData[`project`][0][`project_name`];

            if (registerData[`requestType`] == 'service_request') {

                inputData[`data`][`complaintTitle`] = '';
                //    registerData[`complaintType`][0][`title`]?registerData[`complaintType`][0][`title`]:'';

            } else {

                inputData[`data`][`complaintTitle`] = '';

            }


            inputData[`data`][`fgData`] = JSON.parse(JSON.stringify(this.fgStoredData));

            if (registerData[`technician`] && registerData[`technician`].length > 0) {

                inputData[`data`][`technicianId`] = registerData[`technician`][0][`id`];
                inputData[`data`][`technicianName`] = registerData[`technician`][0][`name`];

            } else {

                inputData[`data`][`technicianId`] = '';
                inputData[`data`][`technicianName`] = '';
            }



            if (registerData[`subordinates`] && registerData[`subordinates`].length > 0) {

                inputData[`data`][`subOrdinateData`] = registerData[`subordinates`];

            } else {

                inputData[`data`][`subOrdinateData`] = [];
            }



            inputData[`data`][`priority`] = registerData[`priority`][0][`name`];

            inputData[`data`][`stateName`] = registerData[`stateName`][0][`state_name`];
            inputData[`data`][`districtName`] = registerData[`districtName`][0][`district_name`];
            inputData[`data`][`city`] = registerData[`city`];
            inputData[`data`][`pincode`] = registerData[`pincode`];

            console.log(this.registerForm.value.expectingDate);

            this.datepipe.transform(this.dates, "yyyy-MM-dd hh:mm a");
            console.log(this.datepipe);

            console.log(this.dates);

            if(inputData[`data`][`date`])
            {
                inputData[`data`][`date`]=moment(inputData[`data`][`date`]).format('YYYY-MM-DD ');
            }
            console.log(inputData[`data`][`date`]);
            inputData[`data`][`date`]=inputData[`data`][`date`]+inputData[`data`][`time`]
            console.log(inputData[`data`][`date`]);

            // moment(this.dates).format('YYYY-MM-DD HH:mm:ss a');
            inputData[`data`][`expectingDate`] = this.datepipe.transform(this.dates, "yyyy-MM-dd hh:mm a");
            console.log(inputData[`data`][`expectingDate`]);

console.log(this.customerBasicDetail);

            inputData[`data`][`customerBasicDetail`] = this.customerBasicDetail;
            inputData[`data`][`projectBasicDetail`] = this.projectBasicDetail;
            inputData['session'] = this.session.trico_user;
console.log(inputData[`data`][`customerBasicDetail`]);

            console.log(inputData);
            
            this.db.FetchData(inputData, 'task/onSubmitTaskData')
                .subscribe(result => {

                    console.log(result);

                    this.isRequestInProcess = false;


                    if (result[`status`] == 'error') {

                        this.alert.error(result[`statusMessage`]);
                        this.submitted = false;

                    } else {
                        var task_type = inputData[`data`][`requestType`];
                        this.redirect.navigate(['/task_list/' + task_type]);
                        setTimeout(() => {
                            this.alert.success('Success', 'Task Saved successfully');
                        }, 500);
                    }

                }, error => {

                    this.isRequestInProcess = false;
                    this.toast.openError('Something Went Wrong, Try Later', '');
                    this.submitted = false;
                });
        }
    }


    onSelectFgDataHandler() {

        const fgData = this.registerForm.value[`fgNo`];
        console.log(fgData);


        if (fgData && fgData.length > 0) {

            console.log(fgData);
console.log(fgData[0].fg_no);

            const fgIndex = this.fgList.findIndex(row => row.fg_no == fgData[0].fg_no);
            console.log(fgIndex);
            console.log(this.fgList);
            
            this.fgBasicDetail = this.fgList[fgIndex];
            console.log(this.fgBasicDetail);
            

        } else {

            this.fgBasicDetail = {};
        }

    }

    onSelectSoDataHandler() {

        const soData = this.registerForm.value[`soNo`];

        if (soData && soData.length > 0) {

            const soIndex = this.fgList.findIndex(row => row.so_no == soData[0].so_no);
            this.soBasicDetail = this.fgList[soIndex];

        } else {

            this.soBasicDetail = {};
        }

    }

    onSelectNatureDataHandler() {

        const natureData = this.registerForm.value[`nature`];

        if (natureData && natureData.length > 0) {

            const natureIndex = this.natureList.findIndex(row => row.nature == natureData[0].nature);
            this.natureBasicDetail = this.natureList[natureIndex];

        } else {

            this.natureBasicDetail = {};
        }


    }

    onSelectServiceTypeHandler() {

        const transactionType = this.registerForm.value[`transaction_type`];

        if (transactionType && transactionType.length > 0) {

            const transactionIndex = this.transactionTypeList.findIndex(row => row.nature == transactionType[0].transaction_type);
            this.transactionTypeDetail = this.transactionTypeList[transactionIndex];

        } else {

            this.transactionTypeDetail = {};
        }


    }

    requestType: any = '';
    tempArrayData: any = [];
    onRequestTypeChangeHandler() {

        const requestTypeData = this.registerForm.value[`requestType`];
        const complaintTypeData = this.registerForm.value[`complaintType`];
        const natureProblem = this.registerForm.value[`natureProblem`];

        console.log(requestTypeData);

        // alert(requestTypeData);

        this.registerForm.get('transactionType').setValue('');
        this.serviceTypeList = [];

        if (requestTypeData == 'service_request') {

            console.log(this.transactionTypeList);

            for (let i = 0; i < this.transactionTypeList.length; i++) {
                if (this.transactionTypeList[i]['transaction_type'] != "Commissioning") {
                    this.serviceTypeList.push(this.transactionTypeList[i]);
                }
            }
            console.log(this.serviceTypeList);

            complaintTypeData.setValidators([Validators.required]);
            complaintTypeData.updateValueAndValidity();
            natureProblem.setValidators([Validators.minLength(3)]);
            natureProblem.updateValueAndValidity();

        }

        if (requestTypeData == 'commissioning') {
            for (let i = 0; i < this.transactionTypeList.length; i++) {
                console.log(this.transactionTypeList[i]['transaction_type']);
                if (this.transactionTypeList[i]['transaction_type'] == "Commissioning") {
                    this.serviceTypeList.push(this.transactionTypeList[i]);
                }
            }
            console.log(this.serviceTypeList);


        }

        // this.tempArrayData = Object.assign([],this.serviceTypeList)
        console.log('**** Service Type ****');
        console.log(this.tempArrayData);


    }

    onAddFGDetailHandler() {

        const fgData = this.registerForm.value[`fgNo`];
        const requestTypeData = this.registerForm.value[`requestType`];
        const natureProblem = this.registerForm.value[`natureProblem`];
        const soNoData = this.registerForm.value[`soNo`];
        const description = this.registerForm.value[`description`];
        const transactionType = this.registerForm.value[`transactionType`];

        console.log(transactionType);
        console.log(fgData);


        if (fgData && fgData.length > 0 && ((requestTypeData == 'service_request' && natureProblem) || (requestTypeData == 'commissioning'&& natureProblem)) && transactionType && soNoData && soNoData.length > 0) {

            const isFGExist = this.fgStoredData.findIndex(row => row.fgNo == fgData[0].fg_no);
            const isSOExist = this.fgStoredData.findIndex(row => row.soNo == soNoData[0].so_no);
            // const isServiceTypeExist = this.fgStoredData.findIndex(row => row.soNo == soNoData[0].so_no);

            console.log(isFGExist);



            console.log(isSOExist);
            if (requestTypeData == 'service_request') {

                if (isFGExist === -1 || isSOExist === -1) {

                    console.log(transactionType[0]);

                    this.fgStoredData.push({
                        fgNo: fgData[0].fg_no ? fgData[0].fg_no : '',
                        natureProblem: natureProblem[0].nature ? natureProblem[0].nature : '',
                        transactionType: transactionType[0].transaction_type ? transactionType[0].transaction_type : '',
                        description: description ? description : '',
                        soNo: soNoData[0].so_no ? soNoData[0].so_no : '',
                        fgBasicData: JSON.parse(JSON.stringify(this.fgBasicDetail)),

                    });

                    console.log(this.fgStoredData);
                    console.log(this.fgStoredData.length);




                    this.fgBasicDetail = {};
                    this.registerForm.get('fgNo').setValue([]);
                    this.registerForm.get('natureProblem').setValue('');
                    this.registerForm.get('soNo').setValue([]);
                    this.registerForm.get('description').setValue('');
                    this.registerForm.get('transactionType').setValue('');



                    const fgNoControl = this.registerForm.get('fgNo');
                    const natureProblemControl = this.registerForm.get('natureProblem');
                    const soNoControl = this.registerForm.get('soNo');
                    const transactionTypeControl = this.registerForm.get('transactionType');

                    console.log(transactionType);

                    fgNoControl.setValidators([]);
                    natureProblemControl.setValidators([Validators.minLength(3)]);
                    soNoControl.setValidators([]);
                    transactionTypeControl.setValidators([]);


                    fgNoControl.updateValueAndValidity();
                    natureProblemControl.updateValueAndValidity();
                    soNoControl.updateValueAndValidity();
                    transactionTypeControl.updateValueAndValidity();

                } else {

                    this.alert.error('Product Already Added!');

                }
            }
            if (requestTypeData == 'commissioning') {


                if (isFGExist === -1 || isSOExist === -1) {

                    this.fgStoredData.push({
                        natureProblem: natureProblem[0].nature ? natureProblem[0].nature : '',

                        fgNo: fgData[0].fg_no ? fgData[0].fg_no : '',
                        soNo: soNoData[0].so_no ? soNoData[0].so_no : '',
                        fgBasicData: JSON.parse(JSON.stringify(this.fgBasicDetail)),
                        transactionType: transactionType[0].transaction_type ? transactionType[0].transaction_type : '',

                    });

                    console.log(this.fgStoredData);
                    console.log(this.fgStoredData.length);



                    this.fgBasicDetail = {};
                    this.registerForm.get('fgNo').setValue([]);
                    this.registerForm.get('soNo').setValue([]);
                    // this.registerForm.get('natureProblem').setValue('');

                    this.registerForm.get('transactionType').setValue([]);


                    const fgNoControl = this.registerForm.get('fgNo');
                    const soNoControl = this.registerForm.get('soNo');
                    const transactionTypeControl = this.registerForm.get('transactionType');
                    const natureProblemControl = this.registerForm.get('natureProblem');


                    console.log(soNoControl);

                    fgNoControl.setValidators([]);
                    soNoControl.setValidators([]);
                    transactionTypeControl.setValidators([]);
                    natureProblemControl.setValidators([Validators.minLength(3)]);

                    fgNoControl.updateValueAndValidity();
                    soNoControl.updateValueAndValidity();
                    transactionTypeControl.updateValueAndValidity();


                } else {

                    this.alert.error('Product Already Added!');

                }
            }


        } else {

            if ((this.registerForm[`value`][`fgNo`] && this.registerForm[`value`][`fgNo`].length > 0) && (this.registerForm[`value`][`soNo`])) {

                console.log(this.registerForm);
                this.alert.error('Fill Required Information!');
            }
        }
    }



    onDeleteFGHandler(index) {

        this.alert.confirmation('').then((confirmResult) => {

            if (confirmResult) {

                this.fgStoredData.splice(index, 1);

                if (this.fgStoredData.length == 0) {

                    const fgNoControl = this.registerForm.get('fgNo');
                    const natureProblemControl = this.registerForm.get('natureProblem');
                    const serviceTypeControl = this.registerForm.get('serviceType');
                    console.log(serviceTypeControl);



                    fgNoControl.setValidators([Validators.required]);
                    natureProblemControl.setValidators([Validators.required, Validators.minLength(3)]);
                    serviceTypeControl.setValidators([Validators.required]);


                    fgNoControl.updateValueAndValidity();
                    natureProblemControl.updateValueAndValidity();
                    serviceTypeControl.updateValueAndValidity();

                }
            }
        });
    }


    onSameAsCorporateAddressHandler() {

        console.log(this.registerForm.value);

        console.log(this.projectBasicDetail);

        if (this.registerForm.value.sameAsCorporateAddress) {

            this.registerForm.get(`street`).setValue(this.projectBasicDetail[`street`]);

            this.registerForm.get(`stateName`).setValue([{
                state_name: this.projectBasicDetail[`state_name`]
            }]);

            this.registerForm.get(`districtName`).setValue([{
                district_name: this.projectBasicDetail[`district_name`]
            }]);


            this.registerForm.get(`city`).setValue(this.projectBasicDetail[`city`]);
            this.registerForm.get(`pincode`).setValue(this.projectBasicDetail[`pincode`]);

            console.log(this.registerForm);

            // this.getDistrictList(2);

        } else {

            this.registerForm.get('street').setValue('');
            this.registerForm.get('stateName').setValue([]);
            this.registerForm.get('districtName').setValue([]);
            this.registerForm.get('city').setValue('');
            this.registerForm.get('pincode').setValue('');

        }
    }



    onGetCustomerProjectList() {
        this.projectList = [];
        if (this.registerForm.value[`project`] && !this.navCtrl.get('data')) {
            this.registerForm.get('project').setValue([]);
        }

        this.customerBasicDetail = {};
        this.projectBasicDetail = {};

        this.projectContactList = [];
        console.log(this.registerForm.value);
        console.log(this.registerForm.value[`company`][0][`id`]);

        const customerId = this.registerForm.value[`company`][0][`id`];
         console.log(this.registerForm.value[`company`][0][`id`]);

        const inputData = {
            customerId: customerId,
            isDecrypt: true
        };
        console.log(inputData);
        this.db.FetchData(inputData, 'project/getProjectList').subscribe(resultData => {
            console.log(resultData);
            this.isRequestInProcess = false;
            this.projectList = resultData[`projectList`];
            console.log(this.customerList);
            
            const customerIndex = this.customerList.findIndex(row => row.id == customerId);
            console.log(this.customerList[customerIndex]);
            
            this.customerBasicDetail = this.customerList[customerIndex];
            console.log(this.customerBasicDetail);
console.log();

        }, error => {
            console.log(error);
            this.isRequestInProcess = false;
            this.toast.openError('Something Went Wrong, Try Later', '');
        });
    }



    getProjectContactList() {

        const projectId = this.registerForm.value[`project`][0][`id`];
        console.log(this.registerForm);

        const inputData = {
            projectId: projectId
        };
        console.log(inputData);

        this.db.FetchData(inputData, 'sharedData/getProjectContactList')
            .subscribe(result => {
                console.log(result);
                this.projectContactList = result;
                console.log(this.projectList);
                console.log(this.projectContactList);
                const projectIndex = this.projectList.findIndex(row => row.id == projectId);
                console.log(projectIndex);
                this.projectBasicDetail = this.projectList[projectIndex];
                console.log(this.projectBasicDetail);
                this.getSOList();
            }, error => {
                console.log(error);
            });
    }


    getStateList() {
        this.db.FetchData({}, 'address/getStateList')
            .subscribe(result => {
                console.log(result);
                this.stateList = result[`stateList`];
            }, error => {
                console.log(error);
            });
    }


    getDistrictList(target: any) {

        this.districtList = [];

        console.log(this.registerForm.value);
        this.registerForm.get('districtName').setValue([]);

        if (this.registerForm.value[`stateName`] && this.registerForm.value[`stateName`][0]) {

            const stateName = this.registerForm.value[`stateName`][0][`state_name`];

            this.db.FetchData({ stateName: stateName }, 'address/getDistrictList')
                .subscribe(result => {

                    console.log(result);
                    this.districtList = result[`districtList`];

                    if (target == 2) {

                        this.registerForm.get(`districtName`).setValue([{
                            district_name: this.customerBasicDetail[`district_name`]
                        }]);

                        console.log(this.registerForm);
                    }

                }, error => {
                    console.log(error);
                });
        }
    }


    getProjectList() {

        const customerId = 1;
        this.db.FetchData({ customerId: customerId }, 'sharedData/getProjectList')
            .subscribe(result => {

                console.log(result);
                this.projectList = result[`projectList`];

            }, error => {
                console.log(error);
            });
    }

    getSOList() {

        const inputData = {

            customerId: this.registerForm.value[`company`][0][`id`],
            projectId: this.registerForm.value[`project`][0][`id`],
            isDecrypt: true
        };

        this.db.FetchData(inputData, 'sharedData/getSOList')
            .subscribe(result => {

                console.log(result);
                this.soList = result[`soList`];
                console.log(this.soList);


            }, error => {
                console.log(error);
            });
    }


    getFGList() {

        console.log(this.registerForm.value[`soNo`]);

        if ((this.registerForm.value[`soNo`].length) > 0) {

            const inputData = {

                customerId: this.registerForm.value[`company`][0][`id`],
                projectId: this.registerForm.value[`project`][0][`id`],
                soNo: this.registerForm.value[`soNo`][0][`so_no`],
                isDecrypt: true

            };

            this.db.FetchData(inputData, 'sharedData/getFGList')
                .subscribe(result => {

                    console.log(result);
                    this.fgList = result[`fgList`];


                }, error => {
                    console.log(error);
                });


        }



    }

    getPriorityList() {
        this.db.FetchData({}, 'sharedData/getPriorityList')
            .subscribe(result => {
                console.log(result);
                this.priorityList = result[`priorityList`];
            }, error => {
                console.log(error);
            });
    }

    getNatureProblemList() {

        this.db.FetchData({}, 'sharedData/getNatureProblemList')
            .subscribe(result => {
                console.log(result);
                this.natureList = result[`natureList`];
            }, error => {
                console.log(error);
            });

    }

    getComplaintTypeList() {
        this.db.FetchData({}, 'sharedData/getComplaintTypeList')
            .subscribe(result => {
                console.log(result);
                this.complaintTypeList = result[`complaintTypeList`];
            }, error => {
                console.log(error);
            });
    }


    getTechnicianList() {
        this.db.FetchData({}, 'sharedData/getTechnicianList')
            .subscribe(result => {
                console.log(result);
                this.savedTechnicianList = result[`technicianList`];
                this.technicianList = JSON.parse(JSON.stringify(this.savedTechnicianList));
                this.subOrdinateList = JSON.parse(JSON.stringify(this.technicianList));

            }, error => {
                console.log(error);
            });
    }

    getTransactioneTypeList() {

        this.isRequestInProcess = true;

        this.db.FetchData({}, 'sharedData/getTransactioneTypeList')
            .subscribe(result => {
                console.log(result);

                this.isRequestInProcess = false;
                this.transactionTypeList = result[`transactionTypeList`];
                console.log(this.transactionTypeList);

            }, error => {

                this.isRequestInProcess = false;
            });


    }



    onChangeTechnicianHandler() {

        const technicianData = this.registerForm.value[`technician`];

        if (technicianData && technicianData.length > 0) {

            const technicianId = technicianData[0].id;
            this.subOrdinateList = this.savedTechnicianList.filter(row => row.id != technicianId);

        } else {

            this.subOrdinateList = [];
            this.registerForm.get('subordinates').setValue([]);
        }
    }

    onValidateFormHandler() {

        if (this.navCtrl.get('data')) {
            console.log('*** DATA SET ***');
            console.log(this.navCtrl.get('data'));
            var data = this.navCtrl.get('data');
            console.log(data.id);
            this.registerForm = this.formBuilder.group({
                preventiveMaintenance: [data['maintenance_id'], [Validators.required]],
                company: [[{ company_name: data[`customer_name`], id: data[`customer_id`] }], [Validators.required]],
                project: [[{ project_name: data[`project_name`], id: data[`project_id`] }], [Validators.required]],
                requestType: ['service_request', [Validators.required]],
                complaintType: [''],
                description: ['', [Validators.minLength(3)]],
                task_description: ['', [Validators.minLength(3)]],
                fgNo: [[{ fg_no: data[`fg_no`] }], [Validators.required]],
                natureProblem: [[{ nature: data[`nature`] }]],
                soNo: [[{ so_no: data[`so_no`], so_name: data[`so_no`] }], [Validators.required]],
                transactionType: ['', [Validators.required]],
                technician: [''],
                // time: ['', [Validators.required]],
                date: ['', [Validators.required]],
                time: ['', [Validators.required]],
                
                expectingDate: [this.dates],

                priority: ['', Validators.required],
                subordinates: [''],
                street: [''],
                stateName: ['', Validators.required],
                districtName: ['', Validators.required],
                city: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                sameAsCorporateAddress: [''],
                contactData: ['']
            });

            setTimeout(() => {
                this.onGetCustomerProjectList();
                this.getProjectContactList();
                setTimeout(() => {
                    this.onSelectFgDataHandler();
                }, 500);
            }, 800);

        }
        else {
            console.log('*** DATA NOT SET ***');
            this.registerForm = this.formBuilder.group({
                company: ['', [Validators.required]],
                project: ['', [Validators.required]],
                requestType: ['service_request', [Validators.required]],
                complaintType: [''],
                description: ['', [Validators.minLength(3)]],
                task_description: ['', [Validators.minLength(3)]],
                fgNo: ['', [Validators.required]],
                soNo: ['', [Validators.required]],
                natureProblem: [''],
                transactionType: ['', [Validators.required]],
                technician: [''],
                // time: ['', [Validators.required]],
                date: ['', [Validators.required]],
                time: ['', [Validators.required]],

                expectingDate: [''],
                priority: ['', Validators.required],
                subordinates: [''],
                street: [''],
                stateName: ['', Validators.required],
                districtName: ['', Validators.required],
                city: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                sameAsCorporateAddress: [''],
                contactData: ['']
            });
        }


    }

    onDropDownSettingHandler() {

        this.dropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'company_name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings1 = {
            singleSelection: true,
            idField: 'id',
            textField: 'project_name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings2 = {
            singleSelection: true,
            idField: 'title',
            textField: 'title',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings3 = {
            singleSelection: true,
            idField: 'fg_no',
            textField: 'fg_no',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };


        this.dropdownSettings4 = {

            singleSelection: true,
            idField: 'id',
            textField: 'name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };


        this.dropdownSettings5 = {

            singleSelection: true,
            idField: 'name',
            textField: 'name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings6 = {

            singleSelection: false,
            idField: 'id',
            textField: 'name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: false
        };

        this.dropdownSettings7 = {

            singleSelection: true,
            idField: 'state_name',
            textField: 'state_name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings8 = {

            singleSelection: true,
            idField: 'district_name',
            textField: 'district_name',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings9 = {
            singleSelection: true,
            idField: 'so_no',
            textField: 'so_no',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings10 = {
            singleSelection: true,
            idField: 'id',
            textField: 'nature',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };

        this.dropdownSettings11 = {
            singleSelection: true,
            idField: 'transaction_type',
            textField: 'transaction_type',
            allowSearchFilter: true,
            maxHeight: 197,
            closeDropDownOnSelection: true
        };
    }

    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }

    numberOnly(event): boolean {

        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    get f() { return this.registerForm.controls; }

    openDialog(): void {
        this.data = Object.assign({}, this.projectContactList);
        console.log(this.data);

        const dialogRef = this.dialog.open(ContactPersonModelComponent, {
            width: '750px',
            data: {
                data: this.data,
            }

        });

        dialogRef.afterClosed().subscribe(result => {
            this.getProjectContactList();


        });
    }


    // onFilterChange(event){
    //     console.log(event);
        
    // }

}
