import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatDividerModule, MatRadioModule, MatToolbarModule, MatInputModule, MatRippleModule, MatFormFieldModule, MatDatepickerModule, MatNativeDateModule, MatIconModule, MatSelectModule, MatCheckboxModule, MatDialogModule, MatTooltipModule, MatAutocompleteModule, MatMenuModule, MatChipsModule, MatButtonModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; //this to use ngModule
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { HeaderComponent } from './header/header.component';
import { LeftNavigationComponent } from './left-navigation/left-navigation.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerAddComponent } from './customer/customer-add/customer-add.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { SalesOrderAddComponent } from './sales-order/sales-order-add/sales-order-add.component';
import { TechniciansAddComponent } from './technicians/technicians-add/technicians-add.component';
import { TechniciansListComponent } from './technicians/technicians-list/technicians-list.component';
import { TechniciansDetailComponent } from './technicians/technicians-detail/technicians-detail.component';
import { ProductListComponent } from './master/product/product-list/product-list.component';
import { ProductAddComponent } from './master/product/product-add/product-add.component';
import { AddBrandModelComponent } from './master/product/add-brand-model/add-brand-model.component';
import { AddProductModelComponent } from './master/product/add-product-model/add-product-model.component';
import { AddSubProductModelComponent } from './master/product/add-sub-product-model/add-sub-product-model.component';
import { SpareListComponent } from './master/spare/spare-list/spare-list.component';
import { SpareAddComponent } from './master/spare/spare-add/spare-add.component';
import { ComplaintListComponent } from './master/complaint/complaint-list/complaint-list.component';
import { PriorityListComponent } from './master/priority/priority-list/priority-list.component';
import { UserListComponent } from './master/user/user-list/user-list.component';

import { UserAddComponent } from './master/user/user-add/user-add.component';
import { CallListComponent } from './call/call-list/call-list.component';
import { FeedbackListComponent } from './feedback/feedback-list/feedback-list.component';
import { TaskListComponent } from './task/task-list/task-list.component';
import { TaskAddComponent } from './task/task-add/task-add.component';
import { TaskDetailComponent } from './task/task-detail/task-detail.component';
import { AllActionModalComponent } from './task/all-action-modal/all-action-modal.component';
import { LoginComponent } from './login/login.component';
import { WarrantyListComponent } from './master/warranty/warranty-list/warranty-list.component';
import { ServiceCenterListComponent } from './master/service-center/service-center-list/service-center-list.component';
import { AddVendorComponent } from './master/vendor/add-vendor/add-vendor.component';
import { EmergencyContactListComponent } from './master/emergency-contact/emergency-contact-list/emergency-contact-list.component';
import { DocumentListComponent } from './master/documents/document-list/document-list.component';
import { CommissioningListComponent } from './master/commissioning/commissioning-list/commissioning-list.component';
import { ListVendorComponent } from './master/vendor/list-vendor/list-vendor.component';
import { DetailVendorComponent } from './master/vendor/detail-vendor/detail-vendor.component';
import { AssignEngineerModalComponent } from './task/assign-engineer-modal/assign-engineer-modal.component';
import { CustomerComplaintsListComponent } from './customer/customer-complaints-list/customer-complaints-list.component';
import { CustomerProjectListComponent } from './customer/customer-project-list/customer-project-list.component';
import { CustomerProjectAddComponent } from './customer/customer-project-add/customer-project-add.component';
import { CustomerProductListComponent } from './customer/customer-product-list/customer-product-list.component';
import { CustomerProductAddComponent } from './customer/customer-product-add/customer-product-add.component';
import { CustomerDocumentListComponent } from './customer/customer-document-list/customer-document-list.component';
import { CustomerDocumentAddComponent } from './customer/customer-document-add/customer-document-add.component';
import { CustomerCallRequestComponent } from './customer/customer-call-request/customer-call-request.component';
import { CustomerAmcListComponent } from './customer/customer-amc-list/customer-amc-list.component';
import { WarrantyRenewalComponent } from './warranty/warranty-renewal/warranty-renewal.component';
import { VendorComplaintListComponent } from './vendor/vendor-complaint-list/vendor-complaint-list.component';
import { VendorAddComponent } from './vendor/vendor-add/vendor-add.component';
import { WarrantyRenewalListComponent } from './warranty/warranty-renewal-list/warranty-renewal-list.component';
import { DialogComponent } from './_services/DialogComponent';
import { DatabaseService } from './_services/database.service';
import { HttpClientModule } from '@angular/common/http';
import { SafeUrlPipe } from './_services/safe-url.pipe';
import { NumberDirective } from './_directives/numbers-only.directive';
import { DocumentsModalComponent } from './master/documents/documents-modal/documents-modal.component';
import { SpareCategoryModalComponent } from './master/spare/spare-category-modal/spare-category-modal.component';
import { AddContactPersonModalComponent } from './task/add-contact-person-modal/add-contact-person-modal.component';
import { AddProductInformationModalComponent } from './task/add-product-information-modal/add-product-information-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VendorInformationModalComponent } from './master/vendor/vendor-information-modal/vendor-information-modal.component';
import { ContactPersonModalComponent } from './master/vendor/contact-person-modal/contact-person-modal.component';
import { ServiceReportComponent } from './task/service-report/service-report.component';
import { RemarkModalComponent } from './call/remark-modal/remark-modal.component';
import { SessionService } from './session.service';
import { AuthGuard } from './AuthGuard';
import { AuthGuardLog } from './AuthGuardLog';
import { DBRequest } from './databaseService';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CostomerDocumentModalOpenComponent } from './customer/costomer-document-modal-open/costomer-document-modal-open.component';
import { ChartModule } from 'angular-highcharts';
import { PmListComponent } from './preventive-maintenance/pm-list/pm-list.component';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { TechniciansEditModelComponent } from './technicians/technicians-edit-model/technicians-edit-model.component';
import { CustomerInfoModelComponent } from './customer/customer-info-model/customer-info-model.component';
import { ContactPersonModelComponent } from './task/contact-person-model/contact-person-model.component';
import { EmailModelComponent } from './task/email-model/email-model.component';
import { ChangeStatusModelComponent } from './customer/change-status-model/change-status-model.component';
import { CustomerContactPersonModalComponent } from './customer/customer-contact-person-modal/customer-contact-person-modal.component';
import { LoginRequestListComponent } from './login-request/login-request-list/login-request-list.component';
import { LoginRequestDetailComponent } from './login-request/login-request-detail/login-request-detail.component';
import { LoginChangeStatusModelComponent } from './login-request/login-change-status-model/login-change-status-model.component';
import { EditReportModelComponent } from './task/edit-report-model/edit-report-model.component';
import { ExportCommReportComponent } from './task/export-comm-report/export-comm-report.component';
import { TaskDetailModelComponent } from './task/task-detail-model/task-detail-model.component';
import { AttachmentModelComponent } from './vendor/attachment-model/attachment-model.component';
import { RatingFeedbackComponent } from './rating-feedback/rating-feedback.component';
import { CustomerFeedbackListComponent } from './customer-feedback/customer-feedback-list/customer-feedback-list.component';
import { CustomerFeedbackModelComponent } from './customer-feedback/customer-feedback-model/customer-feedback-model.component';
import { ThankuPageComponent } from './thanku-page/thanku-page.component';
import {DatePipe} from '@angular/common';
import { NatureOfProblemListComponent } from './master/nature-of-problem-list/nature-of-problem-list.component';
import { EngineerReportComponent } from './report/engineer-report/engineer-report.component';
import { MisReportComponent } from './report/mis-report/mis-report.component';
import { CustomerfeedbackreportComponent } from './customerfeedbackreport/customerfeedbackreport.component';
import { StluserComponent } from './master/stluser/stluser.component';
import { StluseraddComponent } from './master/stluseradd/stluseradd.component';
import { AssignstlmodalComponent } from './assignstlmodal/assignstlmodal.component';
import { OrderinspectionComponent } from './orderinspection/orderinspection.component';
import * as CryptoJS from 'crypto-js';
import { BnNgIdleService } from 'bn-ng-idle';
import { FeedbackdetailComponent } from './feedbackdetail/feedbackdetail.component';
import { FeedbackdocumentComponent } from './feedbackdocument/feedbackdocument.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FeedbackmailComponent } from './feedbackmail/feedbackmail.component';
import { EngineerdatetimemodelComponent } from './engineerdatetimemodel/engineerdatetimemodel.component';
import { LoginrequesteditComponent } from './loginrequestedit/loginrequestedit.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ProducteditComponent } from './productedit/productedit.component';
import { TaskreportComponent } from './taskreport/taskreport.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { InvoicereportComponent } from './invoicereport/invoicereport.component';
import { StarcustomerComponent } from './starcustomer/starcustomer.component';
import { CustomerStluserComponent } from './customer-stluser/customer-stluser.component';
import { ModelComponentOutOfMasterComponent } from './documents/model-component-out-of-master/model-component-out-of-master.component';
import { ReasonremarkComponent } from './task/reasonremark/reasonremark.component';
@NgModule({
  declarations: [
    DocumentsModalComponent,
    ModelComponentOutOfMasterComponent,
    CustomerStluserComponent,
    OrderinspectionComponent,
    AppComponent,
    StluserComponent,
    HeaderComponent,
    LeftNavigationComponent,
    CustomerListComponent,
    CustomerAddComponent,
    CustomerDetailComponent,
    SalesOrderAddComponent,
    TechniciansAddComponent,
    TechniciansListComponent,
    TechniciansDetailComponent,
    ProductListComponent,
    ProductAddComponent,
    AddBrandModelComponent,
    AddProductModelComponent,
    AddSubProductModelComponent,
    SpareListComponent,
    SpareAddComponent,
    ComplaintListComponent,
    PriorityListComponent,
    UserListComponent,
    UserAddComponent,
    CallListComponent,
    FeedbackListComponent,
    TaskListComponent,
    TaskAddComponent,
    TaskDetailComponent,
    AllActionModalComponent,
    RemarkModalComponent,
    LoginComponent,
    WarrantyListComponent,
    ServiceCenterListComponent,
    AddVendorComponent,
    EmergencyContactListComponent,
    DocumentListComponent,
    CommissioningListComponent,
    ListVendorComponent,
    DetailVendorComponent,
    AssignEngineerModalComponent,
    CustomerComplaintsListComponent,
    CustomerProjectListComponent,
    CustomerProjectAddComponent,
    CustomerProductListComponent,
    CustomerProductAddComponent,
    CustomerDocumentListComponent,
    CustomerDocumentAddComponent,
    CustomerCallRequestComponent,
    CustomerAmcListComponent,
    WarrantyRenewalComponent,
    VendorComplaintListComponent,
    VendorAddComponent,
    WarrantyRenewalListComponent,
    SafeUrlPipe,
    NumberDirective,
    SpareCategoryModalComponent,
    AddContactPersonModalComponent,
    AddProductInformationModalComponent,
    DashboardComponent,
    VendorInformationModalComponent,
    ContactPersonModalComponent,
    ServiceReportComponent,
    LoginComponent,
    CostomerDocumentModalOpenComponent,
    PmListComponent,
    TechniciansEditModelComponent,
    CustomerInfoModelComponent,
    ContactPersonModelComponent,
    EmailModelComponent,
    ChangeStatusModelComponent,
    CustomerContactPersonModalComponent,
    CustomerContactPersonModalComponent,
    LoginRequestListComponent,
    LoginRequestDetailComponent,
    LoginChangeStatusModelComponent,
    EditReportModelComponent,
    ExportCommReportComponent,
    TaskDetailModelComponent,
    ReasonremarkComponent,
    AttachmentModelComponent,
    RatingFeedbackComponent,
    CustomerFeedbackListComponent,
    CustomerFeedbackModelComponent,
    ThankuPageComponent,
    NatureOfProblemListComponent,
    EngineerReportComponent,
    MisReportComponent,
    CustomerfeedbackreportComponent,
    StluserComponent,
    StluseraddComponent,
    AssignstlmodalComponent,
    OrderinspectionComponent,
    FeedbackdetailComponent,
    FeedbackdocumentComponent,
    FeedbackmailComponent,
    EngineerdatetimemodelComponent,
    LoginrequesteditComponent,
    ProducteditComponent,
    TaskreportComponent,
    ChangepasswordComponent,
    InvoicereportComponent,
    StarcustomerComponent,
    ModelComponentOutOfMasterComponent,

  ],
  imports: [
    AmazingTimePickerModule,
    NgxMaterialTimepickerModule,
    AngularFontAwesomeModule,
    MatSlideToggleModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatSnackBarModule,
    ChartModule,
    RichTextEditorAllModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    SafeUrlPipe ,
  ],
  providers: [
    DialogComponent,
    DatabaseService,
    SessionService,
    AuthGuard,
    AuthGuardLog,
    DBRequest,
    BnNgIdleService,
    NgxNavigationWithDataComponent,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents:
  [
    DocumentsModalComponent,
    ModelComponentOutOfMasterComponent,
    ChangepasswordComponent,
    ProducteditComponent,
    LoginrequesteditComponent,
    EngineerdatetimemodelComponent,
    FeedbackdocumentComponent,
    AddBrandModelComponent,
    AddProductModelComponent,
    AddSubProductModelComponent,
    AllActionModalComponent,
    AssignEngineerModalComponent,
    CustomerDocumentAddComponent,
    WarrantyRenewalComponent,
    SpareCategoryModalComponent,
    AddContactPersonModalComponent,
    AddProductInformationModalComponent,
    VendorInformationModalComponent,
    ContactPersonModalComponent,
    RemarkModalComponent,
    WarrantyRenewalComponent,
    CostomerDocumentModalOpenComponent,
    TechniciansEditModelComponent,
    CustomerInfoModelComponent,
    ContactPersonModelComponent,
    EmailModelComponent,
    ChangeStatusModelComponent,
    CustomerContactPersonModalComponent,
    LoginRequestDetailComponent,
    EditReportModelComponent,
    LoginChangeStatusModelComponent,
    TaskDetailModelComponent,
    ReasonremarkComponent,
    AttachmentModelComponent,
    FeedbackmailComponent,
    CustomerFeedbackModelComponent,
    AssignstlmodalComponent
  ]
})
export class AppModule { }
