import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DBRequest } from 'src/app/databaseService';
import { DialogComponent } from 'src/app/dialog';
import { FeedbackmailComponent } from 'src/app/feedbackmail/feedbackmail.component';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-assign-engineer-modal',
  templateUrl: './assign-engineer-modal.component.html',
  styleUrls: ['./assign-engineer-modal.component.scss']
})
export class AssignEngineerModalComponent implements OnInit {

  bodyText:any;
  data:any={};
  vendorContactEmail:any=[];
  ContactpersonEmail:any=[];
  techList2:any=[];
vendor:any=[];
list:any=[];

  constructor(
    public db: DatabaseService,
    public dbService: DBRequest,
      public router:Router,
      public route : ActivatedRoute,
      // public dialogRef: MatDialog,
      public dialogs: MatDialog,
      public routes: Router,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      public dialogAlert: DialogComponent,
      public dialogRef: MatDialogRef<AssignEngineerModalComponent>
      )
      {
        this.data = modalData;
        console.log(modalData);






      }

  ngOnInit() {
  }


  updatestatus() {


    this.dbService.onPostRequestData({'data':this.data,'type':this.list.feedtype}, 'Customer/update_feedback_type')
        .subscribe(result => {

            console.log(result);


            if(result &&this.list.feedtype=="ONSITE"){
              this.routes.navigate(['/rating-feedback/004/'+this.data.cust_code+'/'+this.data.id+'/'+this.data.stl_code+'/'+this.data.so_no])

              // window.open('/rating-feedback/004/'+this.data.cust_code+'/'+this.data.id+'/'+this.data.stl_code+'/'+this.data.so_no);
            this.dialogRef.close();
              // this.routes.navigate(["/customer-feedback/feedback_inspection"])

            }
            else{
               this.dialogRef.close();
            this.routes.navigate(["/customer-feedback/feedback_inspection"])
            }


        }, error => {
            console.log(error);
        });

}



}

